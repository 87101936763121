import { Outlet, NavLink, useNavigation } from 'react-router-dom'
import { LoadingSpinner } from '~/Loading'

const settingsTabs = [
  { name: 'Profile', href: '/settings/profile' },
  { name: 'Edit bio', href: '/settings/edit-bio' },
  { name: 'Working hours', href: '/settings/availability' },
  { name: 'Calendar integrations', href: '/settings/calendars/core' },
  { name: 'Billing details', href: '/settings/billing' },
  { name: 'Security', href: '/settings/security' },
  // { name: 'Notifications', href: '/settings/notifications' },
]

export const Settings: React.FC = () => {
  const { state } = useNavigation()

  return (
    <>
      <header className="border-b border-black/5">
        <nav className="flex overflow-x-auto py-4">
          <ul
            role="list"
            className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
          >
            {settingsTabs.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    isActive ? 'text-primary-400' : ''
                  }
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      {state === 'idle' ? (
        <Outlet />
      ) : (
        <div className="mt-5">
          <LoadingSpinner />
        </div>
      )}
    </>
  )
}
