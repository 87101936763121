/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, type LoaderFunctionArgs } from 'react-router-dom'
import { api } from '~/app/api'

interface Root {
  data: Data
}

export interface Data {
  userAssessmentAnswers: UserAssessmentAnswers
  managerAssessmentAnswers: ManagerAssessmentAnswers
  reporteeAssessmentAnswers: ReporteeAssessmentAnswers
  peerAssessmentAnswers: PeerAssessmentAnswers
  leadershipValues: any
}

export interface UserAssessmentAnswers {
  initial: Initial[]
  midpoint: any[]
}

export interface Initial {
  assessmentDate: string
  shareWithUser: boolean
  surveyAnswers: SurveyAnswer[]
  ratings: Rating[]
  doneBy: string
}

export interface SurveyAnswer {
  question: string
  description: string
  answers: string[]
}

export interface Rating {
  focusArea: string
  rating: number
}

export interface ManagerAssessmentAnswers {
  initial: any[]
  midpoint: Midpoint[]
}

export interface Midpoint {
  assessmentDate: string
  shareWithUser: boolean
  surveyAnswers: SurveyAnswer2[]
  ratings: any[]
  doneBy: string
  author?: string
}

export interface SurveyAnswer2 {
  question: string
  answers: string[]
  description?: string
}

export interface ReporteeAssessmentAnswers {
  midpoint: Midpoint2[]
  initial: any[]
}

export interface Midpoint2 {
  assessmentDate: string
  shareWithUser: boolean
  surveyAnswers: SurveyAnswer3[]
  ratings: any[]
  author: string
  doneBy: string
}

export interface SurveyAnswer3 {
  question: string
  description?: string
  answers: string[]
}

export interface PeerAssessmentAnswers {
  initial: any[]
  midpoint: Midpoint3[]
}

export interface Midpoint3 {
  assessmentDate: string
  shareWithUser: boolean
  surveyAnswers: SurveyAnswer4[]
  ratings: any[]
  author: string
  doneBy: string
}

export interface SurveyAnswer4 {
  question: string
  description?: string
  answers: string[]
}

const fetchAssessments = (id: string) =>
  queryOptions({
    queryKey: ['users', id, 'assessment', 'results'],
    queryFn: () =>
      api.url(`/coach/customers/${id}/assessment-results`).get().json<Root>(),
  })

export const loader =
  (queryClient: QueryClient) => (args: LoaderFunctionArgs) => {
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return defer({
      data: queryClient
        .fetchQuery(fetchAssessments(id))
        .then((data) => data.data),
    })
  }
