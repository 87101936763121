import { useContext, useEffect, useMemo } from 'react'
import { mixpanelContext } from '~/utils/mixpanel'
import { useAuth } from './useAuth'

export const useMixpanel = () => {
  const mixpanel = useContext(mixpanelContext)
  return useMemo(() => mixpanel, [mixpanel])
}

export const useIdentifyMixpanel = () => {
  const mixpanel = useMixpanel()
  const { id, token, name, isTeamCoach, email, isTestCoach, loggedInAsAdmin } =
    useAuth()

  useEffect(() => {
    if (token.length === 0 || loggedInAsAdmin) return
    mixpanel.identify(id)
    mixpanel.people.set({
      $name: name,
      $email: email,
      $user_id: id,
      is_team_coach: isTeamCoach,
      is_test_coach: isTestCoach,
    })
  }, [
    mixpanel,
    id,
    token,
    name,
    email,
    loggedInAsAdmin,
    isTeamCoach,
    isTestCoach,
  ])
}
