/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFieldArray } from 'react-hook-form'
import type { UseFormRegister, Control } from 'react-hook-form'
import { Input } from './Input'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Button } from './Button'

interface FieldArrayProps {
  control: Control<any, any>
  register: UseFormRegister<any>
  name: string
  label: string
  placeholder?: string
}

type Props = FieldArrayProps & React.PropsWithChildren

export const FieldArray: React.FC<Props> = (props) => {
  const { name, control, register, label, placeholder } = props
  const { fields, append, remove } = useFieldArray({ control, name })

  return (
    <div className="col-span-full">
      {fields.length === 0 ? (
        <div>
          <label
            htmlFor={name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
          {props.children}
        </div>
      ) : (
        fields.map((field, index) => (
          <div key={field.id} className="flex justify-between gap-3 items-end">
            <Input
              id={field.id}
              containerClassName="flex-1"
              type="text"
              label={index === 0 ? label : undefined}
              placeholder={placeholder}
              {...register(`${name}.${index}`)}
            />
            <button
              type="button"
              className="pb-2"
              onClick={() => remove(index)}
            >
              <TrashIcon className="h-6 w-6 text-red-600" />
            </button>
          </div>
        ))
      )}

      <Button
        type="button"
        variant="outline"
        className="mt-2 py-1 px-2"
        onClick={() => append('', { shouldFocus: true })}
      >
        Add {props.label}
      </Button>
    </div>
  )
}
