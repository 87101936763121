import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect } from 'react-router-dom'
import { api } from '~/app/api'
import { authenticated } from '~/app/cookie'

export type Invoice = {
  _id: string
  date: string
  description: string
  amount: number
  currency: string
  status: string
  reportURL: string
  id: string
}

const fetchInvoices = () =>
  queryOptions({
    queryKey: ['invoices'],
    queryFn: () => api.url('/coach/invoices').get().json<Invoice[]>(),
  })

export const invoicesLoader = (queryClient: QueryClient) => () => {
  if (!authenticated()) {
    return redirect('/auth?mode=login')
  }

  return queryClient.fetchQuery(fetchInvoices())
}
