import { Fragment } from 'react'
import { createPortal } from 'react-dom'
import { Link } from 'react-router-dom'
import { Transition } from '@headlessui/react'
import { UserCircleIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { clearNotifications } from '~/store/ui.slice'

export interface NotificationProps {
  picture?: string
  name: string
  message: string
  replyLink: string
  isGroup?: boolean
}

export const NotificationProvider: React.FC<React.PropsWithChildren> = () => {
  const [props] = useAppSelector((state) => state.ui.notifications)

  return createPortal(
    <div
      aria-live="assertive"
      className="pointer-events-none fixed z-50 inset-0 flex items-end px-4 py-6 sm:items-start sm:top-14 sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {props && <Notification {...props} />}
      </div>
    </div>,
    document.body,
  )
}

export const Notification: React.FC<NotificationProps> = (props) => {
  const { picture, name, message, replyLink, isGroup = false } = props

  const dispatch = useAppDispatch()

  return (
    <Transition.Root
      show
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto flex w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="w-0 flex-1 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              {!picture ? (
                isGroup ? (
                  <UserGroupIcon className="h-10 w-10 rounded-full text-gray-400" />
                ) : (
                  <UserCircleIcon className="h-10 w-10 rounded-full text-gray-400" />
                )
              ) : (
                <img
                  className="h-10 w-10 rounded-full"
                  src={picture}
                  alt={name}
                  referrerPolicy="no-referrer"
                />
              )}
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">{name}</p>
              <p className="mt-1 text-sm text-gray-500">{message}</p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <Link
            to={replyLink}
            onClick={() => dispatch(clearNotifications())}
            className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-gray-800 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-primary"
          >
            Reply
          </Link>
        </div>
      </div>
    </Transition.Root>
  )
}
