import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '~/components/Button'
import { useAuth } from '~/hooks/useAuth'
import { GoogleIcon } from '~/icons/GoogleIcon'
import googleCalendarIcon from '~/assets/google-calendar.svg'

const GOOGLE_CALENDAR_SCOPES = [
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar.events',
]

export const NewCalendar: React.FC = () => {
  const { id, teamId: teamCoachId, groupId: groupCoachId } = useAuth()
  const { type } = useParams<{ type: AppType }>()

  const coachId = useMemo(() => {
    switch (type) {
      case 'team':
        return teamCoachId
      case 'group':
        return groupCoachId
      default:
        return id
    }
  }, [id, teamCoachId, type, groupCoachId])

  const callbackUri = useMemo(() => {
    switch (type) {
      case 'core':
        return import.meta.env.VITE_GOOGLE_CALLBACK_URL
      case 'team':
        return import.meta.env.VITE_GOOGLE_CALLBACK_URL_TEAM
      case 'group':
        return import.meta.env.VITE_GOOGLE_CALLBACK_URL_GROUP
      default:
        return import.meta.env.VITE_GOOGLE_CALLBACK_URL
    }
  }, [type])

  const syncGoogleCalendar = useCallback(
    (args: {
      clientId: string
      callbackUri: string
      coachId: string
      type: string
    }) => {
      const redirectUri = `/settings/calendars/${args.type}?success=1`

      const params = {
        client_id: args.clientId,
        redirect_uri: args.callbackUri,
        scope: GOOGLE_CALENDAR_SCOPES.join(' '),
        state: JSON.stringify({
          coachId: args.coachId,
          redirect: redirectUri,
        }),
        access_type: 'offline',
        prompt: 'consent',
        // include_granted_scopes: 'true',
        response_type: 'code',
      }

      const form = document.createElement('form')
      form.setAttribute(
        'action',
        'https://accounts.google.com/o/oauth2/v2/auth',
      )
      form.setAttribute('method', 'GET')

      for (const p in params) {
        const input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', p)
        input.setAttribute('value', params[p as keyof typeof params])
        form.appendChild(input)
      }

      document.body.appendChild(form)
      form.submit()
    },
    [],
  )

  return (
    <div className="max-w-3xl mx-auto mt-5 rounded-md bg-gray-50 py-5">
      <div className="px-5">
        <h3 className="font-bold text-lg">Select Calendar</h3>
        <p className="text-sm text-gray-500">
          Connect your calendar to let Hupo know when you're available and
          update your calendar as events are scheduled.
        </p>

        <p className="mt-5 text-sm text-gray-900">
          We don't retrieve any of your personal information. We only use it to
          show your availability and to add/update your calendar when sessions
          are scheduled.
        </p>
      </div>

      <div className="mt-4 bg-[#eee] px-5 py-2">
        <div className="flex items-center gap-2">
          <GoogleIcon className="h-5" />
          <span>Google</span>
        </div>
      </div>

      <div className="px-5 mt-5">
        <div className="flex items-center justify-between">
          <div className="flex gap-3 items-center">
            <img className="h-5" src={googleCalendarIcon} alt="Google icon" />
            <span>Google Calendar</span>
          </div>

          <span className="text-gray-500">Gmail, G Suite</span>

          <Button
            onClick={() => {
              syncGoogleCalendar({
                clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
                callbackUri,
                coachId,
                type: type ?? 'core',
              })
            }}
          >
            Connect
          </Button>
        </div>
      </div>
    </div>
  )
}
