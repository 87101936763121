import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

interface Bio {
  summary: string
  full: string
  licenses: string[]
  background: string[]
  focusArea: string[]
  industry: string[]
  languages: string[]
  style: string[]
  philosophy: string
}

interface Root {
  bio: Bio
  _id: string
  title: string
  language: string
  coach: string
  createdAt: string
  updatedAt: string
  __v: number
}

const fetchBio = () =>
  queryOptions({
    queryKey: ['bio'],
    queryFn: () => api.url('/coach/bio').get().json<Root>(),
  })

export const loader = (queryClient: QueryClient) => async () => {
  return await queryClient.fetchQuery(fetchBio())
}
