/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { api } from '~/app/api'
import { useAppDispatch } from '~/app/hooks'
import { setNps } from '~/store/nps.slice'

interface NPS {
  _id: string
  answers: any[]
  status: string
  coach: string
  survey: string
  type: string
  createdAt: string
  updatedAt: string
  meta?: {
    reminder?: boolean
  }
}

interface NPSSurvey {
  _id: string
  questions: Question[]
  languages: string[]
  steps: any[]
  singlePage: boolean
  name: string
  description: string
}

interface Survey {
  _id: string
}

export interface Question {
  _id: string
  optional: boolean
  hasOtherText: boolean
  options: Option[]
  title: string
  friendlyID: string
  type: string
  createdAt: string
  updatedAt: string
  placeholder?: string
}

export interface Option {
  children: any[]
  _id: string
  description?: string
  surveyQuestion?: string
  label: string
  value: string
  createdAt: string
  updatedAt: string
}

interface Args {
  openSlideOver: React.Dispatch<React.SetStateAction<boolean>>
  delay?: number
}

export const useInitNps = ({ delay = 1, openSlideOver }: Args) => {
  const dispatch = useAppDispatch()
  const [forceOpen, setForceOpen] = useState(false)
  const [start, setStart] = useState(false)
  const [surveyId, setSurveyId] = useState<string | null>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setStart(true)
    }, delay * 1000)
    return () => clearTimeout(timer)
  }, [delay])

  const { data: survey, isSuccess } = useQuery({
    enabled: start && !!surveyId,
    queryKey: ['init', 'nps', surveyId],
    queryFn() {
      return api.url(`/coach/nps/${surveyId}`).get().json<{
        data: NPSSurvey
      }>()
    },
  })

  const { data } = useQuery({
    enabled: start,
    queryKey: ['init', 'data'],
    initialData: { data: [], survey: null },
    queryFn() {
      return api.url('/coach/nps').get().json<{
        data: NPS[]
        survey: Survey | null
      }>()
    },
  })

  useEffect(() => {
    if (data.survey) {
      setSurveyId(data.survey._id)
    }

    if (data.data.length > 0) {
      dispatch(
        setNps({
          sessionId: data.data[0]._id,
          hasPendingNps: true,
        }),
      )

      if (data.data[0].meta?.reminder) {
        setForceOpen(true)
      }
    } else {
      dispatch(setNps({ sessionId: '', hasPendingNps: false }))
    }
  }, [data.data, data.survey, dispatch, openSlideOver])

  useEffect(() => {
    if (!survey) return
    dispatch(
      setNps({
        surveyId: survey.data._id,
        name: survey.data.name,
        questions: survey.data.questions,
      }),
    )
  }, [survey, dispatch])

  useEffect(() => {
    if (isSuccess && forceOpen) {
      openSlideOver(true)
    }
  }, [isSuccess, forceOpen, openSlideOver])
}
