import { useParams } from 'react-router-dom'
import { UserCircleIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import type {
  ChatUser as ChatUserType,
  TeamChatUser,
} from '~/routes/_loaders/chats'
import { cn } from '~/utils/cn'
import { timeAgo } from '~/utils/time'

interface ChatUserProps {
  data: ChatUserType | TeamChatUser
  onClick: (id: string) => void
}

export const ChatUser: React.FC<ChatUserProps> = (props) => {
  const { id } = useParams<{ id: string }>()
  const { data, onClick } = props

  return (
    <li
      className={cn(
        'flex gap-x-4 py-3 px-4 cursor-pointer hover:bg-gray-100 select-none active:bg-gray-200 items-center',
        // @ts-expect-error TODO: use same interface
        id === (data?.user?.id ?? data?.chat?.id) ? '!bg-[#ff480b13]' : '',
      )}
      // @ts-expect-error TODO: use same interface
      onClick={() => onClick(data?.user?.id ?? data?.chat?.id)}
    >
      <div className="relative min-w-[15%]">
        {/* @ts-expect-error TODO: use same interface */}
        {data?.user?.picture ?? data?.chat?.picture ? (
          <img
            className="h-10 w-10 object-cover flex-none rounded-full bg-gray-50"
            // @ts-expect-error TODO: use same interface
            src={data?.user?.picture ?? data?.chat?.picture}
            // @ts-expect-error TODO: use same interface
            alt={data?.user?.name ?? data?.chat?.title}
          />
        ) : // @ts-expect-error TODO: use same interface
        data?.chat?.isPrivate === false ? (
          <UserGroupIcon className="p-1 h-10 w-10 flex-none border-gray-300 border rounded-full text-gray-400" />
        ) : (
          <UserCircleIcon className="h-10 w-10 flex-none rounded-full text-gray-400" />
        )}
        {data.unread > 0 && (
          <span className="absolute -top-2 -right-2 rounded-full bg-red-500 w-6 h-6 flex items-center justify-center text-white text-xs">
            {data.unread > 99 ? '99+' : data.unread}
          </span>
        )}
      </div>
      <div className="flex-auto">
        <div className="flex items-baseline justify-between gap-x-4">
          <p
            className={cn(
              'text-sm font-semibold text-gray-900',
              // @ts-expect-error TODO: use same interface
              data?.chat?.title && 'text-xs',
            )}
          >
            {/* @ts-expect-error TODO: use same interface */}
            {data?.user?.name ?? data?.chat?.title}
          </p>
          {data.lastMessage?.date && (
            <p className="flex-none text-[11px] text-gray-400">
              <time dateTime={data.lastMessage.date}>
                {timeAgo(data.lastMessage.date)}
              </time>
            </p>
          )}
        </div>
        {data.lastMessage?.text && (
          <p className="mt-1 line-clamp-1 text-xs text-gray-400">
            {data.lastMessage.text}
          </p>
        )}
      </div>
    </li>
  )
}
