import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { api } from '~/app/api'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { useToast } from '~/components/ui/use-toast'
import { AuthHeader } from './_components/AuthHeader'

type Inputs = {
  email: string
}

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation('auth')
  const { toast } = useToast()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>()

  const { mutate, isPending } = useMutation({
    async mutationFn(data: Inputs) {
      await api.url('/coach/forgot-password').post(data).json<{ ok: true }>()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: t('forgot_password.email_sent'),
        variant: 'success',
      })
    },
  })

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <AuthHeader title={`${t('forgot_password.title')}?`} />

      <div className="mt-10 px-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:px-12 rounded-lg">
          <form
            className="space-y-4"
            onSubmit={handleSubmit((data) => mutate(data))}
          >
            <Input
              id="reset-email"
              type="email"
              label="Enter your email"
              required
              error={errors.email?.message}
              {...register('email', { required: true })}
            />

            <p className="text-sm text-gray-500">
              {/* You'll get an email with a reset link. */}
              {t('forgot_password.instructions')}
            </p>
            <div>
              <Button
                className="w-full"
                type="submit"
                loading={isSubmitting || isPending}
              >
                {t('forgot_password.submit')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
