import { useEffect, useState } from 'react'
import {
  useLoaderData,
  useNavigate,
  useParams,
  useRevalidator,
  useSearchParams,
} from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  CalendarDaysIcon,
  CalendarIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import googleCalendarIcon from '~/assets/google-calendar.svg'
import { loader, type Calendar } from './_loaders/calendars'
import { Button } from '~/components/Button'
import { AlertDialog } from '~/components/AlertDialog'
import { BasicDialog } from '~/components/BasicDialog'
import { useToast } from '~/components/ui/use-toast'
import { Tabs, TabsList, TabsTrigger } from '~/components/ui/tabs'
import { useAuth } from '~/hooks/useAuth'
import { CoachCalendarAlert } from './_components/CoachCalendarAlert'
import { ActiveCalendarModal } from './_components/ActiveCalendarModal'
import { getApi } from '~/utils/getApi'
import { cn } from '~/utils/cn'

const getCheckedCalendars = (calendar: Calendar) =>
  calendar.calendarList.filter(({ checkConflicts }) => checkConflicts)

export const CalendarSettings: React.FC = () => {
  const { revalidate } = useRevalidator()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { isTeamCoach, isGroupCoach } = useAuth()
  const { type = 'core' } = useParams<{ type: AppType }>()
  const navigate = useNavigate()
  const [showCoachAlert, setShowCoachAlert] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { activeCalendar, calendars } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  useEffect(() => {
    const justCreatedTeamAccount = searchParams.get('justCreated') === 'true'
    const success = searchParams.get('success')
    if (typeof success === 'string' && success === '1') {
      toast({
        title: 'Google Calendar connected',
        description: 'Your Google Calendar has been successfully connected.',
        variant: 'success',
      })
      setSearchParams()
    }

    if (justCreatedTeamAccount) setShowCoachAlert(true)
  }, [searchParams, setSearchParams, toast])

  const { mutate: deleteCalendar } = useMutation({
    mutationKey: [type, 'calendars', 'delete'],
    async mutationFn(calendarId: string) {
      await getApi(type).url(`/coach/calendars/${calendarId}`).delete().json()
    },
    async onSuccess() {
      toast({
        title: 'Success',
        description: 'Calendar deleted successfully',
        variant: 'success',
      })
      await queryClient.invalidateQueries({
        queryKey: [type, 'calendars'],
        exact: true,
      })
      revalidate()
    },
    onSettled() {
      setIsDeleteWarningModalOpen(false)
    },
  })

  const { mutate: updateConflictChecking } = useMutation({
    mutationKey: [type, 'calendars', 'update', 'conflict-checking'],
    async mutationFn({
      calendarId,
      calendarIds,
    }: {
      calendarId: string
      calendarIds: string[]
    }) {
      await getApi(type)
        .url(`/coach/calendars/${calendarId}`)
        .put({ conflictList: calendarIds })
        .json()
    },
    async onSuccess() {
      toast({
        title: 'Success',
        description: 'Conflict checking updated successfully',
        variant: 'success',
      })
      await queryClient.invalidateQueries({
        queryKey: [type, 'calendars'],
        exact: true,
      })
      revalidate()
    },
    onSettled() {
      setIsConflictCheckingModalOpen(false)
    },
  })

  const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] =
    useState(false)
  const [isConflictCheckingModalOpen, setIsConflictCheckingModalOpen] =
    useState(false)
  const [isChangeActiveCalendarModalOpen, setIsChangeActiveCalendarModalOpen] =
    useState(false)

  const [selectedCalendar, setSelectedCalendar] = useState<Calendar | null>(
    null,
  )

  return (
    <>
      {(isTeamCoach || isGroupCoach) && (
        <div className="sm:max-w-xs max-sm:mx-auto mt-5 px-4 lg:px-8">
          <Tabs
            defaultValue={type}
            onValueChange={(value) => navigate(`/settings/calendars/${value}`)}
          >
            <TabsList
              className={cn(
                'grid w-full',
                isTeamCoach && isGroupCoach ? 'grid-cols-3' : 'grid-cols-2',
              )}
            >
              <TabsTrigger value="core">Individual</TabsTrigger>
              {isTeamCoach && <TabsTrigger value="team">Team</TabsTrigger>}
              {isGroupCoach && <TabsTrigger value="group">Group</TabsTrigger>}
            </TabsList>
          </Tabs>
        </div>
      )}

      <div className="divide-y divide-black/5">
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7">
              My Calendar Accounts
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              Set your working hours and the number of slots you want to make
              available for booking.
            </p>
          </div>

          <form className="md:col-span-2">
            {calendars.length > 0 ? (
              <div className="shadow-md rounded-md p-3 space-y-2">
                {calendars.map((calendar) => (
                  <div
                    key={calendar.id}
                    className="border rounded-lg flex justify-between p-3"
                  >
                    <div className="flex items-center gap-3">
                      <img
                        className="max-h-8"
                        src={googleCalendarIcon}
                        alt="Icon"
                      />

                      <div>
                        <p className="capitalize">
                          {calendar.integrationType.toLowerCase()}
                        </p>
                        <span className="text-gray-500 text-sm">
                          {calendar.externalId}
                        </span>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="px-2.5 rounded-md"
                      onClick={() => {
                        setSelectedCalendar(calendar)
                        setIsDeleteWarningModalOpen(true)
                      }}
                    >
                      <TrashIcon className="h-5" />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p>No calendar connected yet</p>
            )}

            <div className="mt-10 sm:mt-4 flex">
              <Button
                to={`/settings/calendars/${type}/new`}
                className="w-full sm:w-1/2 flex items-center gap-4"
              >
                <PlusIcon className="h-5" /> Add calendar account
              </Button>
            </div>
          </form>
        </div>

        {calendars.length > 0 && (
          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div>
              <h2 className="text-base font-semibold leading-7">
                Configuration
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-400">
                Set your working hours and the number of slots you want to make
                available for booking.
              </p>
            </div>

            <form className="md:col-span-2">
              <div className="shadow-md rounded-md p-3">
                <div className="flex gap-5 max-sm:flex-col justify-between p-3">
                  <div className="flex-1 flex items-start gap-3">
                    <CalendarDaysIcon className="h-8" />

                    <div>
                      <p>Check for conflicts</p>
                      <span className="text-gray-500 text-sm">
                        Set the calendar(s) to check for conflicts to prevent
                        double bookings.
                      </span>
                    </div>
                  </div>

                  <div className="flex-1 space-y-2">
                    {calendars.map((calendar) => (
                      <div
                        key={calendar.id}
                        className="flex items-center border rounded-lg hover:cursor-pointer hover:border-primary px-2 py-3"
                        onClick={() => {
                          setSelectedCalendar(calendar)
                          setIsConflictCheckingModalOpen(true)
                        }}
                      >
                        <div className="flex items-start gap-3">
                          <CalendarIcon className="h-5" />

                          <div className="text-sm">
                            <p className="text-gray-500">
                              Check {calendar.externalId}
                            </p>

                            {getCheckedCalendars(calendar).length === 0 ? (
                              <p>Don't check calendars for conflicts</p>
                            ) : (
                              getCheckedCalendars(calendar).map((cal) => (
                                <ul key={cal._id} className="list-disc ml-4">
                                  <li>{cal.summary}</li>
                                </ul>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="my-3 border-[0.5px]" />

                <div className="flex gap-5 max-sm:flex-col justify-between p-3">
                  <div className="flex-1 flex items-start gap-3">
                    <CalendarDaysIcon className="h-8" />

                    <div>
                      <p>Add to calendar</p>
                      <span className="text-gray-500 text-sm">
                        Set the calendar you would like to add new sessions to
                        as they’re scheduled.
                      </span>
                    </div>
                  </div>

                  <div
                    className="flex-1 flex items-center border rounded-lg hover:cursor-pointer hover:border-primary px-2 py-3"
                    onClick={() => setIsChangeActiveCalendarModalOpen(true)}
                  >
                    <div className="flex items-start gap-3">
                      <CalendarIcon className="h-5" />

                      <div className="text-sm">
                        <p className="text-gray-500">
                          {activeCalendar?.externalId
                            ? `Add to ${activeCalendar.externalId}`
                            : 'Calendar not selected'}
                        </p>

                        <ul className="list-disc ml-4">
                          <li>
                            {activeCalendar?.externalId
                              ? `Add to ${activeCalendar.externalId}`
                              : 'Do not add new sessions to a calendar'}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {!activeCalendar && (
                  <p className="mt-2 py-2 px-1.5 sm:px-3 rounded-md border border-primary text-sm">
                    There is no calendar selected to add events to. Events
                    booked will not be automatically added to your calendar.
                  </p>
                )}
              </div>
            </form>
          </div>
        )}
      </div>

      <AlertDialog
        isOpen={isDeleteWarningModalOpen}
        onClose={() => setIsDeleteWarningModalOpen(false)}
        title={`Delete the calendar account (${selectedCalendar?.externalId})`}
        confirmText="Delete"
        onConfirm={() => {
          if (selectedCalendar) {
            deleteCalendar(selectedCalendar.id)
          }
        }}
      >
        <p className="text-sm text-gray-500">
          Are you sure you want to delete?
        </p>
      </AlertDialog>

      <CoachCalendarAlert
        type={type}
        isOpen={(isTeamCoach || isGroupCoach) && showCoachAlert}
        setIsOpen={setShowCoachAlert}
      />

      <BasicDialog
        isOpen={isConflictCheckingModalOpen}
        onClose={() => setIsConflictCheckingModalOpen(false)}
        title="How should we check for conflicts?"
      >
        <div className="flex justify-center items-center gap-2">
          <CalendarIcon className="h-6" />
          <span>{selectedCalendar?.externalId}</span>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            const formData = new FormData(e.target as HTMLFormElement)
            const calendarIds = Array.from(formData.values()) as string[]

            if (selectedCalendar) {
              updateConflictChecking({
                calendarId: selectedCalendar.id,
                calendarIds,
              })
            }
          }}
        >
          <div className="my-6">
            <label className="text-base font-semibold text-gray-900">
              Select calendars
            </label>
            <p className="text-sm text-gray-500">
              Check these places for conflicts
            </p>

            <fieldset className="mt-4">
              <legend className="sr-only">Conflict checking calendars</legend>
              <div className="space-y-3">
                {(selectedCalendar?.calendarList ?? []).map((calendar) => (
                  <div key={calendar._id} className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id={calendar._id}
                        type="checkbox"
                        defaultChecked={calendar.checkConflicts}
                        name={calendar._id}
                        value={calendar.id}
                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor={calendar._id}
                        className="font-medium text-gray-900"
                      >
                        {calendar.summary}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          <Button className="w-full" type="submit">
            Save
          </Button>
        </form>
      </BasicDialog>

      <ActiveCalendarModal
        isOpen={isChangeActiveCalendarModalOpen}
        onClose={() => setIsChangeActiveCalendarModalOpen(false)}
        title="Which calendar should we add new sessions to?"
        type={type}
        calendars={calendars}
        hasActiveCalendar={!!activeCalendar}
        onSuccess={revalidate}
      />
    </>
  )
}
