import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { api } from '~/app/api'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { useToast } from '~/components/ui/use-toast'
import { AuthHeader } from './_components/AuthHeader'

type Inputs = {
  newPassword: string
  passwordConfirmation: string
}

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation('auth')
  const { toast } = useToast()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!searchParams.has('token') || !searchParams.has('email')) {
      navigate('/login')
    }
  }, [searchParams, navigate])

  const { mutate, isPending } = useMutation({
    async mutationFn(data: Inputs) {
      await api
        .url('/coach/reset-password')
        .post({
          password: data.newPassword,
          token: searchParams.get('token'),
          email: searchParams.get('email'),
        })
        .json<{ ok: true }>()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: t('reset_password.success'),
        variant: 'success',
      })
      navigate('/login')
    },
  })

  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<Inputs>({
    resolver(data) {
      if (data.newPassword !== data.passwordConfirmation) {
        return {
          errors: {
            passwordConfirmation: {
              type: 'passwordMismatch',
              message: t('reset_password.password_mismatch'),
            },
          },
          values: {},
        }
      }

      return {
        errors: {},
        values: data,
      }
    },
  })

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <AuthHeader title={t('reset_password.title')} />

      <div className="mt-10 px-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:px-12 rounded-lg">
          <form
            className="space-y-4"
            onSubmit={handleSubmit((data) => mutate(data))}
          >
            <Input
              id="new-password"
              type="password"
              autoComplete="current-password"
              label={t('reset_password.new_password_label')}
              required
              error={errors.newPassword?.message}
              {...register('newPassword', { required: true })}
            />
            <Input
              id="password-confirmation"
              type="password"
              autoComplete="new-password"
              label={t('reset_password.new_password_confirmation_label')}
              required
              error={errors.passwordConfirmation?.message}
              {...register('passwordConfirmation', { required: true })}
            />

            <div>
              <Button
                className="w-full"
                type="submit"
                loading={isSubmitting || isPending}
              >
                {t('reset_password.submit')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
