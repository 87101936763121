import { Link } from 'react-router-dom'
import { cn } from '../utils/cn'

interface ButtonProps {
  to?: string
  variant?: 'outline'
  className?: string
  loading?: boolean
  children: React.ReactNode
}

type Props = ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button: React.FC<Props> = (props) => {
  const {
    type = 'button',
    to,
    variant,
    className,
    loading,
    children,
    ...rest
  } = props

  const variants = {
    outline:
      'border border-primary-400 bg-white text-primary-400 hover:bg-primary-100',
    primary: 'bg-primary hover:bg-primary-400',
  }

  const classes = cn(
    'flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary',
    'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
    variants[variant || 'primary'],
    className,
    loading && 'opacity-30',
  )

  if (to) {
    return (
      <Link to={to} className={classes}>
        {children}
      </Link>
    )
  }

  return (
    <button
      type={type}
      className={classes}
      disabled={loading || rest.disabled}
      {...rest}
    >
      {children}
    </button>
  )
}
