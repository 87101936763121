/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect } from 'react-router-dom'
import { teamApi } from '~/app/api'
import { authenticated } from '~/app/cookie'

export interface Root {
  ratingCount: number
  avgRating: string
  answers: Answers
  data: Daum[]
}

type Answers = Record<string, number>

interface Daum {
  session: Session
  feedback: Feedback[]
}

interface Session {
  type: string
  participants: Participant[]
  _id: string
  bookedBy: string
  start: string
  end: string
  title: string
}

interface Participant {
  profile: Profile
  googleCalendar: GoogleCalendar
  limits: Limits
  notifications: Notifications
  role: string
  utilization: number
  tokens: any[]
  peers: any[]
  managers: any[]
  reportees: any[]
  batchNumber: number
  timezone: string
  language: string
  coachingLanguages: any[]
  interfaceLanguage: string
  active: boolean
  canBook: boolean
  markedForDeletion: boolean
  dyteEnabled: boolean
  dyteRecordOnStart: boolean
  dyteUseStt: boolean
  dyteCoachVerification: boolean
  oldPasswords: any[]
  individualSessionCount: number
  onboardingCompleted: boolean
  onboardedOnMobile: boolean
  bookings: any[]
  ghostBookings: any[]
  _id: string
  email: string
  company: string
  team: string
  title: string
  createdAt: string
  updatedAt: string
  externalSlackId?: string
}

interface Profile {
  name: string
  picture: string
  nickName: string
}

interface GoogleCalendar {
  calendarList: any[]
}

interface Limits {
  canUseService: boolean
}

interface Notifications {
  hideAIcontent: boolean
}

interface Feedback {
  user: Participant
  rating: Rating
}

interface Rating {
  answers: string[]
  _id: string
  session: string
  rating: number
  otherAnswer: string
}

const DEFAULT_VALUE: Root = {
  avgRating: '0',
  ratingCount: 0,
  answers: {},
  data: [],
}

const fetchFeedback = () =>
  queryOptions({
    initialData: DEFAULT_VALUE,
    queryKey: ['team', 'feedback'],
    queryFn: () => teamApi.url('/coach/feedback').get().json<Root>(),
  })

export const loader = (queryClient: QueryClient) => () => {
  if (!authenticated()) {
    return redirect('/auth?mode=login')
  }

  return queryClient.ensureQueryData(fetchFeedback())
}
