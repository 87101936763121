import { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select, { type MultiValue } from 'react-select'
import { useMutation, useQuery } from '@tanstack/react-query'
import { SparklesIcon } from '@heroicons/react/24/outline'

import { Button } from '~/components/Button'
import { TextArea } from '~/components/TextArea'
import { useToast } from '~/components/ui/use-toast'
import { api, teamApi, groupApi } from '~/app/api'
import { loader } from './_loaders/edit-bio'
import { FieldArray } from '~/components/FieldArray'
import { Toggle } from '~/components/Toggle'

type Option = { id: string; name: string }
type Options = MultiValue<Option>

type Inputs = {
  bio: string
  summary: string
  philosophy: string
  generateSummary: boolean

  license: string[]
  background: string[]
  languages: string[]
  styles: string[]
}

const filterDefaultOptions = (options: Options, selectedIds: string[] = []) => {
  return options.filter((option) => selectedIds.includes(option.id))
}

export const EditBio: React.FC = () => {
  const { i18n } = useTranslation()
  const { toast } = useToast()
  const coachBio = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >
  const { data } = useQuery({
    initialData: { industries: [], focusAreas: [] },
    queryKey: ['focus-areas', 'industries', i18n.resolvedLanguage],
    queryFn() {
      return api
        .url('/coach/focus-area-and-industry')
        .query({ language: i18n.resolvedLanguage })
        .get()
        .json<{ industries: Options; focusAreas: Options }>()
    },
  })

  const form = useForm<Inputs>({
    defaultValues: {
      bio: coachBio.bio.full,
      summary: coachBio.bio.summary,
      philosophy: coachBio.bio.philosophy,
      license: coachBio.bio.licenses,
      background: coachBio.bio.background,
      languages: coachBio.bio.languages,
      styles: coachBio.bio.style,
      generateSummary: true,
    },
  })

  const generateSummary = form.watch('generateSummary')

  useEffect(() => {
    if (data.focusAreas.length > 0 && data.industries.length > 0) {
      setIndustryExpertises(
        filterDefaultOptions(data.industries, coachBio.bio.industry),
      )
    }

    if (data.focusAreas.length > 0 && data.industries.length > 0) {
      setFocusAreas(
        filterDefaultOptions(data.focusAreas, coachBio.bio.focusArea),
      )
    }
  }, [data, coachBio.bio.industry, coachBio.bio.focusArea])

  const { mutate: updateBio } = useMutation({
    mutationKey: ['update', 'bio'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async mutationFn(payload: any) {
      await api.url('/coach/bio').put(payload).json()
    },
    async onSuccess() {
      toast({
        title: 'Success',
        description: 'Bio updated successfully',
        variant: 'success',
      })
    },
  })

  const { mutate: updateTeamBio } = useMutation({
    mutationKey: ['team', 'update', 'bio'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async mutationFn(payload: any) {
      await teamApi.url('/coach/bio').patch(payload).json()
    },
  })

  const { mutate: updateGroupBio } = useMutation({
    mutationKey: ['group', 'update', 'bio'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async mutationFn(payload: any) {
      await groupApi.url('/coach/bio').patch(payload).json()
    },
  })

  const [industryExpertises, setIndustryExpertises] = useState<Options>([])
  const [focusAreas, setFocusAreas] = useState<Options>([])

  const submitHandler = form.handleSubmit((data) => {
    const payload = {
      language: coachBio?.language ?? i18n.resolvedLanguage,
      bio: {
        full: data.bio,
        summary: data.summary,
        philosophy: data.philosophy,
        focusArea: focusAreas.map((item) => item.id),
        industry: industryExpertises.map((item) => item.id),
        licenses: data.license,
        background: data.background,
        languages: data.languages,
        style: data.styles,
      },
      generateSummary: data.generateSummary,
    }

    updateBio(payload)
    updateTeamBio(payload)
    updateGroupBio(payload)
  })

  return (
    <div className="divide-y divide-black/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7">Bio</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Tell your clients a little bit about yourself.
          </p>
        </div>

        <form className="md:col-span-2" onSubmit={submitHandler}>
          <div className="space-y-4">
            <TextArea
              id="bio"
              rows={10}
              label="Coach Bio"
              placeholder={'fullBioPlaceholder'}
              defaultValue={coachBio.bio.full}
              {...form.register('bio')}
            />

            <TextArea
              id="summary"
              rows={4}
              label="Short summary"
              className="bg-gray-100"
              placeholder={'summaryPlaceholder'}
              defaultValue={coachBio.bio.summary}
              description={
                <>
                  <p className="text-xs text-gray-500">
                    <SparklesIcon className="h-4 w-4 mr-2 inline-block" />
                    Pro tip: Enable AI summary to generate a short summary of
                    your bio.
                  </p>
                </>
              }
              {...form.register('summary')}
              readOnly={generateSummary}
            />

            <div className="flex gap-3 items-center">
              <Toggle
                enabled={generateSummary}
                setEnabled={(val) => form.setValue('generateSummary', val)}
              />

              <span className="block text-sm leading-6 text-gray-900">
                Generate short summary with AI
              </span>
            </div>

            <TextArea
              id="philosophy"
              rows={5}
              label="Coaching Philosophy"
              placeholder="Once you're in my team, expect the journey to be hard but be prepared for success."
              defaultValue={coachBio.bio.philosophy}
              {...form.register('philosophy')}
            />
          </div>

          <div className="mt-8 flex">
            <Button className="w-full" type="submit">
              Save
            </Button>
          </div>
        </form>
      </div>

      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7">
            Other Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Tell your clients a little bit about yourself.
          </p>
        </div>

        <form className="md:col-span-2" onSubmit={submitHandler}>
          <div className="space-y-5">
            <div>
              <label
                htmlFor="industries"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Industry Expertise
                <p className="mt-2 text-sm text-gray-500">
                  Please select the industries you have expertise in.
                </p>
              </label>

              <Select
                id="industries"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={data.industries}
                value={industryExpertises}
                onChange={setIndustryExpertises}
                isMulti
              />
            </div>

            <div>
              <label
                htmlFor="focus-areas"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Focus Areas
                <p className="mt-2 text-sm text-gray-500">
                  Please select the focus areas you have expertise in.
                </p>
              </label>

              <Select
                id="focus-areas"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={data.focusAreas}
                value={focusAreas}
                onChange={setFocusAreas}
                isMulti
              />
            </div>
          </div>

          <div className="flex flex-col gap-7 mt-5">
            {/* Coaching Licenses */}
            <FieldArray
              control={form.control}
              register={form.register}
              name="license"
              label="Coaching Licenses"
              placeholder="Coaching Licenses"
            >
              <p className="mt-2 text-sm text-gray-500">
                Please list any coaching licenses you have. If you don’t have
                any, you can leave this blank.
              </p>
            </FieldArray>

            {/* Professional Background */}
            <FieldArray
              control={form.control}
              register={form.register}
              name="background"
              label="Professional Background"
              placeholder="Professional Background"
            >
              <p className="mt-2 text-sm text-gray-500">
                Please list any professional background you have. If you don’t
                have any, you can leave this blank.
              </p>
            </FieldArray>

            {/*  Coaching Languages */}
            <FieldArray
              control={form.control}
              register={form.register}
              name="languages"
              label="Coaching Languages"
              placeholder="Coaching Languages"
            >
              <p className="mt-2 text-sm text-gray-500">
                Please list any languages you know.
              </p>
            </FieldArray>

            {/*  Coaching Style */}
            <FieldArray
              control={form.control}
              register={form.register}
              name="styles"
              label="Coaching Style"
              placeholder="Coaching Style"
            >
              <p className="mt-2 text-sm text-gray-500">
                Please list any coaching styles you have. If you don’t have any,
                you can leave this blank.
              </p>
            </FieldArray>
          </div>

          <div className="mt-8 flex">
            <Button className="w-full" type="submit">
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
