import type { User } from '../schedule/schedule'
import { useId, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import addMinutes from 'date-fns/addMinutes'

import { UserSelect } from '../schedule/_components/UserSelect'
import { api } from '~/app/api'
import { Button } from '~/components/Button'
import { useToast } from '~/components/ui/use-toast'
import { LoadingSpinner } from '~/Loading'
import { DatePicker } from '~/components/ui/date-picker'
import { TimePickerWithDuration } from '~/components/TimePickerWithDuration'

interface EventCreatePayload {
  userId: string
  start: string
  end: string
}

interface EventFormProps {
  defaultStart: Date
  defaultEnd: Date
  onClose: () => void
  onSuccess?: () => void
}

type Inputs = {
  start: Date | null
  end: Date | null
}

export const BookSessionDialog: React.FC<EventFormProps> = (props) => {
  const { defaultStart, defaultEnd, onClose, onSuccess } = props
  const id = useId()
  const { toast } = useToast()
  const [user, setUser] = useState<User | null>(null)

  const { setValue, watch, handleSubmit } = useForm<Inputs>({
    defaultValues: { start: defaultStart, end: defaultEnd },
  })

  const start = watch('start', defaultStart)

  const { data: usersList, isLoading: usersListLoading } = useQuery({
    initialData: {},
    queryKey: ['users', 'list'],
    queryFn() {
      return api.get('/coach/customers/list').json<Record<string, User[]>>()
    },
  })

  const { mutate: bookSession, isPending } = useMutation({
    mutationKey: ['schedule', 'event', 'create'],
    mutationFn(data: EventCreatePayload) {
      return api.url('/coach/events').post(data).json()
    },
    onSuccess() {
      toast({
        variant: 'success',
        title: 'Success',
        description: 'Event created successfully',
      })
      onSuccess?.()
    },
    onSettled() {
      onClose()
    },
  })

  const onSubmit = handleSubmit((data) => {
    if (!data.start || !data.end) return

    if (!user) {
      toast({
        variant: 'warning',
        title: 'Warning',
        description: 'Please select a user',
      })
      return
    }

    bookSession({
      userId: user.id,
      start: data.start.toISOString(),
      end: data.end.toISOString(),
    })
  })

  if (isPending || usersListLoading) return <LoadingSpinner />

  return (
    <form className="py-4 pb-0 flex flex-col gap-4" onSubmit={onSubmit}>
      <div className="flex flex-col gap-2">
        <label
          htmlFor="user_combobox_home"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          User
        </label>
        <UserSelect
          id={`${id}_user_combobox_home`}
          value={user}
          setValue={setUser}
          usersList={usersList}
        />
      </div>

      <div className="flex flex-col gap-2.5">
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Time
          </label>
          <div className="flex gap-2">
            <DatePicker
              value={start}
              onSelect={(value) => {
                setValue('start', value)
                setValue('end', value)
              }}
            />
            <TimePickerWithDuration
              value={start}
              onChange={(startVal, duration) => {
                if (!startVal) return
                setValue('start', startVal)
                setValue('end', addMinutes(startVal, duration))
              }}
            />
          </div>
        </div>
      </div>

      <Button type="submit" className="mt-5 w-full">
        Book a session
      </Button>
    </form>
  )
}
