import { useAsyncValue } from 'react-router-dom'
import { STATS } from '~/app/constants'

export const Stats: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const metrics = useAsyncValue() as any

  return STATS.map((stat) => (
    <div key={stat.key} className="px-6 py-5 text-center text-sm font-medium">
      <span className="text-gray-900">{metrics[stat.key]}</span>{' '}
      <span className="text-gray-600">{stat.label}</span>
    </div>
  ))
}

export const StatsFallback: React.FC = () => {
  return STATS.map((stat) => (
    <div key={stat.key} className="px-6 py-5 text-center text-sm font-medium">
      <span className="text-gray-600">{stat.label}</span>
    </div>
  ))
}
