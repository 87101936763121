import { useEffect, useState } from 'react'
import { RENDER_TIMEOUT_DURATION } from './app/constants'
import { LoadingSpinner } from './Loading'

export const LoadingTimeout: React.FC<React.PropsWithChildren> = (props) => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowError(true)
    }, RENDER_TIMEOUT_DURATION)

    return () => clearTimeout(timeout)
  }, [])

  if (showError) {
    return (
      <>
        {props.children}
        <div className="absolute left-0 right-0 bottom-10 mx-auto text-center">
          <LoadingSpinner />
          <p>Loading is taking longer than expected...</p>
          <p>
            Please contact support using the chat support on the bottom right of
            the screen or email us at{' '}
            <a
              className="underline text-blue-500 hover:cursor-pointer hover:text-blue-700"
              href="mailto:product@hupo.co"
            >
              product@hupo.co
            </a>
            .
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      {props.children}
      <div className="flex absolute bottom-14 w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    </>
  )
}
