import { Fragment, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Menu, Transition } from '@headlessui/react'
import { Bars3Icon, UserCircleIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { cn } from '~/utils/cn'
import { USER_NAVIGATION } from '../dashboard.nav'

interface Props {
  onOpenSidebar: () => void
  onLogout: () => void
  name: string
  picture: string
}

export const StickyHeader: React.FC<Props> = (props: Props) => {
  const { onOpenSidebar, onLogout, name, picture } = props
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' })
  const { pathname: currentPath } = useLocation()

  const isChatting = useMemo(
    () =>
      isMobileScreen &&
      currentPath.length > 15 &&
      currentPath.includes('/chats/') &&
      'hidden',
    [isMobileScreen, currentPath],
  )

  return (
    <div
      className={cn(
        'sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm',
        'sm:gap-x-6 sm:px-6 lg:px-8',
        isChatting && 'hidden',
      )}
    >
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 xl:hidden"
        onClick={onOpenSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      {/* Separator */}
      <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form className="relative flex flex-1" action="#" method="GET">
          {/* <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <MagnifyingGlassIcon
        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
        aria-hidden="true"
      />
      <input
        id="search-field"
        className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        placeholder="Search..."
        type="search"
        name="search"
      /> */}
        </form>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          {/* <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
      >
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
      </button> */}

          {/* Separator */}
          <div
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
            aria-hidden="true"
          />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              {picture ? (
                <img
                  className="h-8 w-8 object-cover rounded-full bg-gray-50"
                  src={picture}
                  alt={name}
                  referrerPolicy="no-referrer"
                />
              ) : (
                <UserCircleIcon className="h-8 w-8 rounded-full text-gray-400" />
              )}
              <span className="hidden lg:flex lg:items-center">
                <span
                  className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                  aria-hidden="true"
                >
                  {name}
                </span>
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
            <Transition.Root
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {USER_NAVIGATION.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) =>
                      item.action && !item.href ? (
                        <button
                          className={cn(
                            active ? 'bg-gray-50' : '',
                            'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                          )}
                          onClick={onLogout}
                        >
                          {item.name}
                        </button>
                      ) : (
                        <Link
                          to={item.href!}
                          className={cn(
                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                            active && 'bg-gray-50',
                          )}
                        >
                          {item.name}
                        </Link>
                      )
                    }
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition.Root>
          </Menu>
        </div>
      </div>
    </div>
  )
}
