/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, type LoaderFunctionArgs } from 'react-router-dom'

import { api } from '~/app/api'

export interface Data {
  scores: Scores
  selfAnswers: any[]
  answers: Answer[]
  values: Values
  isComplete: boolean
  canShowLiveResults: boolean
  resultsPendingLabel: string
  reportUrl?: string
  scoreComments: any[]
}

interface Scores {
  self: Self
  peers: Peers
  reportees: Reportees
  managers: Managers
  range: Range
}

interface Self {
  'growth-mindset': number
  'coping-with-pressure': number
  'leading-and-engaging-teams': number
  'developing-and-recognising-others': number
  'setting-direction': number
  'driving-business-performance-change': number
}

interface Peers {
  'growth-mindset': number
  'coping-with-pressure': number
  'leading-and-engaging-teams': number
  'developing-and-recognising-others': number
  'setting-direction': number
  'driving-business-performance-change': number
}

interface Reportees {
  'growth-mindset': number
  'coping-with-pressure': number
  'leading-and-engaging-teams': number
  'developing-and-recognising-others': number
  'setting-direction': number
  'driving-business-performance-change': number
}

interface Managers {
  'growth-mindset': number
  'coping-with-pressure': number
  'leading-and-engaging-teams': number
  'developing-and-recognising-others': number
  'setting-direction': number
  'driving-business-performance-change': number
}

interface Range {
  'growth-mindset': RangeValue
  'self-awareness': RangeValue
  'coping-with-pressure': RangeValue
  'leading-and-engaging-teams': RangeValue
  'developing-and-recognising-others': RangeValue
  'setting-direction': RangeValue
  'driving-business-performance-change': RangeValue
}

interface RangeValue {
  min: number
  max: number
}

interface Answer {
  question: string
  questionID: string
  description: string
  descriptionItems: any[]
  responses: Responses
}

interface Responses {
  managers: any[]
  peers: string[]
  reportees: any[]
}

interface Values {
  'growth-mindset': string
  'self-awareness': string
  'coping-with-pressure': string
  'leading-and-engaging-teams': string
  'developing-and-recognising-others': string
  'setting-direction': string
  'driving-business-performance-change': string
}

export interface ResponseData {
  enabled: boolean
  data: Data
}

interface Scores {
  self: Self
  peers: Peers
  reportees: Reportees
  managers: Managers
  range: Range
}

export function getScoresSafe<
  T extends Self | Peers | Reportees | Managers | Range,
>(scores: T) {
  return Object.keys(scores).map((key) => scores[key as keyof T] ?? 0)
}

const fetchHupoPerspectives = (id: string) =>
  queryOptions({
    queryKey: ['users', id, 'hupo-perspectives'],
    queryFn: () =>
      api
        .url(`/coach/customers/${id}/hupo-perspectives`)
        .get()
        .json<ResponseData>(),
  })

export const loader =
  (queryClient: QueryClient) => (args: LoaderFunctionArgs) => {
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return defer({
      data: queryClient.fetchQuery(fetchHupoPerspectives(id)),
    })
  }
