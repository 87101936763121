import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { WretchError } from 'wretch/resolver'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { api } from '~/app/api'
import { useAuth } from '~/hooks/useAuth'
import { TroubleshootDialog } from '~/layouts/_components/TroubleshootDialog'
import { useAppDispatch } from '~/app/hooks'
import { logout } from '~/store/actions/logout'

interface ReportPayload {
  uri: string
  time: string
  message: string
  stack?: string
  userEmail?: string
}

const DEFAULT_ERROR_MESSAGE =
  'Sorry, an unexpected error has occurred. We are working to resolve the issue.'

export const ErrorPage: React.FC = () => {
  const error = useRouteError() as Error
  const dispatch = useAppDispatch()
  const { email } = useAuth()
  const navigate = useNavigate()
  const [troubleshootModalOpen, setTroubleshootModalOpen] = useState(false)
  const [message, setMessage] = useState(DEFAULT_ERROR_MESSAGE)

  const { mutate } = useMutation({
    mutationKey: ['report', 'error'],
    mutationFn(data: ReportPayload) {
      return api.url('/coach/error/client').post(data).json()
    },
  })

  const onTroubleshootingClick = useCallback(() => {
    setTroubleshootModalOpen(true)
  }, [])

  useEffect(() => {
    if (import.meta.env.DEV) return console.error

    const timeWithZoneOffset = new Date().toLocaleString('ru-RU', {
      timeZoneName: 'short',
    })

    mutate({
      uri: location.href,
      time: timeWithZoneOffset,
      message: error.message,
      stack: String(error.stack),
      userEmail: email,
    })

    if (error instanceof WretchError) {
      if (error.status === 401) {
        dispatch(logout())
      }

      if (error.status === 404) {
        return navigate('/404')
      }

      setMessage(error.json.message)
    }
  }, [dispatch, error, mutate, email, navigate])

  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Application error
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">{message}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              Go back home page
            </a>
            <button
              className="rounded-md px-3.5 py-2 text-sm outline outline-gray-500 flex items-center gap-2 font-semibold text-gray-900"
              onClick={onTroubleshootingClick}
            >
              Troubleshooting{' '}
              <span aria-hidden="true">
                <ExclamationTriangleIcon className="w-5 h-5 inline-block" />
              </span>
            </button>
          </div>
        </div>
      </main>

      <TroubleshootDialog
        open={troubleshootModalOpen}
        setOpen={setTroubleshootModalOpen}
      />
    </>
  )
}
