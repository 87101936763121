import type { ResponseData } from './_loaders/hupo-perspectives'
import { Suspense, useState } from 'react'
import { Await, useLoaderData } from 'react-router-dom'

import { Tabs, TabsList, TabsTrigger } from '~/components/ui/tabs'
import { LoadingSpinner } from '~/Loading'
import { LeadershipValues } from './_components/LeadershipValues'
import { SelfAnswers } from './_components/SelfAnswers'
import { OpenEndedAnswers } from './_components/OpenEndedAnswers'

export const HupoPerspectives: React.FC = () => {
  const deferredData = useLoaderData() as { data: ResponseData }
  const resolvedData = deferredData.data

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={resolvedData} errorElement={<div>Error</div>}>
        {(data) => <HupoPerspectivesWithData data={data} />}
      </Await>
    </Suspense>
  )
}

const HupoPerspectivesWithData: React.FC<{ data: ResponseData }> = (props) => {
  const { enabled, data } = props.data
  const [selectedTab, setSelectedTab] = useState<'chart' | 'table'>('chart')

  return (
    <div className="flex-1 rounded-lg bg-white shadow px-4 py-5 space-y-4 sm:px-6 sm:py-2">
      {!enabled && (
        <p className="text-center mt-10">
          Hupo Perspectives is not yet enabled for this user
        </p>
      )}
      {enabled && (
        <>
          {data.reportUrl && (
            <div className="flex justify-center mb-4">
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => window.open(data.reportUrl, '_blank')}
              >
                View detailed report
              </button>
            </div>
          )}

          <div className="flex justify-between items-center max-sm:flex-col max-sm:gap-2">
            <h2 className="sm:text-xl text-lg font-bold">
              Leadership Value Scores
            </h2>
            <Tabs
              className="max-sm:w-full"
              defaultValue="chart"
              onValueChange={(v) => setSelectedTab(v as typeof selectedTab)}
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="chart">Chart View</TabsTrigger>
                <TabsTrigger value="table">Table View</TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
          <div className="sm:flex justify-center mt-8 overflow-x-auto">
            <LeadershipValues showChart={selectedTab === 'chart'} data={data} />
          </div>
          <div className="text-sm pt-4">
            <p className="mb-2">Rating Scale:</p>
            <ul>
              <li>1 - N/A (no opportunity to demonstrate)</li>
              <li>2 - Rarely seen</li>
              <li>3 - Seen a few times</li>
              <li>4 - Seen numerous times</li>
              <li>5 - Consistently demonstrates</li>
              <li>6 - Admired as a role model for others</li>
            </ul>
          </div>
          <div className="space-y-2 divide-y divide-gray-200 overflow-hidden">
            <SelfAnswers selfAnswers={data.selfAnswers} />
            <OpenEndedAnswers
              answers={data.answers}
              scoreComments={data.scoreComments}
              values={data.values}
            />
          </div>
        </>
      )}
    </div>
  )
}
