import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect, type LoaderFunctionArgs } from 'react-router-dom'
import { authenticated } from '~/app/cookie'
import { getApi } from '~/utils/getApi'

export interface ChatUser {
  user: {
    id: string
    name: string
    picture?: string
    email: string
    company: string
  }
  unread: number
  lastMessage?: {
    text: string
    date: string
  }
  sentByUser: boolean
}

export interface TeamChatUser {
  id: string
  chat: {
    id: string
    title: string
    picture: string
    isPrivate: boolean
  }
  unread: number
  lastMessage: {
    text: string
    date: string
  }
  sentByUser: boolean
}

const fetchChats = (type: AppType) =>
  queryOptions({
    queryKey: [type, 'chats'],
    queryFn: () =>
      getApi(type).get('/coach/chats').json<(ChatUser | TeamChatUser)[]>(),
  })

export const loader =
  (queryClient: QueryClient) => (args: LoaderFunctionArgs) => {
    if (!authenticated()) {
      return redirect('/auth?mode=login')
    }

    const { type = 'core' } = args.params as { type: AppType }

    if (type !== 'group' && type.length > 4) {
      return redirect(`/chats/core/${type}`)
    }

    return queryClient.fetchQuery(fetchChats(type))
  }
