import { useCallback } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import {
  UserCircleIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'

import { LOGO } from '~/app/constants'
import { FEEDBACK_HUB, NAVIGATION, RESOURCES } from '../dashboard.nav'
import { cn } from '~/utils/cn'
import { useAppSelector } from '~/app/hooks'
import { totalUnreadMessagesCount } from '~/store/ui.slice'

interface Props {
  navigation: typeof NAVIGATION
  name: string
  picture: string
  hasPendingNps: boolean
  onFeedbackClick: () => void
  onTroubleshootClick: () => void
}

export const DesktopSidebar: React.FC<Props> = (props: Props) => {
  const unreadMessages = useAppSelector(totalUnreadMessagesCount)
  const uiState = useAppSelector((state) => state.ui)
  const hasNpsFeatureEnable = useFeatureFlagEnabled('nps_feedback_tab')
  const {
    navigation,
    name,
    picture,
    onFeedbackClick,
    onTroubleshootClick,
    hasPendingNps,
  } = props

  const renderBadge = useCallback(
    (key: string, className?: string) => {
      let count: number

      if (key === 'chats') {
        count = unreadMessages
      } else {
        count = uiState[key as keyof typeof uiState] as number
        if (typeof count !== 'number') return null
      }

      if (count <= 0) return null

      return (
        <span
          className={cn(
            'ml-auto w-9 bg-white text-gray-600 min-w-max whitespace-nowrap rounded-full px-2.5 py-0.5 text-center text-xs font-medium leading-5 ring-1 ring-inset ring-gray-200',
            className,
          )}
          aria-hidden="true"
        >
          {count}
        </span>
      )
    },
    [unreadMessages, uiState],
  )

  return (
    <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
        <div className="flex h-16 shrink-0 items-center">
          <img className="h-8 w-auto" src={LOGO.src} alt={LOGO.alt} />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        cn(
                          isActive
                            ? 'bg-gray-50 text-primary-600'
                            : 'text-gray-700 hover:text-primary-600 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                        )
                      }
                    >
                      {({ isActive }) => (
                        <>
                          <item.icon
                            className={cn(
                              isActive
                                ? 'text-primary-600'
                                : 'text-gray-400 group-hover:text-primary-600',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                          {item.countKey &&
                            renderBadge(item.countKey, item.className)}
                        </>
                      )}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">
                Resources
              </div>
              <ul role="list" className="-mx-2 mt-2 space-y-1">
                {RESOURCES.map((item) => (
                  <li key={item.id}>
                    <a
                      href={item.href}
                      onClick={(e) => {
                        if (item.id === 'troubleshoot') {
                          e.preventDefault()
                          onTroubleshootClick()
                        } else {
                          item.action?.(e)
                        }
                      }}
                      className={cn(
                        'text-gray-700 hover:text-primary-600 hover:bg-gray-50 hover:cursor-pointer',
                        'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      )}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <item.icon
                        className={cn(
                          'text-gray-400 group-hover:text-primary-600',
                          'h-6 w-6 shrink-0',
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                      {item.external && (
                        <ArrowTopRightOnSquareIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            {hasNpsFeatureEnable && (
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  Feedback Hub
                </div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {FEEDBACK_HUB.map((item, index) => (
                    <li key={item.name}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          if (index === 0) onFeedbackClick()
                        }}
                        className={cn(
                          'text-gray-700 hover:text-primary-600 hover:bg-gray-50 hover:cursor-pointer',
                          'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                        )}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <item.icon
                          className={cn(
                            'text-gray-400 group-hover:text-primary-600',
                            'h-6 w-6 shrink-0',
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                        {hasPendingNps && (
                          <span
                            className="ml-auto w-2 h-2 rounded-full bg-primary"
                            aria-hidden="true"
                          />
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            )}
            <li className="-mx-6 mt-auto">
              <Link
                to="/settings/profile"
                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
              >
                {picture ? (
                  <img
                    className="h-8 w-8 object-cover rounded-full bg-gray-50"
                    src={picture}
                    alt={name}
                    referrerPolicy="no-referrer"
                  />
                ) : (
                  <UserCircleIcon className="h-8 w-8 rounded-full text-gray-400" />
                )}
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true">{name}</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}
