import { redirect, type LoaderFunction } from 'react-router-dom'
import { onboardingInProgress } from '~/app/cookie'

export const loader: LoaderFunction = () => {
  if (!onboardingInProgress()) {
    return redirect('/')
  }

  return new Response(null, { status: 204 }) // No content
}
