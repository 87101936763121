// UserSelect.tsx
import { Combobox, Transition } from '@headlessui/react'
import { BarsArrowUpIcon, UserIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useState } from 'react'

import { cn } from '~/utils/cn'
import type { User } from '../schedule'

interface UserSelectProps {
  usersList: Record<string, User[]>
  value: User | null
  setValue: (value: User | null) => void
}

type Props = UserSelectProps & React.HTMLAttributes<HTMLInputElement>

export const UserSelect: React.FC<Props> = (props) => {
  const { usersList, setValue, value, ...rest } = props
  const [searchQuery, setSearchQuery] = useState('')
  const [sortedUsersList, setSortedUsersList] = useState(usersList)

  const filteredUsersList = Object.entries(sortedUsersList).reduce(
    (acc, [group, users]) => {
      const filteredUsers = users.filter((user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      if (filteredUsers.length > 0) {
        acc[group] = filteredUsers
      }
      return acc
    },
    {} as Record<string, User[]>,
  )

  useEffect(() => {
    setSortedUsersList(usersList)
  }, [usersList])

  const handleSort = () => {
    const sortedGroups = Object.keys(usersList).sort()
    const sortedList = sortedGroups.reduce(
      (acc, group) => {
        acc[group] = usersList[group]
        return acc
      },
      {} as Record<string, User[]>,
    )
    setSortedUsersList(sortedList)
  }

  return (
    <Combobox value={value} onChange={(data) => setValue(data)}>
      <div className="relative flex flex-grow items-stretch focus-within:z-50">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <Combobox.Input
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          displayValue={(item: User) => item?.name}
          placeholder="Search for a user..."
          className="block w-full rounded-none rounded-bl-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          required
          onChange={(event) => setSearchQuery(event.target.value)}
          {...rest}
        />
        <Combobox.Button
          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md rounded-br-none px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={handleSort}
        >
          <BarsArrowUpIcon
            className="-ml-0.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Sort
        </Combobox.Button>

        <Transition.Root
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Combobox.Options className="absolute z-10 w-full mt-9 overflow-auto text-base bg-white rounded-b-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {Object.keys(filteredUsersList).length > 0 ? (
              Object.entries(filteredUsersList).map(([group, users]) => (
                <Fragment key={group}>
                  <h1
                    className="pl-4 py-2 text-gray-600 font-semibold bg-gray-200 sticky z-50 opacity-100 text-md"
                    style={{ top: -5 }}
                  >
                    {group}
                  </h1>
                  <Combobox.Options>
                    {users.map((option) => (
                      <Combobox.Option
                        key={option.id}
                        value={option}
                        disabled={option.active === false}
                        className="disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                      >
                        {({ active, selected }) => (
                          <div
                            className={cn(
                              'cursor-default select-none relative py-2 pr-4 pl-4',
                              active
                                ? 'text-white bg-primary-500'
                                : 'text-gray-900',
                            )}
                          >
                            <span
                              className={cn(
                                'block truncate',
                                selected ? 'font-medium' : 'font-normal',
                              )}
                            >
                              {option?.name}
                            </span>
                            {selected && (
                              <span
                                className={cn(
                                  'absolute inset-y-0 right-2 flex items-center',
                                  active ? 'text-white' : 'text-primary-500',
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Fragment>
              ))
            ) : (
              <h1 className="pl-4 py-2 text-gray-600 font-semibold bg-gray-200 sticky z-50 opacity-100 text-md">
                No users found
              </h1>
            )}
          </Combobox.Options>
        </Transition.Root>
      </div>
    </Combobox>
  )
}
