import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { useAuth } from './useAuth'

export const useIdentifySentry = () => {
  const { id, name: username, email } = useAuth()

  useEffect(() => {
    if (id.length === 0) return

    Sentry.setUser({ id, email, username })
  }, [id, email, username])
}
