/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect } from 'react-router-dom'
import { groupApi } from '~/app/api'
import { authenticated } from '~/app/cookie'

const fetchGroups = () =>
  queryOptions({
    queryKey: ['groups'],
    queryFn() {
      return groupApi.url('/coach/groups').get().json<{ data: any[] }>()
    },
  })

export const loader = (queryClient: QueryClient) => () => {
  if (!authenticated()) {
    return redirect('/auth?mode=login')
  }

  return queryClient.fetchQuery(fetchGroups())
}
