import { CalendarIcon } from '@heroicons/react/24/outline'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { Suspense, useState } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import { Button } from '~/components/Button'

import { LoadingSpinner } from '~/Loading'
import { ResponseData, Summary, loader } from './_loaders/session-summaries'
import { EditSessionSummaryDialog } from './_components/EditSessionSummaryDialog'

const formatSummary = (summary: string, sections: string[]) => {
  if (summary.includes('. **')) {
    const lines = summary.split('\n')
    summary = lines
      .map((line) => {
        if (line.includes('. **') && line.includes(':**')) {
          return line.replace(/\. \*\*/g, '. <b>').replace(/:\*\*/g, ':</b>')
        } else if (line.includes('- **') && line.includes(':**')) {
          return line.replace(/-\\ \*\*/g, '- <i>').replace(/:\*\*/g, ':</i>')
        }
        return line
      })
      .join('\n')
  } else {
    for (const highlightedPart of sections) {
      // Escape special characters in highlightedPart
      const escapedHighlightedPart = highlightedPart.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&',
      )
      const regex = new RegExp(escapedHighlightedPart, 'g')
      summary = summary.replace(regex, '<b>' + highlightedPart + '</b>')
    }
  }
  summary = summary.replace(/\n/g, '<br/>')
  return summary
}

export const SessionSummaries: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deferredData: any = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >
  let resolvedData = deferredData.data
  if (deferredData.sessionSummaries && deferredData.sessionSummaries.data) {
    resolvedData = deferredData.sessionSummaries.data
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={resolvedData} errorElement={<div>Error</div>}>
        {(data) => <SessionSummariesWithData data={data} />}
      </Await>
    </Suspense>
  )
}

const SessionSummariesWithData: React.FC<{ data: ResponseData }> = (props) => {
  const { data } = props
  const [selectedSummary, setSelectedSummary] = useState<Summary | null>(null)

  if (!data.enabled) {
    return (
      <div className="bg-gray-50 rounded-md w-full transition-all space-y-10 divide-y-2">
        <p className="text-center my-5">
          Hupo Highlights is not yet enabled for this user
        </p>
      </div>
    )
  }

  if (data.summaries.length === 0) {
    return (
      <div className="bg-gray-50 rounded-md w-full transition-all space-y-10 divide-y-2">
        <p className="text-center my-5">No session summaries found</p>
      </div>
    )
  }

  return (
    <div className="bg-gray-50 transition-all w-full space-y-10 divide-y">
      <p className="pt-5 text-center font-bold">Session Summaries</p>
      {data.summaries.map((summary) => (
        <div
          key={summary.eventId}
          className="px-4 py-5 space-y-4 sm:px-6 sm:py-2"
        >
          <div className="flex justify-between">
            <div className="flex justify-between text-gray-500">
              <p className="flex items-center gap-2">
                <CalendarIcon className="h-5" />
                {format(parseISO(summary.start), 'MMM dd, yyyy h:mm a')} (
                {differenceInMinutes(
                  parseISO(summary.end),
                  parseISO(summary.start),
                )}
                min)
              </p>
            </div>
            <div className="flex items-center">
              <p
                className={`flex items-center gap-2 ml-2 text-sm ${
                  summary.verified ? 'text-green-600' : 'text-orange-500'
                }`}
              >
                {summary.verified ? 'Verified' : 'Pending'}
              </p>
              {!summary.verified ? (
                <Button
                  className="ml-4"
                  onClick={() => setSelectedSummary(summary)}
                >
                  {'Verify'}
                </Button>
              ) : null}
            </div>
          </div>

          <div className="space-y-4">
            <div
              dangerouslySetInnerHTML={{
                __html: formatSummary(summary.content, data.sections),
              }}
            />
          </div>
        </div>
      ))}
      <EditSessionSummaryDialog
        isOpen={Boolean(selectedSummary)}
        onClose={() => setSelectedSummary(null)}
        selectedSummary={selectedSummary}
      />
    </div>
  )
}
