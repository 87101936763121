import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect } from 'react-router-dom'
import { api } from '~/app/api'
import { authenticated } from '~/app/cookie'

export interface Root {
  ratingCount: number
  avgRating: string
  ratings: Rating[]
  answers: Answers
}

export interface Rating {
  user: User
  ratings: Rating2[]
}

export interface User {
  profile: Profile
  _id: string
  company: Company
}

export interface Profile {
  name: string
}

export interface Company {
  _id: string
  name: string
}

export interface Rating2 {
  answers: string[]
  _id: string
  session: string
  otherAnswer?: string
  rating: number
}

export interface Answers {
  'coaching:chemistry_with_coach': number
  'coaching:coaching_style_structure': number
  'coaching:helpful_takeaways': number
}

const DEFAULT_VALUE: Root = {
  avgRating: '0',
  ratingCount: 0,
  ratings: [],
  answers: {
    'coaching:chemistry_with_coach': 0,
    'coaching:coaching_style_structure': 0,
    'coaching:helpful_takeaways': 0,
  },
}

const fetchFeedback = () =>
  queryOptions({
    initialData: DEFAULT_VALUE,
    queryKey: ['feedback'],
    queryFn: () => api.url('/coach/feedback').get().json<Root>(),
  })

export const loader = (queryClient: QueryClient) => () => {
  if (!authenticated()) {
    return redirect('/auth?mode=login')
  }

  return queryClient.ensureQueryData(fetchFeedback())
}
