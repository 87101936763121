import { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react'

import { useAuth } from '~/hooks/useAuth'
import { useAppSelector } from '~/app/hooks'
import { useIdentifyMixpanel } from '~/hooks/useMixpanel'
import { useIdentifyPosthog } from '~/hooks/useIdentifyPosthog'
import { useIdentifySentry } from '~/hooks/useIdentifySentry'
import { useInitNps } from '~/hooks/useInitNps'
import { useSettings } from '~/hooks/useSetttings'
import { LoadingSpinner } from '~/Loading'
import { LOCAL_TIMEZONE } from '~/app/constants'
import { NAVIGATION } from './dashboard.nav'
import { MobileDrawer } from './_components/MobileDrawer'
import { DesktopSidebar } from './_components/DesktopSidebar'
import { StickyHeader } from './_components/StickyHeader'
import { LogoutAlert } from './_components/LogoutAlert'
import { TimeZoneChangeAlert } from './_components/TimeZoneChangeDialog'
import { FeedbackSlideOver } from './_components/FeedbackSlideOver'
import { useTeam } from '~/hooks/useTeam'
import { useGroup } from '~/hooks/useGroup'
import { useInitSocket } from '~/hooks/useInitSocket'
import { NotificationProvider } from '~/components/notification/notification'
import { TroubleshootDialog } from './_components/TroubleshootDialog'

export const DashboardLayout: React.FC = () => {
  useIdentifyMixpanel()
  useIdentifyPosthog()
  useIdentifySentry()
  useInitSocket()
  const posthog = usePostHog()
  const [feedbackSlideOverOpen, setFeedbackSlideOverOpen] = useState(false)
  useInitNps({
    delay: 2,
    openSlideOver: setFeedbackSlideOverOpen,
  })
  const hasPendingNps = useAppSelector((state) => state.nps.hasPendingNps)
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' })
  const { pathname: currentPath } = useLocation()
  const { state: navigationState, location: to } = useNavigation()
  const { token, name, picture, loggedInAsAdmin } = useAuth()
  const { timeZone } = useSettings()
  const { hasTeamAccount } = useTeam()
  const { hasGroupAccount } = useGroup()

  const hasNpsFeatureEnable = useFeatureFlagEnabled('nps_feedback_tab')
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [troubleshootModalOpen, setTroubleshootModalOpen] = useState(false)
  const [logoutAlertOpen, setLogoutAlertOpen] = useState(false)
  const [newTimezoneDetected, setNewTimezoneDetected] = useState(
    timeZone !== LOCAL_TIMEZONE,
  )

  useEffect(() => {
    posthog.capture('$pageview')
  }, [posthog, currentPath])

  const navigation = useMemo(() => {
    return NAVIGATION.filter((item) => {
      if (item.onlyTeam) {
        return hasTeamAccount
      }
      if (item.onlyGroup) {
        return hasGroupAccount
      }
      return true
    })
  }, [hasTeamAccount, hasGroupAccount])

  const renderContent = useCallback(
    (nextPath?: string) => {
      const isLoading = navigationState === 'loading'

      if (
        isLoading &&
        !currentPath.includes('/chats/') &&
        nextPath?.includes('/chats/')
      ) {
        return <LoadingSpinner />
      }

      if (!isLoading && currentPath.includes('/chats/') && !isMobileScreen) {
        return <Outlet />
      }

      if (isLoading && nextPath?.includes('/chats/') && !isMobileScreen) {
        return <Outlet />
      }

      if (isMobileScreen && !isLoading && currentPath.includes('/chats/')) {
        return <Outlet />
      }

      return (
        <main className="py-5 sm:py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            {isLoading && !nextPath?.includes('/settings/') ? (
              <LoadingSpinner />
            ) : (
              <Outlet />
            )}
          </div>
        </main>
      )
    },
    [navigationState, currentPath, isMobileScreen],
  )

  if (token.length === 0) {
    return <Navigate to="/auth?mode=login" />
  }

  return (
    <>
      <div>
        <MobileDrawer
          name={name}
          picture={picture}
          navigation={navigation}
          isOpen={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          onFeedbackClick={() => setFeedbackSlideOverOpen(true)}
          hasPendingNps={hasPendingNps}
        />

        {/* Static sidebar for desktop */}
        <DesktopSidebar
          navigation={navigation}
          name={name}
          picture={picture}
          onFeedbackClick={() => setFeedbackSlideOverOpen(true)}
          onTroubleshootClick={() => setTroubleshootModalOpen(true)}
          hasPendingNps={hasPendingNps}
        />

        <div className="xl:pl-72">
          {/* Sticky search header */}
          <StickyHeader
            name={name}
            picture={picture}
            onLogout={() => setLogoutAlertOpen(true)}
            onOpenSidebar={() => setSidebarOpen(true)}
          />

          {renderContent(to?.pathname)}

          {/* Activity feed */}
          {/* <aside className="bg-black/10 lg:fixed lg:bottom-0 lg:right-0 lg:top-16 lg:w-96 lg:overflow-y-auto lg:border-l lg:border-white/5">
            <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
              <h2 className="text-base font-semibold leading-7 text-white">
                Activity feed
              </h2>
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-primary-400"
              >
                View all
              </a>
            </header>
            <ul role="list" className="divide-y divide-white/5">
              {ACTIVITY_ITEMS.map((item) => (
                <li key={item.commit} className="px-4 py-4 sm:px-6 lg:px-8">
                  <div className="flex items-center gap-x-3">
                    <img
                      src={item.user.imageUrl}
                      alt=""
                      className="h-6 w-6 flex-none rounded-full bg-gray-800"
                    />
                    <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-white">
                      {item.user.name}
                    </h3>
                    <time
                      dateTime={item.dateTime}
                      className="flex-none text-xs text-gray-600"
                    >
                      {item.date}
                    </time>
                  </div>
                  <p className="mt-3 truncate text-sm text-gray-500">
                    Pushed to{' '}
                    <span className="text-gray-400">{item.projectName}</span> (
                    <span className="font-mono text-gray-400">
                      {item.commit}
                    </span>{' '}
                    on <span className="text-gray-400">{item.branch}</span>)
                  </p>
                </li>
              ))}
            </ul>
          </aside> */}
        </div>
      </div>

      <FeedbackSlideOver
        isOpen={!!hasNpsFeatureEnable && feedbackSlideOverOpen}
        onClose={() => setFeedbackSlideOverOpen(false)}
      />

      <LogoutAlert isOpen={logoutAlertOpen} setIsOpen={setLogoutAlertOpen} />

      <TimeZoneChangeAlert
        localTimeZone={LOCAL_TIMEZONE}
        savedTimeZone={timeZone}
        isOpen={import.meta.env.PROD && newTimezoneDetected && !loggedInAsAdmin}
        onClose={() => setNewTimezoneDetected(false)}
      />

      <NotificationProvider />

      <TroubleshootDialog
        open={troubleshootModalOpen}
        setOpen={setTroubleshootModalOpen}
      />
    </>
  )
}
