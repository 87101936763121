import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  useLoaderData,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom'

import { Tabs, TabsList, TabsTrigger } from '~/components/ui/tabs'
import { cn } from '~/utils/cn'
import { useAuth } from '~/hooks/useAuth'
import {
  ChatUser as ChatUserType,
  TeamChatUser,
  loader,
} from './_loaders/chats'
import { LoadingSpinner } from '~/Loading'
import { ChatUser } from './chats/_components/ChatUser'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { setCoreChats, setGroupChats, setTeamChats } from '~/store/socket.slice'

type Params = { id: string; type: AppType }

export const Chats: React.FC = () => {
  const { isTeamCoach, isGroupCoach } = useAuth()
  const fetchedChats = useLoaderData() as Exclude<
    Awaited<ReturnType<ReturnType<typeof loader>>>,
    Response
  >
  // const { t } = useTranslation()
  const { type = 'core', id: chatId } = useParams<Params>()
  const { state } = useNavigation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { teamChats, coreChats, groupChats } = useAppSelector(
    (store) => store.socket,
  )
  const {
    coreUnreadMessagesCount,
    teamUnreadMessagesCount,
    groupUnreadMessagesCount,
  } = useAppSelector((state) => state.ui)

  const chats = useMemo(() => {
    switch (type) {
      case 'core':
        return coreChats
      case 'team':
        return teamChats
      case 'group':
        return groupChats
    }
  }, [type, coreChats, teamChats, groupChats])

  useEffect(() => {
    switch (type) {
      case 'core':
        dispatch(setCoreChats(fetchedChats as ChatUserType[]))
        break

      case 'team':
        dispatch(setTeamChats(fetchedChats as TeamChatUser[]))
        break

      case 'group':
        dispatch(setGroupChats(fetchedChats as TeamChatUser[]))
        break
    }
  }, [dispatch, type, fetchedChats])

  const content = useMemo(() => {
    const isLoading = state === 'loading'
    if (isLoading) return <LoadingSpinner />
    if (!chatId) return <ChatEmpty />
    return <Outlet />
  }, [state, chatId])

  return (
    <div className="h-[calc(100dvh-64px)] overflow-hidden rounded-md grid grid-cols-[1fr] md:grid-cols-[300px_1fr] lg:grid-cols-[350px_1fr]">
      <div
        className={cn(
          'bg-white border-r-[1px] border-r-gray-150 md:grid grid-rows-[70px_1fr] overflow-hidden',
          chatId ? 'hidden' : 'grid',
        )}
      >
        {isTeamCoach || isGroupCoach ? (
          <div className="grid p-3 gap-3 border-b">
            <Tabs
              defaultValue={type ?? 'core'}
              onValueChange={(value) => navigate(`/chats/${value}`)}
            >
              <TabsList
                className={cn(
                  'grid w-full',
                  isTeamCoach && isGroupCoach ? 'grid-cols-3' : 'grid-cols-2',
                )}
              >
                <TabsTrigger value="core">
                  <span>Individual</span>
                  {coreUnreadMessagesCount > 0 && (
                    <div className="pl-1.5">
                      <span className="bg-primary text-white rounded-full text-[10px] px-2 py-1">
                        {coreUnreadMessagesCount}
                      </span>
                    </div>
                  )}
                </TabsTrigger>
                {isTeamCoach && (
                  <TabsTrigger value="team">
                    <span>Team</span>
                    {teamUnreadMessagesCount > 0 && (
                      <div className="pl-1.5">
                        <span className="bg-primary text-white rounded-full text-[10px] px-2 py-1">
                          {teamUnreadMessagesCount}
                        </span>
                      </div>
                    )}
                  </TabsTrigger>
                )}
                {isGroupCoach && (
                  <TabsTrigger value="group">
                    <span>Group</span>
                    {groupUnreadMessagesCount > 0 && (
                      <div className="pl-1.5">
                        <span className="bg-primary text-white rounded-full text-[10px] px-2 py-1">
                          {groupUnreadMessagesCount}
                        </span>
                      </div>
                    )}
                  </TabsTrigger>
                )}
              </TabsList>
            </Tabs>
          </div>
        ) : null}

        {/* ( <div className="grid p-3 gap-3 border-b">
            <input
              type="text"
              placeholder={t('search')}
              className="border border-slate-200 bg-slate-100 rounded-[3px] px-5 focus:ring-2 focus:ring-inset focus:ring-primary"
            />
          </div>
        ) */}

        {/* Users list */}
        <ul
          role="list"
          className="divide-y divide-gray-150 overflow-y-auto min-h-screen pb-36"
        >
          {(chats ?? []).map((item) => (
            <ChatUser
              key={item?.user?.id ?? item?.chat?.id}
              onClick={(id) => navigate(`./${id}`)}
              data={item}
            />
          ))}
        </ul>
      </div>
      <div className={cn('bg-slate-50 md:grid', chatId ? 'grid' : 'hidden')}>
        {content}
      </div>
    </div>
  )
}

const ChatEmpty: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="items-center justify-center flex flex-col select-none pointer-events-none gap-12">
      <img src="/begin-chat.svg" alt="Chat begin" className="h-[120px]" />
      <p className="text-base text-slate-400">{t('selectUserToChat')}</p>
    </div>
  )
}
