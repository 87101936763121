import { useMemo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './hooks/useAuth'

export const NonAuthGuard: React.FC = () => {
  const { token } = useAuth()
  const isLoggedIn = useMemo(() => token.length > 0, [token])

  if (isLoggedIn) {
    return <Navigate to="/" />
  }

  return <Outlet />
}
