import { Fragment, useMemo, useRef, type PropsWithChildren } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

interface BasicDialogProps {
  title: string
  isOpen: boolean
  toolbar?: React.ReactNode
  onClose: () => void
}

export const BasicDialog: React.FC<BasicDialogProps & PropsWithChildren> = (
  props,
) => {
  const { title, isOpen, onClose, toolbar, children } = props
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const closeButton = useMemo(
    () => (
      <button
        className="relative rounded-full bg-white p-1 text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
        onClick={onClose}
        ref={closeButtonRef}
      >
        <span className="absolute -inset-1.5" />
        <span className="sr-only">Close the dialog</span>
        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    ),
    [onClose],
  )

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100]"
        initialFocus={closeButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 lg:min-w-[32rem] max-sm:w-full overflow-visible sm:max-w-sm sm:p-6">
                <div className="flex gap-10 justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>

                  {toolbar ? (
                    <div className="flex gap-5">
                      {toolbar}
                      {closeButton}
                    </div>
                  ) : (
                    <div>{closeButton}</div>
                  )}
                </div>
                <div>
                  <div className="mt-2 sm:mt-4">{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
