import { forwardRef } from 'react'
import { cn } from '~/utils/cn'

interface TextAreaProps {
  label: string
  placeholder: string
  description?: string | React.ReactNode
}

type Props = TextAreaProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const TextArea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { id, label, className, ...rest } = props

  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}

        {props.description &&
          (typeof props.description === 'string' ? (
            <p className="mb-1 text-sm text-gray-500">{props.description}</p>
          ) : (
            props.description
          ))}
      </label>
      <div className="mt-2">
        <textarea
          ref={ref}
          id={id}
          className={cn(
            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6',
            className,
          )}
          {...rest}
        />
      </div>
    </div>
  )
})

TextArea.displayName = 'TextArea'
