import { Suspense, useState } from 'react'
import {
  Await,
  useLoaderData,
  useParams,
  useRevalidator,
} from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import formatDistance from 'date-fns/formatDistance'
import {
  CalendarIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { type CoachNote, loader } from './_loaders/coach-notes'
import { CoachNoteDialog } from './_components/CoachNoteDialog'
import { AlertDialog } from '~/components/AlertDialog'
import { api } from '~/app/api'
import { useToast } from '~/components/ui/use-toast'
import { LoadingSpinner } from '~/Loading'
import { queryClient } from '~/query'

export const CoachNotes: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deferredData: any = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >
  let resolvedData = deferredData.data
  if (deferredData.coachNotes && deferredData.coachNotes.data) {
    resolvedData = deferredData.coachNotes.data
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={resolvedData} errorElement={<div>Error</div>}>
        {(data) => <CoachNotesWithData data={data} />}
      </Await>
    </Suspense>
  )
}

const CoachNotesWithData: React.FC<{ data: CoachNote[] }> = (props) => {
  const { data } = props
  const { toast } = useToast()
  const { revalidate } = useRevalidator()
  const { id: userId } = useParams<{ id: string }>()
  const [isNoteDeletingAlertOpen, setIsNoteDeletingAlertOpen] = useState(false)
  const [isCoachNoteModalOpen, setIsCoachNoteModalOpen] = useState(false)
  const [selectedCoachNote, setSelectedCoachNote] = useState<{
    id: string
    body: string
  }>({ id: '', body: '' })

  const { mutate: deleteNote } = useMutation({
    mutationKey: ['coach', 'notes', 'delete'],
    async mutationFn(data: { noteId: string }) {
      await api.url(`/coach/notes/${data.noteId}`).delete().json()
    },
    async onSuccess() {
      toast({
        title: 'Success',
        description: 'Note deleted successfully',
        variant: 'success',
      })

      await queryClient.invalidateQueries({
        queryKey: ['users', userId, 'coach', 'notes'],
        exact: true,
      })
      revalidate()
    },
    onSettled() {
      setIsNoteDeletingAlertOpen(false)
    },
  })

  let content
  if (data.length === 0) {
    content = (
      <div className="h-full px-4 py-5 space-y-4 sm:px-6 sm:py-2">
        <p className="text-center">No notes yet</p>
      </div>
    )
  } else {
    content = (
      <div className="divide-y-2">
        {data.map((note) => (
          <div key={note._id} className="first:pt-0 pt-4">
            <div className="flex justify-between text-gray-500">
              <p className="flex items-center gap-2">
                <CalendarIcon className="h-5" />
                {formatDistance(new Date(note.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </p>
              <div className="flex">
                <button
                  className="py-1 px-2"
                  onClick={() => {
                    setSelectedCoachNote({ id: note._id, body: note.body.text })
                    setIsCoachNoteModalOpen(true)
                  }}
                >
                  <PencilIcon className="h-5" />
                </button>
                <button
                  className="py-1 px-2"
                  onClick={() => {
                    setSelectedCoachNote({ id: note._id, body: note.body.text })
                    setIsNoteDeletingAlertOpen(true)
                  }}
                >
                  <TrashIcon className="h-5" />
                </button>
              </div>
            </div>

            <div className="mb-4 mt-2">
              <p className="break-all whitespace-normal">{note.body.text}</p>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      <div className="flex-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="flex items-center justify-between px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Coach (private) notes
          </h3>
          <button
            className="p-1"
            onClick={() => {
              setSelectedCoachNote({ id: '', body: '' })
              setIsCoachNoteModalOpen(true)
            }}
          >
            <PlusCircleIcon className="h-6" />
          </button>
        </div>
        <div className="h-full bg-gray-50 px-4 py-5 sm:px-6 sm:py-2">
          {content}
        </div>
      </div>

      <CoachNoteDialog
        isOpen={isCoachNoteModalOpen}
        onClose={() => setIsCoachNoteModalOpen(false)}
        editMode={selectedCoachNote.id.length > 0}
        userId={userId}
        noteId={selectedCoachNote.id}
        note={selectedCoachNote.body}
      />

      <AlertDialog
        isOpen={isNoteDeletingAlertOpen}
        onClose={() => setIsNoteDeletingAlertOpen(false)}
        title="Delete the note"
        onConfirm={() => {
          const noteId = selectedCoachNote.id
          if (noteId.length === 0) return
          deleteNote({ noteId })
          setIsNoteDeletingAlertOpen(false)
        }}
        confirmText="Delete"
      >
        <p className="text-sm text-gray-500">
          Are you sure? You can't undo this action afterwards.
        </p>
      </AlertDialog>
    </>
  )
}
