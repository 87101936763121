/* eslint-disable @typescript-eslint/no-explicit-any */
import { redirect, type LoaderFunctionArgs } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'
import { authenticated } from '~/app/cookie'

const fetchUsers = (isActive: boolean) =>
  queryOptions({
    queryKey: ['users', isActive ? 'active' : 'inactive'],
    queryFn() {
      return api.url('/coach/customers').query({ isActive }).get().json<any[]>()
    },
  })

export const loader =
  (queryClient: QueryClient) => (args: LoaderFunctionArgs) => {
    if (!authenticated()) {
      return redirect('/auth?mode=login')
    }

    const url = new URL(args.request.url)
    const active = url.searchParams.get('active')
    if (!active) {
      return redirect('/users?active=true')
    }

    return queryClient.ensureQueryData(fetchUsers(active === 'true'))
  }
