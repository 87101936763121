import type { EventData } from '../schedule'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { api, groupApi, teamApi } from '~/app/api'
import { useToast } from '~/components/ui/use-toast'
import { AlertDialog } from '~/components/AlertDialog'
import { Toggle } from '~/components/Toggle'
import { useAuth } from '../../../hooks/useAuth'

interface DeleteEventProps {
  onClose: () => void
  onDeleted: () => void
  isOpen: boolean
  eventData: EventData
}

const getTitle = (type: string) => {
  switch (type) {
    case 'session':
      return 'Cancel the session'
    case 'team-session':
      return 'Cancel the team session'
    default:
      return `Delete the ${type}`
  }
}

export const DeleteEventAlert: React.FC<DeleteEventProps> = (props) => {
  const { isOpen = false, onClose, onDeleted, eventData } = props
  const { toast } = useToast()
  const [requestedByUser, setRequestedByUser] = useState(false)
  const { isTeamCoach, isGroupCoach } = useAuth()

  const { mutate: cancelSession } = useMutation({
    mutationKey: ['schedule', 'session', 'delete'],
    async mutationFn(data: { id: string; requestedByUser: boolean }) {
      const { requestedByUser } = data
      await api
        .url(`/coach/events/${data.id}`)
        .query({ requestedByUser })
        .delete()
        .json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Event cancelled successfully',
        variant: 'success',
      })
      onDeleted()
    },
    onSettled() {
      onClose()
    },
  })

  const { mutate: deleteBlockEvent } = useMutation({
    mutationKey: ['schedule', 'block', 'delete'],
    async mutationFn(data: { eventId: string; ref: string | undefined }) {
      await api
        .url(`/coach/events/block/${data.eventId}/${data.ref}`)
        .delete()
        .json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Block event deleted',
        variant: 'success',
      })
      onDeleted()
    },
    onSettled() {
      onClose()
    },
  })
  const { mutate: deleteTeamBlockEvent } = useMutation({
    mutationKey: ['team', 'schedule', 'block', 'delete'],
    async mutationFn(data: { eventId: string; ref: string | undefined }) {
      await teamApi
        .url(`/coach/events/block/${data.eventId}/${data.ref}`)
        .delete()
        .json()
    },
  })
  const { mutate: deleteGroupBlockEvent } = useMutation({
    mutationKey: ['group', 'schedule', 'block', 'delete'],
    async mutationFn(data: { eventId: string; ref: string | undefined }) {
      await groupApi
        .url(`/coach/events/block/${data.eventId}/${data.ref}`)
        .delete()
        .json()
    },
  })

  const { mutate: deleteOpenSlot } = useMutation({
    mutationKey: ['schedule', 'slot', 'delete'],
    async mutationFn(data: { eventId: string; ref: string | undefined }) {
      await api
        .url(`/coach/events/open/${data.eventId}/${data.ref}`)
        .delete()
        .json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Open slot deleted',
        variant: 'success',
      })
      onDeleted()
    },
    onSettled() {
      onClose()
    },
  })
  const { mutate: deleteTeamOpenSlot } = useMutation({
    mutationKey: ['team', 'schedule', 'slot', 'delete'],
    async mutationFn(data: { eventId: string; ref: string | undefined }) {
      await teamApi
        .url(`/coach/events/open/${data.eventId}/${data.ref}`)
        .delete()
        .json()
    },
  })
  const { mutate: deleteGroupOpenSlot } = useMutation({
    mutationKey: ['group', 'schedule', 'slot', 'delete'],
    async mutationFn(data: { eventId: string; ref: string | undefined }) {
      await groupApi
        .url(`/coach/events/open/${data.eventId}/${data.ref}`)
        .delete()
        .json()
    },
  })

  const onEventDelete = (data: EventData, confirmed = false) => {
    const { type, id, ref } = data
    // setEventData(data)

    // if (!isDeletingAlertOpen && !confirmed) {
    //   setIsDeletingAlertOpen(true)
    //   return
    // }

    if (type === 'open' && confirmed) {
      deleteOpenSlot({ eventId: id, ref: ref })
      if (isTeamCoach) {
        deleteTeamOpenSlot({ eventId: id, ref: ref })
      }
      if (isGroupCoach) {
        deleteGroupOpenSlot({ eventId: id, ref: ref })
      }
    }

    if (type === 'block' && confirmed) {
      deleteBlockEvent({ eventId: id, ref: ref })
      if (isTeamCoach) {
        deleteTeamBlockEvent({ eventId: id, ref: ref })
      }
      if (isGroupCoach) {
        deleteGroupBlockEvent({ eventId: id, ref: ref })
      }
    }

    if (type === 'session' && confirmed) {
      cancelSession({ id, requestedByUser })
    }
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      // title="Delete the event" // Delete the block slot
      title={getTitle(eventData.type)}
      confirmText={
        eventData.type === 'session' ? 'Cancel the session' : 'Delete'
      }
      onConfirm={() => onEventDelete(eventData, true)}
      onClose={onClose}
    >
      <div className="text-sm text-gray-500 mb-2">
        {eventData.type === 'session' && (
          <div className="flex items-center gap-3 my-5">
            <Toggle
              aria-label="Requested by user"
              enabled={requestedByUser}
              setEnabled={setRequestedByUser}
            />
            <p>Requested by user</p>
          </div>
        )}

        <p>
          Are you sure you want to {getTitle(eventData.type).toLowerCase()}?
        </p>
      </div>
    </AlertDialog>
  )
}
