import { useId, useState } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { cn } from '~/utils/cn'
import { DURATION_OPTIONS } from '~/app/constants'
import { useAppSelector } from '~/app/hooks'

interface Props {
  className?: string
  value: Date | null
  onChange: (start: Date | null, duration: number) => void
}

export const TimePickerWithDuration: React.FC<Props> = (props) => {
  const { className, value, onChange } = props
  const [duration, setDuration] = useState(DURATION_OPTIONS[1])
  const { timeZone: tz } = useAppSelector((state) => state.settings)
  const id = useId()

  return (
    <div className={cn('flex max-lg:flex-col', className)}>
      <input
        id={`time-picker-${id}`}
        type="time"
        className={cn(
          'block w-full rounded-l-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
          'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
        )}
        value={value ? formatInTimeZone(value, tz, 'HH:mm') : ''}
        onChange={(e) => {
          const date = new Date(value ? value : new Date())
          const [hours, minutes] = e.target.value.split(':')
          date.setHours(Number(hours))
          date.setMinutes(Number(minutes))
          onChange?.(date, duration.minutes)
        }}
        required
      />

      <Menu>
        <MenuButton className="relative flex-1 lg:min-w-[6.4rem] -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-800 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {duration.name}
          <ChevronDownIcon
            className="-ml-0.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </MenuButton>
        <MenuItems
          transition
          anchor="bottom"
          className="z-50 w-36 origin-top-right rounded-lg divide-y divide-gray-100  border border-black/5 bg-white p-1 text-sm text-gray-900 transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          {DURATION_OPTIONS.map((option) => (
            <MenuItem key={option.minutes}>
              <button
                className={cn(
                  'inline-flex w-full px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 dark:text-gray-200 dark:data-[focus]:bg-gray-600 dark:data-[focus]:text-white',
                  duration.minutes === option.minutes &&
                    'bg-gray-100 dark:bg-gray-600 dark:text-white',
                )}
                onClick={() => {
                  setDuration(option)
                  onChange?.(value, option.minutes)
                }}
              >
                {option.name}
              </button>
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    </div>
  )
}
