/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, useMemo } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import { UserAssessmentAnswers } from './_components/UserAssessmentAnswers'
import { Data, loader } from './_loaders/assessment-results'
import { LoadingSpinner } from '~/Loading'
import {
  leadershipValueScalePeer,
  leadershipValueScaleSelf,
  ratingScale,
} from './ratings'
import { useTranslation } from 'react-i18next'

const transformRatingsData = (ratings: any, scale: any) =>
  ratings && [
    {
      _id: 'competency-results',
      question: 'Ratings',
      answers: ratings.map(
        (competency: any) =>
          `${competency.focusArea} - (${
            scale[competency.rating - 1]?.level ?? competency.rating
          })`,
      ),
    },
  ]

export const AssessmentResults: React.FC = () => {
  const deferredData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={deferredData.data} errorElement={<div>Error</div>}>
        {(data) => <AssessmentResultsWithData data={data} />}
      </Await>
    </Suspense>
  )
}

export const AssessmentResultsWithData: React.FC<{ data: Data }> = (props) => {
  const { data } = props
  const { t } = useTranslation()

  const isAssessmentsAvailable = useMemo(() => {
    const {
      userAssessmentAnswers,
      managerAssessmentAnswers,
      reporteeAssessmentAnswers,
      peerAssessmentAnswers,
    } = data

    return (
      userAssessmentAnswers.midpoint?.length > 0 ||
      userAssessmentAnswers.initial?.length > 0 ||
      managerAssessmentAnswers.initial?.length > 0 ||
      reporteeAssessmentAnswers.initial?.length > 0 ||
      peerAssessmentAnswers.midpoint?.length > 0 ||
      peerAssessmentAnswers.midpoint?.length > 0
    )
  }, [data])

  if (!isAssessmentsAvailable) {
    return (
      <div className="bg-gray-50 rounded-md w-full transition-all space-y-10 divide-y-2">
        <p className="text-center my-5">No assessment results</p>
      </div>
    )
  }

  const getScale = (type: string) => {
    const scaleFunc =
      type === 'self'
        ? leadershipValues?.values?.length
          ? leadershipValueScaleSelf
          : ratingScale
        : leadershipValues?.values?.length
          ? leadershipValueScalePeer
          : ratingScale

    return scaleFunc(t)
  }

  const {
    userAssessmentAnswers,
    managerAssessmentAnswers,
    reporteeAssessmentAnswers,
    peerAssessmentAnswers,
    leadershipValues,
  } = data

  return (
    <div className="flex-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="bg-gray-50 transition-all space-y-10 divide-y-2">
        {(userAssessmentAnswers.midpoint.length > 0 ||
          reporteeAssessmentAnswers.midpoint.length > 0) && (
          <div className="px-4 py-5 space-y-4 sm:px-6 sm:py-2">
            <p className="text-center font-bold">Progress Check-in</p>

            {/* User */}
            {userAssessmentAnswers.midpoint.map((midpoint) => (
              <UserAssessmentAnswers
                key={midpoint.assessmentDate}
                type="User"
                assessmentDate={midpoint.assessmentDate}
                shareWithUser={midpoint.shareWithUser}
                data={[
                  {
                    answers: midpoint.surveyAnswers,
                    sharedWithUser: midpoint.shareWithUser,
                  },
                  {
                    answers: transformRatingsData(
                      midpoint.ratings,
                      getScale('self'),
                    ),
                    sharedWithUser: null,
                  },
                ]}
              />
            ))}

            {/* Manager */}
            {managerAssessmentAnswers.midpoint.map((midpoint) => (
              <UserAssessmentAnswers
                key={midpoint.assessmentDate}
                type="Manager"
                assessmentDate={midpoint.assessmentDate}
                shareWithUser={midpoint.shareWithUser}
                data={[
                  {
                    answers: midpoint.surveyAnswers,
                    sharedWithUser: midpoint.shareWithUser,
                  },
                  {
                    answers: transformRatingsData(
                      midpoint.ratings,
                      getScale('peer'),
                    ),
                    sharedWithUser: undefined,
                  },
                ]}
              />
            ))}

            {/* Reportee */}
            {reporteeAssessmentAnswers.midpoint.map((midpoint) => (
              <UserAssessmentAnswers
                key={midpoint.assessmentDate}
                type="Reportee"
                assessmentDate={midpoint.assessmentDate}
                shareWithUser={midpoint.shareWithUser}
                data={[
                  {
                    answers: midpoint.surveyAnswers,
                    sharedWithUser: midpoint.shareWithUser,
                  },
                  {
                    answers: transformRatingsData(
                      midpoint.ratings,
                      getScale('peer'),
                    ),
                    sharedWithUser: undefined,
                  },
                ]}
              />
            ))}

            {/* Peer */}
            {peerAssessmentAnswers.midpoint.map((midpoint, index) => (
              <UserAssessmentAnswers
                key={midpoint.assessmentDate}
                type={`Peer#${index + 1}`}
                assessmentDate={midpoint.assessmentDate}
                shareWithUser={midpoint.shareWithUser}
                data={[
                  {
                    answers: midpoint.surveyAnswers,
                    sharedWithUser: midpoint.shareWithUser,
                  },
                  {
                    answers: transformRatingsData(
                      midpoint.ratings,
                      getScale('peer'),
                    ),
                    sharedWithUser: undefined,
                  },
                ]}
              />
            ))}
          </div>
        )}
        {(userAssessmentAnswers.initial.length > 0 ||
          managerAssessmentAnswers.initial.length > 0) && (
          <div className="px-4 py-5 space-y-4 sm:px-6 sm:py-2">
            <p className="text-center font-bold">Initial Assessment</p>

            {/* User */}
            {userAssessmentAnswers.initial.map((assessment) => (
              <UserAssessmentAnswers
                key={assessment.assessmentDate}
                type="User"
                assessmentDate={assessment.assessmentDate}
                shareWithUser={assessment.shareWithUser}
                data={[
                  {
                    answers: assessment.surveyAnswers,
                    sharedWithUser: assessment.shareWithUser,
                  },
                  {
                    answers: transformRatingsData(
                      assessment.ratings,
                      getScale('self'),
                    ),
                    sharedWithUser: undefined,
                  },
                ]}
              />
            ))}

            {/* Manager */}
            {managerAssessmentAnswers.initial.map((assessment) => (
              <UserAssessmentAnswers
                key={assessment.assessmentDate}
                type="Manager"
                assessmentDate={assessment.assessmentDate}
                shareWithUser={assessment.shareWithUser}
                data={[
                  {
                    answers: assessment.surveyAnswers,
                    sharedWithUser: assessment.shareWithUser,
                  },
                  {
                    answers: transformRatingsData(
                      assessment.ratings,
                      getScale('peer'),
                    ),
                    sharedWithUser: undefined,
                  },
                ]}
              />
            ))}

            {/* Peer */}
            {peerAssessmentAnswers.initial.map((assessment) => (
              <UserAssessmentAnswers
                key={assessment.assessmentDate}
                type={assessment.doneBy ?? 'Peer'}
                assessmentDate={assessment.assessmentDate}
                shareWithUser={assessment.shareWithUser}
                data={[
                  {
                    answers: assessment.surveyAnswers,
                    sharedWithUser: assessment.shareWithUser,
                  },
                  {
                    answers: transformRatingsData(
                      assessment.ratings,
                      getScale('peer'),
                    ),
                    sharedWithUser: undefined,
                  },
                ]}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
