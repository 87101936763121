import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import {
  XMarkIcon,
  UserCircleIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'

import { LOGO } from '~/app/constants'
import { FEEDBACK_HUB, NAVIGATION, RESOURCES } from '../dashboard.nav'
import { cn } from '~/utils/cn'

interface Props {
  isOpen: boolean
  onClose: () => void
  name: string
  picture: string
  navigation: typeof NAVIGATION
  onFeedbackClick: () => void
  hasPendingNps: boolean
}

export const MobileDrawer: React.FC<Props> = (props: Props) => {
  const hasNpsFeatureEnable = useFeatureFlagEnabled('nps_feedback_tab')
  const {
    navigation,
    isOpen,
    onClose,
    name,
    picture,
    onFeedbackClick,
    hasPendingNps,
  } = props

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 xl:hidden" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 px-6 ring-1 ring-white/10">
                <div className="flex h-16 shrink-0 items-center">
                  <img className="h-8 w-auto" src={LOGO.src} alt={LOGO.alt} />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <NavLink
                              to={item.href}
                              onClick={onClose}
                              className={({ isActive }) =>
                                cn(
                                  isActive
                                    ? 'bg-gray-50 text-primary-600 dark:bg-gray-800 dark:text-white'
                                    : 'text-gray-700 hover:text-primary-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                )
                              }
                            >
                              {({ isActive }) => (
                                <>
                                  <item.icon
                                    className={cn(
                                      isActive
                                        ? 'text-primary-600'
                                        : 'text-gray-400 group-hover:text-primary-600',
                                      'h-6 w-6 shrink-0',
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </>
                              )}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">
                        Resources
                      </div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {RESOURCES.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              onClick={(e) => {
                                onClose()
                                item.action?.(e)
                              }}
                              className={cn(
                                'text-gray-700 hover:text-primary-600 hover:bg-gray-50 dark:bg-gray-800 dark:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                'dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-800',
                              )}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <item.icon
                                className={cn(
                                  'text-gray-400 group-hover:text-primary-600 dark:text-gray-50 dark:group-hover:text-white',
                                  'h-6 w-6 shrink-0',
                                )}
                                aria-hidden="true"
                              />
                              <span className="flex items-center justify-center gap-2">
                                {item.name}
                                {item.external && (
                                  <ArrowTopRightOnSquareIcon
                                    className="h-4 w-4 text-gray-400 dark:text-gray-50"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    {hasNpsFeatureEnable && (
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">
                          Feedback Hub
                        </div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {FEEDBACK_HUB.map((item, index) => (
                            <li key={item.name}>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  if (index === 0) onFeedbackClick()
                                }}
                                className={cn(
                                  'text-gray-700 hover:text-primary-600 hover:bg-gray-50 dark:bg-gray-800 dark:text-white',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                  'dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-800',
                                )}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <item.icon
                                  className={cn(
                                    'text-gray-400 group-hover:text-primary-600 dark:text-gray-50 dark:group-hover:text-white',
                                    'h-6 w-6 shrink-0',
                                  )}
                                  aria-hidden="true"
                                />
                                <span className="flex items-center justify-center gap-2">
                                  {item.name}
                                  {hasPendingNps && (
                                    <span
                                      className="ml-auto w-2 h-2 rounded-full bg-primary"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}
                    <li className="-mx-6 mt-auto">
                      <NavLink
                        to="/settings/profile"
                        onClick={onClose}
                        className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 dark:text-white dark:bg-gray-800"
                      >
                        {picture ? (
                          <img
                            className="h-8 w-8 object-cover rounded-full dark:bg-gray-800 bg-gray-100"
                            src={picture}
                            alt={name}
                            referrerPolicy="no-referrer"
                          />
                        ) : (
                          <UserCircleIcon className="h-8 w-8 rounded-full text-gray-400" />
                        )}
                        <span className="sr-only">Your profile</span>
                        <span aria-hidden="true">{name}</span>
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
