import { cn } from '~/utils/cn'

type BadgeType =
  | 'gray'
  | 'red'
  | 'green'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'primary'

interface BadgeProps {
  variant: BadgeType
}

const badgeVariants: Record<BadgeType, string> = {
  gray: 'bg-gray-50 text-gray-600 ring-gray-500/10',
  red: 'bg-red-50 text-red-700 ring-red-600/10',
  green: 'bg-green-50 text-green-700 ring-green-600/10',
  blue: 'bg-blue-50 text-blue-700 ring-blue-700/10',
  indigo: 'bg-indigo-50 text-indigo-700 ring-indigo-700/10',
  purple: 'bg-purple-50 text-purple-700 ring-purple-700/10',
  pink: 'bg-pink-50 text-pink-700 ring-pink-700/10',
  primary: 'bg-gray-50 text-primary-700 ring-primary-700/10',
}

type Props = React.PropsWithChildren<BadgeProps> &
  React.HTMLAttributes<HTMLSpanElement>

export const Badge: React.FC<Props> = (props) => {
  const { variant, children, ...rest } = props

  return (
    <span
      className={cn(
        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
        badgeVariants[variant],
      )}
      {...rest}
    >
      {children}
    </span>
  )
}
