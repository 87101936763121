import { store } from './app/store'

const unsubscribe = store.subscribe(() => {
  const { auth, settings } = store.getState()

  if (auth.id) {
    window._chatlio.identify(auth.id, {
      email: auth.email,
      name: auth.name,
      timeZone: settings.timeZone,
      timeFormat: settings.timeFormat,
    })

    unsubscribe()
  }
})

document.addEventListener('chatlio.collapsed', () => {
  const path = location.pathname
  if (path.startsWith('/auth') || path.startsWith('/onboarding')) return

  window._chatlio.hide()
})
