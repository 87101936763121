/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, useState } from 'react'
import { Await, Link, useLoaderData } from 'react-router-dom'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import startOfDay from 'date-fns/startOfDay'
import addHours from 'date-fns/addHours'
import format from 'date-fns/format'

import { COMPANY_NAME, DEFAULT_COACH_TITLE } from '~/app/constants'
import { useAuth } from '~/hooks/useAuth'
import { MiniCalendar } from './_components/MiniCalendar'
import { BasicDialog } from '~/components/BasicDialog'
import { BookSessionDialog } from './_components/BookSessionDialog'
import { api, groupApi, teamApi } from '~/app/api'
import { CoachTitle } from './_components/CoachTitle'
import { Stats, StatsFallback } from './_components/Stats'
import { RecentUsers, RecentUsersFallback } from './_components/RecentUsers'
import { QuickStart, QuickStartData } from '~/components/quick-start/QuickStart'

const now = new Date()
now.setMinutes(0, 0, 0)
const date = startOfDay(now)
date.setHours(now.getHours())

const year = now.getFullYear()

const initialCalendarData = {
  events: {},
}

// const announcements = [
//   {
//     id: 1,
//     title: 'Office closed on July 2nd',
//     href: '#',
//     preview:
//       'Cum qui rem deleniti. Suscipit in dolor veritatis sequi aut. Vero ut earum quis deleniti. Ut a sunt eum cum ut repudiandae possimus. Nihil ex tempora neque cum consectetur dolores.',
//   },
//   {
//     id: 2,
//     title: 'New password policy',
//     href: '#',
//     preview:
//       'Alias inventore ut autem optio voluptas et repellendus. Facere totam quaerat quam quo laudantium cumque eaque excepturi vel. Accusamus maxime ipsam reprehenderit rerum id repellendus rerum. Culpa cum vel natus. Est sit autem mollitia.',
//   },
//   {
//     id: 3,
//     title: 'Office closed on July 2nd',
//     href: '#',
//     preview:
//       'Tenetur libero voluptatem rerum occaecati qui est molestiae exercitationem. Voluptate quisquam iure assumenda consequatur ex et recusandae. Alias consectetur voluptatibus. Accusamus a ab dicta et. Consequatur quis dignissimos voluptatem nisi.',
//   },
// ]

export const Root: React.FC = () => {
  const { name, picture, isTeamCoach, isGroupCoach } = useAuth()
  const [month, setMonth] = useState(format(now, 'yyyy-MM'))
  const data = useLoaderData() as any

  const quickStartEnabled = useFeatureFlagEnabled('show_getting_started')
  const [showBookSession, setShowBookSession] = useState(false)
  const [defaultStart] = useState(now)
  const [defaultEnd] = useState(addHours(now, 1))

  const { data: quickStartData, refetch } = useQuery({
    queryKey: ['home', 'quick-start-progess'],
    enabled: quickStartEnabled,
    async queryFn() {
      const core = await api.url('/coach/home/quick-start-progess').get().json<{
        data: QuickStartData
      }>()

      return core.data
    },
  })

  const { data: calendarData } = useQuery({
    initialData: initialCalendarData,
    queryKey: ['home', 'events', month],
    async queryFn() {
      const core = await api
        .url('/coach/home/calendar')
        .query({ month })
        .get()
        .json<any>()

      if (!isTeamCoach) return core

      const team = await teamApi
        .url('/coach/home/calendar')
        .query({ month })
        .get()
        .json<any>()

      for (const date in team.events) {
        if (Object.prototype.hasOwnProperty.call(team.events, date)) {
          if (core.events[date]) {
            core.events[date] = core.events[date].concat(team.events[date])
          } else {
            core.events[date] = team.events[date]
          }
        }
      }

      if (!isGroupCoach) return core

      const group = await groupApi
        .url('/coach/home/calendar')
        .query({ month })
        .get()
        .json<any>()

      for (const date in group.events) {
        if (Object.prototype.hasOwnProperty.call(group.events, date)) {
          if (core.events[date]) {
            core.events[date] = core.events[date].concat(group.events[date])
          } else {
            core.events[date] = group.events[date]
          }
        }
      }

      return core
    },
  })

  const onClickBookASession = () => {
    setShowBookSession(true)
  }

  const onCloseBookASession = () => {
    setShowBookSession(false)
  }

  return (
    <>
      <div className="pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">Profile</h1>
          {/* Main 3 column grid */}
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            {/* Left column */}
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              {/* Welcome panel */}
              <section aria-labelledby="profile-overview-title">
                <div className="overflow-hidden rounded-lg bg-white shadow-md">
                  <h2 id="profile-overview-title" className="sr-only">
                    Profile Overview
                  </h2>
                  <div className="bg-white p-6">
                    <div className="sm:flex sm:items-center sm:justify-between">
                      <div className="sm:flex sm:space-x-5">
                        <div className="flex-shrink-0">
                          {picture ? (
                            <img
                              className="mx-auto h-20 w-20 object-cover rounded-full"
                              src={picture}
                              alt={name}
                            />
                          ) : (
                            <UserCircleIcon
                              className="h-20 w-20 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                          <p className="text-sm font-medium text-gray-600">
                            Welcome back,
                          </p>
                          <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                            {name}
                          </p>
                          {
                            <Suspense
                              fallback={
                                <p className="text-sm font-medium text-gray-600">
                                  {DEFAULT_COACH_TITLE}
                                </p>
                              }
                            >
                              <Await
                                resolve={data.coach}
                                errorElement={<div>Could not load data 😬</div>}
                              >
                                <CoachTitle />
                              </Await>
                            </Suspense>
                          }
                        </div>
                      </div>
                      <div className="mt-5 flex justify-center sm:mt-0">
                        <Link
                          to="/settings/profile"
                          className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Edit profile
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
                    {
                      <Suspense fallback={<StatsFallback />}>
                        <Await
                          resolve={data.metrics}
                          errorElement={<div>Could not load metrics 😬</div>}
                        >
                          <Stats />
                        </Await>
                      </Suspense>
                    }
                  </div>
                </div>
              </section>

              {/* Actions panel */}
              {quickStartEnabled &&
                quickStartData &&
                !quickStartData.allDone && (
                  <section aria-labelledby="quick-start">
                    <QuickStart data={quickStartData} refetch={refetch} />
                  </section>
                )}
              <section aria-labelledby="mini-calendar">
                <MiniCalendar
                  events={calendarData.events}
                  month={month}
                  monthChange={setMonth}
                  addEvent={onClickBookASession}
                />
              </section>
            </div>

            {/* Right column */}
            <div className="grid grid-cols-1 gap-4">
              {/* Announcements */}
              {/* <section aria-labelledby="announcements-title">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-6">
                    <h2
                      id="announcements-title"
                      className="text-base font-medium text-gray-900"
                    >
                      Announcements
                    </h2>
                    <div className="mt-6 flow-root">
                      <ul
                        role="list"
                        className="-my-5 divide-y divide-gray-200"
                      >
                        {announcements.map((announcement) => (
                          <li key={announcement.id} className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                              <h3 className="text-sm font-semibold text-gray-800">
                                <a
                                  href="announcement.href"
                                  className="hover:underline focus:outline-none"
                                >
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  {announcement.title}
                                </a>
                              </h3>
                              <p className="mt-1 line-clamp-2 text-sm text-gray-600">
                                {announcement.preview}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="mt-6">
                      <a
                        href="#"
                        className="flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        View all
                      </a>
                    </div>
                  </div>
                </div>
              </section> */}

              {/* Recent user activity */}
              <section aria-labelledby="recent-user-activity-title">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-6">
                    <h2
                      id="recent-user-activity-title"
                      className="text-base font-medium text-gray-900"
                    >
                      Recent user activity
                    </h2>
                    <div className="mt-6 flow-root">
                      <ul
                        role="list"
                        className="-my-5 divide-y divide-gray-200"
                      >
                        {
                          <Suspense fallback={<RecentUsersFallback />}>
                            <Await
                              resolve={data.recentUsers}
                              errorElement={<div>Could not load users 😬</div>}
                            >
                              <RecentUsers />
                            </Await>
                          </Suspense>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left">
            <span className="block sm:inline">
              &copy; {year} {COMPANY_NAME}
            </span>
            <span className="block sm:inline">&nbsp;All rights reserved.</span>
          </div>
        </div>
      </footer>

      <BasicDialog
        isOpen={showBookSession}
        onClose={onCloseBookASession}
        title="Book a session"
      >
        <BookSessionDialog
          defaultStart={defaultStart}
          defaultEnd={defaultEnd}
          onClose={onCloseBookASession}
        />
      </BasicDialog>
    </>
  )
}
