import { useMemo, useRef } from 'react'
import {
  Link,
  // NavLink,
  Outlet,
  useLoaderData,
  // useLocation,
  // useNavigate,
  // useParams,
} from 'react-router-dom'
// import format from 'date-fns/format'
// import parseISO from 'date-fns/parseISO'
// import addMonths from 'date-fns/addMonths'
import { HomeIcon } from '@heroicons/react/24/outline'
import { loader } from './_loader'
// import { cn } from '~/utils/cn'
// import { getUnifiedCallLink } from '~/utils/links'
// import { Button } from '~/components/ui/button'

// const formatDate = (date: string, monthsToAdd = 0) => {
//   if (!date) return 'N/A'

//   if (monthsToAdd) {
//     return format(addMonths(parseISO(date), monthsToAdd), 'MMM d, yyyy')
//   }

//   return format(parseISO(date), 'MMM d, yyyy')
// }

export const GroupMemberPage: React.FC = () => {
  // const { userId } = useParams<{ userId: string }>()
  const outletWrapper = useRef<HTMLDivElement>(null)
  // const location = useLocation()
  // const navigate = useNavigate()
  const { data } = useLoaderData() as Exclude<
    Awaited<ReturnType<ReturnType<typeof loader>>>,
    Response
  >

  const pages = useMemo(
    () => [
      { name: 'Groups', href: '/groups', current: false },
      {
        name: data.group.name,
        href: '/groups/' + data.group._id,
        current: false,
      },
      {
        name: data.profile?.name ?? '{NO NAME}',
        href: '.',
        current: true,
      },
    ],
    [data],
  )

  // const options = useMemo(
  //   () => [
  //     {
  //       name: 'Hupo Perspectives',
  //       href: `/groups/${data.group._id}/members/${userId}/hupo-perspectives`,
  //     },
  //   ],
  //   [userId, data.group._id],
  // )

  // const defaultTab = useMemo(() => {
  //   return options.find((tab) => location.pathname.startsWith(tab.href))?.href
  // }, [options, location.pathname])

  return (
    <div className="space-y-5">
      <div className="min-w-0 mb-10">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to={!page.current ? page.href : '.'}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>

      <div>
        <dl className="flex flex-wrap gap-5">
          <div className="overflow-hidden max-sm:w-full rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              User info
            </dt>
            <dd className="mt-1 flex justify-between items-center">
              <p className="flex-1 text-xl font-semibold tracking-tight text-gray-900">
                {data.profile?.name ?? '{NO NAME}'} @{' '}
                {data.company?.name ?? '{NO COMPANY}'}
              </p>

              {/* <Link
                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50"
                to={`/chats/group/${chat._id}`}
              >
                <ChatBubbleOvalLeftEllipsisIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Chat</span>
              </Link> */}
            </dd>
          </div>

          <div className="overflow-hidden max-sm:w-full sm:flex-1 rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              Email
            </dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
              {data.email}
            </dd>
          </div>

          {/* {data.stats?.endDate && (
            <div className="overflow-hidden max-sm:w-full rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                Coaching end date
              </dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {formatDate(data.stats.endDate)}
              </dd>
            </div>
          )} */}
        </dl>
      </div>
      {/* 
      {data.upcomingEvents.length > 0 && (
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Upcoming Sessions
          </h3>

          <ul role="list" className="divide-y divide-gray-100 mt-4">
            {data.upcomingEvents.map((event) => (
              <li
                key={event._id}
                className="flex items-center justify-between gap-x-6 py-3"
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {format(parseISO(event.start), 'HH:mm')} -{' '}
                      {format(parseISO(event.end), 'HH:mm (dd.MM.yyyy)')}
                    </p>
                  </div>
                </div>
                <Button
                  variant="outline"
                  onClick={() => {
                    window.open(
                      getUnifiedCallLink({
                        eventId: event._id,
                        referrer: 'new-coach-app-user-detail',
                      }),
                      '_blank',
                    )
                  }}
                >
                  Join
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )} */}

      {/* User stats */}
      {/* <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            User Progress
          </h3>
        </div>
        <div className="px-4 py-5 sm:px-6 sm:py-2">
          <ul role="list" className="divide-y divide-gray-100">
            <li className="flex items-center justify-between gap-x-6 py-3">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    Completed Sessions: {data.stats?.completedSessions ?? 'N/A'}
                  </p>
                </div>
              </div>
            </li>
            <li className="flex items-center justify-between gap-x-6 py-3">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    Total sessions available: {data.stats?.availableSessions}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div> */}

      {/* <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary"
            onChange={(e) => navigate(e.target.value)}
            defaultValue={defaultTab}
          >
            {options.map((tab) => (
              <option key={tab.name} value={tab.href}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {options.map((tab) => (
                <NavLink
                  key={tab.name}
                  to={tab.href}
                  className={({ isActive }) =>
                    cn(
                      'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium',
                      isActive
                        ? 'border-primary-500 text-primary-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    )
                  }
                >
                  {tab.name}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div> */}

      <div ref={outletWrapper} className="flex gap-3 max-md:flex-col">
        <Outlet />
      </div>
    </div>
  )
}
