/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, type LoaderFunctionArgs } from 'react-router-dom'
import { teamApi } from '~/app/api'

type Diagnostics = {
  scores: {
    team: {
      'clear-strategy-and-priorities-that-maximise-impact': number
      'play-engaging-and-meaningful-role-to-deliver-team-impact': number
      'focus-on-problems-rather-than-tasks': number
      'experimentation-to-improve-performance': number
      'productive-routines-for-collaboration-and-decision-making': number
      'taking-time-to-understand-problems-gathering-ideas-before-jumping-to-solutions': number
      'fast-high-quality-feedback-loops-to-improve-learn': number
      'help-each-other-grow-and-build-skills': number
      'one-team-working-towards-shared-vision': number
      'well-structured-transparent-roadmap': number
      'comfortable-sharing-different-views-perspectives': number
      'seek-to-understand-stakeholders-problems': number
      'frequent-sync-loops-with-stakeholders-for-trust-transparency': number
      'pivot-adapt-quickly-to-challenges-changes-in-direction': number
      'see-changes-as-opportunities-for-growth-learning': number
    }
  }
  answers: Array<any>
  values: {
    'clear-strategy-and-priorities-that-maximise-impact': string
    'play-engaging-and-meaningful-role-to-deliver-team-impact': string
    'focus-on-problems-rather-than-tasks': string
    'experimentation-to-improve-performance': string
    'productive-routines-for-collaboration-and-decision-making': string
    'taking-time-to-understand-problems-gathering-ideas-before-jumping-to-solutions': string
    'fast-high-quality-feedback-loops-to-improve-learn': string
    'help-each-other-grow-and-build-skills': string
    'one-team-working-towards-shared-vision': string
    'well-structured-transparent-roadmap': string
    'comfortable-sharing-different-views-perspectives': string
    'seek-to-understand-stakeholders-problems': string
    'frequent-sync-loops-with-stakeholders-for-trust-transparency': string
    'pivot-adapt-quickly-to-challenges-changes-in-direction': string
    'see-changes-as-opportunities-for-growth-learning': string
  }
}

const fetchTeamDiagnostics = (id: string) =>
  queryOptions({
    queryKey: ['teams', id, 'diagnostics'],
    queryFn: () =>
      teamApi.url(`/coach/teams/${id}/diagnostics`).get().json<Diagnostics>(),
  })

type HighlightedArea = {
  description: string
  score: number
}

const fetchHighlightedAreas = (id: string) =>
  queryOptions({
    queryKey: ['teams', id, 'highlighted-areas'],
    async queryFn() {
      const data = teamApi.url(`/coach/teams/${id}/highlighted-areas`)
      const highest = await data
        .query({ type: 'highest' })
        .get()
        .json<HighlightedArea[]>()
      const lowest = await data
        .query({ type: 'lowest' })
        .get()
        .json<HighlightedArea[]>()
      return { highest, lowest }
    },
  })

type AssessmentStatus = {
  _id: string
  name: string
  picture: string
  email: string
  title: string
  completed: boolean
}

const fetchAssessments = (id: string) =>
  queryOptions({
    queryKey: ['teams', id, 'assessments'],
    async queryFn() {
      const status = await teamApi
        .url(`/coach/teams/${id}/assessment-status`)
        .get()
        .json<AssessmentStatus[]>()
      const answers = await teamApi
        .url(`/coach/teams/${id}/assessment-answers`)
        .get()
        .json<any>()
      return { status, answers }
    },
  })

export const loader =
  (queryClient: QueryClient) => (args: LoaderFunctionArgs) => {
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return defer({
      data: Promise.all([
        queryClient.fetchQuery(fetchTeamDiagnostics(id)),
        queryClient.fetchQuery(fetchHighlightedAreas(id)),
        queryClient.fetchQuery(fetchAssessments(id)),
      ]).then(([diagnostics, highlightedAreas, assessments]) => ({
        diagnostics,
        highlightedAreas,
        assessments,
      })),
    })
  }
