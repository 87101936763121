/* eslint-disable @typescript-eslint/no-explicit-any */

export const doOrLog = (
  logMsg: any,
  fn: () => void,
  ...optionalParams: any[]
) => {
  if (import.meta.env.DEV) {
    console.log(logMsg, ...optionalParams)
  } else {
    fn()
  }
}
