import type { Question } from '~/hooks/useInitNps'
import { RadioGroup } from '@headlessui/react'
import { cn } from '~/utils/cn'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { clearNpsAnswers, getAnswer, setNpsAnswer } from '~/store/nps.slice'
import { useEffect } from 'react'

interface Props {
  question: Question
  setRate: React.Dispatch<React.SetStateAction<number | null>>
  onChange: () => void
}

const RATINGS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const SurveyNPSRating: React.FC<Props> = (props: Props) => {
  const { question, setRate, onChange } = props
  const defaultValue = useAppSelector(getAnswer(question._id))
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (question.type !== 'nps') return
    if (defaultValue) setRate(parseInt(defaultValue, 10))
  }, [question.type, setRate, defaultValue])

  if (question.type !== 'nps') return null

  return (
    <RadioGroup
      name={question.friendlyID}
      defaultValue={defaultValue}
      onChange={(val) => {
        dispatch(clearNpsAnswers())
        onChange()
        if (!val) return
        dispatch(setNpsAnswer({ id: question._id, answer: val }))
        setRate(parseInt(val, 10))
      }}
    >
      <RadioGroup.Label className="block text-base font-semibold text-gray-900">
        {question.title}
      </RadioGroup.Label>
      <div className="mt-4 flex flex-wrap items-center gap-2 space-x-0 sm:gap-0 sm:space-x-3 sm:flex-nowrap max-sm:justify-center">
        {RATINGS.map((rate) => (
          <RadioGroup.Option
            key={rate}
            value={rate.toString()}
            className={({ active, checked }) =>
              cn(
                'ring-primary',
                active && checked ? 'ring ring-offset-1' : '',
                !active && checked ? 'ring-2' : '',
                'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none',
              )
            }
          >
            <RadioGroup.Label as="span" className="sr-only">
              {rate}
            </RadioGroup.Label>
            <span
              aria-hidden="true"
              className="bg-white flex items-center justify-center h-8 w-8 rounded-full border border-black border-opacity-10"
            >
              {rate}
            </span>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
