import Cookie from 'js-cookie'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk } from '~/app/store'
import { createAppSlice } from '~/app/createAppSlice'
import { fetchUserInfo } from '~/utils/user-info'

export interface AuthSliceState {
  id: string
  teamId: string
  groupId: string
  name: string
  email: string
  picture: string
  token: string
  isTeamCoach: boolean
  isGroupCoach: boolean
  isTestCoach: boolean
  loggedInAsAdmin: boolean
}

const initialState: AuthSliceState = {
  id: '',
  teamId: '',
  groupId: '',
  name: '',
  email: '',
  picture: '',
  token: '',
  isTeamCoach: false,
  isGroupCoach: false,
  isTestCoach: false,
  loggedInAsAdmin: false,
}

// If you are not using async thunks you can use the standalone `createSlice`.
export const authSlice = createAppSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: (create) => ({
    // increment: create.reducer(state => {
    //   // Redux Toolkit allows us to write 'mutating' logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a 'draft state' and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // }),
    // decrement: create.reducer(state => {
    //   state.value -= 1
    // }),
    // // Use the `PayloadAction` type to declare the contents of `action.payload`
    // setData: create.reducer(
    //   (state, action: PayloadAction<AuthSliceState>) => {
    //     state.value.
    //   },
    // ),
    setData: create.reducer(
      (state, action: PayloadAction<Partial<AuthSliceState>>) => {
        const { id, token, email, name, picture, loggedInAsAdmin } =
          action.payload
        state.id = id ?? state.id
        state.token = token ?? state.token
        state.email = email ?? state.email
        state.name = name ?? state.name
        state.picture = picture ?? state.picture
        state.loggedInAsAdmin = loggedInAsAdmin ?? state.loggedInAsAdmin
      },
    ),
    setIsTeamCoach: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isTeamCoach = action.payload
    }),
    setIsGroupCoach: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isGroupCoach = action.payload
    }),
    setIsTestCoach: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isTestCoach = action.payload
    }),
    setTeamId: create.reducer((state, action: PayloadAction<string>) => {
      state.teamId = action.payload
    }),
    setGroupId: create.reducer((state, action: PayloadAction<string>) => {
      state.groupId = action.payload
    }),
    clearData: create.reducer(() => initialState),
    // The function below is called a thunk and allows us to perform async logic. It
    // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
    // will call the thunk with the `dispatch` function as the first argument. Async
    // code can then be executed and other actions can be dispatched. Thunks are
    // typically used to make async requests.
    setAuthStateFromGoogle: create.asyncThunk(
      async (accessToken: string) => {
        return await fetchUserInfo(accessToken)
      },
      {
        pending(state) {
          state.id = 'pending'
        },
        fulfilled(state, action) {
          state.id = action.payload.sub
          state.name = action.payload.name
          state.email = action.payload.email
          state.picture = action.payload.picture
        },
        rejected(state) {
          state.name = 'error'
          state.email = 'error'
        },
      },
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectName: (state) => state.name,
  },
})

// Action creators are generated for each case reducer function.
export const {
  setData,
  setTeamId,
  setGroupId,
  setIsTeamCoach,
  setIsGroupCoach,
  setIsTestCoach,
  setAuthStateFromGoogle,
} = authSlice.actions

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const { selectName } = authSlice.selectors

export const setAuthData =
  (args: Partial<AuthSliceState>): AppThunk =>
  (dispatch) => {
    if (args.token) {
      Cookie.set('token', args.token)
    }

    dispatch(setData(args))
  }
