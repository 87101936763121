import type { PostHogConfig } from 'posthog-js'
import { Suspense, useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClientProvider, onlineManager } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistGate } from 'redux-persist/integration/react'
import { PostHogProvider } from 'posthog-js/react'

import { router } from './router'
import { persistor, store } from './app/store'
import { MixpanelProvider } from './contexts/MixpanelProvider'
import { LoadingSplash } from './Loading'
import { Toaster } from './components/ui/toaster'
import { queryClient } from './query'
import { Offline } from './Offline'
import { LoadingTimeout } from './LoadingTimeout'
import { ReloadPrompt } from './ReloadPrompt'
import { isTestOrDev } from './utils/is-test-or-dev'
import './chatlio'

const posthogConfig: Partial<PostHogConfig> = {
  name: 'Coach app',
  person_profiles: 'identified_only',
  opt_out_capturing_persistence_type: 'localStorage',
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  debug: import.meta.env.DEV,
  disable_session_recording: isTestOrDev(),
  opt_out_capturing_by_default: isTestOrDev(),
  session_recording: {
    maskAllInputs: true,
    maskTextSelector: '*',
  },
}

export const App: React.FC = () => {
  const [isOffline, setIsOffline] = useState(false)

  useEffect(() => {
    const unsubscribe = onlineManager.subscribe((isOnline) => {
      setIsOffline(!isOnline)
    })

    return unsubscribe
  }, [])

  if (isOffline) return <Offline />

  return (
    <Suspense
      fallback={
        <LoadingTimeout>
          <LoadingSplash />
        </LoadingTimeout>
      }
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PostHogProvider
            apiKey={import.meta.env.VITE_POSTHOG_KEY}
            options={posthogConfig}
          >
            <MixpanelProvider>
              <GoogleOAuthProvider
                clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}
              >
                <PersistGate
                  persistor={persistor}
                  loading={
                    <LoadingTimeout>
                      <LoadingSplash />
                    </LoadingTimeout>
                  }
                >
                  <RouterProvider router={router} />
                </PersistGate>
              </GoogleOAuthProvider>
              <Toaster />
              <ReloadPrompt />
            </MixpanelProvider>
          </PostHogProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </Suspense>
  )
}
