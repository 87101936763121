import { useMemo } from 'react'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import parseISO from 'date-fns/parseISO'
import { useAppSelector } from '~/app/hooks'
import { getWorkingHours } from '~/store/onboarding.slice'

export const useDefaultWorkingHours = () => {
  const workingHours = useAppSelector(getWorkingHours)

  return useMemo(
    () =>
      workingHours.map((day) => {
        return day.map((slot) => {
          const start = parseISO(slot.start)
          const end = parseISO(slot.end)

          return {
            start: utcToZonedTime(start, 'UTC'),
            end: utcToZonedTime(end, 'UTC'),
          }
        })
      }),
    [workingHours],
  )
}
