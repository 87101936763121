import { useCallback } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'
import * as Toast from '@radix-ui/react-toast'
import { Button } from './components/Button'
import { useMixpanel } from './hooks/useMixpanel'

export const ReloadPrompt: React.FC = () => {
  const mixpanel = useMixpanel()
  const {
    needRefresh: [needRefresh],
    offlineReady: [offlineReady],
    updateServiceWorker,
  } = useRegisterSW({
    onNeedRefresh() {
      mixpanel.track('sw_need_refresh')
    },
    onOfflineReady() {
      mixpanel.track('sw_offline_ready')
    },
    onRegisterError(error) {
      mixpanel.track('sw_registration_error', { error: error.message })
    },
    onRegisteredSW() {
      if (needRefresh) {
        mixpanel.track('sw_registered_refresh')
      }

      if (offlineReady) {
        mixpanel.track('sw_registered_first_time')
      }
    },
  })

  const update = useCallback(() => {
    updateServiceWorker(true)
    mixpanel.track('sw_reload')
  }, [updateServiceWorker, mixpanel])

  return (
    <Toast.Provider>
      <Toast.Root open={needRefresh} className="p-3">
        <Toast.Description className="mb-4 sm:max-w-sm w-full" asChild>
          <p>
            New version available, please click on reload button to install the
            latest updates
          </p>
        </Toast.Description>
        <Toast.Action altText="Reload" asChild>
          <Button className="w-full" onClick={update}>
            Reload
          </Button>
        </Toast.Action>
      </Toast.Root>

      <Toast.Viewport className="bg-orange-100 fixed right-5 bottom-10 border border-gray-300 rounded-md z-[1000] text-left shadow-md" />
    </Toast.Provider>
  )
}
