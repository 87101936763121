import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { usePostHog } from 'posthog-js/react'
import { WretchError } from 'wretch/resolver'

import { GoogleIcon } from '~/icons/GoogleIcon'
import { useAppDispatch } from '~/app/hooks'
import { setAuthData } from '~/store/auth.slice'
import { AlertDialog } from '~/components/AlertDialog'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { api } from '~/app/api'
import { setSettings } from '~/store/settings.slice'
import { useToast } from '~/components/ui/use-toast'
import {
  OnboardingSliceState,
  setOnboardingData,
  startOnboarding,
} from '~/store/onboarding.slice'
import { AuthHeader } from './_components/AuthHeader'
import { useMixpanel } from '~/hooks/useMixpanel'
import { ONBOARDING_STEPS } from '~/app/constants'

type Inputs = {
  email: string
  password?: string
  passwordConfirmation?: string
}

type LoginResponse = {
  id: string
  token: string
  email: string
  meta: {
    fullName: string
    avatar: string
    timezone: string
    timeFormat: '12' | '24'
    onboardingCompleted: boolean
  }
}

type LoginTOTPResponse = {
  id: string
  mfa: true
  nextStep: 'TOTP'
}

type AuthMode = 'signup' | 'login'

export const Auth: React.FC = () => {
  const { t } = useTranslation('auth')
  const adminSecretRef = useRef<HTMLInputElement>(null)
  const [searchParams] = useSearchParams()
  const posthog = usePostHog()
  const navigate = useNavigate()
  const mixpanel = useMixpanel()
  const dispatch = useAppDispatch()
  const { toast } = useToast()
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    clearErrors,
    setError,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      email: searchParams.get('email') ?? '',
    },
  })

  const mode = useMemo<AuthMode>(() => {
    const value = searchParams.get('mode')

    switch (value) {
      case 'signup':
      case 'login':
        return value
      default:
        return 'login'
    }
  }, [searchParams])

  const onSignupMode = useMemo(() => mode === 'signup', [mode])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    switch (params.get('mode')) {
      case 'signup':
      case 'login':
        break
      default: {
        params.set('mode', 'login')
      }
    }
    navigate({ search: params.toString() })
  }, [navigate])

  const updateModeHandler = useCallback(
    (value: AuthMode) => {
      if (value === mode) return
      navigate({ search: new URLSearchParams({ mode: value }).toString() })
    },
    [navigate, mode],
  )

  const { mutate: loadOnboardingState, isPending: isOnboardingStatePending } =
    useMutation({
      mutationKey: ['onboarding', 'load'],
      mutationFn() {
        return api
          .url('/coach/onboarding/current-state')
          .get()
          .json<OnboardingSliceState>()
      },
      onSuccess(data) {
        data.inProgress && dispatch(startOnboarding())
        dispatch(setOnboardingData(data))
        if (data.step > -1) {
          const href = ONBOARDING_STEPS[data.step].href
          navigate(`/onboarding${href}`)
        }
      },
    })

  const { mutateAsync: checkUser, isPending } = useMutation({
    mutationKey: ['auth', 'check'],
    mutationFn(email: string) {
      return api
        .url('/coach/check')
        .query({ email })
        .get()
        .forbidden(() => setIsAlertDialogOpen(true))
        .json<{ allowListed: boolean; exists: boolean }>()
    },
    onSuccess(data, email) {
      if (!data.allowListed && !data.exists) {
        setIsAlertDialogOpen(true)
      }

      if (!data.exists && !onSignupMode) {
        updateModeHandler('signup')
        setValue('email', email)

        toast({
          title: 'User does not exist',
          description:
            'You do not have an account. Please sign up. Set password.',
          variant: 'warning',
        })

        throw new Error('User does not exist')
      }
    },
    onError(error) {
      if (error instanceof WretchError) {
        if (error.status === 409) {
          toast({
            description: 'You already have an account. Please login.',
            variant: 'warning',
          })
          updateModeHandler('login')
        }
      }
    },
  })

  const { mutate: signUp, isPending: isSigningUp } = useMutation({
    mutationKey: ['auth', 'signup'],
    mutationFn(data: Inputs) {
      return api
        .url('/coach/signup')
        .post(data)
        .forbidden(() => setIsAlertDialogOpen(true))
        .json<Omit<LoginResponse, 'meta'>>()
    },
    onSuccess(data, { email }) {
      dispatch(startOnboarding())
      dispatch(setAuthData({ id: data.id, token: data.token, email }))
      navigate('/onboarding/user-settings')
    },
    onError(error) {
      if (error instanceof WretchError) {
        if (error.status === 409) {
          toast({
            description: 'You already have an account. Please login.',
            variant: 'warning',
          })
          updateModeHandler('login')
        }
      }
    },
  })

  const { mutate: login, isPending: isLoggingIn } = useMutation({
    mutationKey: ['auth', 'login'],
    mutationFn(data: Inputs & { adminSecret?: string }) {
      return api
        .url('/coach/login')
        .post(data)
        .json<LoginResponse | LoginTOTPResponse>()
    },
    onSuccess(data, { adminSecret }) {
      const loggedInAsAdmin = typeof adminSecret === 'string'
      if ('mfa' in data) {
        dispatch(setAuthData({ id: data.id }))
        navigate('/auth/totp')
        return
      }

      posthog.capture('login', {
        login_type: 'email',
        email: data.email,
        name: data.meta.fullName,
        logged_in_as_admin: loggedInAsAdmin,
      })

      mixpanel.track('login', {
        email: data.email,
        name: data.meta.fullName,
        logged_in_as_admin: loggedInAsAdmin,
      })

      if (loggedInAsAdmin) {
        mixpanel.opt_out_tracking({
          delete_user: false,
        })
      }

      dispatch(
        setAuthData({
          id: data.id,
          token: data.token,
          email: data.email,
          name: data.meta.fullName,
          picture: data.meta.avatar,
          loggedInAsAdmin,
        }),
      )
      dispatch(
        setSettings({
          timeFormat: data.meta.timeFormat,
          timeZone: data.meta.timezone,
        }),
      )

      if (!data.meta.onboardingCompleted) {
        toast({
          variant: 'warning',
          title: 'Please complete onboarding',
          description: 'You have not completed onboarding yet.',
        })

        loadOnboardingState()
      } else {
        const nextURL = searchParams.get('nextURL')
        const navigateTo = typeof nextURL === 'string' ? nextURL : '/'
        navigate(navigateTo)
      }
    },
  })

  const gauthMutation = useMutation({
    mutationKey: ['auth', 'google'],
    mutationFn(accessToken: string) {
      return api
        .url('/coach/google/auth')
        .post({ accessToken })
        .forbidden(() => setIsAlertDialogOpen(true))
        .json<LoginResponse & { newlyCreated: boolean }>()
    },
    onSuccess(data) {
      dispatch(
        setAuthData({
          id: data.id,
          token: data.token,
          email: data.email,
          name: data.meta.fullName,
          picture: data.meta.avatar,
        }),
      )

      dispatch(
        setSettings({
          timeFormat: data.meta.timeFormat,
          timeZone: data.meta.timezone,
        }),
      )

      if (data.newlyCreated) {
        dispatch(startOnboarding())
        dispatch(setOnboardingData({ name: data.meta.fullName }))
        navigate('/onboarding/user-settings')
      } else {
        const nextURL = searchParams.get('nextURL')
        const navigateTo = typeof nextURL === 'string' ? nextURL : '/'
        navigate(navigateTo)
      }

      posthog.capture('login', {
        login_type: 'google',
        email: data.email,
        name: data.meta.fullName,
      })
    },
  })

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    try {
      await checkUser(data.email)
    } catch {
      return
    }

    if (onSignupMode) {
      if (data.password !== data.passwordConfirmation) {
        setError('passwordConfirmation', {
          message: t('password_confirmation_error'),
        })
        return
      }

      signUp(data)
    } else {
      const adminSecret = adminSecretRef.current?.value

      login({
        email: data.email,
        ...(adminSecret ? { adminSecret } : { password: data.password }),
      })
    }
  }

  const googleAuthHandler = useGoogleLogin({
    onSuccess(result) {
      gauthMutation.mutate(result.access_token)
    },
  })

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <AuthHeader title={t(`title.${mode}`)} />

        <div className="mt-4 sm:mt-10 max-sm:mx-5 sm:px-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-5 sm:py-12 shadow sm:px-12 rounded-lg">
            <form
              id="login-form"
              className="space-y-4"
              onSubmit={handleSubmit(submitHandler)}
            >
              <input ref={adminSecretRef} name="adminSecret" type="hidden" />
              <Input
                id="email"
                type="email"
                autoComplete="email"
                label={t('email')}
                error={errors.email?.message}
                aria-label="Email"
                {...register('email', { required: true })}
              />
              <Input
                id="password"
                type="password"
                autoComplete="current-password"
                label={t('password')}
                required={onSignupMode}
                error={errors.password?.message}
                aria-label="Password"
                {...register('password', { required: onSignupMode })}
              />

              {onSignupMode && (
                <Input
                  id="passwordConfirmation"
                  type="password"
                  autoComplete="new-password"
                  label={t('password_confirmation')}
                  required={true}
                  error={errors.passwordConfirmation?.message}
                  {...register('passwordConfirmation', {
                    required: true,
                  })}
                  onFocus={() => {
                    clearErrors('passwordConfirmation')
                  }}
                  aria-label="Password confirmation"
                />
              )}

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    aria-label="Remember me"
                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                  />
                  <label
                    htmlFor="remember-me"
                    aria-labelledby="remember-me"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    {t('remember_me')}
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <Link
                    to="/auth/forgot-password"
                    className="font-semibold text-primary hover:text-primary-500"
                    aria-label="Forgot password?"
                  >
                    {t('forgot_password.title')}?
                  </Link>
                </div>
              </div>

              <div>
                <Button
                  title="Login"
                  className="w-full"
                  type="submit"
                  loading={
                    isSubmitting ||
                    isSigningUp ||
                    isLoggingIn ||
                    isPending ||
                    isOnboardingStatePending
                  }
                >
                  {t(mode ?? 'login')}
                </Button>
              </div>
            </form>

            <div>
              <div className="relative mt-10">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    {t('continue_with')}
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-4">
                <button
                  type="button"
                  title="Sign in with Google"
                  className="flex w-full items-center justify-center gap-3 border border-black rounded-md bg-gray-8 px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F4B400]"
                  onClick={() => googleAuthHandler()}
                >
                  <GoogleIcon className="h-6 w-5" />
                  <span className="text-sm font-semibold leading-6 text-black">
                    Sign in with Google
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertDialog
        isOpen={isAlertDialogOpen}
        title={t('not_allowlisted.title')}
        confirmText={t('not_allowlisted.confirm')}
        onClose={() => setIsAlertDialogOpen(false)}
      >
        <p className="text-sm text-gray-500">
          {t('not_allowlisted.description')}
        </p>
      </AlertDialog>
    </>
  )
}
