import { useState } from 'react'
import addHours from 'date-fns/addHours'
import { BasicDialog } from '~/components/BasicDialog'
import { BookSessionDialog } from '~/routes/_components/BookSessionDialog'

interface QuickBookProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

const now = new Date()
now.setMinutes(0)

export const QuickBook: React.FC<QuickBookProps> = (props) => {
  const [defaultStart] = useState(now)
  const [defaultEnd] = useState(addHours(now, 1))
  const { isOpen, onClose, onSuccess } = props

  return (
    <BasicDialog isOpen={isOpen} onClose={onClose} title="Book a session">
      <BookSessionDialog
        defaultStart={defaultStart}
        defaultEnd={defaultEnd}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </BasicDialog>
  )
}
