import { Fragment, useCallback, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@tanstack/react-query'
import { SurveyOptions } from '~/components/survey/Options'
import { Button } from '~/components/Button'
import { SurveyTextarea } from '~/components/survey/Textarea'
import { SurveyNPSRating } from '~/components/survey/NPSRating'
import { type Answer, useSubmitNps } from '~/hooks/useSubmitNps'
import { useAppSelector } from '~/app/hooks'
import { api } from '~/app/api'
import { LoadingSpinner } from '~/Loading'
import { doOrLog } from '~/utils/do-or-log'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const FeedbackSlideOver: React.FC<Props> = (props: Props) => {
  const { isOpen } = props
  const surveyId = useAppSelector((state) => state.nps.surveyId)
  const formRef = useRef<HTMLFormElement>(null)
  const [rate, setRate] = useState<number | null>(null)
  const [requiredCheckbox, setRequiredCheckbox] = useState(false)

  // Dismiss the auto open of the feedback slide over
  const { mutate: mute } = useMutation({
    mutationKey: ['coach', 'nps', 'mute'],
    mutationFn(surveyId: string) {
      return api.url(`/coach/nps/${surveyId}/mute`).post().json()
    },
  })

  const onClose = useCallback(() => {
    props.onClose()
    doOrLog('Muting NPS', () => mute(surveyId), surveyId)
  }, [props, mute, surveyId])

  const { mutate, isPending, title, questions } = useSubmitNps(onClose)

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const formData = new FormData(e.currentTarget)
      const answers: Answer[] = []

      const rateData = formData.get(
        'how-likely-are-you-to-recommend-hupo-to-other-coaches',
      )

      const rate = rateData ? parseInt(rateData.toString()) : null

      if (!rate || Number.isNaN(rate)) {
        return
      }

      const checkboxGroup = formData.getAll(
        'what-would-it-take-to-bring-your-satisfaction-level-to-10-of-10',
      )

      if (checkboxGroup.length === 0 && rate < 10) {
        setRequiredCheckbox(true)
        return
      }

      for (const [key, value] of formData.entries()) {
        if (typeof value !== 'string') continue
        if (value.length === 0) continue

        const numberValue = parseInt(value)

        answers.push({
          question: key,
          answer: Number.isNaN(numberValue) ? value.toString() : numberValue,
        })
      }

      doOrLog('Submitting NPS', () => mutate(answers), answers)
    },
    [mutate],
  )

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={props.onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
                  <div className="flex h-full flex-col overflow-y-scroll bg-zinc-100 py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          {title.length > 0 ? title : 'Feedback'}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                            onClick={onClose}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {isPending || title.length === 0 ? (
                      <div className="mt-10">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <form
                        ref={formRef}
                        className="relative mt-6 flex-1 px-4 space-y-5 sm:px-6"
                        onSubmit={onSubmit}
                      >
                        {questions.map((question) =>
                          question.type === 'nps' ? (
                            <SurveyNPSRating
                              key={question._id}
                              question={question}
                              setRate={setRate}
                              onChange={() => formRef.current?.reset()}
                            />
                          ) : (
                            <div
                              key={question._id}
                              className={
                                typeof rate === 'number' && rate < 10
                                  ? 'block'
                                  : 'hidden'
                              }
                            >
                              <label
                                htmlFor={question._id}
                                className="text-base font-semibold text-gray-900"
                              >
                                {question.title}
                              </label>
                              <SurveyOptions
                                question={question}
                                requiredError={requiredCheckbox}
                                resetRequiredError={() =>
                                  setRequiredCheckbox(false)
                                }
                              />
                              <SurveyTextarea question={question} />
                            </div>
                          ),
                        )}

                        <Button
                          type="submit"
                          className="w-full"
                          disabled={typeof rate !== 'number'}
                        >
                          Submit
                        </Button>
                      </form>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
