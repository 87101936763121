/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export interface BillingInfo {
  _id: string
  cost: Cost
  billingAddress: string
  bankDetails: BankDetails
  specialPricing: any[]
  dateBasedPricing: any[]
  createdAt: string
  updatedAt: string
}

export interface Cost {
  currency: string
}

export interface BankDetails {
  accountHolder: string
  accountNumber: string
  bank: string
  bankCode: string
  swiftCode: string
}

interface Root {
  data: BillingInfo | null
}

const fetchBilling = () =>
  queryOptions({
    queryKey: ['billing'],
    queryFn: () => api.url('/coach/billing').get().json<Root>(),
  })

export const loader = (queryClient: QueryClient) => () => {
  return queryClient.fetchQuery(fetchBilling())
}
