import type { ResponseData } from './_loaders/files'
import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'

import { LoadingSpinner } from '~/Loading'
import { bytesToMegabytes } from '~/utils/converters'

type Loader = {
  data: ResponseData
}

export const Files: React.FC = () => {
  const deferredData = useLoaderData() as Loader
  const resolvedData = deferredData.data

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={resolvedData} errorElement={<div>Error</div>}>
        {(data) => <FilesWithData data={data} />}
      </Await>
    </Suspense>
  )
}

const FilesWithData: React.FC<Loader> = (props) => {
  const { data } = props

  if (data.length === 0) {
    return (
      <div className="flex-1 w-[95%] mx-auto mt-5">
        <p className="text-center font-medium">No files found</p>
      </div>
    )
  }

  return (
    <div className="flex-1 w-[95%] mx-auto">
      <div className="-mx-4 mt-5 sm:mx-0 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-800 sm:pl-6"
              >
                Title
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-800 lg:table-cell"
              >
                Size
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Select</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item._id}>
                <td className="relative py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="font-medium text-gray-800">{item.title}</div>
                  <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                    <span>{bytesToMegabytes(item.size)}</span>
                    <span className="hidden sm:inline">·</span>
                    <span>MB</span>
                  </div>
                  {index !== 0 ? (
                    <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                  ) : null}
                </td>

                <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                  {bytesToMegabytes(item.size)} MB
                </td>

                <td className="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    onClick={() => window.open(item.publicUrl, '_blank')}
                  >
                    Download<span className="sr-only">, {item.title}</span>
                  </button>
                  {index !== 0 ? (
                    <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
