/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect, type LoaderFunctionArgs } from 'react-router-dom'
import { groupApi } from '~/app/api'
import { authenticated } from '~/app/cookie'

interface Root {
  data: Data
}

interface Data {
  coaches: string[]
  totalSessionCount: number
  _id: string
  members: Member[]
  name: string
  company: Company
  slackId: string
  createdAt: string
  updatedAt: string
  __v: number
  manager: any
  leader: Leader
  upcomingSessions: {
    _id: string
    bookedBy: string
    title: string
    end: string
    start: string
  }[]
}

interface Member {
  profile: Profile
  timezone: string
  _id: string
  email: string
  title: string
}

interface Profile {
  name: string
  picture: string
  nickName: string
}

interface Company {
  _id: string
  name: string
}

interface Leader {
  profile: Profile2
  timezone: string
  _id: string
  email: string
  title: string
}

interface Profile2 {
  name: string
  picture: string
  nickName: string
}

const fetchGroup = (id: string) =>
  queryOptions({
    queryKey: ['groups', id],
    queryFn: () => groupApi.url(`/coach/groups/${id}`).get().json<Root>(),
  })

export const loader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    if (!authenticated()) {
      return redirect('/auth?mode=login')
    }
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return await queryClient.fetchQuery(fetchGroup(id))
  }
