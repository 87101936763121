import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { api, groupApi, teamApi } from '~/app/api'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { TimeZoneSelect } from '~/components/timezone/TimeZoneSelect'
import { useAuth } from '~/hooks/useAuth'
import { useSettings } from '~/hooks/useSetttings'
import { useAppDispatch } from '~/app/hooks'
import { setAuthData } from '~/store/auth.slice'
import { useToast } from '~/components/ui/use-toast'
import { setTimeFormat, setTimeZone } from '~/store/settings.slice'

type ProfileData = {
  name: string
  email: string
  timeZone: string
  timeFormat: string
}

type Passwords = {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export const Profile: React.FC = () => {
  const photoInput = useRef<HTMLInputElement>(null)
  const { picture, name, email } = useAuth()
  const { timeFormat, timeZone } = useSettings()
  const form = useForm<ProfileData>({
    defaultValues: { name, email, timeFormat, timeZone },
  })
  const passwordForm = useForm<Passwords>({
    resolver(data) {
      if (data.newPassword !== data.confirmNewPassword) {
        return {
          errors: {
            confirmNewPassword: {
              type: 'passwordMismatch',
              message: 'Passwords do not match',
            },
          },
          values: {},
        }
      }

      return { errors: {}, values: data }
    },
  })
  const dispatch = useAppDispatch()
  const { toast } = useToast()
  const [photoPreviewURL, setPhotoPreviewURL] = useState(picture)

  const { mutate: uploadAvatar, isPending: isUploadAvatarPending } =
    useMutation({
      mutationKey: ['upload', 'avatar'],
      async mutationFn(file: File) {
        const formData = new FormData()
        formData.append('avatar', file)

        const result = await api
          .url('/coach/avatar')
          .body(formData)
          .post()
          .json<{ url: string }>()

        return result.url
      },
      onSuccess(url) {
        setPhotoPreviewURL(url)
        dispatch(setAuthData({ picture: url }))
        toast({
          title: 'Success',
          description: 'Avatar updated successfully',
          variant: 'success',
        })
      },
    })

  const { mutate: updatePassword, isPending: isUpdatePasswordPending } =
    useMutation({
      mutationKey: ['profile', 'update', 'password'],
      async mutationFn(data: { currentPassword: string; newPassword: string }) {
        await api.url('/coach/password').patch(data).json()
      },
      onSuccess() {
        toast({
          title: 'Success',
          description: 'Password updated successfully',
          variant: 'success',
        })
      },
    })

  const { mutate: updateProfile, isPending: isUpdateProfilePending } =
    useMutation({
      mutationKey: ['profile', 'update', 'profile'],
      async mutationFn(data: Partial<ProfileData>) {
        const { timeZone, ...rest } = data
        await api
          .url('/coach/profile')
          .patch({ ...rest, timezone: timeZone })
          .json()
      },
      onSuccess(_, data) {
        dispatch(setAuthData({ name: data.name }))
        data.timeZone && dispatch(setTimeZone(data.timeZone))
        if (data.timeFormat === '12' || data.timeFormat === '24') {
          dispatch(setTimeFormat(data.timeFormat))
        }
        toast({
          title: 'Success',
          description: 'Profile updated successfully',
          variant: 'success',
        })
      },
    })

  const { mutate: updateTeamProfile } = useMutation({
    mutationKey: ['team', 'profile', 'update', 'profile'],
    async mutationFn(data: Partial<ProfileData>) {
      const { timeZone, ...rest } = data
      await teamApi
        .url('/coach/profile')
        .patch({ ...rest, timezone: timeZone })
        .json()
    },
  })

  const { mutate: updateGroupProfile } = useMutation({
    mutationKey: ['group', 'profile', 'update', 'profile'],
    async mutationFn(data: Partial<ProfileData>) {
      const { timeZone, ...rest } = data
      await groupApi
        .url('/coach/profile')
        .patch({ ...rest, timezone: timeZone })
        .json()
    },
  })

  const onPhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = () => {
      setPhotoPreviewURL(reader.result as string)
    }
    reader.readAsDataURL(file)

    uploadAvatar(file)
  }

  const onUpdateProfileSubmit = form.handleSubmit((data) => {
    updateProfile(data)
    updateTeamProfile(data)
    updateGroupProfile(data)
  })

  const onUpdatePasswordSubmit = passwordForm.handleSubmit((data) => {
    updatePassword({
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    })
  })

  return (
    <div className="divide-y divide-black/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7">
            Personal Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Use a permanent address where you can receive mail.
          </p>
        </div>

        <form className="md:col-span-2" onSubmit={onUpdateProfileSubmit}>
          <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full flex items-center gap-x-8">
              {photoPreviewURL ? (
                <img
                  src={photoPreviewURL}
                  alt={name}
                  className="h-24 w-24 object-cover flex-none rounded-lg bg-gray-800"
                />
              ) : (
                <UserCircleIcon
                  className="h-20 w-20 text-gray-400"
                  aria-hidden="true"
                />
              )}
              <input
                id="photo"
                ref={photoInput}
                type="file"
                name="photo"
                accept="image/*"
                onChange={onPhotoChange}
                hidden
              />
              <div>
                <button
                  type="button"
                  className="rounded-md bg-black/10 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-black/20"
                  onClick={() => photoInput.current?.click()}
                >
                  Change avatar
                </button>
                <p className="mt-2 text-xs leading-5 text-gray-400">
                  JPG, GIF or PNG. 1MB max.
                </p>
              </div>
            </div>

            <Input
              containerClassName="col-span-full"
              id="name"
              type="text"
              label="Name"
              required
              {...form.register('name', { required: true })}
            />
            <Input
              id="email"
              containerClassName="col-span-full"
              type="email"
              label="Email address"
              disabled
              required
              {...form.register('email', { required: true })}
            />

            <div className="col-span-full">
              <TimeZoneSelect
                defaultValue={timeZone}
                onChange={(tz) => form.setValue('timeZone', tz)}
              />
            </div>

            <div className="col-span-full">
              <label
                htmlFor="time-format"
                className="block text-sm font-medium leading-6"
              >
                Time format
              </label>
              <div className="mt-2">
                <select
                  id="time-format"
                  className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-black/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                  {...form.register('timeFormat')}
                >
                  <option value="12">12-hour</option>
                  <option value="24">24-hour</option>
                </select>
              </div>
            </div>
          </div>

          <div className="mt-8 flex">
            <Button
              className="max-sm:w-full"
              type="submit"
              disabled={isUpdateProfilePending || isUploadAvatarPending}
            >
              Save
            </Button>
          </div>
        </form>
      </div>

      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7">Change password</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Update your password associated with your account.
          </p>
        </div>

        <form className="md:col-span-2" onSubmit={onUpdatePasswordSubmit}>
          <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <Input
              id="current-password"
              containerClassName="col-span-full"
              type="password"
              label="Current password"
              minLength={8}
              required
              {...passwordForm.register('currentPassword', { required: true })}
            />

            <Input
              id="new-password"
              containerClassName="col-span-full"
              type="password"
              label="New password"
              minLength={8}
              required
              {...passwordForm.register('newPassword', { required: true })}
            />

            <Input
              id="confirm-password"
              containerClassName="col-span-full"
              type="password"
              label="Confirm password"
              minLength={8}
              required
              {...passwordForm.register('confirmNewPassword', {
                required: true,
              })}
            />
          </div>

          <div className="mt-8 flex">
            <Button
              className="max-sm:w-full"
              type="submit"
              disabled={isUpdatePasswordPending}
            >
              Save
            </Button>
          </div>
        </form>
      </div>

      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7">
            Log out other sessions
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Please enter your password to confirm you would like to log out of
            your other sessions across all of your devices.
          </p>
        </div>

        <form className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <Input
              id="logout-password"
              containerClassName="col-span-full"
              placeholder="This feature is not yet available."
              type="password"
              label="Your password"
              name="password"
              disabled
            />
          </div>

          <div className="mt-8 flex">
            <Button className="max-sm:w-full" variant="outline" disabled>
              Log out other sessions
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
