/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect, type LoaderFunctionArgs } from 'react-router-dom'
import { authenticated } from '~/app/cookie'
import { getApi } from '~/utils/getApi'

export interface ChatMessage {
  _id: string
  createdAt: string
  message?: { text: string }
  text: string
  notifiedEmail?: string
  pushNotificationSent?: string
  read?: string
  sentByUser: boolean
  updatedAt?: string
  file?: {
    url: string
    name: string
    type: string
    size: number
  }
}

interface MessageUser {
  _id: string
  email: string
  profile: {
    name: string
    gender: string
    picture: string
    phone: string
    fullPhone: string
    nickName: string
  }
  company: {
    _id: string
    name: string
  }
}

export interface Chat {
  messages: ChatMessage[]
  user: MessageUser
}

// ---- Team ---
export type TeamChatMessage = {
  _id: string
  message: { text: string }
  read: any[]
  notifiedEmail: any[]
  pushNotificationSent: any[]
  chat: string
  sentBy: {
    _id: string
    profile: {
      name: string
      picture: string
      nickName: string
    }
  }
  sentByModel: string
  coach: string
  createdAt: string
  updatedAt: string
}

export type TeamChat = {
  chat: {
    _id: string
    participants: Array<{
      profile: {
        name: string
        picture: string
        nickName: string
      }
      timezone: string
      _id: string
      email: string
      title: string
    }>
    type: string
    coach: string
    team: string
    createdAt: string
    updatedAt: string
    lastMessage: {
      message: { text: string }
      _id: string
      sentBy: string
      createdAt: string
    }
    title: string
    company: string
  }
  messages: TeamChatMessage[]
}

const fetchChat = (id: string, type: AppType) =>
  queryOptions({
    queryKey: [type, 'chats', id],
    queryFn: () =>
      getApi(type).url(`/coach/chats/${id}`).get().json<Chat | TeamChat>(),
    refetchOnMount: true,
  })

export const loader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    if (!authenticated()) {
      return redirect('/auth?mode=login')
    }
    const { id, type = 'core' } = args.params as { id: string; type: AppType }
    if (!id) throw new Error('No id provided')

    return await queryClient.fetchQuery(fetchChat(id, type))
  }
