import { useEffect, useMemo } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { ONBOARDING_STEPS } from '~/app/constants'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { setCurrentOnboardingStep } from '~/store/onboarding.slice'

export const Onboarding: React.FC = () => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const currentStep = useAppSelector((state) => state.onboarding.step)

  const _currentStep = useMemo(() => {
    const stepIndex = ONBOARDING_STEPS.findIndex(
      (step) => `/onboarding${step.href}` === pathname,
    )
    return stepIndex === -1 ? 0 : stepIndex
  }, [pathname])

  useEffect(() => {
    dispatch(setCurrentOnboardingStep(_currentStep))
  }, [dispatch, _currentStep])

  return (
    <div className="container mx-auto max-w-xl sm:px-6 lg:px-8 sm:py-10 lg:py-8">
      <div className="border-b border-gray-200 pb-5 mb-5 mt-10 flex justify-between">
        <div>
          <h3 className="text-2xl font-semibold leading-6 text-gray-900">
            {ONBOARDING_STEPS[currentStep].title}
          </h3>
          <p
            className="mt-2 max-w-4xl text-sm text-gray-500"
            dangerouslySetInnerHTML={{
              __html: ONBOARDING_STEPS[currentStep].description,
            }}
          />
        </div>
      </div>

      <nav className="flex items-center justify-center" aria-label="Progress">
        <ol role="list" className="ml-8 flex items-center space-x-5">
          {ONBOARDING_STEPS.map((step, index) => (
            <li key={step.name}>
              {currentStep > index && (
                <Link
                  to={`/onboarding${step.href}`}
                  className="block h-2.5 w-2.5 rounded-full bg-primary hover:bg-primary-500"
                >
                  <span className="sr-only">{step.name}</span>
                </Link>
              )}

              {currentStep === index && (
                <Link
                  to={`/onboarding${step.href}`}
                  className="relative flex items-center justify-center"
                  aria-current="step"
                >
                  <span
                    className="absolute flex h-5 w-5 p-px"
                    aria-hidden="true"
                  >
                    <span className="h-full w-full rounded-full bg-primary-200" />
                  </span>
                  <span
                    className="relative block h-2.5 w-2.5 rounded-full bg-primary"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </Link>
              )}

              {currentStep < index && (
                <Link
                  to={`/onboarding${step.href}`}
                  className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
                >
                  <span className="sr-only">{step.name}</span>
                </Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
      <Outlet />
    </div>
  )
}
