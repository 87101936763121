import { CalendarIcon } from '@heroicons/react/24/outline'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { useState } from 'react'
import { Button } from '~/components/Button'
import { EditSessionSummaryDialog } from './EditSessionSummaryDialog'

export interface Summary {
  sessionId: string
  start: string
  end: string
  title: string
  coachName: string
  content: string
  verified: boolean
}

export type ResponseData = {
  summaries: Summary[]
  sections: string[]
}

const formatSummary = (summary: string, sections: string[]) => {
  if (summary.includes('. **')) {
    const lines = summary.split('\n')
    summary = lines
      .map((line) => {
        const isTitle = line.includes('. **') && line.includes(':**')
        const content = isTitle
          ? line.replace('. **', '. <strong>').replace(':**', ':</strong>')
          : line.includes('- **') && line.includes(':**')
            ? line.replace('- **', '- <i>').replace(':**', ':</i>')
            : line
        return content
      })
      .join('\n')
  } else {
    for (const highlightedPart of sections) {
      summary = summary.replace(
        new RegExp(highlightedPart, 'g'),
        '<b>' + highlightedPart + '</b>',
      )
    }
  }
  summary = summary.replace(/\n/g, '<br/>')
  return summary
}

export const SessionSummariesWithData: React.FC<{
  data: ResponseData
  updateSummary: (data: { sessionId: string; summary: string }) => Promise<void>
}> = (props) => {
  const updateSummary = props.updateSummary
  const { summaries, sections } = props.data
  const [selectedSummary, setSelectedSummary] = useState<Summary | null>(null)

  if (summaries.length === 0) {
    return (
      <div className="bg-gray-50 rounded-md w-full transition-all space-y-10 divide-y-2">
        <p className="text-center my-5">No session summaries found</p>
      </div>
    )
  }

  return (
    <div className="bg-gray-50 transition-all space-y-10 divide-y">
      <p className="pt-5 text-center font-bold">Session Summaries</p>

      {summaries.map((summary) => (
        <div
          key={summary.sessionId}
          className="px-4 py-5 space-y-4 sm:px-6 sm:py-2"
        >
          <div>
            <p className="font-medium text-lg">{summary.title}</p>
            <div className="flex justify-between">
              <div className="flex justify-between text-gray-500">
                <p className="flex items-center gap-2">
                  <CalendarIcon className="h-5" />
                  {format(parseISO(summary.start), 'MMM dd, yyyy h:mm a')} (
                  {differenceInMinutes(
                    parseISO(summary.end),
                    parseISO(summary.start),
                  )}
                  min)
                </p>
              </div>

              <div className="flex items-center">
                <p
                  className={`flex items-center gap-2 ml-2 text-sm ${
                    summary.verified ? 'text-green-600' : 'text-orange-500'
                  }`}
                >
                  {summary.verified ? 'Verified' : 'Pending'}
                </p>
                {!summary.verified ? (
                  <Button
                    className="ml-4"
                    onClick={() => setSelectedSummary(summary)}
                  >
                    {'Edit & Verify'}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div
              dangerouslySetInnerHTML={{
                __html: formatSummary(summary.content, sections),
              }}
            />
          </div>
        </div>
      ))}
      <EditSessionSummaryDialog
        isOpen={Boolean(selectedSummary)}
        onClose={() => setSelectedSummary(null)}
        selectedSummary={selectedSummary}
        updateSummary={updateSummary}
      />
    </div>
  )
}
