import { useRef, useState } from 'react'
import { PaperAirplaneIcon } from '@heroicons/react/20/solid'
import { PaperClipIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Textarea } from '~/components/ui/textarea'
import { cn } from '~/utils/cn'

export type ChatSendData = {
  message: string
  file: File | null
}

interface ChatEditorProps {
  onSendMessage: (data: ChatSendData) => void
}

export const ChatEditor: React.FC<ChatEditorProps> = (props) => {
  const [message, setMessage] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const sendMessage = () => {
    const payload = {
      message: message.trim() || file?.name || '',
      file,
    }
    if (!payload.message && !payload.file) return
    props.onSendMessage(payload)
    setFile(null)
    setMessage('')
  }

  return (
    <div className="bg-white border-t min-h-[70px]">
      <input
        type="file"
        className="hidden"
        ref={inputRef}
        onChange={(event) =>
          setFile(
            event.target.files && event.target.files?.length > 0
              ? event.target.files[0]
              : null,
          )
        }
      />
      <div className="border-t-2 border-gray-100 px-4 pt-4 mb-2 sm:mb-0">
        <div className="relative flex">
          <span className="absolute inset-y-0 flex items-center">
            <button
              className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={() => inputRef.current?.click()}
            >
              <PaperClipIcon className="h-5 w-5 text-gray-600" />
            </button>
            {file && (
              <button
                className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                type="button"
                onClick={() => setFile(null)}
              >
                <TrashIcon className="h-5 w-5 text-red-500" />
              </button>
            )}
          </span>

          <Textarea
            className={cn(
              'border-none shadow-none focus:outline-none focus:ring-1 focus:ring-primary',
              'w-full focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-200 rounded-md py-3 pl-10 pr-28',
              file && 'ml-20',
            )}
            value={!file ? message : undefined}
            defaultValue={!file ? undefined : message}
            onChange={(event) => setMessage(event.target.value)}
            disabled={!!file}
            placeholder={file ? file.name : 'Type your message here ...'}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
                sendMessage()
              }
            }}
          />
          <div className="absolute right-0 items-center inset-y-0 sm:flex">
            <button
              className="inline-flex items-center justify-center rounded-lg p-2 sm:px-4 py-3 transition duration-500 ease-in-out text-white bg-primary-500 hover:bg-primary-400 focus:outline-none"
              type="button"
              onClick={sendMessage}
            >
              <span className="font-bold">Send</span>
              <PaperAirplaneIcon className="hidden sm:inline-block sm:h-6 sm:w-6 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
