import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AlertDialog } from '~/components/AlertDialog'

interface Props {
  isOpen: boolean
  type: AppType
  setIsOpen: (isOpen: boolean) => void
}

export const CoachCalendarAlert: React.FC<Props> = (props) => {
  const { isOpen = false, type, setIsOpen } = props
  const [_, setSearchParams] = useSearchParams()

  const onClose = useCallback(() => {
    setIsOpen(false)
    setSearchParams()
  }, [setIsOpen, setSearchParams])

  return (
    <AlertDialog
      title={`Welcome to the Hupo ${type} coaching`}
      isOpen={isOpen}
      onClose={onClose}
      confirmText="Understood"
    >
      <h4 className="text-xs font-semibold">IMPORTANT</h4>

      <p className="text-sm text-gray-500 mt-2 mb-2">
        Before moving on, please sync your calendar for {type} coaching
        separately, even if you've already synced your individual coaching
        calendar.
      </p>
      <p className="text-sm text-gray-500">
        You can sync your {type} coaching calendar by clicking "+ Add calendar
        account" button
      </p>
    </AlertDialog>
  )
}
