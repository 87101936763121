/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ChatUser, TeamChatUser } from '~/routes/_loaders/chats'
import type {
  CoreMarkAsReadPayload,
  CoreMessagePayload,
  ReadAllPayload,
  TeamMessagePayload,
} from '~/socket'
import { useCallback, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from './useAuth'
import { coreSocket, teamSocket, groupSocket } from '~/app/socket'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import {
  appendMessageToCurrentChat,
  markAsRead,
  setActiveCoreChats,
  setActiveGroupChats,
  setActiveTeamChats,
  setCoreChats,
  setCoreConnected,
  setCoreSocketId,
  setGroupChats,
  setGroupConnected,
  setTeamChats,
  setTeamConnected,
} from '~/store/socket.slice'
import { MAX_RETRIEVE_SOCKET_ID_ATTEMPT_COUNT } from '~/app/constants'
import { api, groupApi, teamApi } from '~/app/api'
import { setUIData } from '~/store/ui.slice'
import { playSound } from '~/utils/audio'
import { toast } from '~/components/ui/use-toast'

const onConnectError = (error: any) => {
  // the reason of the error, for example "xhr poll error"
  console.log(error.message)

  // some additional description, for example the status code of the initial HTTP response
  console.log(error.description)

  // some additional context, for example the XMLHttpRequest object
  console.log(error.context)

  toast({
    title: 'Failed to connect to the server',
    description: 'Please check your internet connection',
    variant: 'warning',
  })
}

let retrieveSocketIdAttemptCount = 0

export const useInitSocket = () => {
  const dispatch = useAppDispatch()
  const { coreSocketId } = useAppSelector((store) => store.socket)
  const {
    id: coachId,
    teamId: teamCoachId,
    isTeamCoach,
    isGroupCoach,
  } = useAuth()

  const { data: coreChats } = useQuery({
    initialData: [],
    queryKey: ['core', 'chats'],
    queryFn() {
      return api.get('/coach/chats').json<ChatUser[]>()
    },
  })

  const { data: coreCounter } = useQuery({
    initialData: { unreadMessagesCount: 0, customersCount: 0 },
    queryKey: ['core', 'count'],
    queryFn() {
      return api
        .url('/coach/home/count')
        .get()
        .json<{ unreadMessagesCount: number; customersCount: number }>()
    },
  })

  const { data: teamChats } = useQuery({
    enabled: isTeamCoach,
    initialData: [],
    queryKey: ['team', 'chats'],
    queryFn() {
      return teamApi.get('/coach/chats').json<TeamChatUser[]>()
    },
  })

  const { data: groupChats } = useQuery({
    enabled: isGroupCoach,
    initialData: [],
    queryKey: ['group', 'chats'],
    queryFn() {
      return groupApi.get('/coach/chats').json<TeamChatUser[]>()
    },
  })

  const { data: teamCount } = useQuery({
    enabled: isTeamCoach,
    initialData: { unreadMessagesCount: 0, teamsCount: 0 },
    queryKey: ['team', 'count'],
    queryFn() {
      return teamApi
        .url('/coach/home/count')
        .get()
        .json<{ unreadMessagesCount: number; teamsCount: number }>()
    },
  })
  const { data: groupCount } = useQuery({
    enabled: isGroupCoach,
    initialData: { unreadMessagesCount: 0, groupsCount: 0 },
    queryKey: ['group', 'count'],
    queryFn() {
      return groupApi
        .url('/coach/home/count')
        .get()
        .json<{ unreadMessagesCount: number; groupsCount: number }>()
    },
  })

  useEffect(() => {
    dispatch(
      setUIData({
        usersCount: coreCounter.customersCount,
        teamsCount: teamCount.teamsCount,
        groupsCount: groupCount.groupsCount,
        coreUnreadMessagesCount: coreCounter.unreadMessagesCount,
        teamUnreadMessagesCount: teamCount.unreadMessagesCount,
        groupUnreadMessagesCount: groupCount.unreadMessagesCount,
      }),
    )
  }, [dispatch, coreCounter, teamCount, groupCount])

  useEffect(() => {
    // no-op if the socket is already connected
    coreSocket.connect()

    return () => {
      coreSocket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!isTeamCoach) return
    // no-op if the socket is already connected
    teamSocket.connect()

    return () => {
      teamSocket.disconnect()
    }
  }, [isTeamCoach])

  useEffect(() => {
    if (!isGroupCoach) return
    // no-op if the socket is already connected
    groupSocket.connect()

    return () => {
      groupSocket.disconnect()
    }
  }, [isGroupCoach])

  const retrieveSocketId = useCallback(() => {
    retrieveSocketIdAttemptCount += 1
    if (retrieveSocketIdAttemptCount > MAX_RETRIEVE_SOCKET_ID_ATTEMPT_COUNT) {
      retrieveSocketIdAttemptCount = 0
      return
    }

    if (coreSocket.connected && coreSocket.id) {
      dispatch(setCoreSocketId(coreSocket.id))
      retrieveSocketIdAttemptCount = 0
    } else {
      setTimeout(retrieveSocketId, 500)
    }
  }, [dispatch])

  /**
   * Listening default events
   */
  useEffect(() => {
    const onCoreConnect = () => {
      const socketId = coreSocket.id
      if (socketId) {
        dispatch(setCoreSocketId(socketId))
        dispatch(setCoreConnected(true))
      }
    }
    const onTeamConnect = () => dispatch(setTeamConnected(true))
    const onGroupConnect = () => dispatch(setGroupConnected(true))
    const onCoreDisconnect = () => dispatch(setCoreConnected(false))
    const onTeamDisconnect = () => dispatch(setTeamConnected(false))
    const onGroupDisconnect = () => dispatch(setGroupConnected(false))

    const onReconnect = () => {
      retrieveSocketId()
    }

    coreSocket.on('connect', onCoreConnect)
    coreSocket.on('disconnect', onCoreDisconnect)
    teamSocket.on('connect', onTeamConnect)
    teamSocket.on('disconnect', onTeamDisconnect)
    groupSocket.on('connect', onGroupConnect)
    groupSocket.on('disconnect', onGroupDisconnect)

    coreSocket.io.on('reconnect', onReconnect)
    coreSocket.on('connect_error', onConnectError)
    teamSocket.io.on('reconnect', onReconnect)
    teamSocket.on('connect_error', onConnectError)
    groupSocket.io.on('reconnect', onReconnect)
    groupSocket.on('connect_error', onConnectError)

    return () => {
      coreSocket.off('connect', onCoreConnect)
      coreSocket.off('disconnect', onCoreDisconnect)
      teamSocket.off('connect', onTeamConnect)
      teamSocket.off('disconnect', onTeamDisconnect)
      groupSocket.off('connect', onGroupConnect)
      groupSocket.off('disconnect', onGroupDisconnect)

      coreSocket.off('connect_error', onConnectError)
      teamSocket.off('connect_error', onConnectError)
      groupSocket.off('connect_error', onConnectError)
    }
  }, [dispatch, retrieveSocketId])

  /**
   * Listening custom events for core
   */
  useEffect(() => {
    const onMessage = (payload: CoreMessagePayload) => {
      const messageShape = {
        _id: payload.id,
        message: { text: payload.text },
        file: payload.file,
        sentByUser: payload.sentByUser,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }

      dispatch(
        appendMessageToCurrentChat({
          type: 'core',
          id: payload.user,
          message: messageShape,
        }),
      )

      playSound()
    }

    const onRefresh = () => {
      console.log('refresh')
    }

    const onMarkedAsRead = (payload: CoreMarkAsReadPayload) => {
      dispatch(
        markAsRead({
          type: 'core',
          id: payload.userId,
          count: payload.count,
        }),
      )
    }

    coreSocket.on('message', onMessage)
    coreSocket.on('refresh', onRefresh)
    coreSocket.on('markedAsRead', onMarkedAsRead)

    return () => {
      coreSocket.off('message', onMessage)
      coreSocket.off('refresh', onRefresh)
      coreSocket.off('markedAsRead', onMarkedAsRead)
    }
  }, [dispatch])

  /**
   * Listening custom events for team and group
   */
  useEffect(() => {
    const onMessage =
      (type: 'team' | 'group') => (payload: TeamMessagePayload) => {
        const messageShape = {
          _id: payload._id,
          chat: payload.chat,
          coach: payload.coach,
          sentBy: payload.sentBy,
          sentByModel: payload.sentByModel,
          message: payload.message,
          read: payload.read,
          file: payload.file,
          notifiedEmail: payload.notifiedEmail,
          pushNotificationSent: payload.pushNotificationSent,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        }

        dispatch(
          appendMessageToCurrentChat({
            type,
            id: payload.chat,
            message: messageShape,
          }),
        )

        playSound()
      }
    const onReadAll = (type: 'team' | 'group') => (payload: ReadAllPayload) => {
      dispatch(
        markAsRead({
          type,
          id: payload.chatId,
          count: payload.unreadMessagesCount,
          totalUnread: payload.totalUnreadMessagesCount,
        }),
      )
    }

    const teamOnMessage = onMessage('team')
    const teamOnReadAll = onReadAll('team')

    const groupOnMessage = onMessage('group')
    const groupOnReadAll = onReadAll('group')

    teamSocket.on('new_message', teamOnMessage)
    teamSocket.on('read_all', teamOnReadAll)

    groupSocket.on('new_message', groupOnMessage)
    groupSocket.on('read_all', groupOnReadAll)

    return () => {
      teamSocket.off('new_message', teamOnMessage)
      teamSocket.off('read_all', teamOnReadAll)

      groupSocket.off('new_message', groupOnMessage)
      groupSocket.off('read_all', groupOnReadAll)
    }
  }, [dispatch])

  /**
   * Join core chats
   */
  useEffect(() => {
    const chatIds = []
    for (let i = 0; i < coreChats.length; i++) {
      const chat = coreChats[i]
      const chatId = [coachId, chat.user.id].join('_')

      coreSocket.emit('room:join', {
        socketId: coreSocketId,
        userId: coachId,
        chatRoomId: chatId,
      })

      chatIds.push(chatId)
    }

    dispatch(setCoreChats(coreChats))
    dispatch(setActiveCoreChats(chatIds))
  }, [dispatch, coreChats, coachId, coreSocketId])

  /**
   * Join team chats
   */
  useEffect(() => {
    const chatIds = teamChats.map(({ chat }) => chat.id)
    teamSocket.emit('join_chats', {
      chats: chatIds,
      userId: teamCoachId,
    })

    dispatch(setTeamChats(teamChats))
    dispatch(setActiveTeamChats(chatIds))
  }, [dispatch, teamChats, teamCoachId])

  /**
   * Join group chats
   */
  useEffect(() => {
    const chatIds = groupChats.map(({ chat }) => chat.id)

    groupSocket.emit('join_chats', {
      chats: chatIds,
      userId: coachId,
    })

    dispatch(setGroupChats(groupChats))
    dispatch(setActiveGroupChats(chatIds))
  }, [dispatch, groupChats, coachId])
}
