import { useEffect, useMemo, useState } from 'react'
import addHours from 'date-fns/addHours'
import parseISO from 'date-fns/parseISO'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Toggle } from '~/components/Toggle'
// import { CopyIcon } from '~/icons/CopyIcon'
import { Select } from '~/components/Select'
import {
  DEFAULT_WORKING_HOURS,
  MAX_WORKING_HOURS,
  WORKING_HOUR_OPTIONS,
  getOption,
} from '~/utils/day-schedule'

type Schedule = {
  start: Date
  end: Date
}

interface DayScheduleProps {
  day: string
  is24HourFormat?: boolean
  schedule: Schedule[]
  onChange: (day: string, schedule: Schedule[]) => void
}

export const DaySchedule: React.FC<DayScheduleProps> = (props) => {
  const { day, schedule, is24HourFormat, onChange } = props
  const options = useMemo(
    () => WORKING_HOUR_OPTIONS.map((date) => getOption(date, is24HourFormat)),
    [is24HourFormat],
  )
  const [timeSlots, setTimeSlots] = useState(schedule)

  const toggleDay = (value: boolean) => {
    setTimeSlots(value ? DEFAULT_WORKING_HOURS : [])
  }

  const onSlotChange = (index: number, key: 'start' | 'end', date: Date) => {
    setTimeSlots((prevValue) => {
      const newTimeSlots = [...prevValue]
      newTimeSlots[index] = {
        ...newTimeSlots[index],
        [key]: date,
      }
      return newTimeSlots
    })
  }

  const handleAddTimeSlot = (date: Date) => {
    setTimeSlots((prevValue) => {
      if (prevValue.length === MAX_WORKING_HOURS) {
        return prevValue
      }

      const startTime = addHours(date, prevValue.length)
      const endTime = addHours(startTime, 1)

      return [...prevValue, { start: startTime, end: endTime }]
    })
  }

  const handleDeleteTimeSlot = (index: number) => {
    // Remove the time slot at the given index
    setTimeSlots((prevValue) => [
      ...prevValue.slice(0, index),
      ...prevValue.slice(index + 1),
    ])
  }

  useEffect(() => {
    onChange(day, timeSlots)
  }, [day, timeSlots, onChange])

  // const handleCopyTimes = () => {
  //   // Add your logic to handle copying times
  // }

  return (
    <div className="mb-4 flex w-full flex-col max-sm:gap-2 last:mb-0 sm:flex-row sm:px-0">
      <div className="flex h-[36px] items-center justify-between sm:w-32">
        <div>
          <label className="text-default flex flex-row items-center space-x-2 rtl:space-x-reverse">
            <Toggle enabled={timeSlots.length > 0} setEnabled={toggleDay} />
            <span className="inline-block min-w-[88px] text-sm capitalize">
              {day}
            </span>
          </label>
        </div>
      </div>
      {timeSlots.length > 0 && (
        <div className="space-y-2">
          {timeSlots.map((timeSlot, index) => (
            <div
              key={timeSlot.end.toISOString() + index}
              className="flex sm:ml-2"
            >
              <div className="flex-col">
                <div className="mb-2 flex last:mb-0">
                  <div className="flex flex-row gap-1">
                    <div className="inline-block w-[120px]">
                      <Select
                        options={options}
                        value={getOption(timeSlot.start, is24HourFormat)}
                        onChange={(date) =>
                          onSlotChange(index, 'start', parseISO(date.value))
                        }
                      />
                    </div>
                    <span className="text-default mx-2 w-2 self-center">
                      {' '}
                      -{' '}
                    </span>
                    <div className="inline-block w-[120px] rounded-md">
                      <Select
                        options={options}
                        value={getOption(timeSlot.end, is24HourFormat)}
                        onChange={(date) =>
                          onSlotChange(index, 'end', parseISO(date.value))
                        }
                      />
                    </div>
                  </div>

                  {index === 0 ? (
                    <button
                      title="Add new time slot"
                      className="whitespace-nowrap items-center text-sm font-medium relative rounded-md transition disabled:cursor-not-allowed flex justify-center hover:bg-subtle focus-visible:bg-subtle focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-empthasis disabled:border-subtle disabled:bg-opacity-30 disabled:text-muted disabled:hover:bg-transparent disabled:hover:text-muted disabled:hover:border-subtle h-9 px-4 py-2.5 min-h-[36px] min-w-[36px] !p-2 hover:border-default text-default mx-2"
                      type="button"
                      data-state="closed"
                      onClick={() => handleAddTimeSlot(timeSlot.end)}
                    >
                      <PlusIcon />
                    </button>
                  ) : (
                    <button
                      title="Delete time slot"
                      className="whitespace-nowrap items-center text-sm font-medium relative rounded-md transition disabled:cursor-not-allowed flex justify-center hover:bg-subtle focus-visible:bg-subtle focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-empthasis disabled:border-subtle disabled:bg-opacity-30 disabled:text-muted disabled:hover:bg-transparent disabled:hover:text-muted disabled:hover:border-subtle h-9 px-4 py-2.5 min-h-[36px] min-w-[36px] !p-2 hover:border-default text-default mx-2"
                      type="button"
                      data-state="closed"
                      onClick={() => handleDeleteTimeSlot(index)}
                    >
                      <TrashIcon />
                    </button>
                  )}
                </div>
              </div>
              {/* {index === 0 && (
                <div className="block">
                  <button
                    className="whitespace-nowrap items-center text-sm font-medium relative rounded-md transition disabled:cursor-not-allowed flex justify-center hover:bg-subtle focus-visible:bg-subtle focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-empthasis disabled:border-subtle disabled:bg-opacity-30 disabled:text-muted disabled:hover:bg-transparent disabled:hover:text-muted disabled:hover:border-subtle h-9 px-4 py-2.5 min-h-[36px] min-w-[36px] !p-2 hover:border-default text-default"
                    title="Copy times to …"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    data-state="closed"
                    type="button"
                    onClick={handleCopyTimes}
                  >
                    <CopyIcon className="h-4 w-4" />
                  </button>
                </div>
              )} */}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
