export const Logo: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M255.98 33.3951C167.363 33.3951 95.5107 105.247 95.5107 193.864C95.5107 282.482 167.363 354.334 255.98 354.334C344.597 354.334 416.449 282.482 416.449 193.864C416.449 105.247 344.637 33.3951 255.98 33.3951ZM379.925 266.994C341.524 282.043 299.73 290.306 255.98 290.306C212.23 290.306 170.436 282.043 132.035 266.994L132.075 266.914C138.183 225.998 151.875 185.442 173.75 147.32C195.624 109.199 223.727 76.8256 256.02 50.8391L255.94 50.9589C288.234 76.9453 316.336 109.319 338.211 147.44C360.125 185.562 373.857 226.118 379.925 266.994Z"
          fill="#F04E23"
        />
        <path
          d="M112.191 354.846V395.163C114.424 391.949 117.421 389.412 121.124 387.551C124.827 385.69 129.039 384.788 133.732 384.788C142.41 384.788 149.195 387.41 154.029 392.682C158.863 397.954 161.294 405.2 161.294 414.448V458.713H147.951V416.929C147.951 410.698 146.509 405.877 143.626 402.466C140.742 399.054 136.474 397.362 130.82 397.362C125.449 397.362 121.011 399.167 117.477 402.776C113.972 406.384 112.191 411.093 112.191 416.957V458.741H98.848V356.312L112.191 354.846Z"
          fill="#FF4A0B"
        />
        <path
          d="M185.068 451.805C180.319 446.589 177.973 439.315 177.973 429.955V385.69H191.287V427.304C191.287 433.93 192.729 438.864 195.612 442.134C198.496 445.405 202.708 447.04 208.277 447.04C213.648 447.04 217.973 445.236 221.252 441.627C224.531 438.018 226.171 433.253 226.171 427.304V385.662H239.514V458.713H226.171V449.211C224.107 452.228 221.309 454.709 217.747 456.654C214.185 458.6 210.058 459.587 205.365 459.587C196.573 459.615 189.789 456.993 185.068 451.805Z"
          fill="#FF4A0B"
        />
        <path
          d="M270.694 385.662V395.022C273.323 392.09 276.603 389.665 280.504 387.72C284.405 385.775 288.9 384.788 293.988 384.788C301.027 384.788 307.274 386.339 312.73 389.468C318.186 392.598 322.426 396.968 325.395 402.606C328.363 408.245 329.861 414.786 329.861 422.173C329.861 429.56 328.363 436.101 325.395 441.74C322.426 447.379 318.186 451.777 312.73 454.878C307.246 458.008 300.999 459.558 293.988 459.558C288.9 459.558 284.377 458.6 280.362 456.626C276.348 454.681 273.126 452.228 270.694 449.324V495.252L257.352 497V385.662H270.694ZM310.327 439.964C314.568 435.34 316.688 429.419 316.688 422.201C316.688 414.984 314.568 409.063 310.327 404.439C306.087 399.815 300.49 397.503 293.564 397.503C289.069 397.503 285.055 398.575 281.55 400.717C278.044 402.86 275.302 405.821 273.352 409.57C271.401 413.32 270.412 417.521 270.412 422.201C270.412 426.882 271.373 431.082 273.352 434.832C275.302 438.582 278.044 441.542 281.55 443.685C285.055 445.828 289.069 446.899 293.564 446.899C300.49 446.899 306.059 444.587 310.327 439.964Z"
          fill="#FF4A0B"
        />
        <path
          d="M378.173 385.662C357.763 385.662 341.225 402.155 341.225 422.511C341.225 442.867 357.763 459.361 378.173 459.361C398.583 459.361 415.12 442.867 415.12 422.511C415.12 402.155 398.583 385.662 378.173 385.662ZM406.724 439.287C397.876 442.727 388.265 444.644 378.201 444.644C368.137 444.644 358.498 442.755 349.678 439.287V439.259C351.091 429.87 354.229 420.566 359.261 411.798C364.293 403.029 370.766 395.614 378.201 389.637V389.665C385.636 395.643 392.109 403.058 397.141 411.826C402.145 420.594 405.311 429.898 406.724 439.287Z"
          fill="#FF4A0B"
        />
      </g>
    </svg>
  )
}
