import { useLoaderData } from 'react-router-dom'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { loader } from './_loaders/members'

export const TeamMembers: React.FC = () => {
  const { data } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  return (
    <div className="overflow-x-auto rounded-lg bg-white px-4 py-5 shadow sm:p-6 inline-block min-w-full align-middle sm:px-6 lg:px-8">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Timezone
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {data.members.map((member) => (
            <tr key={member._id}>
              <td className="whitespace-nowrap flex items-center justify-start py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div className="h-11 w-11 flex-shrink-0">
                  {!member.profile?.picture ? (
                    <UserCircleIcon
                      className="h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                  ) : (
                    <img
                      className="h-11 w-11 rounded-full"
                      src={member.profile?.picture}
                      alt={member.profile?.name}
                      referrerPolicy="no-referrer"
                    />
                  )}
                </div>

                <div className="ml-4">
                  <div className="font-medium text-gray-900">
                    {member.profile?.name}
                  </div>
                  <div className="mt-1 text-gray-500">{member.title}</div>
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                <div className="text-gray-900">{member.email}</div>
              </td>
              <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                <div className="text-gray-900">{member.timezone}</div>
              </td>
            </tr>
          ))}

          {data.members.length === 0 && (
            <tr>
              <td
                colSpan={6}
                className="px-3 py-5 text-sm text-gray-500 text-center"
              >
                No team members yet.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
