import type { UISliceState } from '~/store/ui.slice'
import {
  MegaphoneIcon,
  Cog6ToothIcon,
  ChatBubbleLeftRightIcon,
  UsersIcon,
  CalendarIcon,
  BanknotesIcon,
  HomeIcon,
  BookOpenIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'

type CountKey = keyof UISliceState

export const USER_NAVIGATION = [
  { name: 'Settings', href: '/settings/profile' },
  { name: 'Sign out', action: 'logout' },
]

export const NAVIGATION = [
  { name: 'Home', href: '/', icon: HomeIcon },
  {
    name: 'Users',
    href: '/users?active=true',
    countKey: 'usersCount' as CountKey,
    icon: UsersIcon,
  },
  {
    name: 'Teams',
    href: '/teams',
    countKey: 'teamsCount' as CountKey,
    icon: UserGroupIcon,
    onlyTeam: true,
  },
  {
    name: 'Groups',
    href: '/groups',
    countKey: 'groupsCount' as CountKey,
    icon: UserGroupIcon,
    onlyGroup: true,
  },
  {
    name: 'Chat',
    href: '/chats/core',
    countKey: 'chats',
    className: 'bg-primary text-white ring-transparent',
    icon: ChatBubbleLeftRightIcon,
  },
  { name: 'Schedule', href: '/schedule', icon: CalendarIcon },
  {
    name: 'Feedback',
    href: '/feedback/core',
    icon: MegaphoneIcon,
  },
  { name: 'Invoices', href: '/invoices', icon: BanknotesIcon },
  { name: 'Settings', href: '/settings/profile', icon: Cog6ToothIcon },
]

export const RESOURCES = [
  {
    id: 'docs',
    name: 'Coach App Guide',
    href: 'https://lovely-lumber-a4e.notion.site/Hupo-Coach-Guidebook-03701fbc82ad4ed3b708d58f5562dc8e',
    icon: BookOpenIcon,
    external: true,
  },
  {
    id: 'help',
    name: 'Help Center',
    icon: QuestionMarkCircleIcon,
    action(e: React.MouseEvent<HTMLAnchorElement>) {
      e.preventDefault()
      window._chatlio.open()
    },
    external: false,
  },
  {
    id: 'troubleshoot',
    name: 'Troubleshooting',
    icon: ExclamationTriangleIcon,
    action(e: React.MouseEvent<HTMLAnchorElement>) {
      e.preventDefault()
      // window._chatlio.open()
    },
    external: false,
  },
]

export const FEEDBACK_HUB = [
  {
    name: 'Share Your Thoughts',
    icon: ChatBubbleOvalLeftEllipsisIcon,
    external: false,
  },
]

// export const ACTIVITY_ITEMS = [
//   {
//     user: {
//       name: 'Michael Foster',
//       imageUrl:
//         'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//     projectName: 'ios-app',
//     commit: '2d89f0c8',
//     branch: 'main',
//     date: '1h',
//     dateTime: '2023-01-23T11:00',
//   },
//   // More items...
// ]
