export const APP_VERSION = '3.0'
export const COMPANY_NAME = 'AMI LABS PTE. LTD.'
export const PRODUCT_NAME = 'HUPO'
export const DEFAULT_COACH_TITLE = 'Coach'
export const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone
export const MAX_RETRIEVE_SOCKET_ID_ATTEMPT_COUNT = 10

export const LOGO = {
  src: '/logos/trademarked/orange/logo.svg',
  alt: 'Hupo',
}

export const STATS = [
  { label: 'Completed sessions', key: 'completedSessions' },
  { label: 'Upcoming sessions', key: 'upcomingSessions' },
  { label: 'Avg. feedback rate', key: 'avgFeedbackRate' },
]

export const DURATION_OPTIONS = [
  { minutes: 30, name: '30 mins' },
  { minutes: 60, name: '60 mins' },
  { minutes: 90, name: '90 mins' },
]

export const ONBOARDING_STEPS = [
  {
    id: 'profile',
    name: 'Step 1',
    title: 'Welcome to Hupo',
    description:
      'We just need some basic info to get your profile setup. <br />You’ll be able to edit this later.',
    href: '/user-settings',
  },
  {
    id: 'working-hours',
    name: 'Step 2',
    title: 'Set your weekly schedule',
    description:
      'You can further customise your schedule later in the settings page',
    href: '/working-hours',
  },
  {
    id: 'connect-calendar',
    name: 'Step 3',
    title: 'Connect your calendar',
    description:
      'Connect your calendar to automatically sync existing blocked times and new events as they are scheduled',
    href: '/connect-calendar',
  },
  {
    id: 'pre-onboarding',
    name: 'Step 4',
    title: 'Nearly there!',
    description:
      'Please provide the following details to build your coach profile. This information will allow coachees to get to know you better',
    href: '/profile',
  },
  {
    id: 'bio',
    name: 'Step 5',
    title: 'Nearly there!',
    description:
      'Please provide the following details to build your coach profile. This information will allow coachees to get to know you better',
    href: '/bio',
  },
]

/**
 * 10 seconds
 */
export const RENDER_TIMEOUT_DURATION = 10e3
