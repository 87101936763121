import type { CoreSocket, TeamSocket } from '~/socket'
import { io } from 'socket.io-client'

if (import.meta.env.DEV) {
  localStorage.debug = '*'
}

const CORE_SOCKET_URL = import.meta.env.VITE_API_BASE.replace('/v2', '')
const TEAM_SOCKET_URL = import.meta.env.VITE_TEAM_API_BASE.replace('/v2', '')
const GROUP_SOCKET_URL = import.meta.env.VITE_GROUP_API_BASE.replace('/v2', '')

export const coreSocket: CoreSocket = io(CORE_SOCKET_URL, {
  autoConnect: false,
})

export const teamSocket: TeamSocket = io(TEAM_SOCKET_URL, {
  autoConnect: false,
})

export const groupSocket: TeamSocket = io(GROUP_SOCKET_URL, {
  autoConnect: false,
})
