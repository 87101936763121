import Cookies from 'js-cookie'
import { store } from './store'

const storedCookieToken = Cookies.get('token')

let token = storedCookieToken

store.subscribe(() => {
  const { auth, onboarding } = store.getState()

  if (auth.token !== storedCookieToken) {
    Cookies.set('token', auth.token)
    token = auth.token
  }

  if (!onboarding.inProgress) {
    Cookies.remove('onboarding_in_progress')
  }
})

export const authenticated = () => {
  const token = Cookies.get('token')
  return typeof token === 'string' && token.length > 0
}

export const onboardingInProgress = () => {
  const onboarding = Cookies.get('onboarding_in_progress')
  return onboarding === 'true'
}

export { token }
