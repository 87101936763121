/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, type LoaderFunctionArgs } from 'react-router-dom'
import { api } from '~/app/api'

export interface SessionNote {
  _id: string
  start: string
  end: string
  badges: Badge[]
  note?: Note
}

export interface Badge {
  automatic: boolean
  _id: string
  name: string
  description: string
  iconName: string
  createdAt: string
  updatedAt: string
  __v: number
}

export interface Note {
  body: Body
  _id: string
  coach: Coach
  session: string
  user: User
  createdAt: string
  updatedAt: string
  __v: number
}

export interface Body {
  text: string
}

export interface Coach {
  profile: Profile
  notifications: Notifications
  customers: string[]
  timezone: string
  language: string
  bookings: string[]
  appVersion: string
  _id: string
  email: string
}

export interface Profile {
  name: string
  businessName: any
  video: string
  videos: any[]
  picture: string
  title: string
}

export interface Notifications {
  email: Email
}

export interface Email {
  booking: boolean
  cancellation: boolean
  reschedule: boolean
  reminder: boolean
  followUp: boolean
}

export interface User {
  profile: Profile2
  isOnline: boolean
  timezone: string
  language: string
  _id: string
  email: string
  company: string
}

export interface Profile2 {
  name: string
  gender: any
  picture: any
  phone: any
  fullPhone: any
  nickName: any
}

const fetchSessionNotes = (id: string) =>
  queryOptions({
    queryKey: ['users', id, 'session', 'notes'],
    queryFn: () =>
      api
        .url('/coach/session_notes')
        .query({ userId: id })
        .get()
        .json<SessionNote[]>(),
  })

export const loader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return defer({
      data: queryClient.fetchQuery(fetchSessionNotes(id)),
    })
  }
