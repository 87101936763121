/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense } from 'react'
import { Await, useLoaderData, useNavigate } from 'react-router-dom'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { loader } from './_loaders/diagnostics'
import { LoadingSpinner } from '~/Loading'
import { cn } from '~/utils/cn'

interface Props {
  diagnostics: any
  highlightedAreas: { highest: any[]; lowest: any[] }
  assessments: { status: any[]; answers: any }
}

export const GroupDiagnostics: React.FC = () => {
  const deferredData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={deferredData.data} errorElement={<div>Error</div>}>
        {(data) => (
          <Diagnostics
            diagnostics={data.diagnostics}
            highlightedAreas={data.highlightedAreas}
            assessments={data.assessments}
          />
        )}
      </Await>
    </Suspense>
  )
}

type CardProps = React.PropsWithChildren<{ className: string }>
const Card: React.FC<CardProps> = (props) => (
  <div
    className={cn(
      'px-4 py-2 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl',
      props.className,
    )}
  >
    {props.children}
  </div>
)

const statuses = {
  Completed: 'text-green-400 bg-green-400/10',
  Error: 'text-rose-400 bg-rose-400/10',
} as const

const legend = [
  { name: 'Strongly disagree' },
  { name: 'Disagree' },
  { name: 'Neutral' },
  { name: 'Agree' },
  { name: 'Strongly agree' },
]

const Diagnostics: React.FC<Props> = (props) => {
  const { diagnostics, highlightedAreas, assessments } = props
  const navigate = useNavigate()

  return (
    <>
      <Card className="py-7 px-5">
        <h2 className="mb-5 text-xl font-semibold text-gray-900">
          Group diagnostics status
        </h2>
        <table className="mt-6 px-5 w-full sm:whitespace-nowrap text-left">
          <thead className="border-b border-gray-900/10 text-sm leading-6 text-gray-900">
            <tr>
              <th scope="col" className="font-semibold sm:pl-6 lg:pl-8">
                User
              </th>
              <th scope="col" className="py-2 font-semibold text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-900/5">
            {assessments.status.map((item) => (
              <tr
                key={item._id}
                className="hover:cursor-pointer"
                onClick={() =>
                  navigate(`../members/${item._id}/hupo-perspectives`)
                }
              >
                <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                  <div className="flex items-center gap-x-4">
                    {!item?.picture ? (
                      <UserCircleIcon
                        className="h-12 w-12 text-gray-300"
                        aria-hidden="true"
                      />
                    ) : (
                      <img
                        className="h-8 w-8 rounded-full bg-gray-800"
                        src={item.picture}
                        alt={item.name}
                        referrerPolicy="no-referrer"
                      />
                    )}

                    <div>
                      <p className="truncate text-sm font-medium leading-6 text-gray-900">
                        {item.name}
                      </p>
                      <p className="text-sm leading-6 text-gray-500">
                        {item.title}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="py-2 text-sm leading-6">
                  <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                    <div
                      className={cn(
                        statuses[item.completed ? 'Completed' : 'Error'],
                        'flex-none rounded-full p-1',
                      )}
                    >
                      <div className="h-1.5 w-1.5 rounded-full bg-current" />
                    </div>
                    <div className="hidden sm:block text-gray-900">
                      {item.completed ? 'Completed' : 'Pending'}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>

      <div className="flex max-sm:flex-wrap justify-between gap-5">
        <Card className="py-5 px-7 w-full">
          <h2 className="mb-5 text-xl font-semibold text-gray-900">
            Areas for celebration
          </h2>
          <table className="mt-6 w-full sm:whitespace-nowrap">
            <thead className="border-b border-gray-900/10 text-sm leading-6 text-gray-900">
              <tr>
                <th scope="col" className="py-2 text-left font-semibold">
                  Group behaviour
                </th>
                <th scope="col" className="py-2 text-right font-semibold">
                  Average rating
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-900/5">
              {highlightedAreas.highest.map((item) => (
                <tr key={item.description}>
                  <td className="py-4 text-sm text-left leading-6 text-gray-800">
                    {item.description}
                  </td>
                  <td className="py-4 text-sm text-right leading-6 text-gray-800">
                    {item.score.toFixed(1)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>

        <Card className="py-5 px-7 w-full">
          <h2 className="mb-5 text-xl font-semibold text-gray-900">
            Areas for growth
          </h2>
          <table className="mt-6 w-full sm:whitespace-nowrap">
            <thead className="border-b border-gray-900/10 text-sm leading-6 text-gray-900">
              <tr>
                <th scope="col" className="py-2 text-left font-semibold">
                  Group behaviour
                </th>
                <th scope="col" className="py-2 text-right font-semibold">
                  Average rating
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-900/5">
              {highlightedAreas.lowest.map((item) => (
                <tr key={item.description}>
                  <td className="py-4 text-sm text-left leading-6 text-gray-800">
                    {item.description}
                  </td>
                  <td className="py-4 text-sm text-right leading-6 text-gray-800">
                    {item.score.toFixed(1)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </div>

      <Card className="py-5 px-7 w-full">
        <div className="mb-5">
          <h2 className="text-xl font-semibold text-gray-900">
            Leadership Values Scoring
          </h2>
          <p className="text-sm leading-6 text-gray-500">
            You can see chart form of your group's DNA below. The chart shows
            the average score of your group in each area.
          </p>
        </div>

        <table className="mt-6 sm:w-full sm:whitespace-nowrap">
          <thead className="border-b border-gray-900/10 text-sm leading-6 text-gray-900">
            <tr>
              <th scope="col" className="py-2 font-semibold text-left">
                Description
              </th>
              <th
                scope="col"
                className="py-2 font-semibold text-right sm:text-left"
              >
                Average Rating:
                <br />
                <span className="text-center">Coachees</span>
              </th>
              <th
                scope="col"
                className="py-2 font-semibold text-right sm:text-left"
              >
                Average Rating
                <br />
                <span className="text-center">Colleagues</span>
              </th>
              <th
                scope="col"
                className="py-2 font-semibold text-right sm:text-left"
              >
                Score Range
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-900/5">
            {Object.entries<number>(diagnostics.scores.users).map(
              ([key, score]) => (
                <tr key={key}>
                  <td className="py-4 text-sm leading-6 text-gray-800 text-left">
                    {diagnostics.values[key]}
                  </td>
                  <td className="py-4 text-sm leading-6 text-gray-800 max-sm:text-right">
                    {score.toFixed(1)}
                  </td>
                  <td className="py-4 text-sm leading-6 text-gray-800 max-sm:text-right">
                    {diagnostics.scores.managers[key].toFixed(1)}
                  </td>
                  <td className="py-4 text-sm leading-6 text-gray-800 max-sm:text-right">
                    {diagnostics.scores.range[key].join(' - ')}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </Card>

      {/* <Card
        className={cn(
          'py-3.5 px-5',
          Object.keys(assessments.answers).length === 0 && 'hidden',
        )}
      >
        <h2 className="mb-5 text-xl font-semibold text-gray-900">
          Open-ended Questions
        </h2>

        {Object.entries<any[]>(assessments.answers).map(([key, value]) => (
          <div key={value[0]._id}>
            <p className="mb-2 text-lg font-semibold text-gray-900">
              {value[0].question?.title}
            </p>
            <ul className="space-y-2 text-gray-700 list-disc list-inside mb-4">
              {value.map((answer: any, index) => (
                <li key={`${key}_${index}`} className="ml-4">
                  {answer.otherAnswer}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Card> */}

      <Card className="py-5 px-7 w-full">
        <h3 className="text-lg font-semibold text-gray-900">
          The numbers above taken from the following scale
        </h3>
        <div className="flex flex-col gap-2 items-start justify-center my-2">
          {legend.map((item, index) => (
            <div className="flex items-center gap-2" key={item.name}>
              <div className="w-4 h-4 rounded-full bg-gray-900/10 flex items-center justify-center">
                <span className="text-xs font-semibold text-gray-900">
                  {index + 1}
                </span>
              </div>
              <span className="text-sm text-gray-800">{item.name}</span>
            </div>
          ))}
        </div>
      </Card>
    </>
  )
}
