import { useParams, useRevalidator } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { api } from '~/app/api'
import { BasicDialog } from '~/components/BasicDialog'
import { Button } from '~/components/Button'
import { useToast } from '~/components/ui/use-toast'

interface CoachNoteDialogProps {
  isOpen: boolean
  onClose: () => void
  editMode: boolean
  userId?: string
  noteId?: string
  note?: string
}

type Inputs = {
  note: string
}

export const CoachNoteDialog: React.FC<CoachNoteDialogProps> = (props) => {
  const { isOpen, onClose, editMode } = props
  const { revalidate } = useRevalidator()
  const queryClient = useQueryClient()
  const { id: userId } = useParams<{ id: string }>()
  const { toast } = useToast()
  const form = useForm<Inputs>({
    values: {
      note: props.note ?? '',
    },
  })

  const { mutate: createNote } = useMutation({
    mutationKey: ['coach', 'notes', 'create'],
    async mutationFn(data: { userId: string; note: string }) {
      await api
        .url('/coach/notes')
        .post({
          userId: data.userId,
          body: { text: data.note },
        })
        .json()
    },
    async onSuccess(_, data) {
      toast({
        title: 'Success',
        description: 'Note created successfully',
        variant: 'success',
      })

      await queryClient.invalidateQueries({
        queryKey: ['users', data.userId, 'coach', 'notes'],
        exact: true,
      })
      revalidate()
    },
    onSettled() {
      onClose()
    },
  })

  const { mutate: updateNote } = useMutation({
    mutationKey: ['coach', 'notes', 'update'],
    async mutationFn(data: { noteId: string; note: string }) {
      await api
        .url(`/coach/notes/${data.noteId}`)
        .patch({
          body: { text: data.note },
        })
        .json()
    },
    async onSuccess() {
      toast({
        title: 'Success',
        description: 'Note updated successfully',
        variant: 'success',
      })

      await queryClient.invalidateQueries({
        queryKey: ['users', userId, 'coach', 'notes'],
        exact: true,
      })
      revalidate()
    },
    onSettled() {
      onClose()
    },
  })

  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={onClose}
      title={editMode ? 'Edit Note' : 'Add Note'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          const note = form.getValues('note')
          if (editMode) {
            updateNote({ noteId: props.noteId!, note })
          } else {
            createNote({ userId: props.userId!, note })
          }
        }}
      >
        <div>
          <label
            htmlFor="coach-note"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Note
          </label>
          <div className="mt-2">
            <textarea
              id="coach-note"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              placeholder="Your note..."
              aria-describedby="coach-note-description"
              rows={8}
              minLength={5}
              required
              {...form.register('note', { required: true })}
            />
          </div>
          <p id="coach-note-description" className="mt-2 text-sm text-gray-500">
            Note will be visible only for you.
          </p>
        </div>

        <div className="mt-5">
          <Button className="w-full" type="submit">
            Save
          </Button>
        </div>
      </form>
    </BasicDialog>
  )
}
