import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'

import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { TimeZoneSelect } from '~/components/timezone/TimeZoneSelect'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { setOnboardingData } from '~/store/onboarding.slice'
import { Select } from '~/components/Select'
import { LOCAL_TIMEZONE } from '~/app/constants'
import { api } from '~/app/api'
import { setAuthData } from '~/store/auth.slice'
import { setSettings } from '~/store/settings.slice'

type Inputs = {
  name: string
  language: string
  timeZone: string
  timeFormat: '12' | '24'
}

const languages = [
  { value: 'en', label: 'English' },
  { value: 'id', label: 'Bahasa' },
  { value: 'jp', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
  { value: 'th', label: 'Thai' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'zh', label: 'Cantonese' },
  { value: 'ru', label: 'Russian' },
]

const timeFormats = [
  { value: '12', label: '12 hour' },
  { value: '24', label: '24 hour' },
]

export const UserSettings: React.FC = () => {
  const { i18n } = useTranslation()
  const onboardingState = useAppSelector((state) => state.onboarding)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [languageOption, setLanguageOption] = useState(languages[0])
  const form = useForm<Inputs>({
    defaultValues: {
      name: onboardingState.name,
      language: onboardingState.language,
      timeZone: onboardingState.timeZone ?? LOCAL_TIMEZONE,
      timeFormat: onboardingState.timeFormat,
    },
  })

  const { mutate: setProfile } = useMutation({
    mutationKey: ['onboarding', 'profile'],
    mutationFn(payload: Inputs) {
      return api
        .url('/coach/onboarding/profile')
        .post({
          name: payload.name,
          language: payload.language,
          timeZone: payload.timeZone,
          timeFormat: payload.timeFormat,
          dryRun: window.isPlaywright ?? false,
        })
        .json()
    },
    onSuccess(_, variables) {
      const { name, language, timeZone, timeFormat } = variables
      dispatch(
        setOnboardingData({
          name,
          language,
          timeZone,
          timeFormat,
        }),
      )

      dispatch(setAuthData({ name }))
      dispatch(setSettings({ timeFormat, timeZone }))
    },
  })

  const timeZone = form.watch('timeZone')
  const timeFormat = form.watch(
    'timeFormat',
    onboardingState.timeFormat ?? '12',
  )

  const timeFormatValue = useMemo(
    () => timeFormats.find((f) => f.value === timeFormat),
    [timeFormat],
  )

  const time = useMemo(
    () =>
      formatInTimeZone(
        new Date(),
        timeZone,
        timeFormat === '12' ? 'p' : 'HH:mm',
      ),
    [timeZone, timeFormat],
  )

  const onLanguageChange = ({ value, label }: (typeof languages)[number]) => {
    form.setValue('language', value)
    dispatch(setOnboardingData({ language: value }))
    setLanguageOption({ value, label })

    if (i18n.languages.includes(value)) {
      i18n.changeLanguage(value)
    }
  }

  const onSubmit = form.handleSubmit((data) => {
    setProfile(data)
    navigate('/onboarding/working-hours')
  })

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-3">
        <Input
          id="full-name"
          type="text"
          label="Full name"
          placeholder="John Doe"
          required
          error={form.formState.errors.name?.message}
          {...form.register('name')}
        />
        <Select
          options={languages}
          label="Language"
          value={languageOption}
          onChange={onLanguageChange}
        />
        <Select
          options={timeFormats}
          label="Time format"
          value={timeFormatValue}
          onChange={({ value }) => {
            form.setValue('timeFormat', value as Inputs['timeFormat'])
          }}
        />
        <div>
          <TimeZoneSelect
            defaultValue={form.formState.defaultValues?.timeZone}
            onChange={(tz) => form.setValue('timeZone', tz)}
          />
          <p className="text-sm text-gray-400 mt-1.5">
            Current time is: {time}
          </p>
        </div>
      </div>

      <Button type="submit" className="mt-10 w-full">
        Continue
      </Button>
    </form>
  )
}
