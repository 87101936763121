import { useCallback, useMemo } from 'react'
import {
  Link,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import {
  ChatBubbleOvalLeftEllipsisIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { Badge } from '~/components/Badge'
import { Tabs, TabsList, TabsTrigger } from '~/components/ui/tabs'

const formatDate = (date: string) => {
  return format(parseISO(date), 'yyyy-MM-dd')
}

const formatIntervalTime = ({ start, end }: { start: string; end: string }) => {
  return `${format(parseISO(start), 'HH:mm')} - ${format(
    parseISO(end),
    'HH:mm',
  )}`
}

export const Users: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const users = useLoaderData() as any[]
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const defaultTab = useMemo(
    () => (searchParams.get('active') === 'true' ? 'active' : 'inactive'),
    [searchParams],
  )

  const onTabChange = useCallback(
    (value: string) => {
      navigate({ search: `?active=${value === 'active'}` })
    },
    [navigate],
  )

  return (
    <>
      <div className="w-full sm:max-w-md space-y-5">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
          Users
        </h2>

        <Tabs defaultValue={defaultTab} onValueChange={onTabChange}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="active">
              <span>Active</span>
            </TabsTrigger>
            <TabsTrigger value="inactive">
              <span>Inactive</span>
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      <div className="px-4 sm:px-6 lg:px-8 bg-white sm:rounded-lg sm:shadow">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Company
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Last call
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Next call
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Sessions
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users.map((user) => (
                    <tr key={user._id}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <Link
                          to={`/users/${user._id}/assessment-results`}
                          className="flex items-center"
                        >
                          <div className="h-11 w-11 flex-shrink-0">
                            {user.profile.picture ? (
                              <img
                                className="h-11 w-11 object-cover rounded-full"
                                src={user.profile.picture}
                                alt={user.profile.name}
                              />
                            ) : (
                              <UserCircleIcon
                                className="h-12 w-12 text-gray-300"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {user.profile.name}
                            </div>
                            <div className="mt-1 text-gray-500">
                              {user.email}
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{user.company.name}</div>

                        <a
                          className="mt-1 text-gray-500"
                          data-tooltip-id="cohort-no"
                        >
                          Cohort #{user.batchNumber}
                        </a>
                        <Tooltip id="cohort-no" place="top">
                          <div>
                            <p>Cohort is batch number of the user.</p>
                            <p>It is used to group users into cohorts.</p>
                          </div>
                        </Tooltip>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {user.lastEvent && (
                            <time dateTime={formatDate(user.lastEvent.start)}>
                              {formatDate(user.lastEvent.start)}
                            </time>
                          )}
                        </div>
                        <div className="mt-1 text-gray-500">
                          {user.lastEvent
                            ? formatIntervalTime(user.lastEvent)
                            : 'N/A'}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {user.nextEvent && (
                            <time dateTime={formatDate(user.nextEvent.start)}>
                              {formatDate(user.nextEvent.start)}
                            </time>
                          )}
                        </div>
                        <div className="mt-1 text-gray-500">
                          {user.nextEvent
                            ? formatIntervalTime(user.nextEvent)
                            : 'N/A'}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <Badge variant="green" data-tooltip-id="sessions-no">
                          {!user.stats
                            ? 'N/A'
                            : `${user.stats?.completedSessions ?? 0} / ${
                                user.stats?.availableSessions
                                  ? Math.round(user.stats?.availableSessions)
                                  : '∞'
                              }`}
                        </Badge>
                        <Tooltip id="sessions-no" place="top">
                          <div>
                            <p>A / B</p>
                            <p>-------</p>
                            <p>A - completed sessions</p>
                            <p>B - available sessions</p>
                          </div>
                        </Tooltip>
                      </td>
                      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <div className="flex flex-shrink-0">
                          <Link
                            to={`/chats/core/${user._id}`}
                            className="relative inline-flex items-center rounded-md bg-zinc-50 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <ChatBubbleOvalLeftEllipsisIcon
                              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Chat</span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}

                  {users.length === 0 && (
                    <tr>
                      <td
                        colSpan={6}
                        className="px-3 py-5 text-sm text-gray-500 text-center"
                      >
                        No users yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
