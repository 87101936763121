/* eslint-disable @typescript-eslint/no-explicit-any */
import type { TFunction } from 'i18next'
import type { EventData } from './schedule'
import type { CalendarOptions, EventSourceInput } from '@fullcalendar/core'
import { JsonRequestError } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import timeGridPlugin from '@fullcalendar/timegrid'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import format from 'date-fns/format'
import addHours from 'date-fns/addHours'
import isPast from 'date-fns/isPast'
import { api } from '~/app/api'
import { toast } from '~/components/ui/use-toast'

type Data = {
  t: TFunction<'schedule', undefined>
  isMobileScreen: boolean
  landscapeMode: boolean
  timeZone: string
  loggedInAsAdmin: boolean
  hour12: boolean
  setEventData: (value: EventData) => void
  setIsRescheduling: (value: boolean) => void
  setShowEventDetails: (value: boolean) => void
  revertRef: React.MutableRefObject<() => void>
}

const SOURCES: EventSourceInput[] = [
  {
    id: 'session',
    events: (info, successCallback: any, failureCallback) => {
      api
        .url('/coach/events/standalone')
        .query({
          from: info.start.toISOString(),
          to: info.end.toISOString(),
        })
        .get()
        .json()
        .then(successCallback)
        .catch(failureCallback)
    },
    backgroundColor: '#eff6ff',
    textColor: '#1d4ed8',
    borderColor: '#1d4ed8',
  },
  {
    id: 'availability',
    editable: false,
    events: (info, successCallback: any, failureCallback) => {
      api
        .url('/coach/events/availability')
        .query({
          from: info.start.toISOString(),
          to: info.end.toISOString(),
        })
        .get()
        .json()
        .then(successCallback)
        .catch(failureCallback)
    },
    display: 'background',
    backgroundColor: '#22c55e',
    // backgroundColor: "#fdf2f8",
    // textColor: "#ec4899",
    // borderColor: "#ec4899",
  },
  {
    id: 'block',
    editable: false,
    events: (info, successCallback: any, failureCallback) => {
      api
        .url('/coach/events/block')
        .query({
          from: info.start.toISOString(),
          to: info.end.toISOString(),
        })
        .get()
        .json()
        .then(successCallback)
        .catch(failureCallback)
    },
    // display: "background",
    // backgroundColor: "#ef4444",
    backgroundColor: '#fef2f2',
    textColor: '#ef4444',
    borderColor: '#ef4444',
  },
  {
    id: 'slot',
    editable: false,
    events: (info, successCallback: any, failureCallback) => {
      api
        .url('/coach/events/slot')
        .query({
          from: info.start.toISOString(),
          to: info.end.toISOString(),
        })
        .get()
        .json()
        .then(successCallback)
        .catch(failureCallback)
    },
    backgroundColor: '#fef2f2',
    textColor: '#22c55e',
    borderColor: '#22c55e',
  },
]

const MOBILE_HEADER = {
  start: 'title',
  center: 'prev,today,next',
  end: 'timeGridDay,listWeek',
}

const DESKTOP_HEADER = {
  start: 'title',
  end: 'prev,today,next dayGridMonth,timeGridWeek,timeGridDay,listWeek',
}

export const buildOptions = (data: Data): CalendarOptions => {
  const {
    t,
    isMobileScreen,
    landscapeMode,
    timeZone,
    hour12,
    loggedInAsAdmin,
    setEventData,
    setShowEventDetails,
    setIsRescheduling,
    revertRef,
  } = data

  return {
    plugins: [
      momentTimezonePlugin,
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      listPlugin,
    ],
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12,
    },
    initialView: isMobileScreen ? 'timeGridDay' : 'timeGridWeek',
    headerToolbar: isMobileScreen ? MOBILE_HEADER : DESKTOP_HEADER,
    height: 'auto',
    timeZone,
    buttonText: {
      today: t('today'),
      month: t('month'),
      week: t('week'),
      day: t('day'),
      next: t('next'),
      prev: t('prev'),
      nextYear: t('next_year'),
      prevYear: t('prev_year'),
      list: t('week_list'),
    },
    firstDay: 1,
    editable: true,
    selectable: true,
    selectMirror: true,
    nowIndicator: true,
    dayMaxEvents: true,
    weekends: true,
    unselectAuto: false,
    // expandRows: true,
    // slotDuration: { hour: 1 },
    // selectMinDistance: 10,
    titleFormat(value) {
      const { start, end } = value
      const startStr = format(start.marker, 'd MMM yyyy')

      if (isMobileScreen) return `${startStr} (${timeZone})`

      if (end) {
        const endStr = format(end.marker, 'd MMM yyyy')
        return `${startStr} - ${endStr} (${timeZone})`
      }

      return `${startStr} (${timeZone})`
    },
    selectAllow(info) {
      if (isMobileScreen) return false
      const past = isPast(info.start)

      if (past && !loggedInAsAdmin) {
        toast({
          title: 'Warning',
          description: 'You cannot create an event in the past',
          variant: 'warning',
        })
        return false
      }

      const duration = differenceInMinutes(info.end, info.start)
      return duration >= 60 && duration <= 60 * 12
    },
    dateClick(arg) {
      if (isMobileScreen) return
      const { date: start, view } = arg
      const end = addHours(start, 1)

      view.calendar.getEventById('new-event')?.remove()

      if (isPast(start) && !loggedInAsAdmin) {
        toast({
          title: 'Warning',
          description: 'You cannot create an event in the past',
          variant: 'warning',
        })
        return
      }

      setEventData({ id: '', type: '', start, end })
      view.calendar.addEvent({ id: 'new-event', start, end, allDay: false })
    },
    eventClick(info) {
      const eventType = info.event.extendedProps.type
      const eventStart = info.event.start

      if (eventType === 'availability' || eventStart == null) {
        return
      }

      const past = isPast(eventStart)
      info.event.setExtendedProp('isPast', past)

      if (isMobileScreen || landscapeMode) {
        if (!info.event) {
          return
        }
        setEventData({
          id: info.event.id,
          type: eventType ?? info.event.extendedProps?.type,
          start: eventStart,
          end: info.event.end,
          ref: info.event.extendedProps?.ref,
          other: {
            title: info.event.title,
            isPast: past,
            calendarId: info.event.extendedProps?.calendarId,
          },
        })
        setShowEventDetails(true)
      }
    },
    eventDrop(arg) {
      if (arg.event.id === 'new-event') {
        return arg.revert()
      }
      const oldEvent = arg.oldEvent
      const newStart = arg.event.start
      const newEnd = arg.event.end
      if (oldEvent.start && newStart) {
        const isCurrentAlreadyPast = isPast(oldEvent.start)
        const isNewDateIsPast = isPast(newStart)

        if ((isCurrentAlreadyPast || isNewDateIsPast) && !loggedInAsAdmin) {
          toast({
            title: 'Warning',
            description: 'You cannot reschedule past events',
            variant: 'warning',
          })
          return arg.revert()
        }
      }

      revertRef.current = arg.revert
      setEventData({
        id: arg.event.id,
        type: arg.event.extendedProps.type,
        start: newStart,
        end: newEnd,
        ref: arg.event.extendedProps.ref,
      })
      setIsRescheduling(true)
    },
    viewDidMount({ view }) {
      view.calendar.setOption('timeZone', timeZone)
    },
    // ------------------------------------------------------------
    // SOURCES
    eventSources: SOURCES,
    eventSourceSuccess(content: any) {
      return content.data
    },
    eventSourceFailure(error) {
      if (error instanceof JsonRequestError) {
        console.log(`Request to ${error.response.url} failed`)
      }
    },
    eventOrder(a: any, b: any) {
      const aLower =
        a.type === 'availability' || a.type === 'block' || a.type === 'slot'
      const bLower =
        b.type === 'availability' || b.type === 'block' || b.type === 'slot'
      const aHigher = a.type === 'session' || a.type === 'team-session'
      const bHigher = b.type === 'session' || b.type === 'team-session'

      // If one event is a session/team-session and the other is not,
      // sort the session/team-session higher
      if (aHigher && bLower) {
        return -1
      } else if (aLower && bHigher) {
        return 1
      }

      return a.start - b.start
    },
  }
}
