import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk } from '~/app/store'
import Cookies from 'js-cookie'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import { createAppSlice } from '~/app/createAppSlice'
import { LOCAL_TIMEZONE } from '~/app/constants'

type FetchedWorkingHours = {
  start: string
  end: string
}[][]

type DateWorkingHours = {
  start: Date
  end: Date
}[][]

const DEFAULT_SCHEDULE = [
  [],
  [{ start: '2022-01-01T09:00:00.000Z', end: '2022-01-01T17:00:00.000Z' }],
  [{ start: '2022-01-01T09:00:00.000Z', end: '2022-01-01T17:00:00.000Z' }],
  [{ start: '2022-01-01T09:00:00.000Z', end: '2022-01-01T17:00:00.000Z' }],
  [{ start: '2022-01-01T09:00:00.000Z', end: '2022-01-01T17:00:00.000Z' }],
  [{ start: '2022-01-01T09:00:00.000Z', end: '2022-01-01T17:00:00.000Z' }],
  [],
]

export interface OnboardingSliceState {
  // State
  inProgress: boolean
  step: number

  // 1st step
  language: string
  timeFormat: '12' | '24'
  // 2nd step
  name: string
  timeZone: string
  // 3rd step
  workingHours: FetchedWorkingHours
  // 4th step
  isCalendarConnected: boolean
  // 5th step
  industryExpertises: string[]
  focusAreas: string[]
  licenses: string[]
  backgrounds: string[]
  languages: string[]
  coachingStyles: string[]
  // 6th step
  bio: string
  coachingPhilosophy: string
}

const initialState: OnboardingSliceState = {
  inProgress: false,
  step: 0,
  language: 'en',
  workingHours: DEFAULT_SCHEDULE,
  timeFormat: '12',
  name: '',
  timeZone: LOCAL_TIMEZONE,
  isCalendarConnected: false,
  industryExpertises: [],
  focusAreas: [],
  licenses: [],
  backgrounds: [],
  languages: [],
  coachingStyles: [],
  bio: '',
  coachingPhilosophy: '',
}

export const onboardingSlice = createAppSlice({
  name: 'onboarding',
  initialState,
  reducers: (create) => ({
    setData: create.reducer(
      (state, action: PayloadAction<Partial<OnboardingSliceState>>) => {
        const { payload } = action
        state.language = payload.language ?? state.language
        state.timeFormat = payload.timeFormat ?? state.timeFormat
        state.name = payload.name ?? state.name
        state.timeZone = payload.timeZone ?? state.timeZone
        state.isCalendarConnected =
          payload.isCalendarConnected ?? state.isCalendarConnected
        state.licenses = payload.licenses ?? state.licenses
        state.backgrounds = payload.backgrounds ?? state.backgrounds
        state.languages = payload.languages ?? state.languages
        state.coachingStyles = payload.coachingStyles ?? state.coachingStyles
        state.bio = payload.bio ?? state.bio
        state.coachingPhilosophy =
          payload.coachingPhilosophy ?? state.coachingPhilosophy
        state.industryExpertises =
          payload.industryExpertises ?? state.industryExpertises
        state.focusAreas = payload.focusAreas ?? state.focusAreas
      },
    ),
    setWorkingHours: create.reducer(
      (state, action: PayloadAction<FetchedWorkingHours>) => {
        state.workingHours = action.payload
      },
    ),
    startOnboarding: create.reducer((state) => {
      state.inProgress = true
    }),
    updateCurrentStep: create.reducer(
      (state, action: PayloadAction<number>) => {
        state.step = action.payload
      },
    ),
    clearData: create.reducer(() => initialState),
  }),
  selectors: {
    getWorkingHours: (state) => state.workingHours,
  },
})

export const {
  setData: setOnboardingData,
  updateCurrentStep: setCurrentOnboardingStep,
  clearData: clearOnboardingData,
} = onboardingSlice.actions
export const { getWorkingHours } = onboardingSlice.selectors

export const startOnboarding = (): AppThunk => (dispatch) => {
  Cookies.set('onboarding_in_progress', 'true')
  dispatch(onboardingSlice.actions.startOnboarding())
}

export const setWorkingHours =
  (payload: DateWorkingHours): AppThunk =>
  (dispatch) => {
    const data = payload.map((day) => {
      return day.map((slot) => {
        return {
          start: zonedTimeToUtc(slot.start, 'UTC').toISOString(),
          end: zonedTimeToUtc(slot.end, 'UTC').toISOString(),
        }
      })
    })

    dispatch(onboardingSlice.actions.setWorkingHours(data))
  }
