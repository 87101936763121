import { useEffect } from 'react'
import { usePostHog } from 'posthog-js/react'
import { useAuth } from './useAuth'

export const useIdentifyPosthog = () => {
  const posthog = usePostHog()
  const { id, name, email, isTeamCoach, isTestCoach, loggedInAsAdmin } =
    useAuth()

  useEffect(() => {
    if (id.length === 0) return

    posthog.setPersonPropertiesForFlags({ email }, true)

    if (loggedInAsAdmin || isTestCoach) {
      posthog.opt_out_capturing()
      posthog.sessionRecording?.stopRecording()
      posthog.stopSessionRecording()
      return
    }

    posthog.identify(id, {
      email,
      name,
      has_team_account: isTeamCoach,
      is_test_profile: isTestCoach,
    })
  }, [posthog, name, id, email, isTeamCoach, isTestCoach, loggedInAsAdmin])
}
