import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  Toast,
  ToastProvider,
  ToastClose,
  ToastDescription,
  ToastTitle,
  ToastViewport,
} from '~/components/ui/toast'
import { useToast } from '~/components/ui/use-toast'

const icon: Record<string, JSX.Element> = {
  success: <CheckCircleIcon className="h-6 w-6" aria-hidden="true" />,
  error: <XMarkIcon className="h-6 w-6" aria-hidden="true" />,
  warning: <ExclamationTriangleIcon className="h-6 w-6" aria-hidden="true" />,
}

export const Toaster: React.FC = () => {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, ...props }) => (
        <Toast key={id} {...props}>
          <div className="p-4">
            <div className="flex items-start">
              {props.variant && props.variant !== 'default' && (
                <div className="flex-shrink-0">{icon[props.variant]}</div>
              )}
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <ToastTitle>{title}</ToastTitle>
                <ToastDescription>{description}</ToastDescription>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <ToastClose />
              </div>
            </div>
          </div>
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  )
}
