/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { useAsyncValue } from 'react-router-dom'

import { DEFAULT_COACH_TITLE } from '~/app/constants'
import { useAppDispatch } from '~/app/hooks'
import { useMixpanel } from '~/hooks/useMixpanel'
import { setIsTestCoach } from '~/store/auth.slice'
import { setTimeZone } from '~/store/settings.slice'

export const CoachTitle: React.FC = () => {
  const coach = useAsyncValue() as any
  const mixpanel = useMixpanel()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setTimeZone(coach.timezone))
    if (coach.forTesting) {
      dispatch(setIsTestCoach(true))

      // Opt out of tracking for test coaches (works on a device level)
      const alreadyOptedOut = mixpanel.has_opted_out_tracking()
      if (!alreadyOptedOut) {
        mixpanel.opt_out_tracking({
          delete_user: true,
        })
      }
    }
  }, [dispatch, mixpanel, coach.timezone, coach.forTesting])

  return (
    <p className="text-sm font-medium text-gray-600">
      {coach.profile.title ?? DEFAULT_COACH_TITLE}
    </p>
  )
}
