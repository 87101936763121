import { useEffect } from 'react'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import addMinutes from 'date-fns/addMinutes'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { BasicDialog } from '~/components/BasicDialog'
import { Button } from '~/components/Button'
import { useToast } from '~/components/ui/use-toast'
import { api } from '~/app/api'
import { LoadingSpinner } from '~/Loading'
import { DatePicker } from '~/components/ui/date-picker'
import { TimePickerWithDuration } from '~/components/TimePickerWithDuration'

interface RescheduleProps {
  eventId: string
  onRescheduled: () => void
  isOpen: boolean
  onClose: () => void
}

type Inputs = {
  start: Date | null
  end: Date | null
}

type Props = RescheduleProps & Inputs

export const RescheduleDialog: React.FC<Props> = (props) => {
  const { eventId, isOpen, onClose, onRescheduled } = props
  const { toast } = useToast()
  const { handleSubmit, watch, setValue } = useForm<Inputs>()

  const start = watch('start')

  useEffect(() => {
    props.start && setValue('start', props.start)
    props.end && setValue('end', props.end)
  }, [setValue, props.start, props.end])

  const { mutate: rescheduleSession, isPending } = useMutation({
    mutationKey: ['schedule', 'session', 'reschedule'],
    mutationFn(data: { id: string; start: string; end: string }) {
      return api.url(`/coach/events/${data.id}`).put(data).json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Event rescheduled successfully',
        variant: 'success',
      })
      onRescheduled()
    },
    onSettled() {
      onClose()
    },
  })

  const onSubmit = handleSubmit((data) => {
    if (eventId.length === 0) return
    if (!data.start || !data.end || !props.start || !props.end) return
    const isStartModified = differenceInMinutes(data.start, props.start) !== 0
    const isEndModified = differenceInMinutes(data.end, props.end) !== 0
    if (!isStartModified && !isEndModified) return

    rescheduleSession({
      id: eventId,
      start: data.start.toISOString(),
      end: data.end.toISOString(),
    })
  })

  if (isPending) {
    return (
      <BasicDialog
        isOpen={isOpen}
        onClose={onClose}
        title="Reschedule the session"
      >
        <LoadingSpinner />
      </BasicDialog>
    )
  }

  return (
    <BasicDialog
      title="Reschedule the session"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={onSubmit} className="flex flex-col mt-4 gap-2.5">
        <input className="sr-only" name="id" value={eventId} readOnly />

        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Time
          </label>
          <div className="flex gap-2">
            <DatePicker
              value={start}
              onSelect={(value) => {
                setValue('start', value)
                setValue('end', value)
              }}
            />
            <TimePickerWithDuration
              value={start}
              onChange={(startVal, duration) => {
                if (!startVal) return
                setValue('start', startVal)
                setValue('end', addMinutes(startVal, duration))
              }}
            />
          </div>
        </div>

        <Button type="submit" className="mt-5 w-full">
          Reschedule
        </Button>
      </form>
    </BasicDialog>
  )
}
