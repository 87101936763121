import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import Select, { type MultiValue } from 'react-select'

import { api } from '~/app/api'
import { Button } from '~/components/Button'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { setOnboardingData } from '~/store/onboarding.slice'
import { setAuthData } from '~/store/auth.slice'
import { FieldArray } from '~/components/FieldArray'

type Option = { id: string; name: string }
type Options = MultiValue<Option>

const mapIdsToOptions = (ids: string[], options: Options) => {
  return options.filter((o) => ids.includes(o.id))
}

type Inputs = {
  license: string[]
  background: string[]
  languages: string[]
  styles: string[]
}

type Payload = {
  licenses: string[]
  backgrounds: string[]
  focusAreas: string[]
  industries: string[]
  languages: string[]
  styles: string[]
}

export const ProfileOnboarding: React.FC = () => {
  const onboardingState = useAppSelector((state) => state.onboarding)
  const form = useForm<Inputs>({
    defaultValues: {
      background: onboardingState.backgrounds,
      languages: onboardingState.languages,
      license: onboardingState.licenses,
      styles: onboardingState.coachingStyles,
    },
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { data } = useQuery({
    queryKey: ['focus-areas', 'industries', i18n.resolvedLanguage],
    initialData: { industries: [], focusAreas: [] },
    queryFn() {
      return api
        .url('/coach/focus-area-and-industry')
        .query({ language: i18n.resolvedLanguage })
        .get()
        .json<{ industries: Options; focusAreas: Options }>()
    },
  })
  const { mutate: uploadAvatar } = useMutation({
    mutationKey: ['upload', 'avatar'],
    async mutationFn(file: File) {
      const formData = new FormData()
      formData.append('avatar', file)

      const result = await api
        .url('/coach/avatar')
        .body(formData)
        .post()
        .json<{ url: string }>()

      return result.url
    },
    onSuccess(url) {
      setPhotoPreviewURL(url)
      dispatch(setAuthData({ picture: url }))
    },
  })

  const { mutate: preOnboarding } = useMutation({
    mutationKey: ['onboarding', 'pre-onboarding'],
    mutationFn(payload: Payload) {
      const body = {
        ...payload,
        dryRun: window.isPlaywright ?? false,
      }
      return api.url('/coach/onboarding/pre').post(body).json()
    },
  })

  const photoInput = useRef<HTMLInputElement>(null)
  const [photoPreviewURL, setPhotoPreviewURL] = useState<string | null>(null)
  const [industryExpertises, setIndustryExpertises] = useState<Options>([])
  const [focusAreas, setFocusAreas] = useState<Options>([])

  useEffect(() => {
    if (
      data.industries.length > 0 &&
      onboardingState.industryExpertises.length > 0
    ) {
      setIndustryExpertises(
        mapIdsToOptions(onboardingState.industryExpertises, data.industries),
      )
    }

    if (data.focusAreas.length > 0 && onboardingState.focusAreas.length > 0) {
      setFocusAreas(
        mapIdsToOptions(onboardingState.focusAreas, data.focusAreas),
      )
    }
  }, [data, onboardingState.industryExpertises, onboardingState.focusAreas])

  const onPhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = () => {
      setPhotoPreviewURL(reader.result as string)
    }
    reader.readAsDataURL(file)

    uploadAvatar(file)
  }

  const onSubmit = form.handleSubmit((data) => {
    const industryExpertisesIds = industryExpertises.map((i) => i.id)
    const focusAreasIds = focusAreas.map((f) => f.id)

    dispatch(
      setOnboardingData({
        industryExpertises: industryExpertisesIds,
        focusAreas: focusAreasIds,
        licenses: data.license,
        backgrounds: data.background,
        languages: data.languages,
        coachingStyles: data.styles,
      }),
    )

    preOnboarding({
      focusAreas: focusAreasIds,
      industries: industryExpertisesIds,
      backgrounds: data.background,
      licenses: data.license,
      languages: data.languages,
      styles: data.styles,
    })

    navigate('/onboarding/bio')
  })

  return (
    <form className="mt-5" onSubmit={onSubmit}>
      <div className="px-4 py-2 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label
                htmlFor="photo"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Photo
              </label>
              <input
                id="photo"
                ref={photoInput}
                type="file"
                name="photo"
                accept="image/*"
                onChange={onPhotoChange}
                hidden
              />
              <div className="mt-2 flex items-center gap-x-3">
                {photoPreviewURL ? (
                  <img
                    src={photoPreviewURL}
                    alt="Profile photo"
                    className="h-12 w-12 object-cover rounded-full"
                  />
                ) : (
                  <UserCircleIcon
                    className="h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                )}

                <button
                  type="button"
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => photoInput.current?.click()}
                >
                  Change
                </button>
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="industries"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Industry Experience
              </label>

              <Select
                id="industries"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={data.industries}
                value={industryExpertises}
                onChange={setIndustryExpertises}
                isMulti
              />
            </div>

            <div className="col-span-full">
              <label
                htmlFor="focus-areas"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Coaching Specialisations
              </label>

              <Select
                id="focus-areas"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={data.focusAreas}
                value={focusAreas}
                onChange={setFocusAreas}
                isMulti
              />
            </div>

            {/* Coaching Licenses */}
            <FieldArray
              control={form.control}
              register={form.register}
              name="license"
              label="Coaching Licenses"
              placeholder="Coaching Licenses"
            >
              <p className="mt-2 text-sm text-gray-500">
                Please list any coaching licenses you have. If you don’t have
                any, you can leave this blank.
              </p>
            </FieldArray>

            {/* Professional Background */}
            <FieldArray
              control={form.control}
              register={form.register}
              name="background"
              label="Professional Background"
              placeholder="Professional Background"
            >
              <p className="mt-2 text-sm text-gray-500">
                Please list any professional background you have. If you don’t
                have any, you can leave this blank.
              </p>
            </FieldArray>

            {/* Coaching Languages */}
            <FieldArray
              control={form.control}
              register={form.register}
              name="languages"
              label="Coaching Languages"
              placeholder="Coaching Languages"
            >
              <p className="mt-2 text-sm text-gray-500">
                Please list any languages you know.
              </p>
            </FieldArray>

            {/* Coaching Style */}
            <FieldArray
              control={form.control}
              register={form.register}
              name="styles"
              label="Coaching Style"
              placeholder="Coaching Style"
            >
              <p className="mt-2 text-sm text-gray-500">
                Please list any coaching styles you have. If you don’t have any,
                you can leave this blank.
              </p>
            </FieldArray>
          </div>
        </div>
      </div>

      <div className="mt-10 flex gap-2">
        <Button
          to="/onboarding/connect-calendar"
          type="button"
          variant="outline"
          className="w-full"
        >
          Back
        </Button>
        <Button type="submit" className="w-full">
          Next
        </Button>
      </div>
    </form>
  )
}
