/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Link, useLoaderData, useParams } from 'react-router-dom'
import {
  ArrowLeftIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'

import { ChatEditor, type ChatSendData } from './_components/ChatEditor'
import { ChatBubble } from './_components/ChatBubble'
import { type Chat as ChatType, loader } from './_loaders/_id'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { useAuth } from '~/hooks/useAuth'
import { coreSocket, groupSocket, teamSocket } from '~/app/socket'
import { appendCoreChat, setCurrentChat } from '~/store/socket.slice'

const getTitle = (chat: any) => {
  if (!chat) return
  if (chat?.chat) {
    const isPrivate = chat.chat.type !== 'team' && chat.chat.type !== 'group'

    if (isPrivate) {
      return chat.chat.participants[0].profile.name
    }

    return chat.chat.title
  }
  return chat?.user.profile.name
}

const getSubtitle = (chat: any) => {
  if (!chat) return
  if (chat?.chat) {
    const isGroup = chat.chat.type === 'team' || chat.chat.type === 'group'

    if (isGroup) {
      return `${chat.chat.participants.length + 1} participants`
    }

    return chat.chat.participants[0].title
  }
  return chat?.user?.company?.name
}

const getChatPicture = (chat: any) => {
  if (!chat) return
  if (chat?.chat) {
    const isGroup = chat.chat.type === 'team' || chat.chat.type === 'group'
    if (isGroup) return chat.chat.picture

    return chat.chat.participants[0].profile?.picture
  }
  return chat?.user?.profile?.picture
}

const isGroupChat = (chat: any) => {
  if (!chat) return false
  if (chat?.chat) {
    return chat.chat.type === 'team' || chat.chat.type === 'group'
  }
  return false
}

export const Chat: React.FC = () => {
  const endOfChatRef = useRef<HTMLSpanElement>(null)
  const { id: coachId, teamId: teamCoachId, groupId: groupCoachId } = useAuth()
  const dispatch = useAppDispatch()
  const chat = useLoaderData() as Exclude<
    Awaited<ReturnType<ReturnType<typeof loader>>>,
    Response
  >
  const { activeCoreChats, coreSocketId, currentChat } = useAppSelector(
    (store) => store.socket,
  )

  const lastMessageId = useMemo(
    () => currentChat?.messages[currentChat.messages.length - 1]?._id,
    [currentChat?.messages],
  )

  const { type = 'core', id } = useParams<{
    type: AppType
    id: string
  }>()

  useEffect(() => {
    dispatch(setCurrentChat(chat))

    return () => {
      dispatch(setCurrentChat(null))
    }
  }, [chat, dispatch])

  useEffect(() => {
    if (type !== 'core') return
    const ct = chat as ChatType
    const hasChat = activeCoreChats.find(
      (chat) => chat.split('_')[1] === ct.user._id,
    )
    if (hasChat) return
    const roomId = [coachId, ct.user._id].join('_')

    coreSocket.emit('room:join', {
      socketId: coreSocketId,
      chatRoomId: roomId,
      userId: coachId,
    })

    dispatch(
      appendCoreChat({
        user: {
          id: ct.user._id,
          name: ct.user.profile.name,
          picture: ct.user.profile.picture,
          email: ct.user.email,
          company: ct.user.company.name,
        },
        unread: 0,
        lastMessage: null,
        sentByUser: false,
      }),
    )
  }, [dispatch, type, coachId, coreSocketId, activeCoreChats, chat])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!endOfChatRef.current) return
      endOfChatRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 400)

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    // scroll to last message when new message is added
    if (endOfChatRef.current && lastMessageId) {
      endOfChatRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [lastMessageId, endOfChatRef])

  useEffect(() => {
    if (!id) return

    const unreadMessages = chat.messages.filter((message) => {
      // @ts-expect-error TODO: fix this
      if (type === 'core') return message.sentByUser && !message.read
      return (
        // @ts-expect-error TODO: fix this
        message.sentByModel === 'User' &&
        // @ts-expect-error TODO: fix this
        !message.read.includes(type === 'team' ? teamCoachId : groupCoachId)
      )
    })

    if (unreadMessages.length === 0) return

    switch (type) {
      case 'core': {
        coreSocket.emit('room:markAsRead', {
          socketId: coreSocketId,
          receiverId: id,
        })
        break
      }

      case 'team': {
        teamSocket.emit('read_all', {
          chatId: id,
          userId: teamCoachId,
        })
        break
      }

      case 'group': {
        groupSocket.emit('read_all', {
          chatId: id,
          userId: coachId,
        })
        break
      }
    }
  }, [
    coachId,
    teamCoachId,
    groupCoachId,
    type,
    id,
    chat.messages,
    coreSocketId,
  ])

  const onSendMessage = useCallback(
    (data: ChatSendData) => {
      if (!data.message || !id) return
      const file = data.file && {
        name: data.file.name,
        type: data.file.type,
        size: data.file.size,
        data: data.file,
      }

      switch (type) {
        case 'team': {
          teamSocket.emit(file ? 'send_file' : 'send_message', {
            senderId: teamCoachId,
            chatId: id,
            text: data.message,
            file,
          })
          break
        }

        case 'group': {
          groupSocket.emit(file ? 'send_file' : 'send_message', {
            senderId: groupCoachId,
            chatId: id,
            text: data.message,
            file,
          })
          break
        }

        case 'core': {
          if (!file) {
            coreSocket.emit('room:chat', {
              socketId: coreSocketId,
              receiverId: id,
              text: data.message,
            })
          } else {
            coreSocket.emit('room:file', {
              socketId: coreSocketId,
              receiverId: id,
              text: data.message,
              file,
            })
          }
          break
        }
      }
    },
    [coreSocketId, type, id, teamCoachId, groupCoachId],
  )

  return (
    <div className="grid grid-rows-[70px_1fr_auto] h-[calc(100dvh-69px)]">
      <div className="grid bg-white border-b">
        <li className="flex gap-x-4 px-4 select-none items-center">
          <Link
            to=".."
            className="inline-flex md:hidden items-center justify-center rounded-full h-10 w-5"
          >
            <ArrowLeftIcon className="w-10" />
          </Link>

          {getChatPicture(currentChat) ? (
            <img
              className="h-10 w-10 object-cover md:h-12 md:w-12 flex-none rounded-full bg-gray-50"
              src={getChatPicture(currentChat)}
              alt={getTitle(currentChat)}
            />
          ) : currentChat?.chat?.type === 'team' ||
            currentChat?.chat?.type === 'group' ? (
            <UserGroupIcon className="p-2 h-10 w-10 md:h-12 md:w-12 flex-none border-gray-300 border rounded-full text-gray-400" />
          ) : (
            <UserCircleIcon className="h-10 w-10 md:h-12 md:w-12 flex-none rounded-full text-gray-400" />
          )}
          <div className="flex-auto">
            <div className="flex items-baseline justify-between">
              <p className="text-base font-semibold text-gray-900 leading-3 md:leading-4">
                {getTitle(currentChat)}
              </p>
            </div>
            <p className="mt-1 line-clamp-1 text-sm text-gray-400">
              {getSubtitle(currentChat)}
            </p>
          </div>
        </li>
      </div>

      <div className="relative p-4 pb-2 space-y-2 overflow-y-auto">
        {currentChat?.messages.map((message: any, index: number) => (
          <ChatBubble
            key={message._id}
            message={message}
            prevMessage={index > 0 ? chat.messages[index - 1] : undefined}
            isGroupChat={isGroupChat(chat)}
          />
        ))}

        <span
          ref={endOfChatRef}
          className="block mt-5 mb-0 text-center text-sm text-gray-400 select-none"
          tabIndex={-1}
        >
          End of chat
        </span>
      </div>
      <ChatEditor onSendMessage={onSendMessage} />
    </div>
  )
}
