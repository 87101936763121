import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

const fetchBio = () =>
  queryOptions({
    queryKey: ['security', 'mfa'],
    queryFn: () =>
      api.url('/coach/auth/mfa-status').get().json<{ enabled: boolean }>(),
  })

export const loader = (queryClient: QueryClient) => async () => {
  return await queryClient.fetchQuery(fetchBio())
}
