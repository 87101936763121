import { useMutation } from '@tanstack/react-query'
import { api } from '~/app/api'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { useToast } from '~/components/ui/use-toast'
import { clearNpsAnswers, setNps } from '~/store/nps.slice'
import { useMixpanel } from './useMixpanel'

export interface Answer {
  question: string
  answer: string | number
}

export const useSubmitNps = (onSettled: () => void) => {
  const { toast } = useToast()
  const mixpanel = useMixpanel()
  const dispatch = useAppDispatch()
  const {
    name: title,
    surveyId,
    sessionId,
    questions,
  } = useAppSelector((state) => state.nps)

  const { mutate, isPending } = useMutation({
    mutationKey: ['coach', 'nps', 'submit'],
    async mutationFn(answers: Answer[]) {
      await api
        .url(`/coach/nps/${surveyId}`)
        .post({ sessionId: sessionId.length > 0 ? sessionId : null, answers })
        .json<{ ok: true }>()
    },
    onSuccess() {
      toast({
        title: 'Feedback submitted',
        description: 'Thank you for your feedback!',
        variant: 'success',
      })

      dispatch(setNps({ hasPendingNps: false }))
      dispatch(clearNpsAnswers())
      mixpanel.track('NPS Survey Submitted', { surveyId, sessionId })
    },
    onSettled,
  })

  return { title, questions, mutate, isPending }
}
