import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { groupApi } from '~/app/api'
import { useAppDispatch } from '~/app/hooks'
import { useAuth } from './useAuth'
import { setGroupId, setIsGroupCoach } from '~/store/auth.slice'

export const useGroup = () => {
  const { email, isGroupCoach } = useAuth()
  const dispatch = useAppDispatch()

  const { data: hasGroup } = useQuery({
    initialData: { exists: false, groupsCount: 0 },
    queryKey: ['group', 'exists', email],
    queryFn: () =>
      groupApi
        .url('/coach/merge/exists')
        .query({ email })
        .get()
        .json<{ exists: boolean; groupsCount: number }>(),
  })

  const { data: groupCoach } = useQuery({
    enabled: isGroupCoach,
    queryKey: ['group', 'coach', 'home'],
    queryFn() {
      return groupApi.get('/coach/home').json<{ coach: { _id: string } }>()
    },
  })

  useEffect(() => {
    if (hasGroup.exists) {
      dispatch(setIsGroupCoach(true))
    }
  }, [dispatch, hasGroup])

  useEffect(() => {
    if (groupCoach && isGroupCoach) {
      dispatch(setGroupId(groupCoach.coach._id))
    }
  }, [dispatch, groupCoach, isGroupCoach])

  return {
    hasGroupAccount: isGroupCoach,
  }
}
