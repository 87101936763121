import Cookie from 'js-cookie'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import { persistor, type AppThunk } from '~/app/store'
import { clearOnboardingData } from '../onboarding.slice'
import { clearSettings } from '../settings.slice'
import { mixpanel } from '~/utils/mixpanel'
import { queryClient } from '~/query'
import { authSlice } from '../auth.slice'

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(clearOnboardingData())
  dispatch(clearSettings())
  dispatch(authSlice.actions.clearData())
  mixpanel.reset()
  Cookie.remove('token')
  Sentry.setUser(null)
  posthog.reset()
  queryClient.clear()
  await persistor.purge()
}
