/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, redirect } from 'react-router-dom'
import { api } from '~/app/api'
import { authenticated } from '~/app/cookie'

const fetchHomeData = () =>
  queryOptions({
    queryKey: ['home', 'data'],
    queryFn: () => api.url('/coach/home').get().json<any>(),
  })

const fetchMetrics = () =>
  queryOptions({
    queryKey: ['home', 'performance', 'metrics'],
    queryFn: () => api.url('/coach/home/performance-metrics').get().json<any>(),
  })

const fetchRecentUsers = () =>
  queryOptions({
    queryKey: ['home', 'recent', 'users'],
    queryFn: () => api.url('/coach/home/recent-users').get().json<any[]>(),
  })

export const loader = (queryClient: QueryClient) => () => {
  if (!authenticated()) {
    return redirect('/auth?mode=login')
  }
  const homeData = queryClient.ensureQueryData(fetchHomeData())
  const metrics = queryClient.ensureQueryData(fetchMetrics())
  const recentUsers = queryClient.ensureQueryData(fetchRecentUsers())

  return defer({
    coach: homeData.then((data) => data.coach),
    recentUsers,
    metrics,
  })
}
