import { useCallback, useState } from 'react'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import { Button } from '~/components/Button'
import { DaySchedule } from './_components/DaySchedule'
import { loader, type FetchedWorkingHours } from './_loaders/working-hours'
import { useSettings } from '~/hooks/useSetttings'
import { api, groupApi, teamApi } from '~/app/api'
import { useToast } from '~/components/ui/use-toast'
import { queryClient } from '~/query'

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const WorkingHours: React.FC = () => {
  const { timeFormat } = useSettings()
  const { toast } = useToast()
  const { revalidate } = useRevalidator()
  const savedWorkingHours = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >
  const [workingHours, setWorkingHours] = useState(savedWorkingHours)

  const { mutate: updateWorkingHours } = useMutation({
    mutationKey: ['update', 'working-hours'],
    async mutationFn(data: FetchedWorkingHours) {
      await api.url('/coach/schedule').patch({ schedule: data }).json()
    },
    async onSuccess() {
      toast({
        title: 'Success',
        description: 'Working hours updated successfully',
        variant: 'success',
      })

      await queryClient.invalidateQueries({
        queryKey: ['working-hours'],
        exact: true,
      })
      revalidate()
    },
  })

  const { mutate: updateTeamWorkingHours } = useMutation({
    mutationKey: ['team', 'update', 'working-hours'],
    async mutationFn(data: FetchedWorkingHours) {
      await teamApi.url('/coach/schedule').patch({ schedule: data }).json()
    },
  })

  const { mutate: updateGroupWorkingHours } = useMutation({
    mutationKey: ['group', 'update', 'working-hours'],
    async mutationFn(data: FetchedWorkingHours) {
      await groupApi.url('/coach/schedule').patch({ schedule: data }).json()
    },
  })

  const onChange = useCallback(
    (day: string, schedule: { start: Date; end: Date }[]) => {
      setWorkingHours((prevValue) => {
        const index = days.indexOf(day)
        return [
          ...prevValue.slice(0, index),
          schedule,
          ...prevValue.slice(index + 1),
        ]
      })
    },
    [],
  )

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = workingHours.map((day) => {
      return day.map((slot) => {
        return {
          start: zonedTimeToUtc(slot.start, 'UTC').toISOString(),
          end: zonedTimeToUtc(slot.end, 'UTC').toISOString(),
        }
      })
    })

    updateWorkingHours(data)
    updateTeamWorkingHours(data)
    updateGroupWorkingHours(data)
  }

  return (
    <div className="divide-y divide-black/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7">Availability</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Set your working hours and the number of slots you want to make
            available for booking.
          </p>
        </div>

        <form className="md:col-span-2" onSubmit={onSubmit}>
          <div className="sm:p-4">
            {workingHours.map((schedule, index) => (
              <DaySchedule
                key={days[index]}
                schedule={schedule}
                day={days[index]}
                is24HourFormat={timeFormat === '24'}
                onChange={onChange}
              />
            ))}
          </div>

          <div className="mt-8 flex">
            <Button className="w-full sm:w-2/3" type="submit">
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
