/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect, type LoaderFunctionArgs } from 'react-router-dom'
import { api } from '~/app/api'
import { authenticated } from '~/app/cookie'

interface Root {
  customer: Customer
  upcomingEvents: any[]
  stats: Stats
  userHasOnboardingAnswers: boolean
}

export interface Customer {
  profile: Profile
  limits: Limits
  notifications: Notifications
  dashboard: Dashboard
  utilization: number
  isOnline: boolean
  tokens: any[]
  peers: any[]
  managers: any[]
  reportees: any[]
  batchNumber: number
  timezone: string
  language: string
  coachingLanguages: any[]
  defaultCoachingLanguage: string
  interfaceLanguage: string
  onboardedOnMobile: boolean
  bookings: string[]
  ghostBookings: any[]
  active: boolean
  canBook: boolean
  markedForDeletion: boolean
  dyteEnabled: boolean
  dyteRecordOnStart: boolean
  dyteUseStt: boolean
  dyteCoachVerification: boolean
  oldPasswords: any[]
  _id: string
  customers: any[]
  maxHoursPerWeek: number
  email: string
  company: Company
  role: string
  createdAt: string
  updatedAt: string
  __v: number
  postFirstSessionEmailSent: boolean
}

export interface Profile {
  name: string
}

export interface Limits {
  canUseService: boolean
}

export interface Notifications {
  hideAIcontent: boolean
}

export interface Dashboard {
  hide: boolean
}

export interface Company {
  _id: string
  name: string
  contractStartDate: string
  contractDuration: number
  contractEndDate?: string
}

export interface Stats {
  bookings: number
  endDate?: string | null
  availableSessions: number
  completedSessions: number
  recommendedSessions: number
  allAccess: boolean
  progressStatus: string
  _id: string
  user: string
  __v: number
  createdAt: string
  updatedAt: string
}

const fetchUser = (id: string) =>
  queryOptions({
    queryKey: ['users', id],
    queryFn: () => api.url(`/coach/customers/${id}`).get().json<Root>(),
  })

export const loader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    if (!authenticated()) {
      return redirect('/auth?mode=login')
    }

    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return await queryClient.fetchQuery(fetchUser(id))
  }
