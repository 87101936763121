import type { EventContentArg } from '@fullcalendar/core'
import type { EventImpl } from '@fullcalendar/core/internal'
import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import addDays from 'date-fns/addDays'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import format from 'date-fns/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import isFuture from 'date-fns/isFuture'
import isPast from 'date-fns/isPast'
import { Link } from 'react-router-dom'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/popover'
import { GoogleIcon } from '~/icons/GoogleIcon'
import { cn } from '~/utils/cn'
import {
  getUnifiedCallLink,
  getUnifiedCallLinkGroup,
  getUnifiedCallLinkTeam,
} from '~/utils/links'
import type { EventData } from '../schedule'
import { CreateEventPopover } from './CreateEventPopover'

type Props = EventContentArg & {
  formatToken: 'h:mm a' | 'HH:mm'
  isMobileScreen: boolean
  teamCoachId: string
  groupCoachId: string
  timeZone: string
  t: (key: string) => string
  sessionNote?: { note: { body: { text: string } } }
  onEventDelete: (data: EventData) => void
  onEventReschedule: (data: EventData) => void
}

const getInternalLink = (
  event: EventImpl,
  isTeamSession: boolean,
  isGroupSession: boolean,
) => {
  if (isGroupSession) {
    return `/groups/${event.extendedProps.groupId}`
  }

  if (isTeamSession) {
    return `/teams/${event.extendedProps.teamId}`
  }

  return `/users/${event.extendedProps.userId}`
}

/**
 * IMPORTANT: Hooks cannot be used in this component
 * Because it is used in a fullcalendar eventRender callback
 */
export const EventContent: React.FC<Props> = (props) => {
  const {
    event,
    timeText,
    timeZone,
    formatToken,
    onEventDelete,
    onEventReschedule,
    isMobileScreen,
    teamCoachId,
    groupCoachId,
  } = props
  const type = event.extendedProps.type
  const isTeamSession = event.source?.id === 'team-session'
  const isGroupSession = event.source?.id === 'group-session'
  const isWeekView = props.view.type === 'timeGridWeek'

  // New event draft
  if ((!event.id || event.id === 'new-event') && !type) {
    return (
      <CreateEventPopover
        timeText={timeText}
        start={event.start}
        end={event.end}
        calendarApi={props.view.calendar}
      />
    )
  }

  if (type === 'availability' || !event.start || !event.end) {
    return <p className="line-clamp-1 text-sm text-black">{event.title}</p>
  }

  const start = utcToZonedTime(event.start, timeZone)
  const end = utcToZonedTime(event.end, timeZone)
  const eventDuration = differenceInMinutes(end, start)
  const eventInFuture = isFuture(start)

  if (
    !isTeamSession &&
    !isGroupSession &&
    event.source?.id &&
    event.source.id !== 'session'
  ) {
    return (
      <Popover>
        <PopoverTrigger
          className={cn('w-full h-full px-1', isMobileScreen && 'w-[90%]')}
          asChild
        >
          {eventDuration >= 60 ? (
            <div className="text-left">
              <p className="font-semibold">
                {event.title.length > 20
                  ? `${event.title.slice(0, 18)}...`
                  : event.title}
              </p>
              <p className="line-clamp-1">{timeText}</p>
            </div>
          ) : (
            <p
              className={cn(
                'text-left font-semibold line-clamp-1',
                eventDuration < 30 && isWeekView && 'text-xs align-middle',
              )}
            >
              {event.title}
            </p>
          )}
        </PopoverTrigger>
        <PopoverContent
          side={isMobileScreen ? 'top' : 'right'}
          className={cn('w-96', isMobileScreen && 'max-w-sm')}
        >
          <div className="flex justify-between">
            <h3 className="font-bold">{event.title}</h3>

            {eventInFuture && type !== 'team-session' && (
              <button
                className="relative rounded-full bg-white p-1 text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
                onClick={() =>
                  onEventDelete({
                    id: event.id,
                    type: event.source!.id,
                    start: event.start,
                    end: event.end,
                    ref: event.extendedProps.ref,
                  })
                }
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">Delete the event</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>

          <p>
            {format(start, `EEEE, LLLL d ⋅ ${formatToken}`)} -{' '}
            {format(end, formatToken)}
          </p>
          {event.extendedProps?.calendarId && (
            <div className="flex items-center mt-2 text-xs text-gray-500">
              <GoogleIcon className="mr-1 text-blue-500" />
              <span>{event.extendedProps.calendarId}</span>
            </div>
          )}
        </PopoverContent>
      </Popover>
    )
  }

  const eventIsPast = isPast(addDays(end, 1)) // Add a day to include the end date

  return (
    <Popover>
      <PopoverTrigger className="w-full h-full px-1" asChild>
        {eventDuration >= 60 ? (
          <div className="text-left">
            <p className="font-semibold">
              {event.title.length > 20
                ? `${event.title.slice(0, 18)}...`
                : event.title}
            </p>
            <p className="line-clamp-1">{timeText}</p>
          </div>
        ) : (
          <p
            className={cn(
              'text-left font-semibold line-clamp-1',
              eventDuration < 30 && isWeekView && 'text-xs',
            )}
          >
            {event.title}
          </p>
        )}
      </PopoverTrigger>
      <PopoverContent
        side={isMobileScreen ? 'top' : 'right'}
        className={cn('w-96', isMobileScreen && 'max-w-sm')}
      >
        <div className="flex justify-between">
          <Link
            to={getInternalLink(event, isTeamSession, isGroupSession)}
            className="flex gap-2 items-center"
          >
            <h3 className="font-bold text-gray-800">{event.title}</h3>
            <ArrowTopRightOnSquareIcon className="h-4" />
          </Link>

          {!isTeamSession && !isGroupSession && eventInFuture && (
            <div className="flex justify-between gap-5">
              <button
                className="relative rounded-full bg-white p-1 text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
                onClick={() =>
                  onEventReschedule({
                    id: event.id,
                    type: event.source!.id,
                    start: event.start,
                    end: event.end,
                  })
                }
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">Reschedule the session</span>
                <PencilIcon className="h-5 w-5" aria-hidden="true" />
              </button>

              <button
                className="relative rounded-full bg-white p-1 text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
                onClick={() =>
                  onEventDelete({
                    id: event.id,
                    type: event.source!.id,
                    start: event.start,
                    end: event.end,
                  })
                }
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">Cancel the session</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
        <p className="mt-2">
          {format(start, `EEEE, LLLL d ⋅ ${formatToken}`)} -{' '}
          {format(end, formatToken)}
        </p>

        {eventIsPast ? (
          <></>
        ) : (
          <a
            className="mt-3 bg-primary hover:bg-primary-400 w-full flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            href={
              isGroupSession
                ? getUnifiedCallLinkGroup({
                    participantId: groupCoachId,
                    sessionId: event.id,
                    referrer: 'new-coach-app-calendar-desktop',
                  })
                : isTeamSession
                  ? getUnifiedCallLinkTeam({
                      participantId: teamCoachId,
                      sessionId: event.id,
                      referrer: 'new-coach-app-calendar-desktop',
                    })
                  : getUnifiedCallLink({
                      eventId: event.id,
                      referrer: 'new-coach-app-calendar-desktop',
                    })
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Join call
          </a>
        )}
      </PopoverContent>
    </Popover>
  )
}
