import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, type LoaderFunctionArgs } from 'react-router-dom'
import { api } from '~/app/api'

export interface CoachNote {
  _id: string
  body: {
    text: string
  }
  coach: string
  user: string
  createdAt: string
  updatedAt: string
  __v: number
}

const fetchCoachNotes = (id: string) =>
  queryOptions({
    queryKey: ['users', id, 'coach', 'notes'],
    queryFn: () =>
      api.url('/coach/notes').query({ userId: id }).get().json<CoachNote[]>(),
  })

export const loader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return defer({
      data: queryClient.fetchQuery(fetchCoachNotes(id)),
    })
  }
