import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import { loader } from './_loaders/onboarding-answers'
import { UserOnboardingAnswers } from './_components/UserOnboardingAnswers'
import { LoadingSpinner } from '~/Loading'

/* eslint-disable @typescript-eslint/no-explicit-any */
const transformOnboardingAnswersData = (data: any[]) => {
  const result = [
    { type: 'Manager Input', data: [] },
    { type: 'User', data: [] as any[] },
  ]

  for (let i = 0; i < data.length; i++) {
    const element = data[i]
    const index = element.isManager ? 0 : 1
    const answers =
      element.answers.length === 0
        ? [element.otherAnswer]
        : element.answers.map((answer: any) => answer.label)

    result[index].data.push({
      _id: element._id,
      question: element.question.title,
      description: element.question.description,
      answers,
    })
  }

  return result.filter((res) => res.data.length > 0)
}

export const OnboardingAnswers: React.FC = () => {
  const deferredData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={deferredData.data} errorElement={<div>Error</div>}>
        {(data) => <OnboardingAnswersWithData data={data} />}
      </Await>
    </Suspense>
  )
}

const OnboardingAnswersWithData: React.FC<{ data: any[] }> = (props) => {
  const { data } = props

  if (data.length === 0)
    return (
      <div className="bg-gray-50 rounded-md w-full transition-all space-y-10 divide-y-2">
        <p className="text-center my-5">No onboarding answers</p>
      </div>
    )

  return (
    <div className="flex-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="bg-gray-50 transition-all space-y-10 divide-y-0">
        <div className="px-4 py-5 space-y-4 sm:px-6 sm:py-2">
          {transformOnboardingAnswersData(data).map((answers) => (
            <UserOnboardingAnswers
              key={answers.type}
              type={answers.type}
              data={answers.data}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
