import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, type LoaderFunctionArgs } from 'react-router-dom'
import { groupApi } from '~/app/api'

interface Summary {
  sessionId: string
  start: string
  end: string
  title: string
  coachName: string
  content: string
}

export type ResponseData = {
  summaries: Summary[]
  sections: string[]
}

const fetchSessionSummaries = (id: string) =>
  queryOptions({
    queryKey: ['groups', id, 'session', 'summaries'],
    queryFn: () =>
      groupApi.url(`/coach/groups/${id}/summaries`).get().json<ResponseData>(),
  })

export const loader =
  (queryClient: QueryClient) => (args: LoaderFunctionArgs) => {
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return defer({
      data: queryClient.fetchQuery(fetchSessionSummaries(id)),
    })
  }
