import { useEffect, useMemo, useRef } from 'react'
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/24/outline'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import { loader } from './_loaders/_id'
import { cn } from '~/utils/cn'
import { getUnifiedCallLinkTeam } from '~/utils/links'
import { Button } from '~/components/ui/button'
import { useAuth } from '~/hooks/useAuth'

export const Team: React.FC = () => {
  const { id: teamId } = useParams<{ id: string }>()
  const { teamId: teamCoachId } = useAuth()
  const outletWrapper = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { data } = useLoaderData() as Exclude<
    Awaited<ReturnType<ReturnType<typeof loader>>>,
    Response
  >

  useEffect(() => {
    const isLoading = navigation.state === 'loading'
    if (isLoading) return

    const timout = setTimeout(() => {
      outletWrapper.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }, 400)

    return () => clearTimeout(timout)
  }, [navigation.state])

  const pages = useMemo(
    () => [
      { name: 'Teams', href: '/teams', current: false },
      { name: data.name, href: '.', current: true },
    ],
    [data],
  )

  const options = useMemo(
    () => [
      { name: 'Members', href: `/teams/${teamId}/members` },
      { name: 'Diagnostics', href: `/teams/${teamId}/diagnostics` },
      {
        name: 'Session summaries',
        href: `/teams/${teamId}/session-summaries`,
      },
    ],
    [teamId],
  )

  const defaultTab = useMemo(() => {
    return options.find((tab) => location.pathname.startsWith(tab.href))?.href
  }, [options, location.pathname])

  return (
    <div className="space-y-5">
      <div className="min-w-0 mb-10">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to={!page.current ? page.href : '.'}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>

      <div>
        <dl className="flex flex-wrap gap-5">
          <div className="overflow-hidden max-sm:w-full sm:flex-1 rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              Company
            </dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
              {data.company.name} ({data.name})
            </dd>
          </div>

          <div className="overflow-hidden max-sm:w-full sm:flex-1 rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              Team Leader
            </dt>
            <dd className="mt-1">
              <div className="text-xl font-semibold tracking-tight text-gray-900">
                {data.leader?.profile.name}
              </div>
              <div className="mt-1 text-gray-500">{data.leader?.title}</div>
            </dd>
          </div>

          <div className="overflow-hidden max-sm:w-full sm:flex-1 rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              Team info
            </dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
              {data.members.length} members
            </dd>
          </div>
        </dl>
      </div>

      {data.upcomingSessions.length > 0 && (
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Upcoming Sessions
          </h3>

          <ul role="list" className="divide-y divide-gray-100 mt-4">
            {data.upcomingSessions.map((session) => (
              <li
                key={session._id}
                className="flex items-center justify-between gap-x-6 py-3"
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {session.title} -{' '}
                      {format(parseISO(session.start), 'HH:mm')} -{' '}
                      {format(parseISO(session.end), 'HH:mm (dd.MM.yyyy)')}
                    </p>
                  </div>
                </div>
                <Button
                  variant="outline"
                  onClick={() => {
                    window.open(
                      getUnifiedCallLinkTeam({
                        sessionId: session._id,
                        participantId: teamCoachId,
                        referrer: 'new-coach-app-team-detail',
                      }),
                      '_blank',
                    )
                  }}
                >
                  Join
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary"
            onChange={(e) => navigate(e.target.value)}
            defaultValue={defaultTab}
          >
            {options.map((tab) => (
              <option key={tab.name} value={tab.href}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex justify-between" aria-label="Tabs">
              {options.map((tab) => (
                <NavLink
                  key={tab.name}
                  to={tab.href}
                  className={({ isActive }) =>
                    cn(
                      'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium',
                      isActive
                        ? 'border-primary-500 text-primary-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    )
                  }
                >
                  {tab.name}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <section ref={outletWrapper} className="flex flex-col gap-5">
        <Outlet />
      </section>
    </div>
  )
}
