// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ratingScale = (t: any) => [
  {
    scale: 1,
    level: t('rating-level-1'),
    description: [
      t('rating-level-1-description-1'),
      t('rating-level-1-description-2'),
    ],
  },
  {
    scale: 2,
    level: t('rating-level-2'),
    description: [
      t('rating-level-2-description-1'),
      t('rating-level-2-description-2'),
      t('rating-level-2-description-3'),
    ],
  },
  {
    scale: 3,
    level: t('rating-level-3'),
    description: [
      t('rating-level-3-description-1'),
      t('rating-level-3-description-2'),
      t('rating-level-3-description-3'),
    ],
  },
  {
    scale: 4,
    level: t('rating-level-4'),
    description: [
      t('rating-level-4-description-1'),
      t('rating-level-4-description-2'),
      t('rating-level-4-description-3'),
      t('rating-level-4-description-4'),
    ],
  },
  {
    scale: 5,
    level: t('rating-level-5'),
    description: [
      t('rating-level-5-description-1'),
      t('rating-level-5-description-2'),
      t('rating-level-5-description-3'),
      t('rating-level-5-description-4'),
      t('rating-level-5-description-5'),
    ],
  },
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const leadershipValueScaleSelf = (t: any) => [
  {
    scale: 1,
    level: t('self-score-1-description'),
    description: [],
  },
  {
    scale: 2,
    level: t('self-score-2-description'),
    description: [],
  },
  {
    scale: 3,
    level: t('self-score-3-description'),
    description: [],
  },
  {
    scale: 4,
    level: t('self-score-4-description'),
    description: [],
  },
  {
    scale: 5,
    level: t('self-score-5-description'),
    description: [],
  },
  {
    scale: 6,
    level: t('self-score-6-description'),
    description: [],
  },
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const leadershipValueScalePeer = (t: any) => [
  {
    scale: 1,
    level: t('peer-score-1-description'),
    description: [],
  },
  {
    scale: 2,
    level: t('peer-score-2-description'),
    description: [],
  },
  {
    scale: 3,
    level: t('peer-score-3-description'),
    description: [],
  },
  {
    scale: 4,
    level: t('peer-score-4-description'),
    description: [],
  },
  {
    scale: 5,
    level: t('peer-score-5-description'),
    description: [],
  },
  {
    scale: 6,
    level: t('peer-score-6-description'),
    description: [],
  },
]
