import type { Calendar } from '~/routes/settings/_loaders/calendars'
import { Link, type LinkProps } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import {
  CheckBadgeIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'

import { timeAgo } from '~/utils/time'
import { cn } from '~/utils/cn'
import { useMemo, useState } from 'react'
import { ActiveCalendarModal } from '~/routes/settings/_components/ActiveCalendarModal'
import { useQuery } from '@tanstack/react-query'
import { api } from '~/app/api'

export type QuickStartData = {
  allDone: boolean
  onboardedAt: string
  workingHoursModified: boolean
  bioFilled: boolean
  bioSummaryFilled: boolean
  syncedCalendars: number
  activeCalendar: string | null
  hasBillingDetails: boolean
}

const MAX = 6

const calculateWidth = (val: number) => {
  const percentage = (val / MAX) * 100
  return `${percentage.toFixed(0)}%`
}

const AnchorButton: React.FC<LinkProps> = (props) => {
  return (
    <Link
      {...props}
      className={cn(
        'w-1/6 rounded px-2 py-1.5 text-center text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200 hover:bg-gray-50',
        props.className,
      )}
    >
      {props.children}
    </Link>
  )
}

const NormalButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props,
) => {
  return (
    <button
      {...props}
      className={cn(
        'w-1/6 rounded px-2 py-1.5 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200 hover:bg-gray-50',
        props.className,
      )}
    >
      {props.children}
    </button>
  )
}

type Props = {
  data: QuickStartData
  refetch: () => void
}

export const QuickStart: React.FC<Props> = (props) => {
  const { data, refetch } = props
  const [isChangeActiveCalendarModalOpen, setIsChangeActiveCalendarModalOpen] =
    useState(false)

  const { data: calendars } = useQuery({
    initialData: [],
    queryKey: ['core', 'calendars'],
    queryFn: () => api.url('/coach/calendars').get().json<Calendar[]>(),
  })

  const completedCount = useMemo(() => {
    let count = 1
    if (data.workingHoursModified) ++count
    if (data.bioFilled && data.bioSummaryFilled) ++count
    if (data.syncedCalendars > 0) ++count
    if (data.activeCalendar) ++count
    if (data.hasBillingDetails) ++count

    return count
  }, [data])

  const nextUp = useMemo(() => {
    if (!data.workingHoursModified) return 'Check your working hours'
    if (!data.bioFilled) return 'Fill your bio'
    if (!data.bioSummaryFilled) return 'Add short summary'
    if (!data.syncedCalendars) return 'Sync your Google calendar'
    if (!data.activeCalendar) return 'Configure your external calendar'
    if (!data.hasBillingDetails) return 'Add your billing details'

    return null
  }, [data])

  return (
    <>
      <div className="overflow-hidden bg-white sm:rounded-lg sm:shadow-lg">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <CheckBadgeIcon className="h-10 w-10 text-green-500" />
                </div>
                <div className="ml-4">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Let's finish setting up your account
                  </h3>
                  <p className="text-sm text-gray-500">
                    <span className="font-semibold text-green-500">
                      Next up
                    </span>
                    : {nextUp}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/4">
              <p className="font-medium text-sm text-gray-700 text-end">
                {`${calculateWidth(completedCount)} completed`}
              </p>
              <p
                className="inline-block w-full rounded-full bg-gray-200"
                aria-hidden={true}
              >
                <i
                  className="block h-2.5 rounded-full bg-primary"
                  style={{ width: calculateWidth(completedCount) }}
                />
              </p>
            </div>
          </div>
        </div>

        <ul role="list" className="divide-y divide-gray-200">
          <li className="py-3 px-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <Tooltip
                  id={`quick-start-account`}
                  className="max-w-sm z-[9999]"
                >
                  This task is complete. You have successfully created your
                  coaching account.
                </Tooltip>
                <InformationCircleIcon
                  data-tooltip-id={`quick-start-account`}
                  className="h-6 w-6 text-green-500"
                />
              </div>
              <div className="flex items-center justify-between flex-1 min-w-0">
                <s className="block text-sm font-semibold text-gray-500">
                  Create Hupo Coach Account
                </s>

                <p className="text-sm font-medium text-gray-500">
                  {timeAgo(data.onboardedAt)}
                </p>
              </div>
            </div>
          </li>

          <li className="py-3 px-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <Tooltip id="quick-start-wh" className="max-w-sm z-[9999]">
                  Set and confirm the hours you are available for coaching
                  sessions
                </Tooltip>
                <InformationCircleIcon
                  data-tooltip-id="quick-start-wh"
                  className={cn(
                    'h-6 w-6',
                    data.workingHoursModified
                      ? 'text-green-500'
                      : 'text-gray-400',
                  )}
                />
              </div>
              <div className="flex items-center justify-between flex-1 min-w-0">
                {data.workingHoursModified ? (
                  <s className="block text-sm font-semibold text-gray-500">
                    Set up your working hours
                  </s>
                ) : (
                  <p className="text-sm font-medium text-gray-900">
                    Set up your working hours
                  </p>
                )}
              </div>

              {!data.workingHoursModified && (
                <AnchorButton to="/settings/availability">Set now</AnchorButton>
              )}
            </div>
          </li>

          <li className="py-3 px-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <Tooltip id="quick-start-bio" className="max-w-sm z-[9999]">
                  Fill out your biography to let your clients know more about
                  you and your coaching style.
                </Tooltip>
                <InformationCircleIcon
                  data-tooltip-id="quick-start-bio"
                  className={cn(
                    'h-6 w-6',
                    data.bioFilled && data.bioSummaryFilled
                      ? 'text-green-500'
                      : 'text-gray-400',
                  )}
                />
              </div>
              <div className="flex items-center justify-between flex-1 min-w-0">
                {data.bioFilled && data.bioSummaryFilled ? (
                  <s className="block text-sm font-semibold text-gray-500">
                    Complete your bio
                  </s>
                ) : (
                  <p className="text-sm font-medium text-gray-900">
                    {data.bioFilled ? 'Add summary' : 'Complete your bio'}
                  </p>
                )}

                {(!data.bioFilled || !data.bioSummaryFilled) && (
                  <AnchorButton to="/settings/edit-bio">
                    {data.bioFilled ? 'Add summary' : 'Complete your bio'}
                  </AnchorButton>
                )}
              </div>
            </div>
          </li>

          <li className="py-3 px-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <Tooltip id="quick-start-ccc" className="max-w-sm z-[9999]">
                  Sync your calendar to automatically add booked Hupo sessions
                  to your personal schedule.
                </Tooltip>
                <InformationCircleIcon
                  data-tooltip-id="quick-start-ccc"
                  className={cn(
                    'h-6 w-6',
                    data.syncedCalendars > 0
                      ? 'text-green-500'
                      : 'text-gray-400',
                  )}
                />
              </div>
              <div className="flex items-center justify-between flex-1 min-w-0">
                {data.syncedCalendars > 0 ? (
                  <p className="block text-sm font-semibold text-gray-500">
                    <s>Sync your Google calendar</s>
                  </p>
                ) : (
                  <p className="text-sm font-medium text-gray-900">
                    Sync your Google calendar
                  </p>
                )}

                {data.syncedCalendars === 0 && (
                  <AnchorButton to="/settings/calendars/core/new">
                    Sync now
                  </AnchorButton>
                )}
              </div>
            </div>
          </li>

          <li className="py-3 px-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <Tooltip id="quick-start-ccc" className="max-w-sm z-[9999]">
                  Connect your calendar to ensure you don’t have double
                  bookings.
                </Tooltip>
                <InformationCircleIcon
                  data-tooltip-id="quick-start-ccc"
                  className={cn(
                    'h-6 w-6',
                    data.syncedCalendars > 0
                      ? 'text-green-500'
                      : 'text-gray-400',
                  )}
                />
              </div>
              <div className="flex items-center justify-between flex-1 min-w-0">
                {data.activeCalendar ? (
                  <p className="block text-sm font-semibold text-gray-500">
                    <s>Configure your external calendar</s>

                    <span className="block text-sm text-gray-500">
                      New sessions will be added to your{' '}
                      <span className="text-gray-900">
                        {data.activeCalendar}
                      </span>
                    </span>
                  </p>
                ) : (
                  <p className="text-sm font-medium text-gray-900">
                    Configure your external calendar
                    <span className="block text-sm text-primary">
                      To add new sessions to your calendar automatically
                    </span>
                  </p>
                )}

                {!data.activeCalendar && data.syncedCalendars > 0 && (
                  <NormalButton
                    onClick={() => setIsChangeActiveCalendarModalOpen(true)}
                  >
                    Configure
                  </NormalButton>
                )}
              </div>
            </div>
          </li>

          <li className="py-3 px-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <Tooltip id="quick-start-ccc" className="max-w-sm z-[9999]">
                  Provide your billing details to complete your account setup
                  and enable payment processing.
                </Tooltip>
                <InformationCircleIcon
                  data-tooltip-id="quick-start-ccc"
                  className={cn(
                    'h-6 w-6',
                    data.hasBillingDetails ? 'text-green-500' : 'text-gray-400',
                  )}
                />
              </div>
              <div className="flex items-center justify-between flex-1 min-w-0">
                {data.hasBillingDetails ? (
                  <p className="block text-sm font-semibold text-gray-500">
                    <s>Add your billing details</s>
                  </p>
                ) : (
                  <p className="text-sm font-medium text-gray-900">
                    Add your billing details
                  </p>
                )}

                {!data.hasBillingDetails && (
                  <AnchorButton to="/settings/billing">Add</AnchorButton>
                )}
              </div>
            </div>
          </li>
        </ul>
      </div>

      <ActiveCalendarModal
        isOpen={isChangeActiveCalendarModalOpen}
        onClose={() => setIsChangeActiveCalendarModalOpen(false)}
        title="Which calendar should we add new sessions to?"
        type="core"
        calendars={calendars}
        hasActiveCalendar={false}
        onSettled={refetch}
      />
    </>
  )
}
