import { Suspense, useCallback } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import { LoadingSpinner } from '~/Loading'
import { SessionSummariesWithData } from '../../components/hupohighlights/SessionSummaries'
import { loader } from './_loaders/session-summaries'
import { groupApi } from '../../app/api'

export const SessionSummaries: React.FC = () => {
  const deferredData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  const updateSummary = useCallback(
    async (data: { sessionId: string; summary: string }) => {
      await groupApi
        .url(`/coach/groups/summaries/${data.sessionId}/edit`)
        .post({
          content: data.summary,
        })
        .json()
    },
    [],
  )

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={deferredData.data} errorElement={<div>Error</div>}>
        {(data) => (
          <SessionSummariesWithData data={data} updateSummary={updateSummary} />
        )}
      </Await>
    </Suspense>
  )
}
