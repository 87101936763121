import { LoadingSplash } from './Loading'

export const Offline: React.FC = () => {
  return (
    <>
      <LoadingSplash />

      <div className="absolute left-0 right-0 bottom-10 mx-auto text-center">
        <p className="text-xl font-bold">You are offline</p>
        <p className="text-lg text-gray-500">
          Please check your internet connection
        </p>
      </div>
    </>
  )
}
