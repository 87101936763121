import { useState } from 'react'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { loader } from './_loaders/security'
import { api } from '~/app/api'
import { useToast } from '~/components/ui/use-toast'
import { queryClient } from '~/query'

type Inputs = {
  totpCode: string
}

export const Security: React.FC = () => {
  const { revalidate } = useRevalidator()
  const { toast } = useToast()
  const { enabled } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >
  const form = useForm<Inputs>({ defaultValues: { totpCode: '' } })
  const [qrCode, setQrCode] = useState('')

  const { mutate: getSecret } = useMutation({
    mutationKey: ['security', 'mfa', 'secret'],
    async mutationFn() {
      return await api
        .url('/coach/auth/mfa-secret')
        .get()
        .json<{ ok: true; qrCode: string }>()
    },
    onSuccess({ qrCode }) {
      setQrCode(qrCode)
    },
  })

  const { mutate: enableMfa } = useMutation({
    mutationKey: ['security', 'mfa', 'enable'],
    async mutationFn(totpCode: string) {
      await api.url('/coach/auth/mfa-enable').post({ code: totpCode }).json()
    },
    async onSuccess() {
      toast({
        title: 'Success',
        description: 'MFA enabled successfully',
        variant: 'success',
      })
      setQrCode('')

      await queryClient.invalidateQueries({
        queryKey: ['security', 'mfa'],
        exact: true,
      })
      revalidate()
    },
  })

  const { mutate: disableMfa } = useMutation({
    mutationKey: ['security', 'mfa', 'disable'],
    async mutationFn(totpCode: string) {
      await api
        .url('/coach/auth/mfa-disable')
        .post({ code: totpCode })
        .json<{ ok: true }>()
    },
    async onSuccess() {
      toast({
        title: 'Success',
        description: 'MFA disabled successfully',
        variant: 'success',
      })

      await queryClient.invalidateQueries({
        queryKey: ['security', 'mfa'],
        exact: true,
      })
      revalidate()
    },
  })

  return (
    <div className="divide-y divide-black/5">
      {enabled ? (
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7">Disable MFA</h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              You've enabled multi-factor authentication. You can disable it by
              clicking the button below. But we recommend you to keep it
              enabled.
            </p>
          </div>

          <form
            className="md:col-span-2"
            onSubmit={(e) => {
              e.preventDefault()
              disableMfa(form.getValues('totpCode'))
            }}
          >
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <Input
                id="disable-totp"
                containerClassName="col-span-full"
                type="text"
                label="Enter TOTP code"
                maxLength={6}
                pattern="[0-9]{6}"
                inputMode="numeric"
                required
                {...form.register('totpCode')}
              />
            </div>

            <div className="mt-8 flex">
              <Button type="submit">Disable MFA</Button>
            </div>
          </form>
        </div>
      ) : (
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7">Enable MFA</h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              Enable multi-factor authentication to add an extra layer of
              security to your account.
            </p>
          </div>

          <form className="md:col-span-2">
            {qrCode.length > 0 && <img src={qrCode} alt="QR Code" />}

            {qrCode.length > 0 && (
              <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <Input
                  id="enable-totp"
                  containerClassName="col-span-full"
                  type="text"
                  label="Enter TOTP code"
                  maxLength={6}
                  pattern="[0-9]{6}"
                  inputMode="numeric"
                  required
                  {...form.register('totpCode')}
                />
              </div>
            )}

            <div className="sm:mt-8 flex">
              <Button
                className="max-sm:w-full"
                type="button"
                onClick={() => {
                  const totp = form.getValues('totpCode')
                  if (totp.length === 0) {
                    getSecret()
                  } else {
                    enableMfa(totp)
                  }
                }}
              >
                Enable MFA
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}
