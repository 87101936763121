import type { PayloadAction } from '@reduxjs/toolkit'
import { LOCAL_TIMEZONE } from '~/app/constants'
import { createAppSlice } from '~/app/createAppSlice'

export interface SettingsSliceState {
  timeZone: string
  timeFormat: '12' | '24'
}

const initialState: SettingsSliceState = {
  timeZone: LOCAL_TIMEZONE,
  timeFormat: '12',
}

export const settingsSlice = createAppSlice({
  name: 'settings',
  initialState,
  reducers: (create) => ({
    setData: create.reducer(
      (state, action: PayloadAction<SettingsSliceState>) => {
        state.timeZone = action.payload.timeZone
        state.timeFormat = action.payload.timeFormat
      },
    ),
    setTimeZone: create.reducer(
      (state, action: PayloadAction<SettingsSliceState['timeZone']>) => {
        state.timeZone = action.payload
      },
    ),
    setTimeFormat: create.reducer(
      (state, action: PayloadAction<SettingsSliceState['timeFormat']>) => {
        state.timeFormat = action.payload
      },
    ),
    clearData: create.reducer(() => initialState),
  }),
  selectors: {
    getTimeZone: (state) => state.timeZone,
    getTimeFormat: (state) => state.timeFormat,
    getFormatToken: (state) => (state.timeFormat === '12' ? 'h:mm a' : 'HH:mm'),
  },
})

export const {
  setData: setSettings,
  clearData: clearSettings,
  setTimeZone,
  setTimeFormat,
} = settingsSlice.actions

export const { getTimeFormat, getTimeZone, getFormatToken } =
  settingsSlice.selectors
