type AuthHeaderProps = { title: string }
type Props = React.PropsWithChildren<AuthHeaderProps>

export const AuthHeader: React.FC<Props> = (props) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        className="mx-auto h-10 w-auto"
        src="/logos/non-trademarked/orange/logo.svg"
        alt="Hupo"
      />
      <h2 className="mt-2 sm:mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        {props.title}
      </h2>
    </div>
  )
}
