// CreateEventPopover.tsx
import type { CalendarApi } from '@fullcalendar/core'
import type { User } from '../schedule'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { XMarkIcon } from '@heroicons/react/24/outline'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/popover'
import { Tabs, TabsList, TabsTrigger } from '~/components/ui/tabs'
import { Button } from '~/components/Button'
import { UserSelect } from './UserSelect'
import { api, groupApi, teamApi } from '~/app/api'
import { useToast } from '~/components/ui/use-toast'
import { useAuth } from '~/hooks/useAuth'
import { useMediaQuery } from 'react-responsive'
import { DatePicker } from '~/components/ui/date-picker'
import { TimePicker } from '~/components/ui/time-picker'
import { useAppSelector } from '~/app/hooks'
import { getFormatToken, getTimeFormat } from '~/store/settings.slice'
import { TimePickerWithDuration } from '~/components/TimePickerWithDuration'

interface EventContentProps {
  calendarApi: CalendarApi
  timeText: string
  start: Date | null
  end: Date | null
}

type Inputs = {
  mode: 'session' | 'block' | 'open'
  start: Date | null
  end: Date | null
}

const generateUUID32 = () => {
  // Generate 16 random bytes (32 hex characters)
  const array = new Uint8Array(16)
  window.crypto.getRandomValues(array)

  // Convert bytes to hexadecimal format
  return Array.from(array, (byte) => byte.toString(32).padStart(2, '0')).join(
    '',
  )
}

export const CreateEventPopover: React.FC<EventContentProps> = (props) => {
  const { timeText, calendarApi } = props
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' })
  const { isTeamCoach, isGroupCoach } = useAuth()
  const { toast } = useToast()
  const timeFormat = useAppSelector(getTimeFormat)
  const timeFormatToken = useAppSelector(getFormatToken)
  const [user, setUser] = useState<User | null>(null)
  const [isOpen, setIsOpen] = useState(true)
  const { data: usersList } = useQuery({
    initialData: {},
    queryKey: ['users', 'list'],
    queryFn() {
      return api
        .get('/coach/customers/list?isActive=true')
        .json<Record<string, User[]>>()
    },
  })
  const { setValue, watch, handleSubmit } = useForm<Inputs>({
    defaultValues: { mode: 'session', start: props.start, end: props.end },
  })

  const mode = watch('mode', 'session')
  const start = watch('start')

  const handleClose = useCallback(() => {
    setIsOpen(false)
    calendarApi.getEventById('new-event')?.remove()
    calendarApi.unselect()
  }, [calendarApi])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClose, calendarApi])

  const { mutate: bookSession } = useMutation({
    mutationKey: ['schedule', 'event', 'create'],
    async mutationFn(data: { userId: string; start: string; end: string }) {
      await api.url('/coach/events').post(data).json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Event created successfully',
        variant: 'success',
      })
      calendarApi.getEventSourceById('session')?.refetch()
    },
    onSettled() {
      handleClose()
    },
  })

  const { mutate: createBlockEvent } = useMutation({
    mutationKey: ['schedule', 'block', 'create'],
    async mutationFn(data: { start: string; end: string; ref: string }) {
      await api.url('/coach/events/block').post(data).json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Block event created',
        variant: 'success',
      })
      calendarApi.getEventSourceById('block')?.refetch()
    },
    onSettled() {
      handleClose()
    },
  })

  const { mutate: createTeamBlockEvent } = useMutation({
    mutationKey: ['team', 'schedule', 'block', 'create'],
    mutationFn(data: { start: string; end: string; ref: string }) {
      return teamApi.url('/coach/events/block').post(data).json()
    },
  })

  const { mutate: createGroupBlockSlot } = useMutation({
    mutationKey: ['group', 'schedule', 'block', 'create'],
    mutationFn(data: { start: string; end: string; ref: string }) {
      return groupApi.url('/coach/events/block').post(data).json()
    },
  })

  const { mutate: createOpenSlot } = useMutation({
    mutationKey: ['schedule', 'slot', 'create'],
    mutationFn(data: { start: string; end: string; ref: string }) {
      return api.url('/coach/events/open').post(data).json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Time slot opened successfully',
        variant: 'success',
      })
      calendarApi.getEventSourceById('slot')?.refetch()
    },
    onSettled() {
      handleClose()
    },
  })

  const { mutate: createTeamOpenSlot } = useMutation({
    mutationKey: ['team', 'schedule', 'slot', 'create'],
    mutationFn(data: { start: string; end: string; ref: string }) {
      return teamApi.url('/coach/events/open').post(data).json()
    },
  })
  const { mutate: createGroupOpenSlot } = useMutation({
    mutationKey: ['group', 'schedule', 'slot', 'create'],
    mutationFn(data: { start: string; end: string; ref: string }) {
      return groupApi.url('/coach/events/open').post(data).json()
    },
  })

  const onSubmit = handleSubmit((data) => {
    if (!data.start || !data.end) return

    const start = data.start.toISOString()
    const end = data.end.toISOString()

    switch (data.mode) {
      case 'session': {
        if (!user) break
        bookSession({ userId: user.id, start, end })
        break
      }

      case 'block': {
        // Generate a random 16-character hex string
        // This is used to delete corresponding block events from each of core/team/group
        const uuid16 = generateUUID32()
        createBlockEvent({ start, end, ref: uuid16 })
        if (isTeamCoach) {
          createTeamBlockEvent({ start, end, ref: uuid16 })
        }
        if (isGroupCoach) {
          createGroupBlockSlot({ start, end, ref: uuid16 })
        }
        break
      }

      case 'open': {
        // Generate a random 16-character hex string
        // This is used to delete corresponding open slots from each of core/team/group
        const uuid16 = generateUUID32()
        createOpenSlot({ start, end, ref: uuid16 })
        if (isTeamCoach) {
          createTeamOpenSlot({ start, end, ref: uuid16 })
        }
        if (isGroupCoach) {
          createGroupOpenSlot({ start, end, ref: uuid16 })
        }
        break
      }
    }
  })

  return (
    <Popover
      open={isOpen}
      defaultOpen={isOpen}
      onOpenChange={(open) => !open && calendarApi.unselect()}
    >
      <PopoverTrigger>
        <div className="text-left">
          <p className="font-semibold line-clamp-1">{timeText}</p>
        </div>
      </PopoverTrigger>
      <PopoverContent side={isMobileScreen ? 'top' : 'right'} className="w-96">
        <div className="mb-4 flex justify-between">
          <h2 className="text-lg font-semibold">Schedule a session</h2>
          <button
            className="relative rounded-full bg-white p-1 text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
            onClick={handleClose}
          >
            <span className="absolute -inset-1.5" />
            <span className="sr-only">Close the dialog</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <Tabs
            defaultValue="session"
            onValueChange={(value) => {
              setValue('mode', value as Inputs['mode'])
            }}
          >
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="session">Session</TabsTrigger>
              <TabsTrigger value="block">Block</TabsTrigger>
              <TabsTrigger value="open">Open</TabsTrigger>
            </TabsList>
          </Tabs>

          {mode === 'session' && (
            <>
              <div className="mt-4 flex flex-col gap-2">
                <label
                  htmlFor="user_combobox"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  User
                </label>
                <UserSelect
                  id="user_combobox"
                  value={user}
                  setValue={setUser}
                  usersList={usersList}
                />
              </div>

              <div className="flex flex-col mt-4 gap-2.5">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Time
                  </label>
                  <div className="flex flex-col items-center gap-2">
                    <DatePicker
                      className="w-full"
                      value={start}
                      onSelect={(value) => {
                        setValue('start', value)
                        setValue('end', value)
                      }}
                    />
                    <TimePickerWithDuration
                      className="w-full"
                      value={start}
                      onChange={(startVal, duration) => {
                        if (!startVal) return
                        setValue('start', startVal)
                        setValue('end', addMinutes(startVal, duration))
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {mode !== 'session' && (
            <div className="flex flex-col mt-4 gap-2.5">
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Start
                </label>
                <div className="flex flex-col items-center gap-2">
                  <DatePicker
                    className="w-full"
                    value={start}
                    onSelect={(value) => {
                      setValue('start', value)
                      setValue('end', value)
                    }}
                  />
                  <TimePicker
                    className="w-full"
                    value={start}
                    timeFormat={timeFormat}
                    formatToken={timeFormatToken}
                    onSelect={(value) => {
                      setValue('start', value)
                      setValue('end', addHours(value, 1))
                    }}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  End
                </label>
                <div className="flex flex-col gap-2">
                  <DatePicker
                    className="w-full"
                    value={watch('end')}
                    onSelect={(value) => {
                      setValue('end', value)
                    }}
                  />
                  <TimePicker
                    className="w-full"
                    value={watch('end')}
                    timeFormat={timeFormat}
                    formatToken={timeFormatToken}
                    onSelect={(value) => {
                      setValue('end', value)
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <Button type="submit" className="mt-5 w-full">
            {mode === 'session'
              ? 'Book a session'
              : mode === 'block'
                ? 'Block'
                : 'Open'}
          </Button>
        </form>
      </PopoverContent>
    </Popover>
  )
}
