import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import confetti from 'canvas-confetti'

import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { Button } from '~/components/Button'
import { TextArea } from '~/components/TextArea'
import {
  clearOnboardingData,
  setOnboardingData,
} from '~/store/onboarding.slice'
import { api } from '~/app/api'
import { useToast } from '~/components/ui/use-toast'
import { useMixpanel } from '~/hooks/useMixpanel'
import { Toggle } from '~/components/Toggle'

type Inputs = {
  bio: string
  philosophy: string
  generateSummary: boolean
}

const onboardingCongratulation = () => {
  const duration = 3 * 1000
  const animationEnd = Date.now() + duration
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

  function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min
  }

  const interval = setInterval(() => {
    const timeLeft = animationEnd - Date.now()

    if (timeLeft <= 0) {
      clearInterval(interval)
      return
    }

    const particleCount = 50 * (timeLeft / duration)
    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      }),
    )
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      }),
    )
  }, 250)
}

export const BioOnboarding: React.FC = () => {
  const onboardingState = useAppSelector((state) => state.onboarding)
  const dispatch = useAppDispatch()
  const mixpanel = useMixpanel()
  const navigate = useNavigate()
  const { toast } = useToast()
  const form = useForm<Inputs>({
    defaultValues: {
      bio: onboardingState.bio,
      philosophy: onboardingState.coachingPhilosophy,
      generateSummary: true,
    },
  })

  const { mutate, isPending } = useMutation({
    mutationKey: ['onboarding'],
    mutationFn(payload: Inputs) {
      return api
        .url('/coach/onboarding/complete')
        .post({
          fullBio: payload.bio,
          philosophy: payload.philosophy,
          generateSummary: payload.generateSummary,
          dryRun: window.isPlaywright ?? false,
        })
        .json<{ ok: true }>()
    },
    onSuccess() {
      onboardingCongratulation()

      toast({
        title: 'Welcome to Hupo!',
        description: 'Congratulations you successfully joined to Hupo',
        variant: 'success',
      })

      mixpanel.track('onboarded', {
        name: onboardingState.name,
        timeZone: onboardingState.timeZone,
        timeFormat: onboardingState.timeFormat,
        calendarConnected: onboardingState.isCalendarConnected,
      })

      dispatch(clearOnboardingData())

      navigate('/', { replace: true })
    },
  })

  const onSubmit = form.handleSubmit((data) => {
    dispatch(
      setOnboardingData({
        bio: data.bio,
        coachingPhilosophy: data.philosophy,
      }),
    )

    mutate(data)
  })

  return (
    <form className="mt-6" onSubmit={onSubmit}>
      <div className="px-4 py-2 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <TextArea
                id="bio"
                rows={10}
                label="Full Bio"
                placeholder="I am a certified life coach with 5 years of experience. I specialize in helping people overcome their fears and achieve their goals. I believe that everyone has the potential to succeed and I am here to help you unlock that potential."
                {...form.register('bio')}
              />

              <div className="mt-3 flex gap-3 items-center">
                <Toggle
                  enabled={form.watch('generateSummary')}
                  setEnabled={(val) => form.setValue('generateSummary', val)}
                />

                <span className="block text-sm leading-6 text-gray-900">
                  Short summary with AI
                </span>
              </div>

              <div className="mt-3">
                <p className="text-sm text-gray-500">
                  We will generate a short summary of your bio using AI. This
                  will help clients quickly understand your coaching style and
                  expertise. You can edit the summary later.
                </p>
              </div>
            </div>

            <div className="col-span-full">
              <TextArea
                id="philosophy"
                rows={5}
                label="Coaching Philosophy"
                placeholder="Once you're in my team, expect the journey to be hard but be prepared for success."
                {...form.register('philosophy')}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10 flex gap-2">
        <Button
          to="/onboarding/profile"
          type="button"
          variant="outline"
          className="w-full"
        >
          Back
        </Button>
        <Button
          type="submit"
          className="w-full"
          loading={
            form.formState.isSubmitting || form.formState.isLoading || isPending
          }
        >
          Submit
        </Button>
      </div>
    </form>
  )
}
