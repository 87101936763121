/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import {
  CalendarIcon,
  InboxStackIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { UserAnswer } from './UserAnswer'

interface UserAnswerProps {
  assessmentDate: string
  shareWithUser?: boolean
  type: string
  data: {
    answers: any[]
    sharedWithUser?: boolean
  }[]
}

const formatDateString = (input: string) => {
  return format(parseISO(input), 'MMM d, yyyy')
}

export const UserAssessmentAnswers: React.FC<UserAnswerProps> = (props) => {
  const { assessmentDate, type, data } = props

  const filteredData = useMemo(
    () =>
      data.filter(
        (item: any) => Array.isArray(item.answers) && item.answers.length > 0,
      ),
    [data],
  )

  return (
    <div className="my-2">
      <div className="w-full border-y py-2 mb-2 flex items-center justify-center text-gray-500">
        <p className="flex-1 flex items-center gap-2">
          <CalendarIcon className="h-5" /> {formatDateString(assessmentDate)}
        </p>

        <p className="flex-1 flex justify-end items-center gap-2">
          {type === 'User' ? (
            <UserIcon className="h-5" />
          ) : (
            <InboxStackIcon className="h-5" />
          )}
          {type}
        </p>
      </div>

      <ul className="space-y-10">
        {filteredData.map(({ answers }, index) => (
          <li key={`${answers.length}+${index}`}>
            <ul className="space-y-3">
              {answers.map((answer) => (
                <UserAnswer
                  key={answer._id ?? answer.question}
                  answers={answer.answers}
                  question={answer.question}
                  description={answer.description}
                />
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}
