import type { Question } from '~/hooks/useInitNps'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { getAnswer, setNpsAnswer } from '~/store/nps.slice'

interface Props {
  question: Question
}

export const SurveyTextarea: React.FC<Props> = (props: Props) => {
  const { question } = props
  const dispatch = useAppDispatch()
  const defaultValue = useAppSelector(getAnswer(question._id))
  if (question.type !== 'textarea') return null

  return (
    <div className="mt-2">
      <textarea
        id={question._id}
        placeholder={question.placeholder}
        rows={4}
        name={question.friendlyID}
        defaultValue={defaultValue}
        onChange={(e) =>
          dispatch(setNpsAnswer({ id: question._id, answer: e.target.value }))
        }
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
      />
    </div>
  )
}
