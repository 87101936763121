import { useState } from 'react'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ArrowDownTrayIcon, FlagIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon as CheckCircleIconSolid } from '@heroicons/react/24/solid'

import { api } from '~/app/api'
import { AlertDialog } from '~/components/AlertDialog'
import { Badge } from '~/components/Badge'
import { useToast } from '~/components/ui/use-toast'
import { ReportDialog } from './_components/ReportDialog'
import { invoicesLoader as loader } from './invoices.loader'

const mapStatusToBadge = (status: string) => {
  const title = status.toUpperCase()

  switch (status) {
    case 'processing':
      return <Badge variant="blue">{title}</Badge>
    case 'complete':
      return <Badge variant="green">{title}</Badge>
    case 'flagged':
      return <Badge variant="red">{title}</Badge>
    default:
      return <Badge variant="gray">{title}</Badge>
  }
}

export const Invoices: React.FC = () => {
  const invoices = useLoaderData() as Exclude<
    Awaited<ReturnType<ReturnType<typeof loader>>>,
    Response
  >
  const { revalidate } = useRevalidator()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const [isReportInvoiceModalOpen, setReportInvoiceModal] = useState(false)
  const [isVerifyAlertOpen, setVerifyAlert] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null)

  const { mutate: verify, isIdle } = useMutation({
    mutationKey: ['invoices', 'verify'],
    mutationFn(id: string) {
      return api.url(`/coach/invoices/${id}/verify`).post().json()
    },
    async onSuccess() {
      toast({
        title: 'Invoice verified',
        description: 'The invoice has been verified successfully',
        variant: 'success',
      })

      await queryClient.invalidateQueries({
        queryKey: ['invoices'],
      })
      revalidate()
    },
    onSettled() {
      setVerifyAlert(false)
    },
  })

  return (
    <>
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Invoices
        </h2>

        <p className="mt-3">
          This page shows the invoices for your coaching sessions. Contact us
          for updating your billing information.
        </p>
      </div>

      <div className="px-4 sm:px-6 lg:px-8 bg-white sm:rounded-lg sm:shadow">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {invoices.map((invoice) => (
                    <tr key={invoice._id}>
                      <td className="whitespace-nowrap py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{invoice.id}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {invoice.description}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {invoice.amount} {invoice.currency}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {mapStatusToBadge(invoice.status)}
                      </td>
                      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <div className="flex gap-5 items-center">
                          {/* Button to download invoice */}
                          {invoice.reportURL && (
                            <a
                              href={invoice.reportURL}
                              className="-m-1 flex h-7 w-7 items-center justify-center rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <ArrowDownTrayIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Download</span>
                            </a>
                          )}
                          {/* Button to report issues */}
                          {invoice.status === 'processing' && (
                            <button
                              type="button"
                              className="-m-1 flex h-7 w-7 items-center justify-center rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                              onClick={() => {
                                setSelectedInvoice(invoice._id)
                                setReportInvoiceModal(true)
                              }}
                            >
                              <FlagIcon
                                className="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Report an issue</span>
                            </button>
                          )}
                          {/* Verify completion */}
                          {invoice.status !== 'complete' &&
                          invoice.status !== 'paid' ? (
                            <button
                              type="button"
                              className="-m-1 flex h-7 w-7 items-center justify-center rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                              onClick={() => {
                                setSelectedInvoice(invoice._id)
                                setVerifyAlert(true)
                              }}
                            >
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Verify</span>
                            </button>
                          ) : invoice.status === 'complete' ||
                            invoice.status === 'paid' ? (
                            <CheckCircleIconSolid
                              className="h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))}

                  {invoices.length === 0 && (
                    <tr>
                      <td
                        colSpan={6}
                        className="px-3 py-5 text-sm text-gray-500 text-center"
                      >
                        No invoices yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ReportDialog
        open={isReportInvoiceModalOpen}
        onClose={() => setReportInvoiceModal(false)}
        invoiceId={selectedInvoice}
      />

      <AlertDialog
        isOpen={isVerifyAlertOpen}
        title="Verify invoice"
        confirmText="Yes, verify"
        onConfirm={() => {
          if (selectedInvoice && isIdle) {
            verify(selectedInvoice)
          }
        }}
        onClose={() => setVerifyAlert(false)}
      >
        <p className="text-sm text-gray-500">
          Are you sure you want to verify this invoice?
        </p>
      </AlertDialog>
    </>
  )
}
