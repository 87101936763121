import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'

export const timeAgo = (date: string) => {
  try {
    return formatDistanceToNow(parseISO(date), { addSuffix: true })
  } catch {
    return 'invalid date'
  }
}
