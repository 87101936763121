import {
  type LoaderFunctionArgs,
  Navigate,
  createBrowserRouter,
} from 'react-router-dom'
import { Sentry } from './sentry'
// Routes
import { AuthGuard } from './AuthGuard'
import { NonAuthGuard } from './NonAuthGuard'
import { DashboardLayout } from './layouts/dashboard'
import { queryClient } from './query'
import { loader as chatsLoader } from './routes/_loaders/chats'
import { loader as coreFeedbackLoader } from './routes/feedback/_loaders/core'
import { loader as rootLoader } from './routes/_loaders/root'
import { loader as teamsLoader } from './routes/_loaders/teams'
import { loader as usersLoader } from './routes/_loaders/users'
import { Auth } from './routes/auth/auth'
import { ForgotPassword } from './routes/auth/forgot-password'
import { ResetPassword } from './routes/auth/reset-password'
import { TOTP } from './routes/auth/totp'
import { Chats } from './routes/chats'
import { Chat } from './routes/chats/_id'
import { loader as chatLoader } from './routes/chats/_loaders/_id'
import { ErrorPage } from './routes/error-page'
import { Feedback } from './routes/feedback'
import { Invoices } from './routes/invoices/invoices'
import { invoicesLoader } from './routes/invoices/invoices.loader'
import { NotFound } from './routes/not-found'
import { Onboarding } from './routes/onboarding'
import { BioOnboarding } from './routes/onboarding/bio'
import { ConnectCalendar } from './routes/onboarding/connect-calendar'
import { ProfileOnboarding } from './routes/onboarding/profile'
import { UserSettings } from './routes/onboarding/user-settings'
import { WorkingHoursOnboarding } from './routes/onboarding/working-hours'
import { Root } from './routes/root'
import { Schedule } from './routes/schedule/schedule'
import { Settings } from './routes/settings'
import { loader as calendarSettingsLoader } from './routes/settings/_loaders/calendars'
import { loader as editBioLoader } from './routes/settings/_loaders/edit-bio'
import { loader as securityLoader } from './routes/settings/_loaders/security'
import { loader as workingHoursLoader } from './routes/settings/_loaders/working-hours'
import { loader as billingLoader } from './routes/settings/_loaders/billing'
import { CalendarSettings } from './routes/settings/calendars'
import { NewCalendar } from './routes/settings/calendars/new'
import { EditBio } from './routes/settings/edit-bio'
import { Profile } from './routes/settings/profile'
import { Security } from './routes/settings/security'
import { WorkingHours } from './routes/settings/working-hours'
import { Teams } from './routes/teams'
import { Team } from './routes/teams/_id'
import { Billing } from './routes/settings/billing'
import { loader as teamLoader } from './routes/teams/_loaders/_id'
import { loader as teamDiagnosticsLoader } from './routes/teams/_loaders/diagnostics'
import { loader as teamMembersLoader } from './routes/teams/_loaders/members'
import { loader as teamSessionSummariesLoader } from './routes/teams/_loaders/session-summaries'
import { TeamDiagnostics } from './routes/teams/diagnostics'
import { TeamMembers } from './routes/teams/members'
import { SessionSummaries as TeamSessionSummaries } from './routes/teams/session-summaries'
import { Users } from './routes/users'
import { User } from './routes/users/_id'
import { loader as userLoader } from './routes/users/_loaders/_id'
import { loader as assessmentLoader } from './routes/users/_loaders/assessment-results'
import { loader as coachNotesLoader } from './routes/users/_loaders/coach-notes'
import { loader as hupoPerspectivesLoader } from './routes/users/_loaders/hupo-perspectives'
import { loader as onboardingAnswersLoader } from './routes/users/_loaders/onboarding-answers'
import { loader as sessionNotesLoader } from './routes/users/_loaders/session-notes'
import { loader as sessionSummariesLoader } from './routes/users/_loaders/session-summaries'
import { AssessmentResults } from './routes/users/assessment-results'
import { CoachNotes } from './routes/users/coach-notes'
import { HupoPerspectives } from './routes/users/hupo-perspectives'
import { Notes } from './routes/users/notes'
import { OnboardingAnswers } from './routes/users/onboarding-answers'
import { SessionNotes } from './routes/users/session-notes'
import { SessionSummaries } from './routes/users/session-summaries'
import { loader as onboardingLoader } from './routes/_loaders/onboarding'
// Groups
import { loader as groupsLoader } from './routes/_loaders/groups'
import { Groups } from './routes/groups'
import { Group } from './routes/groups/_id'
import { loader as groupLoader } from './routes/groups/_loaders/_id'
import { loader as groupDiagnosticsLoader } from './routes/groups/_loaders/diagnostics'
import { loader as groupMembersLoader } from './routes/groups/_loaders/members'
import { loader as groupSessionSummariesLoader } from './routes/groups/_loaders/session-summaries'
import { GroupMembers } from './routes/groups/members'
import { GroupDiagnostics } from './routes/groups/diagnostics'
import { SessionSummaries as GroupSessionSummaries } from './routes/groups/session-summaries'
import { loader as groupMemberLoader } from './routes/groups/members/_loader'
import { GroupMemberPage } from './routes/groups/members/_user-id'
import { GroupMemberHupoPerspectives } from './routes/groups/members/hupo-perspectives'
import { loader as groupMemberHupoPerspectivesLoader } from './routes/groups/members/hupo-perspective'
import { Files } from './routes/users/files'
import { loader as filesLoader } from './routes/users/_loaders/files'
import { CoreFeedback } from './routes/feedback/core'
import { GroupFeedback } from './routes/feedback/group'
import { TeamFeedback } from './routes/feedback/team'
import { loader as teamFeedbackLoader } from './routes/feedback/_loaders/team'
import { loader as groupFeedbackLoader } from './routes/feedback/_loaders/group'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter([
  {
    path: '/onboarding',
    element: (
      <AuthGuard>
        <Onboarding />
      </AuthGuard>
    ),
    loader: onboardingLoader,
    children: [
      { index: true, element: <Navigate to="user-settings" replace /> },
      {
        path: 'user-settings',
        element: <UserSettings />,
      },
      {
        path: 'working-hours',
        element: <WorkingHoursOnboarding />,
      },
      {
        path: 'connect-calendar',
        element: <ConnectCalendar />,
      },
      {
        path: 'profile',
        element: <ProfileOnboarding />,
      },
      {
        path: 'bio',
        element: <BioOnboarding />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        loader: rootLoader(queryClient),
        element: <Root />,
      },
      {
        path: 'users',
        loader: usersLoader(queryClient),
        element: <Users />,
      },
      {
        path: 'users/:id',
        loader: userLoader(queryClient),
        element: <User />,
        children: [
          {
            index: true,
            element: <Navigate to="assessment-results" replace />,
          },
          {
            path: 'assessment-results',
            loader: assessmentLoader(queryClient),
            element: <AssessmentResults />,
          },
          {
            path: 'onboarding-answers',
            loader: onboardingAnswersLoader(queryClient),
            element: <OnboardingAnswers />,
          },
          {
            path: 'notes',
            loader: async (args: LoaderFunctionArgs) => {
              const sessionNotesData =
                await sessionNotesLoader(queryClient)(args)
              const coachNotesData = await coachNotesLoader(queryClient)(args)
              const sessionSummariesData =
                await sessionSummariesLoader(queryClient)(args)
              return {
                sessionNotes: sessionNotesData.data,
                coachNotes: coachNotesData.data,
                sessionSummaries: sessionSummariesData.data,
              }
            },
            element: <Notes />,
          },
          {
            path: 'session-notes',
            loader: sessionNotesLoader(queryClient),
            element: <SessionNotes />,
          },
          {
            path: 'coach-notes',
            loader: coachNotesLoader(queryClient),
            element: <CoachNotes />,
          },
          {
            path: 'session-summaries',
            loader: sessionSummariesLoader(queryClient),
            element: <SessionSummaries />,
          },
          {
            path: 'hupo-perspectives',
            loader: hupoPerspectivesLoader(queryClient),
            element: <HupoPerspectives />,
          },
          {
            path: 'files',
            loader: filesLoader(queryClient),
            element: <Files />,
          },
        ],
      },
      {
        path: 'teams',
        loader: teamsLoader(queryClient),
        element: <Teams />,
      },
      {
        path: 'teams/:id',
        loader: teamLoader(queryClient),
        element: <Team />,
        children: [
          { index: true, element: <Navigate to="members" replace /> },
          {
            path: 'members',
            loader: teamMembersLoader(queryClient),
            element: <TeamMembers />,
          },
          {
            path: 'diagnostics',
            loader: teamDiagnosticsLoader(queryClient),
            element: <TeamDiagnostics />,
          },
          {
            path: 'session-summaries',
            loader: teamSessionSummariesLoader(queryClient),
            element: <TeamSessionSummaries />,
          },
        ],
      },
      {
        path: 'groups',
        loader: groupsLoader(queryClient),
        element: <Groups />,
      },
      {
        path: 'groups/:id',
        loader: groupLoader(queryClient),
        element: <Group />,
        children: [
          { index: true, element: <Navigate to="members" replace /> },
          {
            path: 'members',
            loader: groupMembersLoader(queryClient),
            element: <GroupMembers />,
          },
          {
            path: 'diagnostics',
            loader: groupDiagnosticsLoader(queryClient),
            element: <GroupDiagnostics />,
          },
          {
            path: 'session-summaries',
            loader: groupSessionSummariesLoader(queryClient),
            element: <GroupSessionSummaries />,
          },
        ],
      },
      // Group member details
      {
        path: 'groups/:id/members/:userId',
        loader: groupMemberLoader(queryClient),
        element: <GroupMemberPage />,
        children: [
          {
            path: 'hupo-perspectives',
            element: <GroupMemberHupoPerspectives />,
            loader: groupMemberHupoPerspectivesLoader(queryClient),
          },
        ],
      },
      {
        path: 'chats',
        children: [{ index: true, element: <Navigate to="core" replace /> }],
      },
      {
        path: 'chats/:type',
        element: <Chats />,
        loader: chatsLoader(queryClient),
        children: [
          {
            path: ':id',
            element: <Chat />,
            loader: chatLoader(queryClient),
          },
        ],
      },
      {
        path: 'schedule',
        element: <Schedule />,
      },
      {
        path: 'feedback',
        element: <Feedback />,
        children: [
          {
            index: true,
            element: <Navigate to="core" replace />,
          },
          {
            path: 'core',
            loader: coreFeedbackLoader(queryClient),
            element: <CoreFeedback />,
          },
          {
            path: 'team',
            loader: teamFeedbackLoader(queryClient),
            element: <TeamFeedback />,
          },
          {
            path: 'group',
            loader: groupFeedbackLoader(queryClient),
            element: <GroupFeedback />,
          },
        ],
      },
      {
        path: 'invoices',
        loader: invoicesLoader(queryClient),
        element: <Invoices />,
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          { index: true, element: <Navigate to="profile" replace /> },
          {
            path: 'profile',
            element: <Profile />,
          },
          {
            path: 'edit-bio',
            loader: editBioLoader(queryClient),
            element: <EditBio />,
          },
          {
            path: 'availability',
            loader: workingHoursLoader(queryClient),
            element: <WorkingHours />,
          },
          {
            path: 'calendars',
            children: [
              { index: true, element: <Navigate to="core" replace /> },
            ],
          },
          {
            path: 'calendars/:type',
            loader: calendarSettingsLoader(queryClient),
            element: <CalendarSettings />,
          },
          {
            path: 'calendars/:type/new',
            element: <NewCalendar />,
          },
          {
            path: 'security',
            loader: securityLoader(queryClient),
            element: <Security />,
          },
          {
            path: 'billing',
            loader: billingLoader(queryClient),
            element: <Billing />,
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    element: <NonAuthGuard />,
    children: [
      {
        index: true,
        element: <Auth />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'totp',
        element: <TOTP />,
      },
    ],
  },
  // Aliases
  {
    path: '/login',
    children: [
      {
        index: true,
        element: <Navigate to="/auth?mode=login" replace />,
      },
    ],
  },
  {
    path: '/register',
    children: [
      {
        index: true,
        element: <Navigate to="/auth?mode=signup" replace />,
      },
    ],
  },
  {
    path: '/signup',
    children: [
      {
        index: true,
        element: <Navigate to="/auth?mode=signup" replace />,
      },
    ],
  },

  // 404
  { path: '*', element: <NotFound /> },
])
