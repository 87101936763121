import type { Data } from '../_loaders/hupo-perspectives'

interface OpenEndedAnswersProps {
  answers: Data['answers']
  scoreComments: Data['scoreComments']
  values: Data['values']
}

export const OpenEndedAnswers: React.FC<OpenEndedAnswersProps> = (props) => {
  const { answers, scoreComments, values } = props

  return (
    <div className="mt-8">
      <h3 className="text-lg font-bold">Respondent open-ended answers</h3>
      {answers.length > 0 ? (
        answers.map((answer, index) => (
          <div key={index} className="mb-8 mt-4">
            <p className="text-base font-bold italic">{answer.question}</p>
            {answer.descriptionItems?.length > 0 && (
              <div>
                <ul className="pl-5">
                  {answer.descriptionItems.map((response, index) => (
                    <li key={'descriptionItem' + index} className="italic">
                      - {response}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {answer.responses.managers.length > 0 && (
              <div className="mt-2">
                <p className="text-sm font-bold">Manager</p>
                <ul className="list-disc pl-5">
                  {answer.responses.managers.map((response, index) => (
                    <li key={index}>{response}</li>
                  ))}
                </ul>
              </div>
            )}

            {answer.responses.peers.length > 0 && (
              <div className="mt-2">
                <p className="text-sm font-bold">Peers</p>
                <ul className="list-disc pl-5">
                  {answer.responses.peers.map((response, index) => (
                    <li key={index}>{response}</li>
                  ))}
                </ul>
              </div>
            )}

            {answer.responses.reportees.length > 0 && (
              <div className="mt-2">
                <p className="text-sm font-bold">Reportees</p>
                <ul className="list-disc pl-5">
                  {answer.responses.reportees.map((response, index) => (
                    <li key={index}>{response}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No responses yet</p>
      )}

      {scoreComments && Object.keys(scoreComments).length > 0 ? (
        <h3 className="text-lg font-bold">Respondent score rating comments</h3>
      ) : null}

      {scoreComments && Object.keys(scoreComments).length > 0 ? (
        Object.keys(scoreComments).map(
          (leadershipValueId: string, index: number) => {
            const leadershipValue =
              values[leadershipValueId as keyof typeof values]
            const comments =
              scoreComments[leadershipValueId as keyof Data['scoreComments']]

            return (
              <div key={index} className="mb-8 mt-4">
                <p className="text-base font-bold italic">{leadershipValue}</p>
                <>
                  {comments['peer'] &&
                    Object.keys(comments['peer']).length > 0 && (
                      <div className="mt-2">
                        <p className="text-sm font-bold">Peers</p>
                        <ul className="list-disc pl-5">
                          {Object.keys(comments['peer']).map(
                            (rating: string) => {
                              const responses = comments['peer'][rating]
                              return responses.map(
                                (response: string, index: number) => (
                                  <li key={index}>
                                    {response}
                                    <br />
                                    <i>{' (Score: ' + rating + ' / 6)'}</i>
                                  </li>
                                ),
                              )
                            },
                          )}
                        </ul>
                      </div>
                    )}
                </>
                <>
                  {comments['reportee'] &&
                    Object.keys(comments['reportee']).length > 0 && (
                      <div className="mt-2" key={'score-comments-reportees'}>
                        <p className="text-sm font-bold">Reportees</p>
                        <ul className="list-disc pl-5">
                          {Object.keys(comments['reportee']).map(
                            (rating: string) => {
                              const responses = comments['reportee'][rating]
                              return responses.map(
                                (response: string, index: number) => (
                                  <li key={index}>
                                    {response}
                                    <br />
                                    <i>{' (Score: ' + rating + ' / 6)'}</i>
                                  </li>
                                ),
                              )
                            },
                          )}
                        </ul>
                      </div>
                    )}
                </>
                <>
                  {comments['manager'] &&
                    Object.keys(comments['manager']).length > 0 && (
                      <div className="mt-2" key={'score-comments-reportees'}>
                        <p className="text-sm font-bold">Managers</p>
                        <ul className="list-disc pl-5">
                          {Object.keys(comments['manager']).map(
                            (rating: string) => {
                              const responses = comments['manager'][rating]
                              return responses.map(
                                (response: string, index: number) => (
                                  <li key={index}>
                                    {response}
                                    <br />
                                    <i>{' (Score: ' + rating + ' / 6)'}</i>
                                  </li>
                                ),
                              )
                            },
                          )}
                        </ul>
                      </div>
                    )}
                </>
              </div>
            )
          },
        )
      ) : (
        <p>No responses yet</p>
      )}
    </div>
  )
}
