/* eslint-disable @typescript-eslint/no-explicit-any */
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import Linkify from 'react-linkify'
import { ArrowDownTrayIcon, UserCircleIcon } from '@heroicons/react/24/outline'

import { bytesToKilobytes, bytesToMegabytes } from '~/utils/converters'
import { cn } from '~/utils/cn'
import { isImageUrl } from '~/utils/detect-file'
import { ChatMessage, TeamChatMessage } from '../_loaders/_id'
import { useEffect, useState } from 'react'

interface ChatBubbleProps {
  message: ChatMessage | TeamChatMessage
  prevMessage?: ChatMessage | TeamChatMessage
  isGroupChat: boolean
}

const getSenderPicture = (message: any) => {
  return message.sentBy?.profile?.picture
}

const getSenderName = (message: any) => {
  if (!message) return
  return message.sentBy?.profile?.name
}

const getSenderId = (message: any) => {
  if (!message) return
  return message?.sentBy?._id
}

export const ChatBubble: React.FC<ChatBubbleProps> = (props) => {
  const { message, isGroupChat, prevMessage } = props
  const [name, setName] = useState('')
  // @ts-expect-error TODO: use same interface
  const isMe = message?.sentByModel
    ? // @ts-expect-error TODO: use same interface
      message?.sentByModel === 'Coach'
    : // @ts-expect-error TODO: use same interface
      !message?.sentByUser

  useEffect(() => {
    setName(getSenderName(message))
  }, [message])

  return (
    <div className={cn('flex gap-2', isMe ? 'justify-end' : 'justify-start')}>
      {isGroupChat &&
        !isMe &&
        (getSenderPicture(message) ? (
          <img
            className="h-10 w-10 object-cover flex-none rounded-full bg-gray-50"
            src={getSenderPicture(message)}
            alt={name}
            title={name}
          />
        ) : (
          <UserCircleIcon className="h-10 w-10 flex-none rounded-full text-gray-400" />
        ))}
      <div className="relative flex flex-col gap-1">
        {isGroupChat &&
          !isMe &&
          getSenderId(prevMessage) !== getSenderId(message) && (
            <p className="text-sm text-gray-500">{name}</p>
          )}
        <div
          className={cn(
            'border min-w-[200px] p-2 max-w-[250px] md:max-w-[400px] lg:max-w-[500px] px-3',
            isMe
              ? 'bg-primary-400 border-primary'
              : 'bg-gray-200 border-gray-300',
          )}
          style={{
            borderRadius: isMe ? '12px 0 12px 12px' : '0 12px 12px 12px',
          }}
        >
          {/* @ts-expect-error TODO: use same interface */}
          {message?.file && Object.keys(message?.file).length > 0 && (
            <div
              className={cn(
                'border grid mb-2.5 gap-2 rounded-md p-2',
                isMe
                  ? 'bg-primary-600 border-primary-700'
                  : 'bg-gray-100 border-gray-300',
              )}
            >
              {/* @ts-expect-error TODO: use same interface */}
              {isImageUrl(message?.file.url) && (
                <img
                  // @ts-expect-error TODO: use same interface
                  src={message?.file.url}
                  // @ts-expect-error TODO: use same interface
                  alt={message?.file.name}
                  className="max-h-[250px] w-auto object-contain rounded-md"
                />
              )}
              <div
                className={cn(
                  'flex items-start justify-between gap-2',
                  isMe
                    ? 'bg-primary-600 border-primary-700'
                    : 'bg-gray-100 border-gray-300',
                )}
              >
                <div className="me-2">
                  <span
                    className={cn(
                      'text-xs max-w-[150px] md:max-w-[500px] line-clamp-1 font-medium',
                      isMe ? 'text-gray-50' : 'text-gray-800',
                    )}
                  >
                    {/* @ts-expect-error TODO: use same interface */}
                    {message?.file.name}
                  </span>
                  <span
                    className={cn(
                      'flex text-xs font-normal gap-2',
                      isMe ? 'text-primary-200' : 'text-gray-500',
                    )}
                  >
                    {/* @ts-expect-error TODO: use same interface */}
                    {message?.file.size / 1000 > 1024
                      ? //@ts-expect-error TODO: use same interface
                        bytesToMegabytes(message?.file.size)
                      : //@ts-expect-error TODO: use same interface
                        bytesToKilobytes(message?.file.size)}{' '}
                    {/* @ts-expect-error TODO: use same interface */}
                    {message?.file.size / 1000 > 1024 ? 'MB' : 'KB'}
                  </span>
                </div>
                <div className="inline-flex self-center items-center">
                  <button
                    className={cn(
                      'inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 rounded-lg',
                      isMe ? 'bg-primary-100' : 'bg-gray-200',
                    )}
                    type="button"
                    // @ts-expect-error TODO: use same interface
                    onClick={() => window.open(message?.file?.url)}
                  >
                    <ArrowDownTrayIcon
                      className={cn(
                        'w-5 h-5',
                        isMe ? 'text-primary-500' : 'text-gray-500',
                      )}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
          <p
            className={cn(
              'text-sm break-words whitespace-pre-line pb-3.5',
              isMe ? 'text-white' : 'text-gray-800',
            )}
          >
            <Linkify
              componentDecorator={(href, text) => (
                <a
                  className={cn(
                    'font-medium underline',
                    isMe ? 'text-white' : 'text-blue-500',
                  )}
                  key={href}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </a>
              )}
            >
              {message.message?.text}
            </Linkify>
          </p>
        </div>
        {message.createdAt && (
          <p
            className={cn(
              'pr-1.5 pb-1 text-xs text-gray-500 absolute bottom-0 right-0',
              isMe ? 'text-right text-gray-200' : 'text-left',
            )}
          >
            {format(parseISO(message.createdAt), 'MMMM d, h:mm a')}
          </p>
        )}
      </div>
    </div>
  )
}
