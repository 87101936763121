import { type QueryClient, queryOptions } from '@tanstack/react-query'
import parseISO from 'date-fns/parseISO'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { api } from '~/app/api'

export type FetchedWorkingHours = {
  start: string
  end: string
}[][]

const fetchWorkingHours = () =>
  queryOptions({
    queryKey: ['working-hours'],
    async queryFn() {
      const workingHours = await api
        .url('/coach/schedule')
        .get()
        .json<FetchedWorkingHours>()

      return workingHours.map((day) => {
        return day.map((slot) => {
          const start = parseISO(slot.start)
          const end = parseISO(slot.end)

          return {
            start: utcToZonedTime(start, 'UTC'),
            end: utcToZonedTime(end, 'UTC'),
          }
        })
      })
    },
  })

export const loader = (queryClient: QueryClient) => async () => {
  return await queryClient.fetchQuery(fetchWorkingHours())
}
