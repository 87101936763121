type Params = {
  eventId: string
  referrer?: string
  skipInApp?: boolean
}

export const getUnifiedCallLink = (param: Params) => {
  const { eventId, referrer, skipInApp } = param
  const url = new URL(`https://api.hupo.co/event/join/${eventId}`)
  url.searchParams.append('coach', '1')

  if (skipInApp) {
    url.searchParams.append('skipinapp', '1')
  }

  if (referrer) {
    url.searchParams.append('ref', referrer)
  }

  return url.toString()
}

type TeamCallLinkParams = {
  sessionId: string
  participantId: string
  referrer?: string
  skipInApp?: boolean
}

export const getUnifiedCallLinkTeam = (param: TeamCallLinkParams) => {
  const { sessionId, participantId, referrer, skipInApp } = param
  const url = new URL(
    `https://teamapi.hupo.co/v2/session/join/${sessionId}/${participantId}`,
  )
  url.searchParams.append('coach', '1')

  if (skipInApp) {
    url.searchParams.append('skipinapp', '1')
  }

  if (referrer) {
    url.searchParams.append('ref', referrer)
  }

  return url.toString()
}

export const getUnifiedCallLinkGroup = (param: TeamCallLinkParams) => {
  const { sessionId, participantId, referrer, skipInApp } = param
  const url = new URL(
    `https://groupapi.hupo.co/v2/session/join/${sessionId}/${participantId}`,
  )
  url.searchParams.append('coach', '1')

  if (skipInApp) {
    url.searchParams.append('skipinapp', '1')
  }

  if (referrer) {
    url.searchParams.append('ref', referrer)
  }

  return url.toString()
}
