import type { OverridedMixpanel } from 'mixpanel-browser'
import { createContext } from 'react'
import mixpanel from 'mixpanel-browser'

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  debug: import.meta.env.DEV,
  track_pageview: 'url-with-path-and-query-string',
  persistence: 'localStorage',
  // Opt out of tracking by default in development
  opt_out_tracking_by_default: import.meta.env.DEV,
})

const mixpanelContext = createContext<OverridedMixpanel>(mixpanel)

export { mixpanel, mixpanelContext }
