/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, type LoaderFunctionArgs } from 'react-router-dom'
import { groupApi } from '~/app/api'

type Diagnostics = {
  scores: {
    users: {
      'aligning-with-the-business': number
      'collaborative-excellence': number
      'driving-business-impact': number
      'growth-mindset': number
      'nurturing-high-performing-teams': number
      'self-ownership-accountability': number
    }
    managers: {
      'aligning-with-the-business': number
      'collaborative-excellence': number
      'driving-business-impact': number
      'growth-mindset': number
      'nurturing-high-performing-teams': number
      'self-ownership-accountability': number
    }
  }
  answers: Array<any>
  values: {
    'aligning-with-the-business': string
    'collaborative-excellence': string
    'driving-business-impact': string
    'growth-mindset': string
    'nurturing-high-performing-teams': string
    'self-ownership-accountability': string
  }
}

const fetchGroupDiagnostics = (id: string) =>
  queryOptions({
    queryKey: ['groups', id, 'diagnostics'],
    queryFn: () =>
      groupApi.url(`/coach/groups/${id}/diagnostics`).get().json<Diagnostics>(),
  })

type HighlightedArea = {
  description: string
  score: number
}

const fetchHighlightedAreas = (id: string) =>
  queryOptions({
    queryKey: ['groups', id, 'highlighted-areas'],
    async queryFn() {
      const data = groupApi.url(`/coach/groups/${id}/highlighted-areas`)
      const highest = await data
        .query({ type: 'highest' })
        .get()
        .json<HighlightedArea[]>()
      const lowest = await data
        .query({ type: 'lowest' })
        .get()
        .json<HighlightedArea[]>()
      return { highest, lowest }
    },
  })

type AssessmentStatus = {
  _id: string
  name: string
  picture: string
  email: string
  title: string
  completed: boolean
}

const fetchAssessments = (id: string) =>
  queryOptions({
    queryKey: ['groups', id, 'assessments'],
    async queryFn() {
      const status = await groupApi
        .url(`/coach/groups/${id}/assessment-status`)
        .get()
        .json<AssessmentStatus[]>()
      const answers = await groupApi
        .url(`/coach/groups/${id}/assessment-answers`)
        .get()
        .json<any>()
      return { status, answers }
    },
  })

export const loader =
  (queryClient: QueryClient) => (args: LoaderFunctionArgs) => {
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return defer({
      data: Promise.all([
        queryClient.fetchQuery(fetchGroupDiagnostics(id)),
        queryClient.fetchQuery(fetchHighlightedAreas(id)),
        queryClient.fetchQuery(fetchAssessments(id)),
      ]).then(([diagnostics, highlightedAreas, assessments]) => ({
        diagnostics,
        highlightedAreas,
        assessments,
      })),
    })
  }
