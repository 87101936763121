import type { ApexOptions } from 'apexcharts'
import type { Data } from '../_loaders/hupo-perspectives'
import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { getScoresSafe } from '../_loaders/hupo-perspectives'

type LeadershipValueChartProps = {
  data: Data
  showChart?: boolean
  chartWidth?: number
}

type SelfKeys = keyof Data['scores']['self']
type ManagersKeys = keyof Data['scores']['managers']
type PeersKeys = keyof Data['scores']['peers']
type ReporteesKeys = keyof Data['scores']['reportees']
type RangeKeys = keyof Data['scores']['range']

export const LeadershipValues: React.FC<LeadershipValueChartProps> = (
  props,
) => {
  const { data, showChart = false, chartWidth = 900 } = props
  const [series, setSeries] = useState<ApexOptions['series']>([])
  const [options, setOptions] = useState<ApexOptions>({})

  useEffect(() => {
    if (!data?.scores) {
      return
    }

    const series: ApexOptions['series'] = [
      {
        name: 'Self',
        data: getScoresSafe(data.scores.self),
      },
      {
        name: 'Manager',
        data: getScoresSafe(data.scores.managers),
      },
      {
        name: 'Peers (mean)',
        data: getScoresSafe(data.scores.peers),
      },
      {
        name: 'Reportees (mean)',
        data: getScoresSafe(data.scores.reportees),
      },
    ]

    setSeries(series)

    setOptions({
      chart: {
        height: 450,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      colors: ['#1C7AEB', '#FF4B0A', '#6E6E70', '#EBFF29'],
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: Object.entries(data.values).map(([_, value]) =>
          splitText(value),
        ),
      },
      yaxis: {
        labels: {
          formatter(value) {
            return value ? value.toFixed(1) : '-'
          },
        },
        max: 6,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
    })
  }, [data])

  console.log({ data })

  if (showChart) {
    return (
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width={chartWidth ?? 900}
      />
    )
  }

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-100 text-primary">
        <tr className="divide-x divide-gray-200">
          <th
            scope="col"
            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold sm:pl-2"
          >
            Values
          </th>
          <th
            scope="col"
            className="px-4 py-3.5 text-left text-sm font-semibold"
          >
            Self
          </th>
          <th
            scope="col"
            className="px-4 py-3.5 text-left text-sm font-semibold"
          >
            Manager
          </th>
          <th
            scope="col"
            className="px-4 py-3.5 text-left text-sm font-semibold"
          >
            Peers (mean)
          </th>
          <th
            scope="col"
            className="px-4 py-3.5 text-left text-sm font-semibold"
          >
            Reportees (mean)
          </th>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold sm:pr-2"
          >
            Range
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 text-center bg-white">
        {Object.entries(data.values).map(([key, value]) => (
          <tr key={key} className="text-left divide-x divide-gray-200">
            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-2">
              {value}
            </td>
            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
              {Object.keys(data.scores.self).length > 0 &&
              data.scores.self[key as SelfKeys]
                ? data.scores.self[key as SelfKeys].toFixed(0)
                : '-'}
            </td>
            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
              {Object.keys(data.scores.managers).length > 0 &&
              data.scores.managers[key as ManagersKeys]
                ? data.scores.managers[key as ManagersKeys].toFixed(0)
                : '-'}
            </td>
            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
              {Object.keys(data.scores.peers).length > 0 &&
              data.scores.peers[key as PeersKeys]
                ? data.scores.peers[key as PeersKeys].toFixed(0)
                : '-'}
            </td>
            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
              {Object.keys(data.scores.reportees).length > 0 &&
              data.scores.reportees[key as ReporteesKeys]
                ? data.scores.reportees[key as ReporteesKeys].toFixed(0)
                : '-'}
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-2">
              {Object.keys(data.scores.range).length > 0 &&
              data.scores.range[key as RangeKeys]
                ? `${data.scores.range[key as RangeKeys].min}-${
                    data.scores.range[key as RangeKeys].max
                  }`
                : '-'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

function splitText(text: string) {
  const words = text.split(' ')
  const result = []
  let line = []
  for (let i = 0; i < words.length; i++) {
    if (line.join(' ').length + words[i].length > 18) {
      result.push(line.join(' '))
      line = []
    }
    line.push(words[i])
  }

  result.push(line.join(' '))

  return result
}
