import { format } from 'date-fns-tz'
import { LOCAL_TIMEZONE } from '~/app/constants'
import { store } from '~/app/store'

const timeZones =
  // @ts-expect-error - TS doesn't know about this yet
  typeof Intl.supportedValuesOf === 'function'
    ? // @ts-expect-error - TS doesn't know about this yet
      (Intl.supportedValuesOf('timeZone') as string[])
    : []

let savedTimeZone = LOCAL_TIMEZONE

const unsubscribe = store.subscribe(() => {
  const tz = store.getState().settings.timeZone
  if (tz) {
    savedTimeZone = tz
    unsubscribe()
  }
})

export const timeZoneData = timeZones
  .map((timeZone) => {
    const offset = format(new Date(), 'xxx', { timeZone })

    return { offset, name: timeZone }
  })
  .sort((a, b) => a.offset.localeCompare(b.offset))

export const getSelectedTimeZoneIndex = () => {
  const index = timeZoneData.findIndex((tz) => tz.name === savedTimeZone)
  return index > 0 ? index : 0
}
