import type { Data } from '../_loaders/hupo-perspectives'

interface SelfAnswersProps {
  selfAnswers: Data['selfAnswers']
}

export const SelfAnswers: React.FC<SelfAnswersProps> = (props) => {
  const { selfAnswers } = props

  return (
    <div className="mt-8">
      <h3 className="text-lg font-bold mb-4">Self Answers</h3>
      {selfAnswers && selfAnswers.length > 0 ? (
        <div>
          {selfAnswers.map((answer, index) => (
            <div key={index} className="mb-8">
              <p className="text-base font-bold italic">{answer.question}</p>
              <p className="text-sm">{answer.responses[0]}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No responses yet</p>
      )}
    </div>
  )
}
