import { UserAnswer } from './UserAnswer'

interface UserOnboardingAnswersProps {
  type: string
  data: {
    _id: string
    question: string
    description?: string
    answers: string[]
  }[]
}

export const UserOnboardingAnswers: React.FC<UserOnboardingAnswersProps> = (
  props,
) => {
  const { type, data } = props

  return (
    <div className="my-2 space-y-4">
      <p className="font-bold">{type}</p>
      <ul className="space-y-3">
        {data.map((survey) => (
          <UserAnswer
            key={survey._id}
            question={survey.question}
            description={survey.description}
            answers={survey.answers}
          />
        ))}
      </ul>
    </div>
  )
}
