/* eslint-disable @typescript-eslint/no-explicit-any */
import type { EventSourceInput } from '@fullcalendar/core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { buildOptions } from './fullcalendar'
import { EventContent } from './_components/EventContent'
import { useSettings } from '~/hooks/useSetttings'
import { useAppSelector } from '~/app/hooks'
import { getFormatToken, getTimeFormat } from '~/store/settings.slice'
import { RescheduleDialog } from './_components/RescheduleDialog'
import { DeleteEventAlert } from './_components/DeleteEventAlert'
import { useAuth } from '~/hooks/useAuth'
import { Button } from '~/components/Button'
import { QuickBook } from './_components/QuickBook'
import { QuickBlock } from './_components/QuickBlock'
import { QuickSlot } from './_components/QuickSlot'
import { EventDetails } from './_components/EventDetails'
import { teamApi, groupApi } from '~/app/api'

export interface User {
  id: string
  name: string
  active?: boolean
}

export type EventData = {
  type: string
  id: string
  start: Date | null
  end: Date | null
  other?: Record<string, any>
  ref?: string
}

const initialEventData: EventData = {
  type: '',
  id: '',
  start: null,
  end: null,
  other: {},
}

const teamSessionSource: EventSourceInput = {
  id: 'team-session',
  events: (info, successCallback: any, failureCallback) => {
    teamApi
      .url('/coach/events/standalone')
      .query({
        from: info.start.toISOString(),
        to: info.end.toISOString(),
      })
      .get()
      .json()
      .then(successCallback)
      .catch(failureCallback)
  },
  backgroundColor: '#f0fff4',
  textColor: '#3C78D8',
  borderColor: '#3C78D8',
}

const groupSessionSource: EventSourceInput = {
  id: 'group-session',
  events: (info, successCallback: any, failureCallback) => {
    groupApi
      .url('/coach/events/standalone')
      .query({
        from: info.start.toISOString(),
        to: info.end.toISOString(),
      })
      .get()
      .json()
      .then(successCallback)
      .catch(failureCallback)
  },
  backgroundColor: '#d6eaff',
  textColor: '#0044cc',
  borderColor: '#0044cc',
}

export const Schedule: React.FC = () => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' })
  const landscapeMode = useMediaQuery({
    query: '(max-width:1024px) and (orientation:landscape)',
  })
  const { teamId: teamCoachId, groupId: groupCoachId } = useAuth()
  const { t } = useTranslation('schedule')
  const formatToken = useAppSelector(getFormatToken)
  const timeFormat = useAppSelector(getTimeFormat)
  const calendarRef = useRef<FullCalendar>(null)
  const { timeZone } = useSettings()
  const revertRef = useRef<() => void>(() => {})
  const [eventData, setEventData] = useState<EventData>(initialEventData)
  const [isRescheduling, setIsRescheduling] = useState(false)
  const [isDeletingAlertOpen, setIsDeletingAlertOpen] = useState(false)
  const { isTeamCoach, isGroupCoach, loggedInAsAdmin } = useAuth()

  const [showBookSession, setShowBookSession] = useState(false)
  const [showBlockEvent, setShowBlockEvent] = useState(false)
  const [showOpenSlot, setShowOpenSlot] = useState(false)
  const [showEventDetails, setShowEventDetails] = useState(false)

  useEffect(() => {
    const api = calendarRef.current?.getApi()
    if (!api) return

    const hasTeamSessions = api
      .getEventSources()
      .some((source) => source.id === 'team-session')
    const hasGroupSessions = api
      .getEventSources()
      .some((source) => source.id === 'group-session')

    if (isTeamCoach && !hasTeamSessions) {
      api.addEventSource(teamSessionSource)
    }

    if (isGroupCoach && !hasGroupSessions) {
      api.addEventSource(groupSessionSource)
    }
  }, [isTeamCoach, isGroupCoach])

  const onClose = useCallback(() => {
    revertRef.current()
    setIsRescheduling(false)
    setIsDeletingAlertOpen(false)
    setEventData((prev) => ({
      ...prev,
      id: initialEventData.id,
      type: initialEventData.type,
    }))
  }, [])

  const refetchEvents = useCallback(() => {
    const api = calendarRef.current?.getApi()
    if (!api) return
    api.getEventSourceById('session')?.refetch()
    api.getEventSourceById('block')?.refetch()
    api.getEventSourceById('slot')?.refetch()
  }, [])

  const onEventDeleteClick = (data: EventData) => {
    setEventData(data)
    setIsDeletingAlertOpen(true)
  }

  const onEventRescheduleClick = (data: EventData) => {
    setEventData(data)
    setIsRescheduling(true)
  }

  const options = useMemo(
    () =>
      buildOptions({
        t,
        isMobileScreen,
        landscapeMode,
        timeZone,
        revertRef,
        loggedInAsAdmin,
        setIsRescheduling,
        setShowEventDetails,
        setEventData,
        hour12: timeFormat === '12',
      }),
    [isMobileScreen, timeZone, timeFormat, t, loggedInAsAdmin, landscapeMode],
  )

  return (
    <>
      <div className="sm:flex sm:justify-between sm:items-center max-sm:space-y-4 mb-10">
        <h3 className="text-xl font-semibold text-center">Quick actions</h3>
        <div className="flex flex-wrap gap-5 justify-center">
          <Button onClick={() => setShowBookSession(true)}>
            Book a session
          </Button>
          <Button onClick={() => setShowBlockEvent(true)}>Block time</Button>
          <Button onClick={() => setShowOpenSlot(true)}>Open a slot</Button>
        </div>
      </div>

      <div className="sm:min-h-[180vh]">
        <FullCalendar
          ref={calendarRef}
          {...options}
          eventContent={
            !isMobileScreen
              ? (props) => (
                  <EventContent
                    t={t}
                    formatToken={formatToken}
                    onEventDelete={onEventDeleteClick}
                    onEventReschedule={onEventRescheduleClick}
                    isMobileScreen={false}
                    teamCoachId={teamCoachId}
                    groupCoachId={groupCoachId}
                    timeZone={timeZone}
                    {...props}
                  />
                )
              : undefined
          }
        />
      </div>

      <EventDetails
        isOpen={showEventDetails && (isMobileScreen || landscapeMode)}
        onClose={() => setShowEventDetails(false)}
        onDeleteClick={() => setIsDeletingAlertOpen(true)}
        onRescheduleClick={() => setIsRescheduling(true)}
        event={eventData}
      />

      <QuickBook
        isOpen={showBookSession}
        onClose={() => setShowBookSession(false)}
        onSuccess={refetchEvents}
      />
      <QuickBlock
        isOpen={showBlockEvent}
        onClose={() => setShowBlockEvent(false)}
        onSuccess={refetchEvents}
      />
      <QuickSlot
        isOpen={showOpenSlot}
        onClose={() => setShowOpenSlot(false)}
        onSuccess={refetchEvents}
      />

      <DeleteEventAlert
        isOpen={isDeletingAlertOpen}
        onClose={onClose}
        onDeleted={() => {
          setShowEventDetails(false)
          refetchEvents()
        }}
        eventData={eventData}
      />

      <RescheduleDialog
        eventId={eventData.id}
        isOpen={isRescheduling}
        onClose={onClose}
        onRescheduled={() => {
          setShowEventDetails(false)
          refetchEvents()
        }}
        start={eventData.start}
        end={eventData.end}
      />
    </>
  )
}
