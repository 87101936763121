import { CalendarIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Await, useLoaderData, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import { LoadingSpinner } from '~/Loading'
import { cn } from '~/utils/cn'
import {
  FaAssistiveListeningSystems,
  FaBinoculars,
  FaFlagCheckered,
  FaHeartbeat,
  FaRunning,
  FaShieldAlt,
  FaUsers,
} from './_components/Icons'
import { UserSessionNoteDialog } from './_components/UserSessionNoteDialog'
import { loader, type SessionNote } from './_loaders/session-notes'

const ICONS = {
  FaFlagCheckered: FaFlagCheckered,
  FaHeartbeat: FaHeartbeat,
  FaBinoculars: FaBinoculars,
  FaUsers: FaUsers,
  FaRunning: FaRunning,
  FaAssistiveListeningSystems: FaAssistiveListeningSystems,
  FaShieldAlt: FaShieldAlt,
} as const

type IconName = keyof typeof ICONS

const resolveIcon = (iconName: string) => {
  const Icon = ICONS[iconName as IconName]
  if (!Icon) return null

  return <Icon className="text-white h-6" />
}

export const SessionNotes: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deferredData: any = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >
  let resolvedData = deferredData.data
  if (deferredData.sessionNotes && deferredData.sessionNotes.data) {
    resolvedData = deferredData.sessionNotes.data
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={resolvedData} errorElement={<div>Error</div>}>
        {(data) => <SessionNotesWithData data={data} />}
      </Await>
    </Suspense>
  )
}

export const SessionNotesWithData: React.FC<{ data: SessionNote[] }> = (
  props,
) => {
  const { data } = props
  const { t } = useTranslation(['badges'])
  const { id } = useParams<{ id: string }>()

  const [isSessionNoteModalOpen, setIsSessionNoteModalOpen] = useState(false)
  const [selectedSessionNote, setSelectedSessionNote] = useState({ id: '' })

  let content
  if (data.length === 0) {
    content = (
      <div className="h-full px-4 py-5 space-y-4 sm:px-6 sm:py-2">
        <p className="text-center">No notes yet</p>
      </div>
    )
  } else {
    content = (
      <div className="divide-y-2">
        {data.map((session) => (
          <div
            key={session._id}
            className={cn(
              'pt-4',
              !session.note
                ? 'bg-primary-100 px-2 py-1 my-2 rounded-md'
                : 'first:pt-0',
            )}
          >
            <div className="flex justify-between text-gray-500">
              <p className="flex items-center gap-2">
                <CalendarIcon className="h-5" />
                {format(parseISO(session.start), 'MMM dd, yyyy h:mm a')} (
                {differenceInMinutes(
                  parseISO(session.end),
                  parseISO(session.start),
                )}
                min)
              </p>
            </div>

            <div
              className={cn(
                'mb-4 mt-2',
                !session.note && 'flex items-center justify-between',
              )}
            >
              <p className="break-all whitespace-normal">
                {!session.note ? 'No notes' : session.note.body.text}
              </p>

              {!session.note && (
                <button
                  className="p-1"
                  onClick={() => {
                    setSelectedSessionNote({ id: session._id })
                    setIsSessionNoteModalOpen(true)
                  }}
                >
                  <PlusCircleIcon className="h-6" />
                </button>
              )}
            </div>

            {session.badges.length > 0 && (
              <div className="flex flex-wrap justify-center gap-3 my-3">
                {session.badges.map((badge) => (
                  <button
                    key={badge._id}
                    id={badge._id}
                    data-tooltip-id={badge._id}
                    aria-label={badge.name}
                    className="flex max-sm:p-2.5 items-center justify-center bg-primary-400 h-10 w-10 sm:h-12 sm:w-12 rounded-full"
                  >
                    {resolveIcon(badge.iconName)}
                    <Tooltip id={badge._id} place="top">
                      <p className="max-w-sm">
                        {t(badge.description.replace('badge_', ''), {
                          ns: 'badges',
                        })}
                      </p>
                    </Tooltip>
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg w-full">
        <div className="px-4 py-5 sm:p-6 w-full">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Session notes (shared with users)
          </h3>
        </div>
        <div className="h-full bg-gray-50 px-4 py-5 sm:px-6 sm:py-2">
          {content}
        </div>
      </div>

      <UserSessionNoteDialog
        isOpen={isSessionNoteModalOpen}
        onClose={() => setIsSessionNoteModalOpen(false)}
        userId={id!}
        sessionId={selectedSessionNote.id}
      />
    </>
  )
}
