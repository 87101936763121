import type { ConfiguredMiddleware } from 'wretch'
import wretch from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'
import { toast } from '~/components/ui/use-toast'
import { APP_VERSION } from './constants'
import { token } from './cookie'

let unauthorizedAttempts = -1

const authMiddleware = (): ConfiguredMiddleware => (next) => (url, opts) => {
  if (token) {
    opts.headers = {
      ...opts.headers,
      Authorization: `Bearer ${token}`,
    }
  }

  return next(url, opts)
}

const versionMiddleware: ConfiguredMiddleware = (next) => (url, opts) => {
  opts.headers = {
    ...opts.headers,
    'X-App-Version': APP_VERSION,
    ...(window.isPlaywright ? { 'X-Playwright': 'true' } : {}),
  }

  return next(url, opts)
}

const api = wretch(import.meta.env.VITE_API_BASE)
  .addon(QueryStringAddon)
  .middlewares([authMiddleware(), versionMiddleware])
  .catcherFallback((error) => {
    if (error.status === 401) {
      if (unauthorizedAttempts < 0) {
        return Promise.reject(error)
      }

      unauthorizedAttempts++
    }

    const message = error.json?.message ?? error.message
    toast({
      title: 'Error',
      description: message,
      variant: 'error',
    })

    return Promise.reject(error)
  })

const teamApi = wretch(import.meta.env.VITE_TEAM_API_BASE)
  .addon(QueryStringAddon)
  .middlewares([authMiddleware(), versionMiddleware])

const groupApi = wretch(import.meta.env.VITE_GROUP_API_BASE)
  .addon(QueryStringAddon)
  .middlewares([authMiddleware(), versionMiddleware])

export { api, teamApi, groupApi }
