import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import addMinutes from 'date-fns/addMinutes'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import isAfter from 'date-fns/isAfter'
import format from 'date-fns-tz/format'

export const generateTimeOptions = (TIME_INCREMENT = 30) => {
  const times = []
  const now = new Date()
  const endOfDayDate = endOfDay(now)
  let currentTime = startOfDay(now)

  while (!isAfter(currentTime, endOfDayDate)) {
    times.push(currentTime)
    currentTime = addMinutes(currentTime, TIME_INCREMENT)
  }

  return times
}

export const getOption = (time: Date, is24HourFormat?: boolean) => {
  const formatToken = is24HourFormat ? 'HH:mm' : 'h:mm a'
  return {
    value: time.toISOString(),
    label: format(time, formatToken, { timeZone: 'UTC' }), // Specifies UTC time zone for formatting
  }
}

export const MAX_WORKING_HOURS = 5
export const WORKING_HOUR_OPTIONS = generateTimeOptions()
export const DEFAULT_WORKING_HOURS = [
  {
    start: utcToZonedTime(new Date('2022-01-01T09:00:00Z'), 'UTC'),
    end: utcToZonedTime(new Date('2022-01-01T17:00:00Z'), 'UTC'),
  },
]
