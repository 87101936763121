import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { useAppDispatch } from '~/app/hooks'
import { Button } from '~/components/Button'
import { setOnboardingData } from '~/store/onboarding.slice'
import { useAuth } from '~/hooks/useAuth'
import googleCalendarLogo from '~/assets/google-calendar.svg'
import { useToast } from '~/components/ui/use-toast'

const GOOGLE_CALENDAR_SCOPES = [
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar.events',
]

export const ConnectCalendar: React.FC = () => {
  const { id } = useAuth()
  const { toast } = useToast()
  const form = useForm()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const success = searchParams.get('success')
    if (typeof success === 'string' && success === '1') {
      dispatch(setOnboardingData({ isCalendarConnected: true }))
      toast({
        title: 'Google Calendar connected',
        description: 'Your Google Calendar has been successfully connected.',
        variant: 'success',
      })
      navigate('/onboarding/profile')
    }
  }, [searchParams, toast, dispatch, navigate])

  const syncGoogleCalendar = () => {
    const redirectUri = '/onboarding/connect-calendar?success=1'

    const params = {
      client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      redirect_uri: import.meta.env.VITE_GOOGLE_CALLBACK_URL,
      scope: GOOGLE_CALENDAR_SCOPES.join(' '),
      state: JSON.stringify({
        coachId: id,
        redirect: redirectUri,
        duringOnboarding: true,
      }),
      access_type: 'offline',
      prompt: 'consent',
      // include_granted_scopes: 'true',
      response_type: 'code',
    }

    const form = document.createElement('form')
    form.setAttribute('action', 'https://accounts.google.com/o/oauth2/v2/auth')
    form.setAttribute('method', 'GET')

    for (const p in params) {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', p)
      input.setAttribute('value', params[p as keyof typeof params])
      form.appendChild(input)
    }

    document.body.appendChild(form)
    form.submit()
  }

  const onSubmit = form.handleSubmit(() => {
    dispatch(setOnboardingData({ isCalendarConnected: false }))
    navigate('/onboarding/profile')
  })

  return (
    <form onSubmit={onSubmit}>
      <div className="mt-8 flex justify-between items-center px-4 py-3 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <div className="flex items-center gap-3 w-full">
          <img
            src={googleCalendarLogo}
            alt="Google Calendar Logo"
            className="max-h-6"
          />
          <p>Google Calendar</p>
        </div>

        <Button
          type="button"
          variant="outline"
          className="w-40"
          onClick={syncGoogleCalendar}
        >
          Connect
        </Button>
      </div>

      <div className="mt-10 flex gap-2">
        <Button
          to="/onboarding/working-hours"
          type="button"
          variant="outline"
          className="w-full"
        >
          Back
        </Button>
        <Button type="submit" className="w-full">
          Continue
        </Button>
      </div>
    </form>
  )
}
