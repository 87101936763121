/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react'
import format from 'date-fns/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import parse from 'date-fns/parse'
import addMonths from 'date-fns/addMonths'
import parseISO from 'date-fns/parseISO'
import isFuture from 'date-fns/isFuture'
import getDate from 'date-fns/getDate'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
} from '@heroicons/react/24/outline'
import { Button } from '~/components/Button'
import { cn } from '~/utils/cn'
import { useAppSelector } from '~/app/hooks'
import { getFormatToken } from '~/store/settings.slice'
import {
  getUnifiedCallLink,
  getUnifiedCallLinkGroup,
  getUnifiedCallLinkTeam,
} from '~/utils/links'
import { generateMiniCalendar } from '~/utils/mini-cal'
import { useAuth } from '~/hooks/useAuth'

interface MiniCalendarProps {
  month: string
  events: Record<string, any[]>
  monthChange: (data: string) => void
  daySelect?: (date: string, isToday: boolean) => void
  addEvent: () => void
}

const getEventTitle = (event: any) => {
  if (Array.isArray(event.participants)) {
    return event.participants.length === 1
      ? event.participants[0].profile.name
      : `${event.team?.name} @ ${event.team?.company?.name}`
  }

  return `${event.user.profile.name} @ ${event.user.company.name}`
}

const getEventSubTitle = (event: any) => {
  return event.participants.length === 1
    ? 'Team Leader Session'
    : `Team Session (${event.participants.length} members invited)`
}

export const MiniCalendar: React.FC<MiniCalendarProps> = (props) => {
  const formatToken = useAppSelector(getFormatToken)
  const timeZone = useAppSelector((state) => state.settings.timeZone)
  const { events, month, addEvent, daySelect, monthChange } = props
  const todaysDate = useMemo(
    () => utcToZonedTime(new Date(), timeZone),
    [timeZone],
  )
  const [date, setDate] = useState(format(todaysDate, 'yyyy-MM-dd'))
  const monthStart = useMemo(
    () => parse(month, 'yyyy-MM', todaysDate),
    [month, todaysDate],
  )
  const isSelected = useCallback((day: any) => day.date === date, [date])
  const { teamId: teamCoachId, groupId: groupCoachId } = useAuth()

  const days = useMemo(() => generateMiniCalendar(month), [month])

  const onMonthChange = (dir: 'prev' | 'next') => {
    const newMonth = dir === 'prev' ? -1 : 1
    const data = format(addMonths(monthStart, newMonth), 'yyyy-MM')
    monthChange(data)
  }

  const onDaySelect = (day: any) => {
    setDate(day.date)

    if (!day.isCurrentMonth) {
      const isNextMonth = parse(day.date, 'yyyy-MM-dd', todaysDate) > monthStart
      onMonthChange(isNextMonth ? 'next' : 'prev')
    }

    daySelect?.(day.date, day.isToday)
  }

  const goToday = () => {
    const data = format(todaysDate, 'yyyy-MM')
    setDate(format(todaysDate, 'yyyy-MM-dd'))
    monthChange(data)
  }

  return (
    <div className="max-lg:flex max-lg:h-full max-lg:flex-col">
      <header className="flex items-center justify-between border-b border-gray-200 py-4 max-lg:flex-none">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          <time dateTime={month}>
            {format(monthStart, 'MMMM')} {monthStart.getFullYear()}
          </time>
        </h1>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <button
              type="button"
              onClick={() => onMonthChange('prev')}
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={goToday}
              className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={() => onMonthChange('next')}
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            <Button onClick={addEvent}>Book a session</Button>
          </div>
        </div>
      </header>
      <div className="shadow ring-1 ring-black ring-opacity-5 max-lg:flex max-lg:flex-auto max-lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 max-lg:flex-none">
          <div className="bg-white py-2">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 max-lg:flex-auto">
          <div className="isolate grid w-full grid-cols-7 grid-rows-5 gap-px">
            {days.map((day) => (
              <button
                key={day.date}
                type="button"
                className={cn(
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                  (isSelected(day) || day.isToday) && 'font-semibold',
                  isSelected(day) && 'text-white',
                  !isSelected(day) && day.isToday && 'text-primary-600',
                  !isSelected(day) &&
                    day.isCurrentMonth &&
                    !day.isToday &&
                    'text-gray-900',
                  !isSelected(day) &&
                    !day.isCurrentMonth &&
                    !day.isToday &&
                    'text-gray-500',
                  'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10',
                )}
                onClick={() => onDaySelect(day)}
              >
                <time
                  dateTime={day.date}
                  className={cn(
                    isSelected(day) &&
                      'flex h-6 w-6 items-center justify-center rounded-full',
                    isSelected(day) && day.isToday && 'bg-primary-600',
                    isSelected(day) && !day.isToday && 'bg-gray-900',
                    'ml-auto',
                  )}
                >
                  {getDate(parseISO(day.date))}
                </time>
                <span className="sr-only">
                  {(events[day.date] ?? []).length} events
                </span>
                {(events[day.date] ?? []).length > 0 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {events[day.date].map((event: any) => (
                      <span
                        key={event._id}
                        className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
                      />
                    ))}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      {(events[date] ?? []).length > 0 && (
        <div className="py-5">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {events[date].map((event) => (
              <li
                key={event._id}
                className="flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
              >
                <div className="flex-auto">
                  <p className="font-semibold text-gray-900">
                    {getEventTitle(event)}
                  </p>
                  {Array.isArray(event.participants) ? (
                    <p className="text-gray-500">{getEventSubTitle(event)}</p>
                  ) : (
                    <p className="text-gray-500">Individual Session</p>
                  )}
                  <time
                    dateTime="event.start"
                    className="mt-2 flex items-center text-gray-700"
                  >
                    <ClockIcon
                      className="mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {format(
                      utcToZonedTime(parseISO(event.start), timeZone),
                      formatToken,
                    )}{' '}
                    &ndash;{' '}
                    {format(
                      utcToZonedTime(parseISO(event.end), timeZone),
                      formatToken,
                    )}
                  </time>
                </div>
                {isFuture(utcToZonedTime(parseISO(event.start), timeZone)) && (
                  <div className="ml-6 flex-none self-center">
                    <a
                      href={
                        'team' in event // only team session has participants array param
                          ? getUnifiedCallLinkTeam({
                              sessionId: event._id,
                              participantId: teamCoachId,
                              referrer: 'new-coach-app-mini-cal',
                            })
                          : 'group' in event
                            ? getUnifiedCallLinkGroup({
                                sessionId: event._id,
                                participantId: groupCoachId,
                                referrer: 'new-coach-app-mini-cal',
                              })
                            : getUnifiedCallLink({
                                eventId: event._id,
                                referrer: 'new-coach-app-mini-cal',
                              })
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                    >
                      Join
                      <span className="sr-only">Join</span>
                    </a>
                  </div>
                )}
              </li>
            ))}
          </ol>
        </div>
      )}

      {(events[date] ?? []).length === 0 && (
        <div>
          <div className="py-5 mt-5 max-lg:hidden divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            <p className="text-center text-gray-500">No events on this day</p>
          </div>
        </div>
      )}
    </div>
  )
}
