import { useEffect, useMemo, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { cn } from '~/utils/cn'
import { getSelectedTimeZoneIndex, timeZoneData } from '~/utils/tz'

interface TimeZoneSelectProps {
  onChange?: (tz: string) => void
  defaultValue?: string
}

export const TimeZoneSelect: React.FC<TimeZoneSelectProps> = (props) => {
  const { onChange, defaultValue } = props
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState<(typeof timeZoneData)[number]>(
    timeZoneData[0],
  )

  useEffect(() => {
    if (!defaultValue) {
      setSelected(timeZoneData[getSelectedTimeZoneIndex()])
    } else {
      const value = timeZoneData.find((tz) => tz.name === defaultValue)
      if (value) setSelected(value)
    }
  }, [defaultValue])

  const filteredOptions = useMemo(() => {
    if (query === '') return timeZoneData
    return timeZoneData.filter((tz) =>
      tz.name.toLowerCase().includes(query.toLowerCase()),
    )
  }, [query])

  return (
    <Combobox
      as="div"
      by="name"
      value={selected}
      onChange={(data) => {
        onChange?.(data.name)
        setSelected(data)
      }}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        Timezone
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(tz: typeof selected) => tz?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute w-full z-[101] mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.name}
                value={option}
                className={({ active }) =>
                  cn(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-primary-600 text-white' : 'text-gray-900',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <span
                        className={cn(
                          selected ? 'font-semibold' : 'font-normal',
                          'truncate',
                        )}
                      >
                        {option.name}
                      </span>
                      <span
                        className={cn(
                          active ? 'text-primary-200' : 'text-gray-500',
                          'ml-2 truncate',
                        )}
                      >
                        {option.offset}
                      </span>
                    </div>

                    {selected ? (
                      <span
                        className={cn(
                          active ? 'text-white' : 'text-primary-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
