/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk } from '~/app/store'
import { createAppSlice } from '~/app/createAppSlice'
import { addNotification, incUnreadMessagesCount, setUIData } from './ui.slice'

export interface SocketSliceState {
  coreConnected: boolean
  coreSocketId: string
  teamConnected: boolean
  groupConnected: boolean
  activeCoreChats: string[]
  activeTeamChats: string[]
  activeGroupChats: string[]

  currentChat: any
  coreChats: any[]
  teamChats: any[]
  groupChats: any[]
}

type AppendMessage = { type: AppType; id: string; message: any }
type MarkAsRead = { type: AppType; id: string; count: number }

const initialState: SocketSliceState = {
  coreConnected: false,
  coreSocketId: '',
  teamConnected: false,
  groupConnected: false,
  activeCoreChats: [],
  activeTeamChats: [],
  activeGroupChats: [],

  currentChat: null,
  coreChats: [],
  teamChats: [],
  groupChats: [],
}

export const socketSlice = createAppSlice({
  name: 'socket',
  initialState,
  reducers: (create) => ({
    setCoreConnected: create.reducer(
      (state, action: PayloadAction<boolean>) => {
        state.coreConnected = action.payload
      },
    ),
    setCoreSocketId: create.reducer((state, action: PayloadAction<string>) => {
      state.coreSocketId = action.payload
    }),
    setTeamConnected: create.reducer(
      (state, action: PayloadAction<boolean>) => {
        state.teamConnected = action.payload
      },
    ),
    setActiveCoreChats: create.reducer(
      (state, action: PayloadAction<SocketSliceState['activeCoreChats']>) => {
        state.activeCoreChats = action.payload
      },
    ),
    setActiveTeamChats: create.reducer(
      (state, action: PayloadAction<SocketSliceState['activeTeamChats']>) => {
        state.activeTeamChats = action.payload
      },
    ),
    setGroupConnected: create.reducer(
      (state, action: PayloadAction<boolean>) => {
        state.groupConnected = action.payload
      },
    ),
    setActiveGroupChats: create.reducer(
      (state, action: PayloadAction<SocketSliceState['activeGroupChats']>) => {
        state.activeGroupChats = action.payload
      },
    ),
    setCurrentChat: create.reducer(
      (state, action: PayloadAction<object | null>) => {
        state.currentChat = action.payload
      },
    ),
    appendMessageToCurrentChat: create.reducer(
      (state, action: PayloadAction<AppendMessage>) => {
        const id = action.payload.id
        const type = action.payload.type
        const message = action.payload.message
        const currentChat = state.currentChat

        let currentChatIsActive = false

        switch (type) {
          case 'core': {
            const chat = state.coreChats.find((chat) => chat.user.id === id)

            if (chat) {
              chat.unread += currentChat?.user?._id === id ? 0 : 1
              chat.lastMessage = {
                date: message.createdAt,
                text: message.message.text,
              }
            }

            currentChatIsActive = currentChat?.user?._id === id
            break
          }

          case 'team': {
            const chat = state.teamChats.find((chat) => chat.chat.id === id)

            if (chat) {
              chat.unread += currentChat?.chat?._id === id ? 0 : 1
              chat.lastMessage = {
                date: message.createdAt,
                text: message.message.text,
              }
            }

            currentChatIsActive = currentChat?.chat?._id === id
            break
          }

          case 'group': {
            const chat = state.groupChats.find((chat) => chat.chat.id === id)

            if (chat) {
              chat.unread += currentChat?.chat?._id === id ? 0 : 1
              chat.lastMessage = {
                date: message.createdAt,
                text: message.message.text,
              }
            }

            currentChatIsActive = currentChat?.chat?._id === id
            break
          }
        }

        if (!currentChat || !currentChatIsActive) return
        const isAlreadyContains = currentChat.messages.some(
          (msg: any) => msg._id === message._id,
        )

        if (!isAlreadyContains) {
          state.currentChat.messages = [...state.currentChat.messages, message]
        }
      },
    ),

    markAsRead: create.reducer((state, action: PayloadAction<MarkAsRead>) => {
      const id = action.payload.id
      const type = action.payload.type
      const count = action.payload.count

      switch (type) {
        case 'core': {
          const chat = state.coreChats.find((chat) => chat.user.id === id)
          if (!chat) return

          chat.unread -= count
          break
        }

        case 'team': {
          const chat = state.teamChats.find((chat) => chat.chat.id === id)
          if (!chat) return

          chat.unread = count
          break
        }

        case 'group': {
          const chat = state.groupChats.find((chat) => chat.chat.id === id)
          if (!chat) return

          chat.unread = count
          break
        }
      }
    }),
    // ---
    setCoreChats: create.reducer(
      (state, action: PayloadAction<SocketSliceState['coreChats']>) => {
        state.coreChats = action.payload
      },
    ),
    appendCoreChat: create.reducer((state, action: PayloadAction<object>) => {
      state.coreChats = state.coreChats.concat(action.payload)
    }),
    setTeamChats: create.reducer(
      (state, action: PayloadAction<SocketSliceState['teamChats']>) => {
        state.teamChats = action.payload
      },
    ),
    setGroupChats: create.reducer(
      (state, action: PayloadAction<SocketSliceState['groupChats']>) => {
        state.groupChats = action.payload
      },
    ),
  }),
})

export const {
  setCoreConnected,
  setTeamConnected,
  setGroupConnected,
  setActiveCoreChats,
  setActiveTeamChats,
  setActiveGroupChats,
  setCoreSocketId,
  setCurrentChat,
  // ---
  setCoreChats,
  appendCoreChat,
  setTeamChats,
  setGroupChats,
} = socketSlice.actions

export const appendMessageToCurrentChat =
  (payload: AppendMessage): AppThunk =>
  (dispatch, getState) => {
    const { currentChat, coreChats, teamChats, groupChats } = getState().socket
    let incAmount = 0

    switch (payload.type) {
      case 'core': {
        incAmount = currentChat?.user?._id === payload.id ? 0 : 1
        break
      }
      case 'team': {
        incAmount = currentChat?.chat?._id === payload.message.chat ? 0 : 1
        break
      }
      case 'group': {
        incAmount = currentChat?.chat?._id === payload.message.chat ? 0 : 1
        break
      }
    }

    dispatch(socketSlice.actions.appendMessageToCurrentChat(payload))

    if (incAmount <= 0) return

    dispatch(
      incUnreadMessagesCount({
        type: payload.type,
        amount: incAmount,
      }),
    )

    switch (payload.type) {
      case 'core': {
        const chat = coreChats.find((chat) => chat.user.id === payload.id)
        if (chat) {
          dispatch(
            addNotification({
              message: payload.message.message.text,
              name: [chat.user.name, chat.user.company].join(' @ '),
              picture: chat.user.picture,
              replyLink: ['/chats', 'core', chat.user.id].join('/'),
            }),
          )
        }
        break
      }

      case 'team':
      case 'group': {
        const chat = (payload.type === 'team' ? teamChats : groupChats).find(
          (chat) => chat.chat.id === payload.message.chat,
        )
        if (chat && chat.chat.isPrivate) {
          dispatch(
            addNotification({
              message: payload.message.message.text,
              name: chat.chat.title,
              picture: chat.chat.picture,
              replyLink: ['/chats', payload.type, chat.chat.id].join('/'),
            }),
          )
        } else if (chat) {
          dispatch(
            addNotification({
              message: payload.message.message.text,
              name: [
                payload.message.sentBy?.profile?.name,
                chat.chat.title,
              ].join(' @ '),
              picture: chat.chat.picture,
              replyLink: ['/chats', payload.type, chat.chat.id].join('/'),
              isGroup: true,
            }),
          )
        }
        break
      }
    }
  }

export const markAsRead =
  (payload: MarkAsRead & { totalUnread?: number }): AppThunk =>
  (dispatch) => {
    if (payload.type === 'team' && typeof payload.totalUnread === 'number') {
      dispatch(
        setUIData({
          teamUnreadMessagesCount: payload.totalUnread,
        }),
      )
    }

    if (payload.type === 'group' && typeof payload.totalUnread === 'number') {
      dispatch(
        setUIData({
          groupUnreadMessagesCount: payload.totalUnread,
        }),
      )
    }

    if (payload.type === 'core') {
      dispatch(
        incUnreadMessagesCount({
          type: 'core',
          amount: -payload.count,
        }),
      )
    }

    dispatch(
      socketSlice.actions.markAsRead({
        count: payload.count,
        id: payload.id,
        type: payload.type,
      }),
    )
  }
