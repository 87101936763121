import { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from './app/hooks'
import { useAuth } from './hooks/useAuth'

export const AuthGuard: React.FC<React.PropsWithChildren> = (props) => {
  const { token } = useAuth()
  const location = useLocation()
  const onboardingInProgress = useAppSelector(
    (state) => state.onboarding.inProgress,
  )
  const isOnboarding = useMemo(
    () => location.pathname.startsWith('/onboarding'),
    [location.pathname],
  )

  if (token.length === 0) {
    return <Navigate to="/auth?mode=login" />
  }

  if (onboardingInProgress && !isOnboarding) {
    return <Navigate to="/onboarding/user-settings" />
  }

  return props.children
}
