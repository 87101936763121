import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { teamApi } from '~/app/api'
import { useAppDispatch } from '~/app/hooks'
import { useAuth } from './useAuth'
import { setIsTeamCoach, setTeamId } from '~/store/auth.slice'

export const useTeam = () => {
  const { email, isTeamCoach } = useAuth()
  const dispatch = useAppDispatch()

  const { data: hasTeam } = useQuery({
    initialData: { exists: false, teamsCount: 0 },
    queryKey: ['team', 'exists', email],
    queryFn: () =>
      teamApi
        .url('/coach/merge/exists')
        .query({ email })
        .get()
        .json<{ exists: boolean; teamsCount: number }>(),
  })

  const { data: teamCoach } = useQuery({
    enabled: isTeamCoach,
    queryKey: ['team', 'coach', 'home'],
    queryFn() {
      return teamApi.get('/coach/home').json<{ coach: { _id: string } }>()
    },
  })

  useEffect(() => {
    if (hasTeam.exists) {
      dispatch(setIsTeamCoach(true))
    }
  }, [dispatch, hasTeam])

  useEffect(() => {
    if (teamCoach && isTeamCoach) {
      dispatch(setTeamId(teamCoach.coach._id))
    }
  }, [dispatch, teamCoach, isTeamCoach])

  return {
    hasTeamAccount: isTeamCoach,
  }
}
