import type { EventData } from '../schedule'
import { useMemo } from 'react'
import format from 'date-fns/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { BasicDialog } from '~/components/BasicDialog'
import {
  getUnifiedCallLink,
  getUnifiedCallLinkGroup,
  getUnifiedCallLinkTeam,
} from '~/utils/links'
import { useAuth } from '~/hooks/useAuth'
import { useAppSelector } from '~/app/hooks'
import { getFormatToken } from '~/store/settings.slice'
import { useSettings } from '~/hooks/useSetttings'

interface EventDetailsProps {
  isOpen: boolean
  onClose: () => void
  onDeleteClick: () => void
  onRescheduleClick: () => void
  event: EventData
}

const getTitle = (type?: string) => {
  switch (type) {
    case 'block':
      return 'Block event'
    case 'session':
      return 'Session details'
    case 'open':
      return 'Open slot'
    case 'team-session':
      return 'Team session details'
    case 'group-session':
      return 'Group session details'

    default:
      return 'Event details'
  }
}

export const EventDetails: React.FC<EventDetailsProps> = (props) => {
  const { teamId: teamCoachId, groupId: groupCoachId } = useAuth()
  const { timeZone } = useSettings()
  const formatToken = useAppSelector(getFormatToken)
  const { event, isOpen, onClose, onDeleteClick, onRescheduleClick } = props

  const title = useMemo(() => {
    if (event.other?.title) {
      return event.other.title
    }

    return getTitle(event.type)
  }, [event.other?.title, event.type])

  const editEventButton = useMemo(
    () => (
      <button
        className="relative rounded-full bg-white p-1 text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
        onClick={onRescheduleClick}
      >
        <span className="absolute -inset-1.5" />
        <span className="sr-only">Reschedule the session</span>
        <PencilIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    ),
    [onRescheduleClick],
  )

  const cancelEventButton = useMemo(
    () => (
      <button
        className="relative rounded-full bg-white p-1 text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
        onClick={onDeleteClick}
      >
        <span className="absolute -inset-1.5" />
        <span className="sr-only">Cancel the session</span>
        <TrashIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    ),
    [onDeleteClick],
  )

  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      toolbar={
        event.other?.isPast === true ? undefined : (
          <>
            {event.type === 'session' && editEventButton}
            {cancelEventButton}
          </>
        )
      }
    >
      {event.start && event.end && (
        <p className="mt-5">
          {format(
            utcToZonedTime(event.start, timeZone),
            `EEEE, LLLL d ⋅ ${formatToken}`,
          )}{' '}
          - {format(utcToZonedTime(event.end, timeZone), formatToken)}
        </p>
      )}

      {['session', 'team-session', 'group-session'].includes(event.type) && (
        <a
          className="mt-3 bg-primary hover:bg-primary-400 w-full flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          href={
            event.type === 'group-session'
              ? getUnifiedCallLinkGroup({
                  participantId: groupCoachId,
                  sessionId: event.id,
                  referrer: 'new-coach-app-calendar-mobile',
                })
              : event.type === 'team-session'
                ? getUnifiedCallLinkTeam({
                    participantId: teamCoachId,
                    sessionId: event.id,
                    referrer: 'new-coach-app-calendar-mobile',
                  })
                : getUnifiedCallLink({
                    eventId: event.id,
                    referrer: 'new-coach-app-calendar-mobile',
                  })
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Join call
        </a>
      )}
    </BasicDialog>
  )
}
