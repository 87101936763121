import type { PayloadAction } from '@reduxjs/toolkit'
import type { NotificationProps } from '~/components/notification/notification'
import { createAppSlice } from '~/app/createAppSlice'

export interface UISliceState {
  usersCount: number
  teamsCount: number
  groupsCount: number
  coreUnreadMessagesCount: number
  teamUnreadMessagesCount: number
  groupUnreadMessagesCount: number
  notifications: NotificationProps[]
}

const initialState: UISliceState = {
  usersCount: 0,
  teamsCount: 0,
  groupsCount: 0,
  coreUnreadMessagesCount: 0,
  teamUnreadMessagesCount: 0,
  groupUnreadMessagesCount: 0,
  notifications: [],
}

export const uiSlice = createAppSlice({
  name: 'ui',
  initialState,
  reducers: (create) => ({
    setData: create.reducer(
      (state, action: PayloadAction<Partial<UISliceState>>) => {
        const {
          usersCount,
          teamsCount,
          groupsCount,
          coreUnreadMessagesCount,
          teamUnreadMessagesCount,
          groupUnreadMessagesCount,
        } = action.payload
        state.usersCount = usersCount || state.usersCount
        state.teamsCount = teamsCount || state.teamsCount
        state.groupsCount = groupsCount || state.groupsCount

        state.coreUnreadMessagesCount =
          coreUnreadMessagesCount || state.coreUnreadMessagesCount
        state.teamUnreadMessagesCount =
          teamUnreadMessagesCount || state.teamUnreadMessagesCount
        state.groupUnreadMessagesCount =
          groupUnreadMessagesCount || state.groupUnreadMessagesCount
      },
    ),
    incUnreadMessagesCount: create.reducer(
      (
        state,
        action: PayloadAction<{
          type: AppType
          amount: number
        }>,
      ) => {
        const { type, amount } = action.payload
        let key: keyof UISliceState

        switch (type) {
          case 'core': {
            key = 'coreUnreadMessagesCount'
            break
          }

          case 'team': {
            key = 'teamUnreadMessagesCount'
            break
          }

          case 'group': {
            key = 'groupUnreadMessagesCount'
            break
          }

          default: {
            key = 'coreUnreadMessagesCount'
            break
          }
        }

        state[key] += amount
      },
    ),
    addNotification: create.reducer(
      (state, action: PayloadAction<NotificationProps>) => {
        state.notifications = [action.payload]
      },
    ),
    clearNotifications: create.reducer((state) => {
      state.notifications = []
    }),
  }),
  selectors: {
    totalUnreadMessagesCount: (state) => {
      const total =
        state.coreUnreadMessagesCount +
        state.teamUnreadMessagesCount +
        state.groupUnreadMessagesCount

      return total
    },
  },
})

export const {
  setData: setUIData,
  incUnreadMessagesCount,
  addNotification,
  clearNotifications,
} = uiSlice.actions

export const { totalUnreadMessagesCount } = uiSlice.selectors
