/* TOTP (Time-based One-Time Password) component */

import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { api } from '~/app/api'
import { useAppDispatch } from '~/app/hooks'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { useToast } from '~/components/ui/use-toast'
import { useAuth } from '~/hooks/useAuth'
import { setAuthData } from '~/store/auth.slice'
import { setSettings } from '~/store/settings.slice'
import { AuthHeader } from './_components/AuthHeader'

type Inputs = {
  totp: string
}

type LoginResponse = {
  id: string
  token: string
  email: string
  meta: {
    fullName: string
    avatar: string
    timezone: string
    timeFormat: '12' | '24'
  }
}

export const TOTP: React.FC = () => {
  const { t } = useTranslation('auth')
  const { id } = useAuth()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { toast } = useToast()

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
  } = useForm<Inputs>()

  const { mutate, isPending } = useMutation({
    async mutationFn(data: Inputs) {
      return await api
        .url('/coach/verify-mfa')
        .post({ id, totp: data.totp })
        .json<LoginResponse>()
    },
    onSuccess(data) {
      toast({
        title: 'Success',
        description: t('totp.success'),
        variant: 'success',
      })

      dispatch(
        setAuthData({
          id: data.id,
          token: data.token,
          email: data.email,
          name: data.meta.fullName,
          picture: data.meta.avatar,
        }),
      )
      dispatch(
        setSettings({
          timeFormat: data.meta.timeFormat,
          timeZone: data.meta.timezone,
        }),
      )

      navigate('/')
    },
  })

  const onSubmit = handleSubmit((data) => {
    if (id.length === 0) {
      toast({
        title: 'Error',
        variant: 'error',
        description: 'Invalid user ID. Please log in again.',
      })
      navigate('/login')
    }

    mutate(data)
  })

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <AuthHeader title={t('totp.title')} />

      <div className="mt-10 px-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:px-12 rounded-lg">
          <form className="space-y-4" onSubmit={onSubmit}>
            <Input
              id="totp"
              type="text"
              label={t('totp.label')}
              maxLength={6}
              pattern="[0-9]{6}"
              inputMode="numeric"
              error={errors.totp?.message}
              {...register('totp', { required: true })}
            />

            <p className="text-sm text-gray-500">{t('totp.instructions')}</p>
            <div>
              <Button
                className="w-full"
                type="submit"
                loading={isSubmitting || isPending}
              >
                {t('totp.submit')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
