import { useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useAppDispatch } from '~/app/hooks'
import { api } from '~/app/api'
import { BasicDialog } from '~/components/BasicDialog'
import { Button } from '~/components/Button'
import { TimeZoneSelect } from '~/components/timezone/TimeZoneSelect'
import { useToast } from '~/components/ui/use-toast'
import { setTimeZone } from '~/store/settings.slice'
import { LoadingSpinner } from '~/Loading'

interface TimeZoneChangeAlertProps {
  localTimeZone: string
  savedTimeZone?: string
  isOpen: boolean
  onClose: () => void
}

export const TimeZoneChangeAlert: React.FC<TimeZoneChangeAlertProps> = (
  props,
) => {
  const { localTimeZone, isOpen, onClose } = props
  const { toast } = useToast()
  const dispatch = useAppDispatch()
  const [selected, setSelected] = useState(localTimeZone)

  const { mutate, isPending } = useMutation({
    async mutationFn() {
      await api.url('/coach/profile').patch({ timezone: selected }).json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Timezone updated successfully',
        variant: 'success',
      })
      dispatch(setTimeZone(selected))
      onClose()
    },
  })

  const time = useMemo(
    () =>
      new Date().toLocaleTimeString('en-US', {
        timeZone: selected,
        hour: '2-digit',
        minute: '2-digit',
      }),
    [selected],
  )

  if (isPending)
    return (
      <BasicDialog isOpen={isOpen} onClose={onClose} title="Timezone detected">
        <LoadingSpinner />
      </BasicDialog>
    )

  return (
    <BasicDialog isOpen={isOpen} onClose={onClose} title="Timezone detected">
      <div className="space-y-2">
        <p>
          We detected your timezone as <strong>{localTimeZone}</strong>. If this
          is incorrect, please change it.
        </p>

        <TimeZoneSelect defaultValue={localTimeZone} onChange={setSelected} />

        <p className="text-sm text-gray-500 mt-1.5">
          Current time is: <span className="text-gray-600">{time}</span>
        </p>
      </div>

      <Button className="mt-4 w-full" onClick={() => mutate()}>
        Save
      </Button>
    </BasicDialog>
  )
}
