import { useState } from 'react'
import { useRevalidator } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { api } from '~/app/api'
import { BasicDialog } from '~/components/BasicDialog'
import { Button } from '~/components/Button'
import { Toggle } from '~/components/Toggle'
import { useToast } from '~/components/ui/use-toast'

interface UserSessionNoteDialogProps {
  isOpen: boolean
  onClose: () => void
  sessionId: string
  userId: string
}

type Inputs = {
  note?: string
}

export const UserSessionNoteDialog: React.FC<UserSessionNoteDialogProps> = (
  props,
) => {
  const { isOpen, onClose } = props
  const { revalidate } = useRevalidator()
  const { toast } = useToast()
  const form = useForm<Inputs>()
  const [userShowedUp, setUserShowedUp] = useState(true)

  const { mutate } = useMutation({
    async mutationFn(data: {
      sessionId: string
      userId: string
      note?: string
      userShowedUp: boolean
    }) {
      await api
        .url('/coach/session_notes')
        .post({
          userId: data.userId,
          eventId: data.sessionId,
          userShowedUp: data.userShowedUp,
          body: data.note,
        })
        .json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Note created successfully',
        variant: 'success',
      })
    },
    onSettled() {
      onClose()
      revalidate()
    },
  })

  return (
    <BasicDialog isOpen={isOpen} onClose={onClose} title="Add a session note">
      <form
        className="space-y-4"
        onSubmit={(e) => {
          e.preventDefault()
          const note = form.getValues('note')
          mutate({
            sessionId: props.sessionId,
            userId: props.userId,
            note,
            userShowedUp,
          })
        }}
      >
        <div className="flex gap-3">
          <Toggle
            aria-label="User showed up"
            enabled={userShowedUp}
            setEnabled={setUserShowedUp}
          />
          <p>User showed up</p>
        </div>
        {userShowedUp && (
          <div>
            <label
              htmlFor="session-note"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Session note
            </label>
            <div className="mt-2">
              <textarea
                id="session-note"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                placeholder="Session notes..."
                aria-describedby="session-note-description"
                minLength={5}
                rows={8}
                required
                {...form.register('note')}
              />
            </div>
            <p
              id="session-note-description"
              className="mt-2 text-sm text-gray-500"
            >
              Note will be visible for user and user's future coach(es) (if they
              ever change the coach).
            </p>
          </div>
        )}

        <div className="mt-5">
          <Button className="w-full" type="submit">
            {userShowedUp ? 'Create' : 'Mark as no show'}
          </Button>
        </div>
      </form>
    </BasicDialog>
  )
}
