import { useRevalidator } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from '~/app/api'
import { BasicDialog } from '~/components/BasicDialog'
import { Button } from '~/components/Button'
import { TextArea } from '~/components/TextArea'
import { useToast } from '~/components/ui/use-toast'

interface ReportDialogProps {
  invoiceId: string | null
  open: boolean
  onClose: () => void
}

type Inputs = {
  message: string
}

export const ReportDialog: React.FC<ReportDialogProps> = (props) => {
  const { invoiceId, open, onClose } = props
  const { register, handleSubmit } = useForm<Inputs>()
  const { revalidate } = useRevalidator()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { mutate: reportIssue, isIdle } = useMutation({
    mutationKey: ['invoices', 'report'],
    mutationFn(data: { id: string; message: string }) {
      return api
        .url(`/coach/invoices/${data.id}/report`)
        .post({ message: data.message })
        .json()
    },
    async onSuccess() {
      toast({
        title: 'Invoice reported',
        description: 'The issue has been reported successfully',
        variant: 'success',
      })

      await queryClient.invalidateQueries({
        queryKey: ['invoices'],
      })
      revalidate()
    },
    onSettled: onClose,
  })

  const onSubmit = handleSubmit((data) => {
    if (!invoiceId || !isIdle) return
    reportIssue({ id: invoiceId, message: data.message })
  })

  return (
    <BasicDialog isOpen={open} title="Report an issue" onClose={onClose}>
      <form onSubmit={onSubmit}>
        <p className="text-sm text-gray-500">
          If you have any issues with this invoice, please report it to us. We
          will get back to you as soon as possible.
        </p>

        <div className="mt-4">
          <TextArea
            id="report"
            label="Describe the issue"
            placeholder="Please describe the issue you are having with this invoice."
            rows={3}
            {...register('message')}
          />
        </div>

        <Button type="submit" className="mt-4 w-full">
          Report
        </Button>
      </form>
    </BasicDialog>
  )
}
