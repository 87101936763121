import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '~/app/hooks'
import { AlertDialog } from '~/components/AlertDialog'
import { logout } from '~/store/actions/logout'

interface LogoutAlertProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const LogoutAlert: React.FC<LogoutAlertProps> = (props) => {
  const { isOpen = false, setIsOpen } = props
  const { t } = useTranslation('auth')
  const dispatch = useAppDispatch()

  const logoutHandler = useCallback(() => {
    setIsOpen(false)
    dispatch(logout())
  }, [dispatch, setIsOpen])

  return (
    <AlertDialog
      title={t('logout.title')}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      confirmText={t('logout.confirm')}
      onConfirm={logoutHandler}
    >
      <p className="text-sm text-gray-500">{t('logout.description')}</p>
    </AlertDialog>
  )
}
