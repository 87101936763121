/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { useLoaderData } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import type { Root } from './_loaders/team'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion'

const calculateWidth = (max: number, value: number) => {
  const percentage = (value / max) * 100
  return `${percentage}%`
}

export const TeamFeedback: React.FC = () => {
  const data = useLoaderData() as Root
  const { t } = useTranslation('feedback')

  const hasAnswers = useMemo(
    () => Object.keys(data.answers ?? {}).length > 0,
    [data.answers],
  )

  return (
    <>
      <div className="flex gap-16 py-5 sm:p-6 border-b border-gray-200">
        <h3>Summary</h3>

        <div className="text-gray-500 text-sm">
          <p>
            Average rating: <strong>{data.avgRating}</strong> (out of 5)
          </p>

          <p>
            Feedback submitted: <strong>{data.ratingCount}</strong>
          </p>
        </div>
      </div>

      <div className="space-y-7 py-5 sm:p-6 border-b border-gray-200">
        <h3>Highlights</h3>

        {!hasAnswers && (
          <div className="text-gray-500 text-sm">
            <p>No feedback yet</p>
          </div>
        )}

        {hasAnswers && (
          <div className="space-y-5">
            {Object.entries(data.answers).map(([key]) => (
              <div key={key}>
                <h4 className="sr-only">{t(key.replace(/:/, '_'))}</h4>
                <p className="text-sm font-medium text-gray-900">
                  {t(key.replace(/:/, '_'))}
                </p>
                <div className="flex gap-3">
                  <div className="mt-3 flex-1" aria-hidden={true}>
                    <div className="overflow-hidden rounded-full bg-gray-200">
                      <div
                        className="h-2 rounded-full bg-primary"
                        style={{
                          width: calculateWidth(
                            data.ratingCount,
                            data.answers[key as keyof typeof data.answers],
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <QuestionMarkCircleIcon
                    data-tooltip-id={key}
                    className="cursor-pointer h-5"
                  />
                  <Tooltip id={key} className="mt-1.5">
                    <p className="max-w-sm">
                      Your users picked {t(key.replace(/:/, '_'))} as a
                      highlight of your session{' '}
                      {data.answers[key as keyof typeof data.answers]} out of{' '}
                      {data.ratingCount} times.
                    </p>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {data.data.length > 0 && (
        <div className="py-5 sm:p-6">
          <h3 className="font-medium">Feedback details</h3>

          <Accordion type="multiple" className="mt-5 w-full">
            {data.data.map(({ feedback, session }) => (
              <AccordionItem key={session._id} value={session._id}>
                <AccordionTrigger>{session.title}</AccordionTrigger>
                <AccordionContent>
                  {feedback.length === 0 && (
                    <p className="text-gray-500 text-sm">No feedback yet</p>
                  )}

                  {feedback.map((r) => (
                    <ul
                      key={r.rating._id}
                      className="text-base leading-7 border-b mb-2 pb-1 last:border-none last:mb-0 last:pb-0"
                    >
                      <li>
                        <p>{r.user.profile.name}</p>
                        <span className="text-gray-500">
                          {!r.rating
                            ? 'Not rated'
                            : r.rating.answers
                                .filter(
                                  (a) =>
                                    !['other', 'other:tell_us_more'].includes(
                                      a,
                                    ),
                                )
                                .map((a) => t(a.replace(/:/, '_')))
                                .join(', ')}
                        </span>

                        {r.rating?.otherAnswer && (
                          <p className="text-primary">
                            Other answer: {r.rating.otherAnswer}
                          </p>
                        )}
                      </li>
                    </ul>
                  ))}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      )}
    </>
  )
}
