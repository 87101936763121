import { api, groupApi, teamApi } from '~/app/api'

export const getApi = (type?: AppType) => {
  switch (type) {
    case 'core':
      return api
    case 'team':
      return teamApi
    case 'group':
      return groupApi

    default:
      return api
  }
}
