import type { SelectSingleEventHandler } from 'react-day-picker'
import { useCallback } from 'react'
import { format } from 'date-fns'
import { CalendarIcon } from '@heroicons/react/24/solid'

import { cn } from '~/utils/cn'
import { Button } from '~/components/ui/button'
import { Calendar } from '~/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/popover'

interface Props {
  className?: string
  value?: Date | null
  onSelect?: (date: Date) => void
}

export const DatePicker: React.FC<Props> = (props) => {
  const { className, value, onSelect } = props

  const onSelectHandler: SelectSingleEventHandler = useCallback(
    (date) => {
      if (!date) return
      onSelect?.(date)
    },
    [onSelect],
  )

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            'w-[280px] justify-start text-left font-normal',
            !value && 'text-muted-foreground',
            className,
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? format(value, 'PPP') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 z-[1000]">
        <Calendar
          mode="single"
          selected={value ?? undefined}
          onSelect={onSelectHandler}
          initialFocus={false}
        />
      </PopoverContent>
    </Popover>
  )
}
