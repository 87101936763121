import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import addHours from 'date-fns/addHours'
import { BasicDialog } from '~/components/BasicDialog'
import { Button } from '~/components/Button'
import { useToast } from '~/components/ui/use-toast'
import { api } from '~/app/api'
import { LoadingSpinner } from '~/Loading'
import { useAppSelector } from '~/app/hooks'
import { getFormatToken, getTimeFormat } from '~/store/settings.slice'
import { DatePicker } from '~/components/ui/date-picker'
import { TimePicker } from '~/components/ui/time-picker'

interface QuickSlotProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

const now = new Date()
now.setMinutes(0)

type Inputs = {
  start: Date | null
  end: Date | null
}

export const QuickSlot: React.FC<QuickSlotProps> = (props) => {
  const { isOpen, onClose, onSuccess } = props
  const [defaultStart] = useState(now)
  const [defaultEnd] = useState(addHours(now, 1))
  const timeFormat = useAppSelector(getTimeFormat)
  const timeFormatToken = useAppSelector(getFormatToken)
  const { toast } = useToast()

  const { setValue, watch, handleSubmit } = useForm<Inputs>({
    defaultValues: { start: defaultStart, end: defaultEnd },
  })

  const { mutate: createOpenSlot, isPending } = useMutation({
    mutationKey: ['schedule', 'slot', 'create'],
    mutationFn(data: { start: string; end: string }) {
      return api.url('/coach/events/open').post(data).json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Time slot opened successfully',
        variant: 'success',
      })
      onSuccess()
    },
    onSettled: onClose,
  })

  const onSubmit = handleSubmit((data) => {
    if (!data.start || !data.end) {
      return
    }

    createOpenSlot({
      start: data.start.toISOString(),
      end: data.end.toISOString(),
    })
  })

  if (isPending) {
    return (
      <BasicDialog isOpen={isOpen} onClose={onClose} title="Open a slot">
        <LoadingSpinner />
      </BasicDialog>
    )
  }

  return (
    <BasicDialog isOpen={isOpen} onClose={onClose} title="Open a slot">
      <form className="py-4 pb-0 flex flex-col gap-4" onSubmit={onSubmit}>
        <div className="flex flex-col gap-2.5">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Start
            </label>
            <div className="flex gap-2">
              <DatePicker
                value={watch('start')}
                onSelect={(value) => {
                  setValue('start', value)
                  setValue('end', value)
                }}
              />
              <TimePicker
                value={watch('start')}
                timeFormat={timeFormat}
                formatToken={timeFormatToken}
                onSelect={(value) => {
                  setValue('start', value)
                  setValue('end', addHours(value, 1))
                }}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              End
            </label>
            <div className="flex gap-2">
              <DatePicker
                value={watch('end')}
                onSelect={(value) => {
                  setValue('end', value)
                }}
              />
              <TimePicker
                value={watch('end')}
                timeFormat={timeFormat}
                formatToken={timeFormatToken}
                onSelect={(value) => {
                  setValue('end', value)
                }}
              />
            </div>
          </div>
        </div>

        <Button type="submit" className="mt-5 w-full">
          Open slot
        </Button>
      </form>
    </BasicDialog>
  )
}
