import { useLoaderData } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { loader } from './_loaders/billing'
import { Input } from '~/components/Input'
import { Button } from '~/components/Button'
import { useMutation } from '@tanstack/react-query'
import { api } from '~/app/api'
import { useToast } from '~/components/ui/use-toast'
// import { Select } from '~/components/Select'
// import { useEffect, useMemo } from 'react'

type Inputs = {
  accountHolderName?: string
  bank: string
  bankCode: string
  accountNumber: string
  swiftCode: string
  billingAddress: string
}

// const DEFAULT_CURRENCY = { label: 'Select currency', value: '' }

export const Billing: React.FC = () => {
  const { toast } = useToast()
  // const [currency, setCurrency] =
  //   useState<typeof DEFAULT_CURRENCY>(DEFAULT_CURRENCY)

  const { data } = useLoaderData() as Exclude<
    Awaited<ReturnType<ReturnType<typeof loader>>>,
    Response
  >

  // const { data: currenciesData } = useQuery({
  //   initialData: { data: [] },
  //   queryKey: ['billing', 'currencies'],
  //   queryFn: () =>
  //     api.url('/coach/billing/currencies').get().json<{ data: string[] }>(),
  // })

  const mutation = useMutation({
    mutationKey: ['billing', 'update'],
    mutationFn: (data: Inputs) => api.url('/coach/billing').post(data).json(),
    onSuccess() {
      toast({
        title: 'Billing details updated',
        description: 'Your billing details have been updated',
        variant: 'success',
      })
    },
    onError() {
      toast({
        title: 'Error updating billing details',
        description: 'An error occurred while updating your billing details',
        variant: 'error',
      })
    },
  })

  // const currencies = useMemo(() => {
  //   const data = [DEFAULT_CURRENCY]
  //   data.push(
  //     ...currenciesData.data.map((currency) => ({
  //       value: currency,
  //       label: currency,
  //     })),
  //   )
  //   return data
  // }, [currenciesData.data])

  const form = useForm<Inputs>({
    defaultValues: data
      ? {
          accountHolderName: data.bankDetails.accountHolder,
          bank: data.bankDetails.bank,
          bankCode: data.bankDetails.bankCode,
          accountNumber: data.bankDetails.accountNumber,
          swiftCode: data.bankDetails.swiftCode,
          billingAddress: data.billingAddress,
        }
      : undefined,
  })

  const submitHandler = form.handleSubmit((data) => {
    // if (!currency.value) {
    //   return toast({ title: 'Select a currency', variant: 'warning' })
    // }

    mutation.mutate({ ...data })
  })

  return (
    <div className="divide-y divide-black/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7">Billing details</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Check and update your billing details
          </p>
        </div>

        <form className="md:col-span-2" onSubmit={submitHandler}>
          <div className="space-y-4">
            <Input
              id="account-holder-name"
              label="Account holder name"
              placeholder="Account holder name"
              {...form.register('accountHolderName')}
            />

            <Input
              id="account-number"
              label="Bank account number"
              placeholder="Bank account number"
              {...form.register('accountNumber')}
              required
              description="Please provide the details of the account you wish to receive compensation to."
            />

            <Input
              id="bank"
              label="Bank name"
              placeholder="Bank name"
              required
              {...form.register('bank')}
            />

            <Input
              id="bank-code"
              label="Bank code"
              placeholder="Bank code"
              required
              {...form.register('bankCode')}
            />

            <Input
              id="swift-code"
              label="Swift code"
              placeholder="Swift code"
              {...form.register('swiftCode')}
              required
              description=" Mandatory for processing payments to bank accounts outside Singapore"
            />

            {/* <div>
              <Select
                label="Currency"
                className="mt-0"
                options={currencies}
                onChange={setCurrency}
                value={currency}
              />

              <p className="mt-2 text-sm text-gray-500">
                As per your Hupo Coach Agreement
              </p>
            </div> */}

            <Input
              id="billing-address"
              label="Billing address with postal code"
              placeholder="2 Havelock Rd, Singapore 059763"
              required
              {...form.register('billingAddress')}
              description="Mandatory for processing the payments"
            />
          </div>

          <div className="mt-8 flex">
            <Button className="w-full" type="submit">
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
