/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, useAsyncValue } from 'react-router-dom'
import { UserCircleIcon } from '@heroicons/react/24/outline'

export const RecentUsers: React.FC = () => {
  const recentUsers = useAsyncValue() as any

  return recentUsers.map((user: any) => (
    <li key={user._id} className="py-4">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          {user.profile.picture ? (
            <img
              className="h-10 w-10 object-cover rounded-full"
              src={user.profile.picture}
              alt={user.profile.name}
            />
          ) : (
            <UserCircleIcon
              className="h-10 w-10 text-gray-300"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="min-w-0 flex-1">
          <p className="truncate text-sm font-medium text-gray-900">
            {user.profile.name}
          </p>
          <p className="truncate text-sm text-gray-500">{user.company.name}</p>
        </div>
        <div>
          <Link
            to={`/users/${user._id}`}
            className="inline-flex items-center rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            View
          </Link>
        </div>
      </div>
    </li>
  ))
}

export const RecentUsersFallback: React.FC = () => {
  return <p className="mt-2 text-sm text-gray-600">Loading...</p>
}
