import type { Question } from '~/hooks/useInitNps'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { getAnswers, setNpsAnswer } from '~/store/nps.slice'
import { cn } from '~/utils/cn'

interface Props {
  question: Question
  requiredError: boolean
  resetRequiredError: () => void
}

export const SurveyOptions: React.FC<Props> = (props: Props) => {
  const { question, requiredError, resetRequiredError } = props
  const dispatch = useAppDispatch()
  const answers = useAppSelector(getAnswers)
  if (question.options.length === 0) return null

  return (
    <fieldset className="mt-4">
      <legend className="sr-only">{question.title}</legend>
      <div className="space-y-4">
        {question.options.map((option) => (
          <div key={option._id} className="flex items-center">
            <input
              id={option._id}
              type={question.type === 'multiselect' ? 'checkbox' : 'radio'}
              value={option.value}
              name={question.friendlyID}
              defaultChecked={answers[option._id] === 'true'}
              className={cn(
                'h-4 w-4 border-gray-300 text-primary focus:ring-primary',
                question.type === 'multiselect' && 'rounded',
              )}
              onChange={(e) => {
                dispatch(
                  setNpsAnswer({
                    id: option._id,
                    answer: e.target.checked.toString(),
                  }),
                )

                if (requiredError) {
                  resetRequiredError()
                }
              }}
            />
            <label
              htmlFor={option._id}
              className="ml-3 block text-sm font-medium leading-6 text-gray-900"
            >
              {option.label}
            </label>
          </div>
        ))}

        {requiredError && (
          <p className="text-sm text-red-600">
            Please select at least one option
          </p>
        )}
      </div>
    </fieldset>
  )
}
