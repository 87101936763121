/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, type LoaderFunctionArgs } from 'react-router-dom'
import { api } from '~/app/api'

const fetchOnboardingAnswers = (id: string) =>
  queryOptions({
    queryKey: ['users', id, 'onboarding', 'answers'],
    queryFn: () =>
      api.url(`/coach/customers/${id}/onboarding-answers`).get().json<any>(),
  })

export const loader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return defer({
      data: queryClient
        .fetchQuery(fetchOnboardingAnswers(id))
        .then((data) => data.data),
    })
  }
