import { CheckCircleIcon } from '@heroicons/react/24/outline'

interface UserAnswerProps {
  question: string
  description?: string
  answers: string[]
}

export const UserAnswer: React.FC<UserAnswerProps> = (props) => {
  const { question, description, answers } = props

  return (
    <li>
      <div className="flex items-center gap-1.5">
        <CheckCircleIcon className="h-5 text-green-500" />
        <p>
          <strong>{question}</strong>
        </p>
      </div>

      {description && (
        <p className="ml-6 max-w-md text-gray-400 text-sm">{description}</p>
      )}

      <ul className="list-disc ml-8">
        {answers.map((answer) => (
          <li key={answer}>{answer}</li>
        ))}
      </ul>
    </li>
  )
}
