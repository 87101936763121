import { QueryClient } from '@tanstack/react-query'
import { WretchError } from 'wretch/resolver'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: import.meta.env.PROD,
      refetchOnReconnect: true,
      retryDelay: (retryCount) => Math.min(1000 * 2 ** retryCount, 30000), // 1s, 2s, 4s, 8s, 16s, 30s
      retry(failureCount, error) {
        if (error instanceof WretchError) {
          if (error.status === 401) {
            return failureCount > 2
          }
        }

        return failureCount < 1
      },
    },
  },
})
