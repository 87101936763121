/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { defer, type LoaderFunctionArgs } from 'react-router-dom'

import { api } from '~/app/api'

export type ResponseData = File[]

interface File {
  _id: string
  title: string
  userFile: string
  s3Bucket: string
  createdAt: string
  updatedAt: string
  __v: number
  mimeType: string
  size: number
  publicUrl: string
}

const fetchData = (id: string) =>
  queryOptions({
    queryKey: ['users', id, 'files'],
    queryFn: () =>
      api.url(`/coach/customers/${id}/files`).get().json<ResponseData>(),
  })

export const loader =
  (queryClient: QueryClient) => (args: LoaderFunctionArgs) => {
    const { id } = args.params
    if (!id) throw new Error('No id provided')

    return defer({
      data: queryClient.fetchQuery(fetchData(id)),
    })
  }
