import { createSelector, type PayloadAction } from '@reduxjs/toolkit'
import type { Question } from '~/hooks/useInitNps'
import { createAppSlice } from '~/app/createAppSlice'

export interface NpsSliceState {
  surveyId: string
  sessionId: string
  name: string
  hasPendingNps: boolean
  questions: Question[]
  answers: Record<string, string>
}

const initialState: NpsSliceState = {
  surveyId: '',
  sessionId: '',
  name: '',
  hasPendingNps: false,
  questions: [],
  answers: {},
}

export const npsSlice = createAppSlice({
  name: 'nps',
  initialState,
  reducers: (create) => ({
    setData: create.reducer(
      (state, action: PayloadAction<Partial<NpsSliceState>>) => {
        state.surveyId = action.payload?.surveyId ?? state.surveyId
        state.sessionId = action.payload?.sessionId ?? state.sessionId
        state.name = action.payload?.name ?? state.name
        state.hasPendingNps =
          action.payload?.hasPendingNps ?? state.hasPendingNps
        state.questions = action.payload?.questions ?? state.questions
      },
    ),
    setAnswer: create.reducer(
      (state, action: PayloadAction<{ id: string; answer: string }>) => {
        const { id, answer } = action.payload
        state.answers[id] = answer
      },
    ),
    clearAnswers: create.reducer((state) => {
      state.answers = initialState.answers
    }),
    clearData: create.reducer(() => initialState),
  }),
  selectors: {
    getAnswers: (state) => state.answers,
  },
})

export const getAnswer = (id: string) =>
  createSelector([npsSlice.selectors.getAnswers], (answers) => answers[id])
export const { getAnswers } = npsSlice.selectors
export const {
  setData: setNps,
  clearData: clearNps,
  setAnswer: setNpsAnswer,
  clearAnswers: clearNpsAnswers,
} = npsSlice.actions
