/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect, type LoaderFunctionArgs } from 'react-router-dom'
import { groupApi } from '~/app/api'
import { authenticated } from '~/app/cookie'

export interface Root {
  data: Data
  chat: {
    _id: string
  }
}

export interface Data {
  profile: Profile
  timezone: string
  _id: string
  email: string
  title: string
  group: {
    _id: string
    name: string
  }
  company: {
    _id: string
    name: string
  }
}

export interface Profile {
  name: string
  picture: string
  nickName: string
}

const fetchGroupMember = (groupId: string, userId: string) =>
  queryOptions({
    queryKey: ['groups', groupId, 'members', userId],
    queryFn: () =>
      groupApi.url(`/coach/groups/${groupId}/${userId}`).get().json<Root>(),
  })

export const loader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    if (!authenticated()) {
      return redirect('/auth?mode=login')
    }
    const { id: groupId, userId } = args.params
    if (!userId || !groupId) throw new Error('No id provided')

    return await queryClient.fetchQuery(fetchGroupMember(groupId, userId))
  }
