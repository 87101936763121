/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { redirect } from 'react-router-dom'
import { teamApi } from '~/app/api'
import { authenticated } from '~/app/cookie'

const fetchTeams = () =>
  queryOptions({
    queryKey: ['teams'],
    queryFn() {
      return teamApi.url('/coach/teams').get().json<{ data: any[] }>()
    },
  })

export const loader = (queryClient: QueryClient) => () => {
  if (!authenticated()) {
    return redirect('/auth?mode=login')
  }

  return queryClient.fetchQuery(fetchTeams())
}
