import { useEffect } from 'react'
import { useRevalidator } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { api } from '~/app/api'
import { BasicDialog } from '~/components/BasicDialog'
import { Button } from '~/components/Button'
import { useToast } from '~/components/ui/use-toast'
import { Summary } from '../_loaders/session-summaries'

interface EditSessionSummaryDialogProps {
  isOpen: boolean
  onClose: () => void
  selectedSummary: Summary | null
}

type Inputs = {
  summary: string
}

export const EditSessionSummaryDialog: React.FC<
  EditSessionSummaryDialogProps
> = (props) => {
  const { isOpen, onClose, selectedSummary } = props
  const { revalidate } = useRevalidator()
  const { toast } = useToast()
  const form = useForm<Inputs>()

  // Set the default value for the 'summary' field
  useEffect(() => {
    console.log({ selectedSummary })
    if (selectedSummary) {
      form.setValue('summary', selectedSummary.content)
    }
  }, [selectedSummary, form])

  const { mutate } = useMutation({
    async mutationFn(data: { eventId?: string; summary: string }) {
      if (!data.eventId) {
        return
      }

      await api
        .url(`/coach/session-summaries/edit/${data.eventId}`)
        .post({
          content: data.summary,
        })
        .json()
    },
    onSuccess() {
      toast({
        title: 'Success',
        description: 'Summary verified successfully',
        variant: 'success',
      })
    },
    onSettled() {
      onClose()
      revalidate()
    },
  })

  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Verify session summary"
    >
      <form
        className="space-y-4"
        onSubmit={(e) => {
          e.preventDefault()
          const summary = form.getValues('summary')
          mutate({
            eventId: selectedSummary?.eventId,
            summary,
          })
        }}
      >
        <div>
          <label
            htmlFor="session-summary"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Session summary
          </label>
          <div className="mt-2">
            <textarea
              id="session-summary"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              placeholder="Session summary..."
              aria-describedby="session-summary-description"
              minLength={5}
              rows={8}
              required
              {...form.register('summary')}
            />
          </div>
        </div>

        <div className="flex justify-between mt-5">
          <Button className="w-full mr-2" variant="outline" onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button className="w-full ml-2" type="submit">
            {'Verify'}
          </Button>
        </div>
      </form>
    </BasicDialog>
  )
}
