import wretch from 'wretch'

interface GoogleResponse {
  email: string
  email_verified: boolean
  family_name: string
  given_name: string
  hd: string
  locale: string
  name: string
  picture: string
  sub: string
}

export const fetchUserInfo = async (token: string) => {
  const result = await wretch('https://www.googleapis.com/oauth2/v3/userinfo')
    .auth(`Bearer ${token}`)
    .get()
    .json<GoogleResponse>()
    .catch(() => {
      throw new Error('Failed to fetch user info from Google.')
    })

  return result
}
