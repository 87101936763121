import type { LoaderFunctionArgs } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { getApi } from '~/utils/getApi'

export interface Calendar {
  id: string
  integrationType: string
  externalId: string
  calendarList: CalendarList[]
  createEvents: boolean
}

export interface CalendarList {
  _id: string
  id: string
  checkConflicts: boolean
  summary: string
}

export interface ActiveCalendar {
  _id: string
  createEvents: boolean
  integrationType: string
  externalId: string
  syncedCalendars: CalendarList[]
}

const fetchCalendars = (type: AppType) =>
  queryOptions({
    queryKey: [type, 'calendars'],
    queryFn: () =>
      getApi(type).url('/coach/calendars').get().json<Calendar[]>(),
  })

const fetchActiveCalendar = (type: AppType) =>
  queryOptions({
    queryKey: [type, 'calendars', 'active'],
    queryFn: () =>
      getApi(type).url('/coach/calendars/active').get().json<ActiveCalendar>(),
  })

export const loader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    const type = args.params.type as AppType
    const calendars = await queryClient.fetchQuery(fetchCalendars(type))
    const activeCalendar = await queryClient.fetchQuery(
      fetchActiveCalendar(type),
    )

    return { calendars, activeCalendar }
  }
