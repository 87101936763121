import { useMemo, useState } from 'react'

import { Tabs, TabsList, TabsTrigger } from '~/components/ui/tabs'
import { CoachNotes } from './coach-notes'
import { SessionNotes } from './session-notes'

export const Notes: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('session-notes')

  const content = useMemo(() => {
    switch (selectedTab) {
      case 'session-notes':
        return <SessionNotes />
      case 'coach-notes':
        return <CoachNotes />
      default:
        return null
    }
  }, [selectedTab])

  return (
    <div className="w-full">
      <div className="max-w-md">
        <Tabs defaultValue={selectedTab} onValueChange={setSelectedTab}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="session-notes">
              <span>Session Notes</span>
            </TabsTrigger>
            <TabsTrigger value="coach-notes">
              <span>Coach Notes</span>
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      <div className="mt-4">{content}</div>
    </div>
  )
}
