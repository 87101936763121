import type { FetchedWorkingHours } from '~/routes/settings/_loaders/working-hours'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'

import { DaySchedule } from '../settings/_components/DaySchedule'
import { Button } from '~/components/Button'
import { useDefaultWorkingHours } from '~/hooks/useDefaultWorkingHours'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { setWorkingHours as setWorkingHoursData } from '~/store/onboarding.slice'
import { api } from '~/app/api'

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const generateUniqueKeyByIndex = (
  dataItem: {
    start: Date
    end: Date
  }[],
  index: number,
) => {
  const startString = dataItem.map((item) => item.start.toISOString()).join('')
  const endString = dataItem.map((item) => item.end.toISOString()).join('')
  return `key-${index}-${startString}-${endString}`
}

export const WorkingHoursOnboarding: React.FC = () => {
  const defaultWorkingHours = useDefaultWorkingHours()
  const timeFormat = useAppSelector((state) => state.onboarding.timeFormat)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const form = useForm()
  const [workingHours, setWorkingHours] = useState(defaultWorkingHours)

  const { mutate: updateWorkingHours } = useMutation({
    mutationKey: ['onboarding', 'working-hours'],
    mutationFn(data: FetchedWorkingHours) {
      return api
        .url('/coach/schedule')
        .patch({
          schedule: data,
          duringOnboarding: true,
          dryRun: window.isPlaywright ?? false,
        })
        .json()
    },
    onSuccess() {
      dispatch(setWorkingHoursData(workingHours))
    },
  })

  const onChange = useCallback(
    (day: string, schedule: (typeof workingHours)[number]) => {
      setWorkingHours((prevValue) => {
        const index = days.indexOf(day)
        return [
          ...prevValue.slice(0, index),
          schedule,
          ...prevValue.slice(index + 1),
        ]
      })
    },
    [],
  )

  const onSubmit = form.handleSubmit(() => {
    const data = workingHours.map((day) =>
      day.map((slot) => {
        return {
          start: zonedTimeToUtc(slot.start, 'UTC').toISOString(),
          end: zonedTimeToUtc(slot.end, 'UTC').toISOString(),
        }
      }),
    )

    updateWorkingHours(data)
    navigate('/onboarding/connect-calendar')
  })

  return (
    <form onSubmit={onSubmit}>
      <div className="mt-6 pt-5 px-4 py-2 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        {workingHours.map((schedule, index) => (
          <DaySchedule
            key={generateUniqueKeyByIndex(schedule, index)}
            schedule={schedule}
            day={days[index]}
            is24HourFormat={timeFormat === '24'}
            onChange={onChange}
          />
        ))}
      </div>

      <div className="mt-10 flex gap-2">
        <Button
          to="/onboarding/user-settings"
          type="button"
          variant="outline"
          className="w-full"
        >
          Back
        </Button>
        <Button type="submit" className="w-full">
          Continue
        </Button>
      </div>
    </form>
  )
}
