import type { Calendar } from '../_loaders/calendars'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BasicDialog } from '~/components/BasicDialog'
import { Button } from '~/components/Button'
import { useToast } from '~/components/ui/use-toast'
import { getApi } from '~/utils/getApi'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  type?: AppType
  calendars: Calendar[]
  hasActiveCalendar: boolean

  onSuccess?: () => void
  onSettled?: () => void
}

export const ActiveCalendarModal: React.FC<Props> = (props) => {
  const {
    type,
    isOpen,
    onClose,
    calendars,
    title,
    hasActiveCalendar,
    onSuccess,
    onSettled,
  } = props
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const { mutate: updateActiveCalendar } = useMutation({
    mutationKey: [type, 'calendars', 'update', 'active-calendar'],
    async mutationFn(calendarId: string) {
      if (calendarId === 'disable') {
        await getApi(type).url('/coach/calendars/disable').post().json()
      } else {
        await getApi(type)
          .url(`/coach/calendars/${calendarId}`)
          .put({ createEvents: true })
          .json()
      }
    },
    async onSuccess() {
      toast({
        title: 'Success',
        description: 'Active calendar updated successfully',
        variant: 'success',
      })
      await queryClient.invalidateQueries({
        queryKey: [type, 'calendars', 'active'],
        exact: true,
      })
      onSuccess && onSuccess()
    },
    onSettled() {
      onSettled && onSettled()
      onClose()
    },
  })

  return (
    <BasicDialog isOpen={isOpen} onClose={onClose} title={title}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          const formData = new FormData(e.target as HTMLFormElement)
          const activeCalendar = formData.get('active-calendar') as string

          updateActiveCalendar(activeCalendar)
        }}
      >
        <label className="text-base font-semibold text-gray-900">
          Active calendar
        </label>
        <p className="text-sm text-gray-500">
          We create a new events to selected calendar
        </p>
        <fieldset className="mt-4">
          <legend className="sr-only">Active calendar</legend>
          <div className="space-y-4">
            {calendars.map((calendar) => (
              <div key={calendar.id} className="flex items-center">
                <input
                  id={calendar.id}
                  name="active-calendar"
                  value={calendar.id}
                  type="radio"
                  defaultChecked={calendar.createEvents}
                  className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                />
                <label
                  htmlFor={calendar.id}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >
                  {calendar.externalId}
                </label>
              </div>
            ))}

            <div className="flex items-center">
              <input
                id="disable-active-calendar"
                name="active-calendar"
                value="disable"
                type="radio"
                defaultChecked={!hasActiveCalendar}
                className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
              />
              <label
                htmlFor="disable-active-calendar"
                className="ml-3 block text-sm font-medium leading-6 text-gray-900"
              >
                Do not add new sessions to a calendar
              </label>
            </div>
          </div>
        </fieldset>

        <Button className="w-full mt-5" type="submit">
          Save
        </Button>
      </form>
    </BasicDialog>
  )
}
