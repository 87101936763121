import { NavLink, Outlet } from 'react-router-dom'
import { useAuth } from '~/hooks/useAuth'

export const Feedback: React.FC = () => {
  const { isTeamCoach, isGroupCoach } = useAuth()

  return (
    <>
      {(isTeamCoach || isGroupCoach) && (
        <>
          <header className="border-b border-black/5">
            <nav className="flex overflow-x-auto py-4">
              <ul
                role="list"
                className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
              >
                <li className="flex-1 text-center">
                  <NavLink
                    to="/feedback/core"
                    className={({ isActive }) =>
                      isActive ? 'text-primary-400' : ''
                    }
                  >
                    Individual
                  </NavLink>
                </li>

                {isTeamCoach && (
                  <li className="flex-1 text-center">
                    <NavLink
                      to="/feedback/team"
                      className={({ isActive }) =>
                        isActive ? 'text-primary-400' : ''
                      }
                    >
                      Team
                    </NavLink>
                  </li>
                )}

                {isGroupCoach && (
                  <li className="flex-1 text-center">
                    <NavLink
                      to="/feedback/group"
                      className={({ isActive }) =>
                        isActive ? 'text-primary-400' : ''
                      }
                    >
                      Group
                    </NavLink>
                  </li>
                )}
              </ul>
            </nav>
          </header>
        </>
      )}

      <div className="overflow-hidden">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg font-semibold leading-6 text-gray-900">
            Feedback
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            This page shows the feedback you have received from your users after
            sessions.
          </p>
        </div>

        <Outlet />
      </div>
    </>
  )
}
