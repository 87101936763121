import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/react'
import format from 'date-fns/format'
import startOfMinute from 'date-fns/startOfMinute'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import { ClockIcon } from '@heroicons/react/24/outline'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

import { cn } from '~/utils/cn'

interface Props {
  className?: string
  formatToken: 'h:mm a' | 'HH:mm'
  timeFormat: '12' | '24'
  value?: Date | null
  onSelect?: (date: Date) => void
}

const hours = Array.from({ length: 24 }, (_, i) => ({
  id: i,
  name: i.toString().padStart(2, '0'),
  type: 'hour',
}))

const hours12 = Array.from({ length: 11 }, (_, i) => ({
  id: i + 1,
  name: (i + 1).toString().padStart(2, '0'),
  type: 'hour',
}))

hours12.unshift({
  id: 12,
  name: '12',
  type: 'hour',
})

const minutes = Array.from({ length: 60 / 5 }, (_, i) => ({
  id: i * 5,
  name: (i * 5).toString().padStart(2, '0'),
  type: 'minute',
}))

const ampm = [
  {
    id: 'am',
    name: 'AM',
    type: 'ampm',
  },
  {
    id: 'pm',
    name: 'PM',
    type: 'ampm',
  },
]

const [am, pm] = ampm

type MinHour = (typeof hours)[number]
type AmPm = (typeof ampm)[number]

const defaultTime = new Date()
defaultTime.setMinutes(0, 0, 0)

const getDefaultHour = (date: Date, timeFormat: Props['timeFormat']) => {
  let result
  if (timeFormat === '24') {
    result = hours.find((item) => item.id === date.getHours())
  } else {
    result = hours12.find((item) => item.id === date.getHours() % 12)
  }

  return result ?? hours[0]
}

const getDefaultMinute = (date: Date) => {
  const result = minutes.find((item) => item.id === date.getMinutes())

  return result ?? minutes[0]
}

const getDefaultAmPm = (date: Date) => (date.getHours() < 12 ? am : pm)

export const TimePicker: React.FC<Props> = (props) => {
  const { className, timeFormat, onSelect } = props
  const incomingValue = useMemo(() => props.value ?? defaultTime, [props.value])
  const [value, setValue] = useState(incomingValue)
  const [selectedHour, setSelectedHour] = useState(
    getDefaultHour(value, timeFormat),
  )
  const [selectedMinute, setSelectedMinute] = useState(getDefaultMinute(value))
  const [currentAmPm, setCurrentAmPm] = useState(getDefaultAmPm(value))

  useEffect(() => {
    setValue(incomingValue)
    setSelectedHour(getDefaultHour(incomingValue, timeFormat))
    setSelectedMinute(getDefaultMinute(incomingValue))
    setCurrentAmPm(getDefaultAmPm(incomingValue))
  }, [incomingValue, timeFormat])

  const handleSelectHour = useCallback(
    (item: MinHour) => {
      setSelectedHour(item)
      const newDate = setHours(value, item.id)
      setValue(newDate)

      return newDate
    },
    [value],
  )

  const handleSelectMinute = useCallback(
    (item: MinHour) => {
      setSelectedMinute(item)
      const newDate = startOfMinute(setMinutes(value, item.id))
      setValue(newDate)

      return newDate
    },
    [value],
  )

  const handleSelectAmPm = useCallback(
    (item: AmPm) => {
      const hours = value.getHours()
      let newHours = hours

      // Correctly adjust hours when switching between AM and PM
      if (item.id === 'pm' && hours < 12) {
        newHours = hours + 12
      } else if (item.id === 'am' && hours >= 12) {
        newHours = hours - 12
      }

      const newDate = setHours(value, newHours)
      setValue(newDate)

      setCurrentAmPm(item)
      return newDate
    },
    [value],
  )

  const handleSelect = useCallback(
    (item: MinHour | AmPm) => {
      let date: Date

      if (item.type === 'hour') {
        date = handleSelectHour(item as MinHour)
      } else if (item.type === 'minute') {
        date = handleSelectMinute(item as MinHour)
      } else {
        date = handleSelectAmPm(item as AmPm)
      }

      onSelect?.(date)
    },
    [handleSelectHour, handleSelectMinute, handleSelectAmPm, onSelect],
  )

  const renderListboxOptions = (
    items: MinHour[] | AmPm[],
    selected: MinHour | AmPm,
  ) => (
    <ListboxOptions
      as="ul"
      transition
      modal={false}
      className={cn(
        'absolute z-[101] mt-1 max-h-60 overflow-y-auto bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
        timeFormat === '24' ? 'w-1/2' : 'w-1/3',
        items[0].type === 'hour' && 'rounded-s-md left-0',
        timeFormat === '12' &&
          items[0].type === 'minute' &&
          'right-[50%] transform translate-x-1/2',
        (items[0].type === 'ampm' ||
          (timeFormat === '24' && items[0].type === 'minute')) &&
          'right-0 rounded-e-md',
      )}
    >
      {items.map((item) => (
        <ListboxOption
          key={item.id}
          as="li"
          value={item}
          className={cn(
            'relative cursor-default lg:py-2 lg:pl-3 lg:pr-9 pr-3 pl-1.5 py-1 text-gray-900',
            item.id === selected.id && 'bg-primary text-white',
          )}
        >
          <span
            className={cn(
              'block truncate',
              item.id === selected.id && 'font-semibold',
            )}
          >
            {item.name}
          </span>
          <span
            className={cn(
              'absolute hidden inset-y-0 right-0 items-center lg:pr-4 text-white ',
              item.id === selected.id && 'flex',
            )}
          >
            <CheckIcon
              className="h-5 w-5 hidden lg:inline-block"
              aria-hidden="true"
            />
          </span>
        </ListboxOption>
      ))}
    </ListboxOptions>
  )

  return (
    <Listbox value={selectedMinute} onChange={handleSelect}>
      <div className={cn('relative w-2/3', className)}>
        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
          <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
            <ClockIcon className="h-5 w-5 text-gray-800" aria-hidden="true" />
          </span>
          <span className="pl-5 block truncate">
            {format(value, props.formatToken)}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        {renderListboxOptions(
          timeFormat === '24' ? hours : hours12,
          selectedHour,
        )}
        {renderListboxOptions(minutes, selectedMinute)}
        {timeFormat === '12' && renderListboxOptions(ampm, currentAmPm)}
      </div>
    </Listbox>
  )
}
