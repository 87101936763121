import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { useLoaderData, useNavigate } from 'react-router-dom'

import { loader } from './_loaders/groups'
import { getUnifiedCallLinkGroup } from '../utils/links'

const formatDate = (date: string) => {
  return format(parseISO(date), 'yyyy-MM-dd')
}

const formatIntervalTime = ({ start, end }: { start: string; end: string }) => {
  return `${format(parseISO(start), 'HH:mm')} - ${format(
    parseISO(end),
    'HH:mm',
  )}`
}

export const Groups: React.FC = () => {
  const navigate = useNavigate()
  const { data: groups } = useLoaderData() as Exclude<
    Awaited<ReturnType<ReturnType<typeof loader>>>,
    Response
  >

  return (
    <>
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Groups
        </h2>
      </div>

      <div className="px-4 sm:px-6 lg:px-8 bg-white sm:rounded-lg sm:shadow">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Company
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Group Facilitator
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Members
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Next Group session
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {groups.map((group) => (
                    <tr
                      key={group._id}
                      className="hover:cursor-pointer"
                      onClick={() => navigate(`/groups/${group._id}/members`)}
                    >
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="text-gray-900">{group.name}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {group.company?.name ?? 'Error (no company)'}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {!group.leader
                            ? 'No Group Facilitator'
                            : group.leader.profile.name}
                        </div>
                        <div className="mt-1 xt-gray-500">
                          {!group.leader ? 'N/A' : group.leader.email}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{group.members}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {group.nextGroupSession && (
                            <a
                              href={getUnifiedCallLinkGroup({
                                participantId: group.nextGroupSession.coach,
                                sessionId: group.nextGroupSession._id,
                                referrer: 'new-coach-app-calendar-desktop',
                              })}
                            >
                              <time
                                dateTime={formatDate(
                                  group.nextGroupSession.start,
                                )}
                              >
                                {formatDate(group.nextGroupSession.start)}
                              </time>
                            </a>
                          )}
                        </div>
                        <div className="mt-1 text-gray-500">
                          {!group.nextGroupSession
                            ? 'N/A'
                            : formatIntervalTime(group.nextGroupSession)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {group.nextIndividualSession && (
                            <time
                              dateTime={formatDate(
                                group.nextIndividualSession.start,
                              )}
                            >
                              {formatDate(group.nextIndividualSession.start)}
                            </time>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}

                  {groups.length === 0 && (
                    <tr>
                      <td
                        colSpan={6}
                        className="px-3 py-5 text-sm text-gray-500 text-center"
                      >
                        No groups yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
