import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import isWithinInterval from 'date-fns/isWithinInterval'
import parseISO from 'date-fns/parseISO'

const now = new Date()

export const generateMiniCalendar = (month: string) => {
  const monthStart = startOfMonth(parseISO(`${month}-01`))
  const monthEnd = endOfMonth(monthStart)

  const calendarMonthStart = startOfWeek(monthStart, { weekStartsOn: 1 })
  const calendarMonthEnd = endOfWeek(monthEnd, { weekStartsOn: 1 })

  const data = []
  let currentDay = new Date(calendarMonthStart)

  while (currentDay <= calendarMonthEnd) {
    const isToday = isSameDay(currentDay, now)
    const isCurrentMonth = isWithinInterval(currentDay, {
      start: monthStart,
      end: monthEnd,
    })

    data.push({
      isToday,
      isCurrentMonth,
      date: format(currentDay, 'yyyy-MM-dd'),
    })

    currentDay = addDays(currentDay, 1)
  }

  return data
}
